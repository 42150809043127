const images = {
	logo: require("../images/logo.png").default,
	cigarette: require("../images/cigarette.jpg").default,
	cigars: require("../images/cigars.jpg").default,
	electronicCigarettes: require("../images/electronicCigarettes.jpg").default,
	heatedTobaccoProducts: require("../images/heatedTobaccoProducts.jpg").default,
	hookah: require("../images/hookah.jpg").default,
	otherTobaccoProducts: require("../images/otherTobaccoProducts.jpg").default,

	// yrbs21 images
	response_shotPatchRing: require("../images/response_shotPatchRing.jpg").default,
	response_IUDimplant: require("../images/response_IUDimplant.jpg").default,
	section_cigs: require("../images/section_cigs.jpg").default,
	section_ecigs_hookah: require("../images/section_ecigs_hookah.jpg").default,
	section_marijuana: require("../images/section_marijuana.jpg").default,
	section_pain_meds: require("../images/section_pain_meds.jpg").default,
	section_cigars: require("../images/question_cigars.jpg").default,
	section_smokeless: require("../images/question_smokeless.jpg").default,
	question_cigars: require("../images/question_cigars.jpg").default,
	question_smokeless: require("../images/question_smokeless.jpg").default,
	yrbs_logo: require("../images/YRBS_Visual_3-Color.jpg").default
};

export default images;