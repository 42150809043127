import React, {useState} from "react";
import "./index.css";
import Typography from "@material-ui/core/Typography";
import {makeStyles, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Message from "../../../components/Message";
import LoadingIndicator from "../../../components/LoadingIndicator";
import strings from "../../../config/strings";
import helpers from "../../../utils/helpers";
import * as yup from "yup";
import {useFormik} from "formik";
import apiService from "../../../services/apiService";
import config from '../../../config'
import {toast} from "react-toastify";
import useWebAppState from "../../../hooks/useWebAppState";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import {useTranslation} from "react-i18next";
import images from "../../../utils/images";
import UserSchoolClass from "../../../database/Tables/UserSchoolClass";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {Visibility} from "@material-ui/icons";

const validationSchema = yup.object({
	accessCode: yup
		.string('Enter your Access Code')
		.required('Access Code is required'),
});

const useStyles = makeStyles({
	root: {
		marginTop: '20px',
		padding: 15,
	}
})
export default function StudentLoginScreen() {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);
	const [surveyLanguage, setSurveyLanguage] = useState(undefined)

	const [values, setValues] = useState({
		password: "",
		showPassword: true,
	});
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const {
		setSurvey,
		setUserSchoolClass,
		setStudentLoginID,
		setSurveyStatus,
		sessionID,
		backupID,
		language,
		setLanguage,
		createApplication,
		jwt
	} = useWebAppState()
	const classes = useStyles();
	const handleLanguage = (
		event,
		newLanguage,
	) => {
		event.preventDefault();
		setLanguage(newLanguage);
	};
	const accessCodeLogin = async (values) => {
		try {
			setIsLoading(true);
			setError(false);
			const hashedPin = helpers.createHash(values.accessCode.toUpperCase());
			const { studentLoginId, userSchoolClass, application, backup, session, applicationSession, token } = await apiService.studentLoginWeb(hashedPin, !jwt)
			createApplication(application, backup, session, applicationSession, token)
			const { survey, isNew, isWeb } = await apiService.beginSurveyWeb(studentLoginId, userSchoolClass.School_id, config.instrument,backupID,sessionID, userSchoolClass.DataCollectorTeamID, surveyLanguage === 'Spanish' ? 2 : 1)
			if(!isWeb) {
				toast.warn(t('ReusedAccessCode'),{
					position: "top-center",
					autoClose: false,
					hideProgressBar: true,
					closeOnClick: true,
					draggable: false,
				})
				setIsLoading(false)
			} else {
				if(survey.isComplete) {
					toast.warn(`You have completed and submitted the ${config.appTitle}. Thank you!`,{
						position: "top-center",
						autoClose: false,
						hideProgressBar: true,
						closeOnClick: true,
						draggable: false,
					})
					setIsLoading(false)
				} else {
					setSurveyStatus(isNew ? config.surveyStatus.NEW : config.surveyStatus.IN_PROGRESS)
					setUserSchoolClass(userSchoolClass)
					setStudentLoginID(studentLoginId)
					setSurvey(survey)
				}
			}
		 } catch (err) {
				setError(true)
				setIsLoading(false)
		 }
	}

	const handleLanguageSelection = (lang) => {
		setLanguage(lang)
		setSurveyLanguage(lang)
	}

	const formik = useFormik({
		initialValues: {
			accessCode: ''
		},
		validationSchema: validationSchema,
		onSubmit:accessCodeLogin
	})


	if(isLoading) {
		return (<LoadingIndicator text={'Validating Access Code and Preparing Survey'}/>)
	}

	if(error) {
		return (
			<Message title={'Access Code Login Error'} text={'There was an issue validating your code. Make sure code is correct. If you are still having issues please reach out to the Data Collector to validate that your code is on this tablet.'} action={() => setError(false)} actionText={'Try Again'} error/>
		)
	}
	console.log("studentLoginID", language);
	if(!language){
		return (
			<div className="screen-container align-center">
				<Typography className=" align-self-center" color="primary" variant="h4">Language Selection Page/Página para Seleccionar tu Idioma</Typography>
				<img src={images.yrbs_logo} alt={helpers.getImageAltText('yrbs_logo')} height="200" width="400"/>
				<div className="form-container-base">
					<Typography gutterBottom className=" align-self-center" variant="body2" color="primary">Please select the language you feel most comfortable using to complete the Youth Risk Behavior Survey (YRBS).</Typography>
					<Typography gutterBottom className=" align-self-center" variant="body2" color="primary">Por favor selecciona el idioma en el que te sientas más cómodo(a) para llenar la Encuesta Nacional sobre Comportamientos de Riesgo (YRBS por sus siglas en inglés).</Typography>

					<Button value='English' classes={{root: classes.root}} className="margin-above-double" color="primary" variant="contained" fullWidth onClick={()=>handleLanguageSelection("English")}>
						<Typography variant="h5">English (Inglés)</Typography>
					</Button>
					<Button value='Spanish' classes={{root: classes.root}} className="margin-above-double" color="primary" variant="contained" fullWidth onClick={()=>handleLanguageSelection("Spanish")}>
						<Typography variant="h5">Spanish (Español)</Typography>
					</Button>
				</div>
			</div>
		)
	}
	return (
		<div className="screen-container align-center">
			<div className="align-self-end">
				<ToggleButtonGroup
					value={language}
					exclusive
					onChange={(e, v) => handleLanguageSelection(v)}
					aria-label="text alignment"
				>
					<ToggleButton value='English'  aria-label="Select English Language Toggle Button" disabled={language==="English"}>
						<Typography variant="h5">{t('EnglishButton')}</Typography>
					</ToggleButton>
					<ToggleButton  value='Spanish' aria-label="Select Spanish Language Toggle Button" disabled={language==="Spanish"}>
						<Typography variant="h5">{t('SpanishButton')}</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
			</div>
			<Typography className=" align-self-center" color="primary" variant="h4">{t('StudentLogin')}</Typography>
			<img src={images.yrbs_logo} alt={helpers.getImageAltText('yrbs_logo')} height="200" width="400"/>

			<div className="form-container-base">
				<Typography gutterBottom className=" align-self-center" variant="body2" color="primary">{t('StudentLoginTitle')}</Typography>
				<form onSubmit={formik.handleSubmit} className="full-width margin-above-double margin-below-double">
					<TextField
						fullWidth
						id="accessCode"
						name="accessCode"
						label={t('AccessCode')}
						type={values.showPassword ? "password" : "text"}
						value={formik.values.accessCode}
						onChange={formik.handleChange}
						error={formik.touched.accessCode && Boolean(formik.errors.accessCode)}
						helperText={formik.touched.accessCode && formik.errors.accessCode && t('AccessCodeError')}
						className="margin-below-double"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										value={'Toggle Access Code Visibility Button'}
									>
										{values.showPassword ? <VisibilityOff aria-label={'Hide Password Icon'}  /> : <Visibility aria-label={'Show Password Icon'}  />}
									</IconButton>
								</InputAdornment>
							)}

						}
					/>
					<Button value={'Start Survey Button'} classes={{root: classes.root}} className="margin-above-double" color="primary" variant="contained" fullWidth type="submit">
						<Typography variant="h5">{t('StudentLoginButton')}</Typography>
					</Button>
				</form>
			</div>
			<div className="form-container-base">
				<Typography variant="body2" gutterBottom>{t('DATA_COLLECTOR_LOGIN_MESSAGE')}</Typography>
			</div>
		</div>
	)
}

