import { db } from "../index";
import BaseTable from "./index";

class Question extends BaseTable {
	constructor() {
		super(db.question);
	}
}

const QuestionTable = new Question();

export default QuestionTable;
