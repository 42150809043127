import { db } from "../index";
import BaseTable from "./index";

const seedData = [
	{
		SiteID: 1,
		ParentSiteID: 0,
		Name: "Demo Location",
		SiteTypeCodeID: 1,
		Active: 0,
		DisplaySortOrder: 1000,
	},
	{
		SiteID: 5,
		ParentSiteID: 1,
		Name: "FedCASIC 2016",
		SiteTypeCodeID: 2,
		Active: 1,
		DisplaySortOrder: 1050,
	},
	{
		SiteID: 6,
		ParentSiteID: 1,
		Name: "ICF Rockville",
		SiteTypeCodeID: 2,
		Active: 1,
		DisplaySortOrder: 1100,
	},
];

class Site extends BaseTable {
	constructor() {
		super(db.site, seedData);
	}
}

const SiteTable = new Site();

export default SiteTable;
