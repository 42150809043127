import databaseService from '../services/datbaseService';
import databaseMapper from '../services/mappers/databaseMapper';
const sjcl = require("sjcl");
const moment = require("moment");

const helpers = {
	instrumentMetadataArr: [],
	sectionMetadataArr: [],
	sectionDescriptorMetadataArr: [],
	questionMetadataArr: [],
	questionDescriptorMetadataArr: [],
	responseMetadataArr: [],
	responseDescriptorMetadataArr: [],
	getImageAltText: (text) => {
		let altText;
		if(text.indexOf('_') !== -1) {
			altText = text.replaceAll('_', ' ')
		} else {
			altText = text.replace(/([A-Z])/g, " $1");
		}

		return altText
	},
	bytesToSize: (bytes) => {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes === 0) return 'n/a';
		const i = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), sizes.length - 1);
		if (i === 0) return `${bytes} ${sizes[i]}`;
		return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
	},
	guid: function () {
		function guidChunk(isSmallChunk) {
			var chunk = (Math.random().toString(16) + "000000000").substr(2, 8);
			return isSmallChunk
				? "-" + chunk.substr(0, 4) + "-" + chunk.substr(4, 4)
				: chunk;
		}
		return (
			guidChunk() +
			guidChunk(true) +
			guidChunk(true) +
			guidChunk()
		).toUpperCase();
	},

	currentDateTime: function () {
		return moment().format("D/M/YYYY H:m:s");
	},

	currentDateTimeUTC: function () {
		return moment.utc().format("YYYY-MM-DD hh:mm:ss");
	},

	createHash: function (str) {
		return sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(str));
	},
	xml2json: function (xml, tab) {
		var X = {
			toObj: function (xml) {
				var o = {};
				if (xml.nodeType == 1) {
					// element node ..
					if (xml.attributes.length)
						// element with attributes  ..
						for (var i = 0; i < xml.attributes.length; i++)
							o["@" + xml.attributes[i].nodeName] = X.escape(
								(xml.attributes[i].nodeValue || "").toString()
							);
					if (xml.firstChild) {
						// element has child nodes ..
						var textChild = 0,
							cdataChild = 0,
							hasElementChild = false;
						for (var n = xml.firstChild; n; n = n.nextSibling) {
							if (n.nodeType == 1) hasElementChild = true;
							else if (
								n.nodeType == 3 &&
								n.nodeValue.match(/[^ \f\n\r\t\v]/)
							)
								textChild++;
							// non-whitespace text
							else if (n.nodeType == 4) cdataChild++; // cdata section node
						}
						if (hasElementChild) {
							if (textChild < 2 && cdataChild < 2) {
								// structured element with evtl. a single text or/and cdata node ..
								X.removeWhite(xml);
								for (
									var n = xml.firstChild;
									n;
									n = n.nextSibling
								) {
									if (n.nodeType == 3)
										// text node
										o["#text"] = X.escape(n.nodeValue);
									else if (n.nodeType == 4)
										// cdata node
										o["#cdata"] = X.escape(n.nodeValue);
									else if (o[n.nodeName]) {
										// multiple occurence of element ..
										if (o[n.nodeName] instanceof Array)
											o[n.nodeName][
												o[n.nodeName].length
											] = X.toObj(n);
										else
											o[n.nodeName] = [
												o[n.nodeName],
												X.toObj(n),
											];
									} // first occurence of element..
									else o[n.nodeName] = X.toObj(n);
								}
							} else {
								// mixed content
								if (!xml.attributes.length)
									o = X.escape(X.innerXml(xml));
								else o["#text"] = X.escape(X.innerXml(xml));
							}
						} else if (textChild) {
							// pure text
							if (!xml.attributes.length)
								o = X.escape(X.innerXml(xml));
							else o["#text"] = X.escape(X.innerXml(xml));
						} else if (cdataChild) {
							// cdata
							if (cdataChild > 1) o = X.escape(X.innerXml(xml));
							else
								for (
									var n = xml.firstChild;
									n;
									n = n.nextSibling
								)
									o["#cdata"] = X.escape(n.nodeValue);
						}
					}
					if (!xml.attributes.length && !xml.firstChild) o = null;
				} else if (xml.nodeType == 9) {
					// document.node
					o = X.toObj(xml.documentElement);
				} else console.log("unhandled node type: " + xml.nodeType);
				return o;
			},
			toJson: function (o, name, ind) {
				var json = name ? '"' + name + '"' : "";
				if (o instanceof Array) {
					for (var i = 0, n = o.length; i < n; i++)
						o[i] = X.toJson(o[i], "", ind + "\t");
					json +=
						(name ? ":[" : "[") +
						(o.length > 1
							? "\n" +
							  ind +
							  "\t" +
							  o.join(",\n" + ind + "\t") +
							  "\n" +
							  ind
							: o.join("")) +
						"]";
				} else if (o == null) json += (name && ":") + "null";
				else if (typeof o == "object") {
					var arr = [];
					for (var m in o)
						arr[arr.length] = X.toJson(o[m], m, ind + "\t");
					json +=
						(name ? ":{" : "{") +
						(arr.length > 1
							? "\n" +
							  ind +
							  "\t" +
							  arr.join(",\n" + ind + "\t") +
							  "\n" +
							  ind
							: arr.join("")) +
						"}";
				} else if (typeof o == "string")
					json += (name && ":") + '"' + o.toString() + '"';
				else json += (name && ":") + o.toString();
				return json;
			},
			innerXml: function (node) {
				var s = "";
				if ("innerHTML" in node) s = node.innerHTML;
				else {
					var asXml = function (n) {
						var s = "";
						if (n.nodeType == 1) {
							s += "<" + n.nodeName;
							for (var i = 0; i < n.attributes.length; i++)
								s +=
									" " +
									n.attributes[i].nodeName +
									'="' +
									(
										n.attributes[i].nodeValue || ""
									).toString() +
									'"';
							if (n.firstChild) {
								s += ">";
								for (var c = n.firstChild; c; c = c.nextSibling)
									s += asXml(c);
								s += "</" + n.nodeName + ">";
							} else s += "/>";
						} else if (n.nodeType == 3) s += n.nodeValue;
						else if (n.nodeType == 4)
							s += "<![CDATA[" + n.nodeValue + "]]>";
						return s;
					};
					for (var c = node.firstChild; c; c = c.nextSibling)
						s += asXml(c);
				}
				return s;
			},
			escape: function (txt) {
				return txt
					.replace(/[\\]/g, "\\\\")
					.replace(/[\"]/g, '\\"')
					.replace(/[\n]/g, "\\n")
					.replace(/[\r]/g, "\\r");
			},
			removeWhite: function (e) {
				e.normalize();
				for (var n = e.firstChild; n; ) {
					if (n.nodeType == 3) {
						// text node
						if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
							// pure whitespace text node
							var nxt = n.nextSibling;
							e.removeChild(n);
							n = nxt;
						} else n = n.nextSibling;
					} else if (n.nodeType == 1) {
						// element node
						X.removeWhite(n);
						n = n.nextSibling;
					} // any other node
					else n = n.nextSibling;
				}
				return e;
			},
		};
		if (xml.nodeType == 9)
			// document node
			xml = xml.documentElement;
		var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
		return (
			"{\n" +
			tab +
			(tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) +
			"\n}"
		);
	},

	getSurveyInstrumentDevice: () => {
		let surveyinstrument = "Unknown";

		if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) 
		{
			surveyinstrument = "Opera";
		}
		else if(navigator.userAgent.indexOf("Chrome") != -1 )
		{
			surveyinstrument = "Chrome";
		}
		else if(navigator.userAgent.indexOf("Safari") != -1)
		{
			surveyinstrument = "Safari";
		}
		else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
		{
			surveyinstrument = "FireFox";
		}
		else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
		{
			surveyinstrument = "IE";
		}  
		else 
		{
			surveyinstrument = "Android";
		}
		return surveyinstrument;
	},	
	// metadata parsing functions

	parseSurveyMetaData:async function (data) {
		// filtering is needed if only one survey's metadata needs to be seeded
		const instrumentIDs  = await databaseService.getSelectedInstrumentID();
		if(!instrumentIDs) return;
		const filteredDataBySelectedInstrument = data.filter((el) => instrumentIDs.includes(el.InstrumentID)); 
		
		// reset the metadata arrays
		this.instrumentMetadataArr = [];
		this.sectionMetadataArr = [];
		this.sectionDescriptorMetadataArr = [];
		this.questionMetadataArr = [];
		this.questionDescriptorMetadataArr = [];
		this.responseMetadataArr = [];
		this.responseDescriptorMetadataArr = [];

		this.parseInstrument(filteredDataBySelectedInstrument);
		this.printMetaDataArr();

		const result = {
			instrumentMetadataArr: this.instrumentMetadataArr,
			sectionMetadataArr: this.sectionMetadataArr,
			sectionDescriptorMetadataArr: this.sectionDescriptorMetadataArr,
			questionMetadataArr: this.questionMetadataArr,
			questionDescriptorMetadataArr: this.questionDescriptorMetadataArr,
			responseMetadataArr: this.responseMetadataArr,
			responseDescriptorMetadataArr: this.responseDescriptorMetadataArr,
		};
		return result;
	},

	parseInstrument: function (instrumentDataObjOrArr) {
		if (!instrumentDataObjOrArr) return;

		if (!Array.isArray(instrumentDataObjOrArr)) {
			instrumentDataObjOrArr = [{ ...instrumentDataObjOrArr }];
		}
		instrumentDataObjOrArr.forEach((instrument) => {
			const { Section, ...Instrument } = instrument;
			this.instrumentMetadataArr.push(Instrument);
			this.parseSection(Section, instrument.InstrumentID);
		});
	},

	parseSection: function (sectionDataObjOrArr, InstrumentID) {
		if (!sectionDataObjOrArr) return;

		if (!Array.isArray(sectionDataObjOrArr)) {
			sectionDataObjOrArr = [{ ...sectionDataObjOrArr }];
		}

		sectionDataObjOrArr.forEach((section) => {
			const { SectionDescriptor, Question, ...SectionData } = section;

			this.sectionMetadataArr.push({...SectionData, InstrumentID: InstrumentID});

			const SectionID = section.SectionID;
			this.parseSectionDescriptor(SectionDescriptor, SectionID);
			this.parseQuestion(Question, SectionID);
		});
	},

	parseSectionDescriptor: function (sectionDescriptorObjOrArr, SectionID) {
		if (!sectionDescriptorObjOrArr) return;

		let tempSectionDescriptorArr = [];
		if (Array.isArray(sectionDescriptorObjOrArr))
			tempSectionDescriptorArr = [...tempSectionDescriptorArr, ...sectionDescriptorObjOrArr];
		else tempSectionDescriptorArr.push(sectionDescriptorObjOrArr);

		this.sectionDescriptorMetadataArr.push(...tempSectionDescriptorArr.map((obj) => databaseMapper.addSectionID(obj, SectionID)));
	},
	parseQuestion: function (questionDataObjOrArr, SectionID) {
		if (!questionDataObjOrArr) return;

		if (!Array.isArray(questionDataObjOrArr)) {
			questionDataObjOrArr = [{ ...questionDataObjOrArr }];
		}

		questionDataObjOrArr.forEach((question) => {
			const { QuestionDescriptor, Response, ...QuestionData } = question;

			this.questionMetadataArr.push({...QuestionData, SectionID: SectionID });

			const QuestionID = question.QuestionID;
			this.parseQuestionDescriptor(QuestionDescriptor, QuestionID);
			this.parseResponse(Response, QuestionID);
		});
	},

	parseQuestionDescriptor: function ( questionDescriptorDataObjOrArr, QuestionID) {
		if (!questionDescriptorDataObjOrArr) return;

		let tempQuestionDescriptorArr = [];

		if (Array.isArray(questionDescriptorDataObjOrArr))
			tempQuestionDescriptorArr = [...tempQuestionDescriptorArr, ...questionDescriptorDataObjOrArr];
		else tempQuestionDescriptorArr.push(questionDescriptorDataObjOrArr);

		this.questionDescriptorMetadataArr.push(...tempQuestionDescriptorArr.map((obj) => databaseMapper.addQuestionID(obj, QuestionID)));
	},

	parseResponse: function (responseDataObjOrArr, QuestionID) {
		if (!responseDataObjOrArr) return;

		if (!Array.isArray(responseDataObjOrArr)) {
			responseDataObjOrArr = [{ ...responseDataObjOrArr }];
		}

		responseDataObjOrArr.forEach((response) => {
			const { ResponseDescriptor, ...ResponseData } = response;

			this.responseMetadataArr.push({...ResponseData, QuestionID: QuestionID});

			const ResponseID = response.ResponseID;
			this.parseResponseDescriptor(ResponseDescriptor, ResponseID);
		});
	},

	parseResponseDescriptor: function (responseDescriptorDataObjOrArr, ResponseID) {

		if (!responseDescriptorDataObjOrArr) 
			return;

		let tempResponseDescriptorArr = [];

		if (Array.isArray(responseDescriptorDataObjOrArr))
			tempResponseDescriptorArr = [...tempResponseDescriptorArr, ...responseDescriptorDataObjOrArr];
		else 
			tempResponseDescriptorArr.push(responseDescriptorDataObjOrArr);

		this.responseDescriptorMetadataArr.push(...tempResponseDescriptorArr.map((obj) => databaseMapper.addResponseID(obj, ResponseID)));

	},


	printMetaDataArr: function () {
		console.log(this.sectionMetadataArr, "Section Metadata Arr");
		console.log(this.sectionDescriptorMetadataArr,"Section Descriptor Metadata Arr");
		console.log(this.questionMetadataArr, "Question Metadata Array");
		console.log(this.questionDescriptorMetadataArr,"Question Descriptor Metadata Arr");
		console.log(this.responseMetadataArr, "Response Metadata Array");
		console.log(this.responseDescriptorMetadataArr,"Response Descriptor Metadata Arr");
	},
};

export default helpers;
