import { db } from "../index";
import BaseTable from "./index";

class Section extends BaseTable {
	constructor() {
		super(db.section);
	}
}

const SectionTable = new Section();

export default SectionTable;
