import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import useLocalStorage from "../useLocalStorage";
import apiService from "../../services/apiService";
import LoadingIndicator from "../../components/LoadingIndicator";
import Message from "../../components/Message";
import i18n from "i18next";

const WebAppContext = createContext({})

export const  WebAppProvider = ({children, config}) => {
    const [applicationID, setApplicationID, clearApplicationID] = useLocalStorage(config.surveyApplicationID, undefined)
    const [backupID, setBackupID, clearBackupID] = useLocalStorage(config.surveyBackupID,undefined)
    const [applicationGUID, setApplicationGUID, clearApplicationGUID] = useLocalStorage(config.surveyApplicationGUID, undefined)
    const [sessionID, setSessionID, clearSessionID] = useLocalStorage(config.surveyApplicationSessionID, undefined)
    const [applicationSession, setApplicationSession, clearApplicationSession] = useLocalStorage(config.surveyApplicationSessionValue, undefined)

    const [language,setLanguage] = useState(undefined)
    const [survey, setSurvey] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [studentLoginID, setStudentLoginID] = useState(undefined)
    const [userSchoolClass, setUserSchoolClass] = useState(undefined)
    const [surveyStatus, setSurveyStatus] = useState(undefined)
    const [jwt, setJWT, clearJWT] = useLocalStorage(config.jwtKey, undefined)


    useEffect(() => {
        if(language==="English"){
            i18n.changeLanguage("en")
        }
        if(language ==="Spanish"){
            i18n.changeLanguage("es")
        }
    }, [language]);
    const logout = useCallback(() => {
        setUserSchoolClass(undefined)
        setStudentLoginID(undefined)
        setSurvey(undefined)
        setSurveyStatus(undefined)
        clearApplicationGUID()
        clearSessionID()
        clearBackupID()
        clearJWT()
        clearApplicationSession()
    }, [setStudentLoginID, setUserSchoolClass, setSurvey])

    const createApplication = useCallback(async (application, backup, session, applicationSession, token) => {
        setApplicationID(application.ApplicationID)
        setApplicationGUID(application.ApplicationGUID)
        setBackupID(backup.BackupID)
        setSessionID(session.LocalApplicationSessionID)
        setApplicationSession(applicationSession)
        setJWT(token)
    },[])

    const memoizedValue = useMemo(() => ({
        applicationID,
        backupID,
        applicationGUID,
        survey,
        setSurvey,
        logout,
        studentLoginID,
        setStudentLoginID,
        userSchoolClass,
        setUserSchoolClass,
        surveyStatus,
        setSurveyStatus,
        sessionID,
        applicationSession,
        setJWT,
        jwt,
        setLanguage,
        language,
        createApplication
    }), [createApplication, applicationID, language,setLanguage, applicationSession, backupID, survey, setSurvey, logout, studentLoginID, setStudentLoginID, userSchoolClass, setUserSchoolClass, surveyStatus, setSurveyStatus, sessionID, setSessionID, setJWT, jwt])


    if(isLoading) {
        return(
            <LoadingIndicator text={`Starting ${config.appTitle}`}/>
        )
    }

    if(isError) {
        return (
            <Message text="Reload page." actionText="Reload" action={() => window.location.reload()} error title="Application Start Error"/>
        )
    }

    return (
        <WebAppContext.Provider value={memoizedValue}>
            {children}
        </WebAppContext.Provider>
    )
}

const useWebAppState = () => {
    const context = useContext(WebAppContext)
    if(context === undefined) {
        throw new Error('useWebAppState must be used within a WebAppStateProvider')
    }

    return context
}

export default  useWebAppState