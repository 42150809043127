import { db } from "../index";
import BaseTable from "./index";

const seedData = [
	{
		SurveyLogCodeID: 1,
		Description: "Created",
	},
	{
		SurveyLogCodeID: 2,
		Description: "Opened",
	},
	{
		SurveyLogCodeID: 3,
		Description: "Closed",
	},
	{
		SurveyLogCodeID: 4,
		Description: "Synched",
	},
	{
		SurveyLogCodeID: 5,
		Description: "New User Assigned",
	},
	{
		SurveyLogCodeID: 6,
		Description: "Comment",
	},
	{
		SurveyLogCodeID: 7,
		Description: "File Attached",
	},
	{
		SurveyLogCodeID: 8,
		Description: "File Removed",
	},
	{
		SurveyLogCodeID: 9,
		Description: "Deleted",
	},
	{
		SurveyLogCodeID: 10,
		Description: "Completed",
	},
	{
		SurveyLogCodeID: 11,
		Description: "Abandoned",
	},
	{
		SurveyLogCodeID: 12,
		Description: "User Assignment Accepted",
	},
	{
		SurveyLogCodeID: 13,
		Description: "User Assignment Rejected",
	},
];
class SurveyLogCode extends BaseTable {
	constructor() {
		super(db.surveyLogCode, seedData);
	}
}

const SurveyLogCodeTable = new SurveyLogCode();

export default SurveyLogCodeTable;
