import { db } from "../index";
import BaseTable from "./index";
import databaseMapper from "../../services/mappers/databaseMapper";
const seedData = [
	{
		"studentid": 2164,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "90226835c000b751c4d7b906a73d540f6f470fdfd10860bfd7c4ebbb25aa8402"
	},
	{
		"studentid": 2165,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "f4956d8a3c195758ee5162def4ee99672965fe2c4887af11de054088dd6e8838"
	},
	{
		"studentid": 2166,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "3d49123ea2cf9ef5bc22c039d4fc5557c6b373d9a0f9050845f48d996fbb404d"
	},
	{
		"studentid": 2167,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "28307634ee9b824b1b8110b521794ce2ffd0c330c447e3aa29a3ad6c1cf9c4db"
	},
	{
		"studentid": 2168,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "0883307c8ba4a7a3b3102cf9a774eb9bb3fd6a58508ba3e5feb8f6bc00726eaa"
	},
	{
		"studentid": 2169,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "d35043458de22aa5471b374eadd671e7362b88259fe5fddabd9f7a82a82ff001"
	},
	{
		"studentid": 2170,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "d50bd9e1766872dfe7955093809921313f652cea79cdefedbdf93ceb3ceaff3e"
	},
	{
		"studentid": 2171,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "cd95abc8fa97afc030a750529fc2bdef25b5516a84b1500bc11251a02cd429e4"
	},
	{
		"studentid": 2172,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "98b69d0b42f6273182e968cedb45ec61403cabc831cf0b380704d9753acc0f4a"
	},
	{
		"studentid": 2173,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "b0243d731eda5faaf9a7e1a6d036737f1691c9f9642a12e85d0a641384230f9e"
	},
	{
		"studentid": 2174,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "34a011c5dda0c494d32b7046deb0ec0d0cd3bd65e8a1c5a8981f95aa14a35a5a"
	},
	{
		"studentid": 2175,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "6e8e14bc3cf68ceac8257bf990e5ea16d2e49f6ad493ea06d532149dedbf1ba0"
	},
	{
		"studentid": 2176,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "1aac8712719fb4839d8b8da348c9f5bba3982c4bd3f42fa5d302f1b534304533"
	},
	{
		"studentid": 2177,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "8be0c6d5e792a6fe367401656b640d7de194332701c1900892091ee3e6781434"
	},
	{
		"studentid": 2178,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "d24074be9eac52e6e86a967aec1a5aa0f71579637daa329ba405cc1ecd5ad3e4"
	},
	{
		"studentid": 2179,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "f1f522f8c99da7d80d23fc8299aaa8e630cdd66190df98b3ee8e47ba4d58c96f"
	},
	{
		"studentid": 2180,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "fa3b5b4a9fb0ee9d73936b92594ab93c151dced0815860daf5fe1f3da837a243"
	},
	{
		"studentid": 2181,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "296d3e72b9879297850c2139b764cb0ccd04e85c92b088de139082ba795e68d6"
	},
	{
		"studentid": 2182,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "0eed921c8ba396388951ce406a37ff84cd977d5d1eb427737561b39be8934811"
	},
	{
		"studentid": 2183,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "3831abe98298d789a5d5c33a0f80b184e0b89948059ce7fe416d32239155eea2"
	},
	{
		"studentid": 2184,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "7a5deafac62b55bfdc76dab03ed44c2a721f7ebd123348a66fcb66f951cc63ed"
	},
	{
		"studentid": 2185,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "8b2550b018b59f5c14df37a10b453127d1c1cef784ace19479fce37668e9d688"
	},
	{
		"studentid": 2186,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "aab92d8ab082c70fe373918eba14d11aaf53043f47548bf4fa969b9b8e92ec85"
	},
	{
		"studentid": 2187,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "a484538165fc583b8f51ab03d763ed496db8929c889255800e8bae812aa5a596"
	},
	{
		"studentid": 2188,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "d84b07801afa25b8397d37752b28dbecbf907219cf2976a7cbd8b303b43dd3c9"
	},
	{
		"studentid": 2189,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "290d9f02001deee3d4b51a638080954a61f4a918aaaa511b557a2dd5565080c1"
	},
	{
		"studentid": 2190,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "bbb5e3a9adfd5e28e77d5517b143b017104c5a14dd60b21de75b210e755f9e04"
	},
	{
		"studentid": 2191,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "34b847c24e7f5af6aeb0734206816ff1c2e3caab91d4d92d0aec97df8ab017f1"
	},
	{
		"studentid": 2192,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "5db3358ee5e5bb5e1d0980afa1de24ae6c87af95f8fc91eb02486e391d076438"
	},
	{
		"studentid": 2193,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "49e416442ed364b772937c6d8fbf9f1f8d77139611515d24af3923123eeb4acc"
	},
	{
		"studentid": 2194,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "1a13e221680cf906c824b7986dc4f4b9b71ebcbf9264d0a339380aeee0d48eb4"
	},
	{
		"studentid": 2195,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "5110da8b035ad97afecb4895cf3ab90cc976a4a5ce4a4c3129491d7ad384c024"
	},
	{
		"studentid": 2196,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "63b2123cee0e5c3ca77410424a4d2c5b0519f6954f01c39c794b191b89b65347"
	},
	{
		"studentid": 2197,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "94cd71c97a9b36fcca66cd4228fde144dfbf5e58c8a5e7d85d8e021986b1a8ab"
	},
	{
		"studentid": 2198,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "b2c98646d5787c498d93a80081beb3c95d564a36e94dad278b13414c6f9eabd1"
	},
	{
		"studentid": 2199,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "3ee88f2ff2fbd6e284aa93b0c42255369db9c3bde4f13d609529c44279ff532f"
	},
	{
		"studentid": 2200,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "b72b2c14672e56f2c5143ca31ec1d12cd06720a8d8cee62a76432af2bee5c323"
	},
	{
		"studentid": 2201,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "f36f14b0a16ceca9e198c99e1d1f6b0fbfcee712d04328101237b88da9198e0f"
	},
	{
		"studentid": 2202,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "c9da7b29b5f92ad75c286d0314b651cd47d635f31115aef7abd1fd1cc64e9147"
	},
	{
		"studentid": 2203,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "531c88680c2ffb338f70300093dc3a3f59e18168b81245b51fb1ddac8591f34f"
	},
	{
		"studentid": 2204,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "8b56bfb6d0c036f3043f782fc0aff449c397cf07ec1d8d828e0c801c7813e9e0"
	},
	{
		"studentid": 2205,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "d53d366c0c6621291bdc33d1751a66ed86cceacbd66359b0d3f125b935f3b532"
	},
	{
		"studentid": 2206,
		"classid": 4959,
		"schoolid": 6314,
		"studentpin": "bd8f7f2b12e18b5e45170fa2a7dcaa29b0d024d0bbdab84615be7844de9a010f"
	},
	{
		"studentid": 2207,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "cf5319584b745b64abaa47101e8d5deb68e570bd32811c0f6963874b15a19366"
	},
	{
		"studentid": 2208,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "cb0f98d79f5b899ffdc6eea096a69d5a42a48ee04004faead5b12fd9d0f3515a"
	},
	{
		"studentid": 2209,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "e10d43a3d9b75e2cb950245bf96bfeab7308ac069c91381ac0f9c2f9269a5340"
	},
	{
		"studentid": 2210,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "a5ccddf4af99d970bb8c7fe52328c1b0abcf2fdf9fdecc99a14a4599b4c74f53"
	},
	{
		"studentid": 2211,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "d2645df9684ccb4f8460916f1637f9cf85eac9f136130087d66a72e747d96a57"
	},
	{
		"studentid": 2212,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "48291e03be20ecfa45fa86ff8d9b8e5ef034becf923d5ec2e6fd9f514c3aee85"
	},
	{
		"studentid": 2213,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "ebf227b87b614e9167db85685d6fe7a22690b080e18463901d6a373c855953c6"
	},
	{
		"studentid": 2214,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "2c55d34caed239bf09d8e08b778f2b8b10ce2b006ac5c27000c7d9a3c36661ca"
	},
	{
		"studentid": 2215,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "d2274e57918b714a2e1e0b11892273d71ad76be359a9d66c710478c483a700d0"
	},
	{
		"studentid": 2216,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "6d839bfb339231eee6f263945047dcd67ae7ead22f2a1554ddd60c5f41ccfc3d"
	},
	{
		"studentid": 2217,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "adbaed5222c26ce52d5df359d924131a4cf466833b3c173bb10282ad66167c84"
	},
	{
		"studentid": 2218,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "a933559feb754b15253a8b04faba3ee7b35429a93cdeed7ce07dedf4fb3e22d8"
	},
	{
		"studentid": 2219,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "12d0af708c0b39ddd9ed15f966b487e04f33ab13ab59bf2e1d7e23b52bea38fd"
	},
	{
		"studentid": 2220,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "f9b648465df0f186b40dd99ff085c9eca2461d93e265156a2c43a114cb8db4bb"
	},
	{
		"studentid": 2221,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "fcdfdcecad9689895dfc1ac5aa52f8394b6c398d82afa2c67650abb58547e42d"
	},
	{
		"studentid": 2222,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "4de7f49c86eb968a47647ca11c04b6d362100924f929c12ab4015b385090b656"
	},
	{
		"studentid": 2223,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "b7a16c5db8f3178510907ba5eed3840e004e64cfb08757c62acc58af6d0bd80e"
	},
	{
		"studentid": 2224,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "5d9dec31b674c684046ad5e3e7930d01da80b19fda335ec2e9b143411e954bba"
	},
	{
		"studentid": 2225,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "5ba7944a375fa32cb707d0bce3c46aff31d0d67f24d92c71e1a10f8af839326c"
	},
	{
		"studentid": 2226,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "d922984357e4a08a971422223317dfb46a3ed8bbb32f88467c524dd71f2a5f79"
	},
	{
		"studentid": 2227,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "9af1f8ec6e17c86fb68ed158c1129564d4dd3e1da9095777cf8ab2c667fcbe6a"
	},
	{
		"studentid": 2228,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "c319608aae4e44cb789da7738a8fc3131c4e8c4706a5650cf72d10dd89e3ab0b"
	},
	{
		"studentid": 2229,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "c4787131ac6ea3bb1067bca3145ef9c72df78827f8fd9c01995f186344994c07"
	},
	{
		"studentid": 2230,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "6c197bb7d176c8b6a3d4e660ba5a471de525571009494adad32220f442544346"
	},
	{
		"studentid": 2231,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "442f70abc5b69111d7e55c2c776a58b1c1fda5a8399dc569b5dd85496f383ed4"
	},
	{
		"studentid": 2232,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "293a8ee3e29bf8c9fd51e3379db94801fbb8e2a6237a9debd5dfffb555b3fbb2"
	},
	{
		"studentid": 2233,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "d02b46d8941407921a25b6dcc319e459c2716b4bf13b651aed66abd2fde1d940"
	},
	{
		"studentid": 2234,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "1d86304802589351a9f32b20066d04e3daf029661f08d13b167f34d9c1e33ed6"
	},
	{
		"studentid": 2235,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "db301e9d3fdeb09b91c6d3723725966d8282623f303de17f6753ddbb566193c9"
	},
	{
		"studentid": 2236,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "6154779c6f89a4229da95d37c8184410cea9f45296e734986d9b36473656ff0a"
	},
	{
		"studentid": 2237,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "ec3a6a52cf03f8756b4f3fd381ffef14de75b4e09d54b30d1c4ac51c443ddea7"
	},
	{
		"studentid": 2238,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "108fc4c9d2923cd7b4088963990da1fe286e7ca70a1a36b4a769b84d031804e2"
	},
	{
		"studentid": 2239,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "cfc53edb2fcc229c13dbe0a7ad9ab65ee1361319538a6b8aa50c1d9fe3cb8838"
	},
	{
		"studentid": 2240,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "35fc7957aed254bc6ada667223ba8b06b2b69e23fd30b6f01154fa1438b20f37"
	},
	{
		"studentid": 2241,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "0383c7cbdd521abf29b910a771a0aa2ea18bc0284a0832b0a1233e53c561c6d1"
	},
	{
		"studentid": 2242,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "3118ba374405e8aa7b598c42987d05e764339efa763a9cc22bb354eef40a409b"
	},
	{
		"studentid": 2243,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "3c5dbb73a15e7bc35fd87bbf61ae2ec1d9ec98c4a1d52a7dc2403a36bbde013a"
	},
	{
		"studentid": 2244,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "c68d1ad01f0688d223dafe703978ddecaea987b5c13ab9243914c654a340eae0"
	},
	{
		"studentid": 2245,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "8de2b9a07b0c763d9a6db1d0d4b15807eee7403af4c270bb444a06643658c5d2"
	},
	{
		"studentid": 2246,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "6704389cdc0283a5128c6fabbdcb808b9d62de2677f9a68f67beee83b6436a0d"
	},
	{
		"studentid": 2247,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "4b05e06abf7f85654f0448ae7fdfe7425409acf54685078ec01599547e98e5e9"
	},
	{
		"studentid": 2248,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "d7f48ee0b76e002f1f24c527e5794c24b9c048f24b9025a9f0b65a629357ea85"
	},
	{
		"studentid": 2249,
		"classid": 4960,
		"schoolid": 6314,
		"studentpin": "0ab38d731a3a660f50b1daa993df84f311187b0cd54b889ee79865cba59f8148"
	},
	{
		"studentid": 2250,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "fde9b4abfb504f71b2506dca0a5a8ebce12985883e69006c93dce82c11ad798f"
	},
	{
		"studentid": 2251,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "42316fe7cfba75bb1a648789ecd612d1965b86798a5c38cc26b67261ab3e9acb"
	},
	{
		"studentid": 2252,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "4108458d5b673150cc2e32512060acb7b7168dc9e01ab7a30588111beed02afa"
	},
	{
		"studentid": 2253,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "4c01fa4faac65ab69c6d297c5cdf0f269623bc674d25b1a7124af949f3cfa3eb"
	},
	{
		"studentid": 2254,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "078b1c4e68eadb8d6881c2466d05e877d9196a979849c6e00456b6d9c10bf339"
	},
	{
		"studentid": 2255,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "541ed7b1cf6e9881bb7e2eceecfee5945f39619fba811b4552be3998f191787a"
	},
	{
		"studentid": 2256,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "1620cfea658618311360fd220faecb5b831ce3624aedb88cbf2f89423eea7877"
	},
	{
		"studentid": 2257,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "63b84e8c0e6296eed088f74a01335530bf4d6163a28a5681f88eda82d82979d1"
	},
	{
		"studentid": 2258,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "6d044c3abaf0bde44a7cfd15ac03681b9def518ae4903cef21c889b5fa503657"
	},
	{
		"studentid": 2259,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "5417cecd4c203d9aa35feca6f4a3efae13f99cea96502c3a9178260c6215e6f8"
	},
	{
		"studentid": 2260,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "d36a949c42676d59f04547b872022b0d105159706cf13553d4b3a6dfc0fdb5cb"
	},
	{
		"studentid": 2261,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "be8cbd517c176412dc0ebea74c60151c77a52c8642ac069343dac5997bba91cf"
	},
	{
		"studentid": 2262,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "a6a7d5d3c517173251e8bd6b970687ec683ba5f66217b9149283c061893caf4e"
	},
	{
		"studentid": 2263,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "5b571be6967c729edc8054ce7ff0542baaa321ab71a166b35bdcf7516a266293"
	},
	{
		"studentid": 2264,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "5e9d8b2d79d3440fcdbca3f63a04caf00e57bb7b35167ee6a54644d39ab9a903"
	},
	{
		"studentid": 2265,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "fe3497cdda9c4f297d09f141651749185d21ff3c72c2dcb065e177cd58a479bb"
	},
	{
		"studentid": 2266,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "fd3de488ba2b075def8a3829a1320c01a904f4739a10dd7e129e356bedd2e956"
	},
	{
		"studentid": 2267,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "e7ae1cef2d86f3fe595f6868244c3211237156493bf08552685b1bc8550ef029"
	},
	{
		"studentid": 2268,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "45f76cb9f5892f7029d1661fdbf7d9da8b63d2aeb9ee1efc40c0573e1c6f702a"
	},
	{
		"studentid": 2269,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "99a089f22c8db28ec25e98a7cccc8ca11e539d623f43caf3babf61c91fa617a4"
	},
	{
		"studentid": 2270,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "e2317bf67d859490ccf3ab6bfa0c10366ceaf4db967370249a3919095d141065"
	},
	{
		"studentid": 2271,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "10f4abece9f56b24ed9b3b113e5df9f2e14c3e2525a9d902a925fe79fc0e9f82"
	},
	{
		"studentid": 2272,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "274ab66fb5fad169e2fb1bbaa2143d7048626543eb126e251ee091c9ff041a28"
	},
	{
		"studentid": 2273,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "576cd727c192072ed32944e4987e14443be660339002829616a276b4eb70d6fa"
	},
	{
		"studentid": 2274,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "ac2907f7716d67c7bfb0d872fe0e80a9700eaca86d6f8374daa902f25fce03eb"
	},
	{
		"studentid": 2275,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "f8c4a32f9775c76ad0481c54ce388fdbb6c5c546d0b8b3002667ad2c2b7a8b3d"
	},
	{
		"studentid": 2276,
		"classid": 4961,
		"schoolid": 6314,
		"studentpin": "5c8e46f887691d3ade0b402fb56f365560445943b2a61f9fbda26107f3b3ba92"
	},
	{
		"studentid": 2277,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "0dfa024148fecf972bd567d8a137b027eb9465b1cbf6d669e35c0cec7ace1f85"
	},
	{
		"studentid": 2278,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "5e7ecc05680e56a56bb8afae8bad9c50bb0b89c5e2d447322019605c3fb0fb22"
	},
	{
		"studentid": 2279,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "db4817c1a2246d98f0012d769453d08edbe2c3228f49c79ad7915f2ef049c835"
	},
	{
		"studentid": 2280,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "49abb8be77428936a4bc5ec82b07a19b3dea61388be2ce505516224c332012d8"
	},
	{
		"studentid": 2281,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "265919ab3f31433b4f271144c80b78205b39444d78fdbfef81e9f86de73dcd35"
	},
	{
		"studentid": 2282,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "f49e2e80313ce35255b49c195d16f3cdd9c46f8987aebad6dddf242156a38839"
	},
	{
		"studentid": 2283,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "cc4a84a7ad064666a8e7f6ad681f662c330b0e3d9bf07f1f103dd6ed454c2ac9"
	},
	{
		"studentid": 2284,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "8b53b83b8de03e832c240197aea30918d87fe7c126deffcf55756afac2545dc6"
	},
	{
		"studentid": 2285,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "4782c0348aeb17353276b73032606a9e9bdf36b5cd76c3e55a539c065fbbfdc2"
	},
	{
		"studentid": 2286,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "f8b77e45f13cf77c9d413266f35e1250e0c8c1edc5f5db9afda267fa860b4878"
	},
	{
		"studentid": 2287,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "f45239971dfb9a97e9798f794f9429cd8ebca502b4e055b04d2603ff56fc0cb1"
	},
	{
		"studentid": 2288,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "dd3d62ca2d569aa646915fc11ffe21c2c4d3b053f5b8587309df1547a016854d"
	},
	{
		"studentid": 2289,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "392b31247e637f12498339339f37c2630e7a7ce1b6f70dd9f525add75e76ba18"
	},
	{
		"studentid": 2290,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "044799bc6deecab34451884a9255fa86e8ccecf880b45a3417f512922eca5ac5"
	},
	{
		"studentid": 2291,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "706dc947e3c988386759b6863260d3a65ee7e89347b032a2f264e58a04983dd7"
	},
	{
		"studentid": 2292,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "a36e322d2bd33d577eb29ff60d8f21fc9f85360497ff7249dfe1934956e4d700"
	},
	{
		"studentid": 2293,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "21d64278292241e6dfdaa4551e9e527353721748099c8a52a28fe8a4d6b46f41"
	},
	{
		"studentid": 2294,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "a22033f03acaf19bfcf9f35cec290ee6c51f1a507c0392f8e741719f1298c0be"
	},
	{
		"studentid": 2295,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "680a7625abfd007c023ab63d4d983824554daf592f46f9251c3e2c8925b1e383"
	},
	{
		"studentid": 2296,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "56904647bd587a935b8903ed0f836dcf7035e0456f062403a48fd3a446f7beb0"
	},
	{
		"studentid": 2297,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "ef2090a1c7346c7718dfdd7452d5b7c52ee52fc9efd747bcd7da7d9d3bf09f3d"
	},
	{
		"studentid": 2298,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "2852ac8483075b61e62287ff6dc397fcacb14110176448d54dd23b61794f2aa2"
	},
	{
		"studentid": 2299,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "8c2e210f11af426c73db971dcfd830664267ec5e4ff38d48054f44481a58d227"
	},
	{
		"studentid": 2300,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "16a694f464fe3bbd103fffea403abe7b27b6265a13a8b08c09365d52c64a0702"
	},
	{
		"studentid": 2301,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "a04a611ba621c7ec61a1962962379ca476c573cbf050a862647d48f32c6734a6"
	},
	{
		"studentid": 2302,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "674d26bc9f7cc12ea060bbe763f4be49af7104f34b0a5960ed0f3d572d42e814"
	},
	{
		"studentid": 2303,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "a1e937c9c8e038b23c8021084bd7882df337baf814c80925c6a02469de5318c8"
	},
	{
		"studentid": 2304,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "9b6664bb413daf84168ec651b4a4edb5dd15531da54010f3462aad2f2feb8f16"
	},
	{
		"studentid": 2305,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "f25190eca50d0fcef9f91257818e9b93c28ed25affc0cadb9f2e94e7e62d17ee"
	},
	{
		"studentid": 2306,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "78942b7a1ab4a9d905053161497d6875329968a0234187e7b4e636c45bed7a9e"
	},
	{
		"studentid": 2307,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "d1cab405cce785c419f2c79d26659080b2638c5fc44375a771ab9d7416d37a22"
	},
	{
		"studentid": 2308,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "0f47ef6951af25401b8fe3521c859e9d91488c46412cd2bc559ff9dccfd3651b"
	},
	{
		"studentid": 2309,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "d9c78af338b885df741d6f1187e4d7efc04d71ac2e24bbb1949afa10749a9da3"
	},
	{
		"studentid": 2310,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "0d82ea3f77e0fece18ddc0f636a474e8cf9ba696ac6511d707fe840970675973"
	},
	{
		"studentid": 2311,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "cf7d2c9ea097268e38485f3d6d5a8394ea422429486d19b6d8c1abd669f4c608"
	},
	{
		"studentid": 2312,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "67b3001ba67746583a7e53859131317ce092460c52d452eafcfd8f1eb10674b3"
	},
	{
		"studentid": 2313,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "8adaa3e082ce9599e33f7d83f664b8e966c7d8f2d450837ee8b0eeb6957b32f6"
	},
	{
		"studentid": 2314,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "31665bad8e31c2873a6511d0ba9d6ad5015916ad63226bca3820e5f39629b3b7"
	},
	{
		"studentid": 2315,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "8f754baa06143a66b8f1022f1ce28ee0954026f995f6c8e41b3df83cdedbd9a8"
	},
	{
		"studentid": 2316,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "3a6a6324c8264d9f20e36179ecfc5f29c8a62eaca2b78fcc28b458c27c3ad7cd"
	},
	{
		"studentid": 2317,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "9e5eeea489828aec9ef83ba4dba3c2ecc11ec505839f32158b36a84c9257de0f"
	},
	{
		"studentid": 2318,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "5e12ea0c67101807467c6c4631a46cf038cd9789b0e8ea0c21bcb4816b629f4e"
	},
	{
		"studentid": 2319,
		"classid": 4962,
		"schoolid": 6314,
		"studentpin": "93e5bccab011824d7042134059d1c268b541f2de6b5ebdaeef273bebba05dcd1"
	},
	{
		"studentid": 2320,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "7c8467b568c6bc13ed06ccd45a925bccf55d6dc7edf58478539f5a8aa467f6cb"
	},
	{
		"studentid": 2321,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "62676891fdbf3ab17baac99b6626c40c288da5498488f9b714cf834e08ad12fe"
	},
	{
		"studentid": 2322,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "e33921e78df852312610116f670834b7ae5944b3df7c3b84c63f22273248ed30"
	},
	{
		"studentid": 2323,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "37d1d50dbb7b37b50f6b7909a7f7455b0419f012b591924c5110333fd321e688"
	},
	{
		"studentid": 2324,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "2f4df22b15ca80a1c0fdcf02761d287bf6110e56898d284524fa31d279a1b459"
	},
	{
		"studentid": 2325,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "6480a60c6a1aafa05aaab60095f58eb852a0f62b14d08f937a09a7a127d58e6d"
	},
	{
		"studentid": 2326,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "2f1967be58c293472cefd0c9414f9bc3e8e004a15b16f1658810d9dc035e3d67"
	},
	{
		"studentid": 2327,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "ecb19c2cf64afa23ad97eaae3f31bc9cbae308ff15021ba4910dcc0454b42121"
	},
	{
		"studentid": 2328,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "9e224a285fcd3f2a63419117611d065dda8bce452e29294073d87ef382ecfb5a"
	},
	{
		"studentid": 2329,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "e20a768daf8bbc43185d8320c0396735b3c2d593b7f27800069b3a47f356f3e4"
	},
	{
		"studentid": 2330,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "27e79a1ece90c6c38195b257001fee293e63ef9aecc509abe9ab1138348248fb"
	},
	{
		"studentid": 2331,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "a19c34311444c79d6001540dcfd6006e83bf5f0f1c4eedbf01eced29b4e068f7"
	},
	{
		"studentid": 2332,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "ab7f677a62bc0167030e451e0e7d9ca7210753c65fd776efa79aab835d5cbad2"
	},
	{
		"studentid": 2333,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "f52f185f6ccfd7d9af7b933be94b4c2547496e987a83eb1b5c4ae9d30f4d1bb0"
	},
	{
		"studentid": 2334,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "2c6e1d66e9ec5a6eb7fa7d05271c0d72acae72dbe01269725fa9595d904d6867"
	},
	{
		"studentid": 2335,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "17705335e05bc32e015380b464464c54d3c64f7da4b173f3c379de836ac959f1"
	},
	{
		"studentid": 2336,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "2ebd00967137e8caf082b5f2e48fb657beb7d8177d05e260be4cc4381b294aa8"
	},
	{
		"studentid": 2337,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "76f366eb7fde594c56adb632915d3efebddfff3a85f937b99c63e833b43e5ea7"
	},
	{
		"studentid": 2338,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "515ebec0f9ea0017f4b8b67ee064e92c99ab477dc9037631b892ff678d56692a"
	},
	{
		"studentid": 2339,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "019602f64e3769495cd81837089ea47bd6aac4205fe42a9186766d76ed6908a4"
	},
	{
		"studentid": 2340,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "bf89b194acd66b04a6a23ba1336bfe881cdab50165af338c9efe5faef4eef0ba"
	},
	{
		"studentid": 2341,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "22781d047ef06970b42ef4dd359649bba5f1753b8ea9900fb7ad773218a566d8"
	},
	{
		"studentid": 2342,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "113cbdc47826d2df0d947302c2d37761199a9cae5580529116fe5d35e108786f"
	},
	{
		"studentid": 2343,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "88ab2a7e51b2d645a43bbd0ff29facce1b62e18538163c5e1d1575e3a6db3826"
	},
	{
		"studentid": 2344,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "939488bef342d90bc3e2c06bf221c2ad6fbcb08050b04142be505d1b3a9c612c"
	},
	{
		"studentid": 2345,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "cf08184a989d2acbf9e493542e7e1e6d3e58853da80bd90e83a67816f24e0485"
	},
	{
		"studentid": 2346,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "729facb6fee5e1d4aaa6556a25064f48cdfe9eb02d988bbf3144c52055b2ba80"
	},
	{
		"studentid": 2347,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "adcff89770dc8e76cae0180fa9495eb3d9f8c4c8e437dfbd63aee6c46dab74f2"
	},
	{
		"studentid": 2348,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "f3a99c9730dfc0db9d17c81087cad650a6b319b3ea04c57c7177559fe79ed019"
	},
	{
		"studentid": 2349,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "bbbbfce153ed955642bd0fcba2d6eb1584c0ff6577da406d350be38a0fdf126d"
	},
	{
		"studentid": 2350,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "359b1b8e28b03e0d554877bd1fb7e258f4db6b2b3d42b3c7ddbea56cfa52544e"
	},
	{
		"studentid": 2351,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "5064a84eb3bf7432be5ee69ad12fcb7a0ee6da995f9c4518d22ed738eac90ec2"
	},
	{
		"studentid": 2352,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "9bc8d4ac7b6834fd6192e3499dd4b886628560fb83a254440b3cf3edbb8ea353"
	},
	{
		"studentid": 2353,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "5f25d9a7d79c1dac696e1b8cbcc58a3fbe07369268e40b4b9130d30d65133b03"
	},
	{
		"studentid": 2354,
		"classid": 4963,
		"schoolid": 6314,
		"studentpin": "55c1b72a606d1b508c7467395babdaaf36358dcf4a20b7562b5cb3fcc1b1e2c0"
	},
	{
		"studentid": 2355,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "c42f17e41b2db86b232acb5e8d3d09cc67468301b3de0327238fb5ddd5fed25a"
	},
	{
		"studentid": 2356,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "9015c6fa30f4bbc31b4fcacb78c052e80580fce9b0d6107751c93249285318c2"
	},
	{
		"studentid": 2357,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "b46cb55be11ff87488b6cdb859014b978c8f01f73f23dfd2cd6b19b8e4d4befa"
	},
	{
		"studentid": 2358,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "a96855e4d63f599c8e8f3f87c13ad72e73ccafd36f22aaa8addfe124fba8e5cb"
	},
	{
		"studentid": 2359,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "3f130f57974b4297b3bb6ff25185b2d6a83d84d30a221afb08bfa41962d49176"
	},
	{
		"studentid": 2360,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "eca5e11f08cde72e37e2f86ea3b32d87c60672df549405d713844d237fa7585b"
	},
	{
		"studentid": 2361,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "aaf4232421dd0459ce18d84c2f9f2d25b9204af23d4c61aab3a8518652099724"
	},
	{
		"studentid": 2362,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "f3d59c214dc8d44a01a85977526d7f9dcd9cb1d1f251143cb0f581f39ccf13f4"
	},
	{
		"studentid": 2363,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "5d894b466f658446fbfdbe55a73aa235712cdd421b339ebb1d973bc4ac30edb7"
	},
	{
		"studentid": 2364,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "8b0361c68ffb1142fb56f7b96632f2a6e8388aa2bf256dba5b0393efa930efe9"
	},
	{
		"studentid": 2365,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "b977da8dcbe840052abe850f6cbc960fec883429f4a62fded0970fcb4ab7ec93"
	},
	{
		"studentid": 2366,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "2457fd3d39fe7a193d5a9ca49a6dd19aa68bda389b809cb41facfdf31c9aeffc"
	},
	{
		"studentid": 2367,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "0306331fe9d3948e565b2f409b4f4290a2ef0cc32492e12e2330b12c615a3969"
	},
	{
		"studentid": 2368,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "64da2c9abc534b364f90a9e208ecef25c08a217e537cbb10522786abbd5bd640"
	},
	{
		"studentid": 2369,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "7c3392bfc4273c49c0b655acf4468158ea9d5c5bf39a8ccc9d9b3120ad955722"
	},
	{
		"studentid": 2370,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "d280c8b1a14e1cc41e1e437d70b2f053b46211d6b73aceab7616ec1a29a4481c"
	},
	{
		"studentid": 2371,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "bf7621960c8d92e54a77e419d191e2a660387b9d8c542cc664ca2a3ea95d9ed0"
	},
	{
		"studentid": 2372,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "e4936f940e23ff100eb566e24e046e918887b6ed540314c58a30d3bd5047bb3a"
	},
	{
		"studentid": 2373,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "5c7e1cfa6b7be5c2a9d4f65c499c666137a107612e2ee72614350976cf9d013e"
	},
	{
		"studentid": 2374,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "e1a2fcf4da3d53d2a9527d52784c573490b4a145ec8ed9d8caf7f2a700fd740c"
	},
	{
		"studentid": 2375,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "77b8b975c368e435844ea9e3c0846e8d7720c5a86e8b7058c651dfdc3e46250f"
	},
	{
		"studentid": 2376,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "283ee6629ead9425bf9ef420c14c1eb2daf158f842049aae08ad5eef553d1ae5"
	},
	{
		"studentid": 2377,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "8250be1544781e91ef3b8df1dfb24c8c642e5c32c508afae2f555111bd25aff7"
	},
	{
		"studentid": 2378,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "d4e1cd0f4a2e8bdcc4013a4bee36b40a7432ac6afa34aa1f0f527c996d9502cf"
	},
	{
		"studentid": 2379,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "173c86e040634e5c346bc808d7cbfdbcc62c63b2a51adb0ee8fff8e055bd5607"
	},
	{
		"studentid": 2380,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "e2028089599744d5cedb255dfa13eaa98c9af71bde0d933df791d4a5480c63e0"
	},
	{
		"studentid": 2381,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "d1293271ea0cc66e03dc2848ec5a809cb0d630eb335c43418358e7154f8b9c07"
	},
	{
		"studentid": 2382,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "0edcaa383dacec8d37b176dfd875570f4342dfe4234442c089c388a39a68405e"
	},
	{
		"studentid": 2383,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "25315a4afd2cf98c0bf921d956bc919d9c01010ff55559dc7620b32bd2dc69dd"
	},
	{
		"studentid": 2384,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "75ead7d694db5e34e75196802f47549ab49f660d6b3a9749159c334c9c50c024"
	},
	{
		"studentid": 2385,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "3838d18ffcdf5281d395d515f53a20fe58c1c281d5c4fde62a04c2041db8706f"
	},
	{
		"studentid": 2386,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "60879169c0f8502843be879e2c9f55ca85f6f2dd136431c6058397aa437e5e20"
	},
	{
		"studentid": 2387,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "8aa9fb0f4b9d0863ed1fa52cea9bdcbfee602d6a164e3aa9aca184ce182bf664"
	},
	{
		"studentid": 2388,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "8046f97c8fb34228ec0b10f4c15cd27ed316a735e8bfc8f356be6cc631944337"
	},
	{
		"studentid": 2389,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "33c7fcba52bacfc854472bc64cd41550a7dfda53480807b740493cc1d1c2ba7e"
	},
	{
		"studentid": 2390,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "7adcc941b2b6a203661afbdfc5f6d67e5fe4e43a591210c68374f9e5ca9d532e"
	},
	{
		"studentid": 2391,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "300fda04a26bce1792eca44b8b5d469420c8b6eaa51fd8287bbef214b71d9d8c"
	},
	{
		"studentid": 2392,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "5391d3191afe2d8579756cbf3c6a6d25f19b27b656d15987501e7f0845963dc0"
	},
	{
		"studentid": 2393,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "82788d5ed9da93fa7141491f4f408b7e731c14df21c733e8ad56dc64f9223c18"
	},
	{
		"studentid": 2394,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "01b63951fe270577ac8f46ee0cf3fe711e77ab14bbbbc4f1bfce72ddcd163ac6"
	},
	{
		"studentid": 2395,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "556246ae546eee8acdfabcd650df17a70ae785a15e92bc099474efcc30b1fd6e"
	},
	{
		"studentid": 2396,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "136156a230809d7144894654b68e8095cbf8b088427046287e5660eacc503ad8"
	},
	{
		"studentid": 2397,
		"classid": 4964,
		"schoolid": 6314,
		"studentpin": "ada40d1614f9a863bb29f37fd15b3660990ecf5b8ae35e2c7dc5a04c88753121"
	},
	{
		"studentid": 2934,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "a23450e6ff75b279cde91f7b3f29f79a2a132698eba5521a64eee24cd31c683b"
	},
	{
		"studentid": 2935,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "896912448a14e81f380fa600521cdf2f80edd3518733af26fb287926ae6c2652"
	},
	{
		"studentid": 2936,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "bab7f5a3c1027822e34fcb1375babffdad13bb2682340c5038255f69947a742c"
	},
	{
		"studentid": 2937,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "7c6ea77ddf4fff2e940b3f216efae3c47ee2d20ea8e463dc1e201d49ba36f5c7"
	},
	{
		"studentid": 2938,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "aff7169953541d34368ca9ddb703c1effc890f9c8c105a37e0dec957d77f4460"
	},
	{
		"studentid": 2939,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "471443324145867603f1867e371a0459a63e7fab4ea99bf0187bce5bbde97128"
	},
	{
		"studentid": 2940,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "54a07c0a9aa383435c31d871c91abc3405b2417efe9f858151b8172c0828c15b"
	},
	{
		"studentid": 2941,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "68aa4c9f13978c5f4537ca1402b775ad0a32ba790066c737ea5116937292e8f3"
	},
	{
		"studentid": 2942,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "053149a152bce1511ed1ce71c8318532a6e1b56ccc58fec6b75f67abbc72c208"
	},
	{
		"studentid": 2943,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "1c2fb499a36dc89c2beb3e076c9a7641a39a4d35ac547a71936c85acda763bfb"
	},
	{
		"studentid": 2944,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "09c20845d2e5d264e22e8aa8ca0e3a7f5502b74c8537c83106a45e56a964de1f"
	},
	{
		"studentid": 2945,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "ef39bc4c191a2d6725fe563bfa3d55a93cdd2998e51044a0b2ccb393f4d40e83"
	},
	{
		"studentid": 2946,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "f2503347155c3b8866e9d50a716af7e49fe6eeee79c17326aa03fca752297a3d"
	},
	{
		"studentid": 2947,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "3ce8e4e2e570a2eb24d967dc095a50d25d5166d5a43592adccef8e18d57d68b2"
	},
	{
		"studentid": 2948,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "844902613135331adba29d17746c1b4b1e955a87710b1aa93dc77024bb9b95f0"
	},
	{
		"studentid": 2949,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "c062ecfa0b7e11a8a908f19a7f9ac29d3ca0280daa65847ff7048a646665eb42"
	},
	{
		"studentid": 2950,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "0cf18c155beff82e0efc7d36933aa0e6db606ebb879a216b7f9ea4671cc5b72c"
	},
	{
		"studentid": 2951,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "ee2d9d04ec900881c2a301a5643a8b48013357bd9d6807205da178989d954971"
	},
	{
		"studentid": 2952,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "9c5715591d172d8ae9e5cf57ed6bb10c04ae3c06ac28810e4c995a4bbe3f645e"
	},
	{
		"studentid": 2953,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "cba46b28017bf591675b65d04dc8bac9a26a631771edc56fe9bacea5b25f6dce"
	},
	{
		"studentid": 2954,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "ea810083dc69740397be358f6c92ee42f622ac51ec0c4cb775f601a19a62a34d"
	},
	{
		"studentid": 2955,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "8e926962c297092cc62405f32281afb531befaffc53c9038435aedddfe89c56d"
	},
	{
		"studentid": 2956,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "a9c5ca6c02fe0b37122739e6518573fbdcd85e41ed114c5bb4cca285bfda3abd"
	},
	{
		"studentid": 2957,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "afc9fc2a2c224d6d63dc6038aa3b206f5593960b40a10e10abbe5d0298cb3b83"
	},
	{
		"studentid": 2958,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "2451aaacdbae8be3177f49cf51b82594420198b54fa7d7df583315123557fc27"
	},
	{
		"studentid": 2959,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "6c771214dabd22ee4520147c2a118e15f3b4fc1f198a19176cc1f3fe48b17987"
	},
	{
		"studentid": 2960,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "693b14c5f68b4caf84221accc615cdf4825ac3eb426bb01fe6652c83925c4775"
	},
	{
		"studentid": 2961,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "c5c9f978537c06c9e9f56b81c17091aa153106ff26ce45a0900f33f8765d882f"
	},
	{
		"studentid": 2962,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "bd645b7b1f6b8fc2d3701840cc883f0d63adf4547dff4e59787f28f5cf0aaa02"
	},
	{
		"studentid": 2963,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "52bd08ba4791ca2ac252e27f54aad2445598cdaf06de640b50423fa4ef154c41"
	},
	{
		"studentid": 2964,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "d8245028b651b30cab095f79f0f3739a8af4806c49de48fc2d600231e3d29c3b"
	},
	{
		"studentid": 2965,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "20f8991bf201279a448a959d37a039b40595947a786e97208bdfd7488307cd39"
	},
	{
		"studentid": 2966,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "19d4d848b4aff1d5517e485dce347bafa57b79021160c820e34de0956d24a9ea"
	},
	{
		"studentid": 2967,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "1d16946b13ebeb6d11331ff9d1d5548f5c97101727b1bf9824647e142da438f8"
	},
	{
		"studentid": 2968,
		"classid": 5338,
		"schoolid": 6201,
		"studentpin": "9a64e22048196a9e5cad5f59760f5b58e594fa2a47cde8803e1e623847b945e6"
	},
	{
		"studentid": 2969,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "c03b3a64b5a1ff0df9c1fdceaffc594ee2c9c061c05c7edb0c53c7f3eaa7489f"
	},
	{
		"studentid": 2970,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "5c7aa62ecf780c352448c9aead9d3cc5f10756a66f2733abc72174a8d2b42bbd"
	},
	{
		"studentid": 2971,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "424bdd0488c1e56bcc2bbf5c24cb5d1bcf3e53d98712c5badc74c3a4c94c7a18"
	},
	{
		"studentid": 2972,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "4a326a4ca49aa7c671a8f9239d474829429b69bf0b7d9149c720a6aabb62c25b"
	},
	{
		"studentid": 2973,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "6ac2fede5b8bf819929e407a08851b130fe13faf9acd792941d760582dff4524"
	},
	{
		"studentid": 2974,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "ef1562b9a9470c98e634a486806353492ebd628487ea077cea2a86dc42881555"
	},
	{
		"studentid": 2975,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "cbf83bc3d0034832bacfc8176f3881b368914ec0385a78df8a907bbaed426a70"
	},
	{
		"studentid": 2976,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "251c22227df611432c1df7ea61120c9b1c571674504e91f412b64855ef03768b"
	},
	{
		"studentid": 2977,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "b752fee1aac0c4686f8aa4d88ce54144c78e256f659cccda6368219eadae2ee1"
	},
	{
		"studentid": 2978,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "c607d2cf7f9acf926d96aab27f37b300d0caa7c770eeac6a48c9249d21601c91"
	},
	{
		"studentid": 2979,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "177a566b8a77a4567dc8b095fe8a3aa65baf076596ef21a08d53c1f04b32f0ec"
	},
	{
		"studentid": 2980,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "dd97ee05144488e58eb30b8325750bb9f3f5ad5170c091e1f8e2b01925adb210"
	},
	{
		"studentid": 2981,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "439d7b01cca6372cb0fc4145086eace299f3f4d709dc23c386ce8d090c131db4"
	},
	{
		"studentid": 2982,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "9ca4962a38bb2cdf2e68d9da551405c97d7944dfafbeaf47bf275c01a8e1a440"
	},
	{
		"studentid": 2983,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "90c06fbd85d888bd024f06d42a8563ede0ae6a0d2c4079b373e2f9fa585fdc13"
	},
	{
		"studentid": 2984,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "866f36008ffbba3ce907c53a298727da7a8fa667500d8158fc1263788c1c4284"
	},
	{
		"studentid": 2985,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "944cd678c0d10a93f9167eb43224e6edffe9be578a8122dcf6b8c361357f3c52"
	},
	{
		"studentid": 2986,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "0b40018490a736d2ce2cd2d7c6f13d9d76df5d6540d8e2680c4a08568fd65fbb"
	},
	{
		"studentid": 2987,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "2c2bb8ffe7d12297f673a1e877de03099ef11c2081985fcb445dc788b9835efb"
	},
	{
		"studentid": 2988,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "43aa9c21b5c895451178356aab1e053425b5d77795072021c59294313b396c38"
	},
	{
		"studentid": 2989,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "986b517b6ee42790c65596b43b6e15d19c4157b4263eb8f0c33660ea0af14547"
	},
	{
		"studentid": 2990,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "1bb1a8e265c1fa71d606751770152645ef30df67110dc65be8dcd3c34d62d810"
	},
	{
		"studentid": 2991,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "a9e24c8546b5ebca6877b020bd5c58a8b91d2eec057f5c3be0b8dc1aa229aa49"
	},
	{
		"studentid": 2992,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "4f3c1d53c9b61d3ddd3ca9e20e6d12544d83c553f691b332b62b924770e8ffd4"
	},
	{
		"studentid": 2993,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "51a405df352bb7565c70abadf1e7b03f6ff4bff1fb2ad861529c66bb8287401d"
	},
	{
		"studentid": 2994,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "617941dc5170fb8b07f4c99bb8371898bac26487bdf2d36b882edb9b42936ad8"
	},
	{
		"studentid": 2995,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "2f3de6b284a3ac785e09578bf22334b923edce69aae9f3c18800b25dc606c7b3"
	},
	{
		"studentid": 2996,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "c4f3f15684befc58204ff2a6ac5a0644f2fc03882370660d81fbcf0710ef3b51"
	},
	{
		"studentid": 2997,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "3551c23cc255bbd38895354d6f52cf451fa4a68175b79c6fe5484001151b1da1"
	},
	{
		"studentid": 2998,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "0bf11db088ace5306e59a6eefe74cbd2099936300b85de41031cb781abcd114c"
	},
	{
		"studentid": 2999,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "f55a9b221a7617d7b245abb7b2841f87da1fc92c66d4f8f1d2f097b48ae625bb"
	},
	{
		"studentid": 3000,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "d75f1035418ae06996894999c2f564ad648cc2c878fe8ea00bac7e6757827a15"
	},
	{
		"studentid": 3001,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "66f8117c02e033b9b1da8dc30baec9f52cebe7e04b70c66f21f8f3623fc3d779"
	},
	{
		"studentid": 3002,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "34e2b44502a4fefa0b11d36a4091a8e59216e746f65115b3d089dbedf6c01241"
	},
	{
		"studentid": 3003,
		"classid": 5339,
		"schoolid": 6201,
		"studentpin": "b43ca2835263225b6333a0daa2925d79fb3341043648b8ccf1a352152784b7c7"
	},
	{
		"studentid": 3004,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "f09291720323be4292ae1dc943c64f9af20ac0b1e8d748e10170437eddcbf9d7"
	},
	{
		"studentid": 3005,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "8f223cc7673afb84ac802c42645fff838d07a608a0a14e35571372e4e301d10f"
	},
	{
		"studentid": 3006,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "61ef96684a2924df0778f083136e5edae0f2590a44315bdb47c25b811c33e222"
	},
	{
		"studentid": 3007,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "734598ffc1cfce4ac9c36e9b2cbb32df278dc7ae9d891e23f037bbd65bf1091a"
	},
	{
		"studentid": 3008,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "4013958935c24fede25427ea2c08f55e14852b6764a290c3b3dde6bfed44fba7"
	},
	{
		"studentid": 3009,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "0883e93696239d656391eb3c54e8726409b6f7a391a68b113fde484df417a9bd"
	},
	{
		"studentid": 3010,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "a2d09ec9278161ac03d0daff4fb36285cc5962a0be4d84568953dbb5747bcea9"
	},
	{
		"studentid": 3011,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "92c42af318fc0c3960b55e0886d38551d709fae61b826dffae82c01ffeb3f86b"
	},
	{
		"studentid": 3012,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "eb4f641b35b54fd090e293d1ddc84a1cade9d011b39f1a552bd6636a1d732313"
	},
	{
		"studentid": 3013,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "7b8632ea7d269ada106213176e434d7a15cde63a2112554df53f7abd36c1acf0"
	},
	{
		"studentid": 3014,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "9f65282861f692ff7f9f276bac790335acc55fdc4502871aa9f6b43a73ce8d69"
	},
	{
		"studentid": 3015,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "1eec997d7d831608a7402e294aa38bcb86c7a0664955c579a9e56b43049f64bc"
	},
	{
		"studentid": 3016,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "4eb5837afe1cea203c39b16ffe246e4626a524cc4987ccc4e817a6ddb4709c45"
	},
	{
		"studentid": 3017,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "1be892c8f16994aec9523b94a4b2327df52b51390794f18a99e76b8e44cd4293"
	},
	{
		"studentid": 3018,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "97e9fefc6df58961885852830be85d3f5ce217f5acaf520f3a231490bb5aec93"
	},
	{
		"studentid": 3019,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "ed8a00ea0a2d069072467d9c5e5540f021c215c32324a5f4a7993345d22dfdec"
	},
	{
		"studentid": 3020,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "2d2dab51bb16777b8c0e2c817c8d7d0178ea44bafee6d5dfc6e06bd8f6e3c912"
	},
	{
		"studentid": 3021,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "88738a3a14611f7bd07d37caf10e6c9a7e3f04e7ae7d8d6f151b2557182089da"
	},
	{
		"studentid": 3022,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "7152a845b0251447a00ae96556945e52d8cd7126e9e578b476fb23d9854b657c"
	},
	{
		"studentid": 3023,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "8e769686024c80a18aa8ed116ca651e00667b8ff877efec2367e6117a1f2e8ce"
	},
	{
		"studentid": 3024,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "959c524398fa1acedc4667682133fb8c3f9e3820e0b8c022979803cbca3c0bef"
	},
	{
		"studentid": 3025,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "a39b12932f4b8269b7fe5712772f5320164bee590f7907420706c9b1f2e2b6a7"
	},
	{
		"studentid": 3026,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "d161f258abec6e7af40ce1a0f32b84fd1407c105eb6fb972f6c12cb086465779"
	},
	{
		"studentid": 3027,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "20bca093e59af7ee2fbc42794823650e1591f1e5145611078119b2f16453a435"
	},
	{
		"studentid": 3028,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "f3c295f27f18463a441df2cdfeaf4127bc559b6c201fcc449c7706d68416e51e"
	},
	{
		"studentid": 3029,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "01261f533570faae51194e025205f25f37ad8955a542016876b6742a77a50145"
	},
	{
		"studentid": 3030,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "32a523f6edec6bc50fe559106ba4ad28be539253f0234d627e8b9e3211ca2590"
	},
	{
		"studentid": 3031,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "0d652c90cd005a431df0b9f8db52d1437d6240e61657b3d4d0d68b519135006d"
	},
	{
		"studentid": 3032,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "53a3437d586f1d63c269d2748b3a18f0af22190e2b1dcd3de0f6c7f99a1c3317"
	},
	{
		"studentid": 3033,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "45e08bb4b66577f6b0e9dead00a21f0b6b418c0d7e71a527df29af810e6d45be"
	},
	{
		"studentid": 3034,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "d4c765d1b7ad513b2cc9a15f37b00b3e6c3c2ddb9ad0c45a41750f0b1aa723ba"
	},
	{
		"studentid": 3035,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "0abe7f8af581219aba552b603f87130dd29c7ea10390c6cab2b1616ee1182f00"
	},
	{
		"studentid": 3036,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "c7a024d650d1266725b8f60fb74054972959a97cdc56ebbd873146eba2acd2b1"
	},
	{
		"studentid": 3037,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "e086339d71a5606d4a5f3a20fb1b72f242b1a40a8050a8765f8638fc8caea77c"
	},
	{
		"studentid": 3038,
		"classid": 5340,
		"schoolid": 6201,
		"studentpin": "7631e4ab7c97d38760514a56f0ff94fa7b06f20b066ad7b0130de64d4e45cce3"
	},
	{
		"studentid": 6811,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "2279a26263096565f5b91eb169d930a838e7b86926919f8ee974028cd2edc882"
	},
	{
		"studentid": 6812,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "f996d25592a6cf8b41a303144d3fe13e6942297ed92a8a0a719f114cfc15c9bd"
	},
	{
		"studentid": 6813,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "6408325f316e553a2b70b234d9377d49577664844c3f0521e57bba159ad20ea9"
	},
	{
		"studentid": 6814,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "08bd2b37626a3d79dbb4ae11c8fd85c4487e97c13d6be026ba041e201b9c60f2"
	},
	{
		"studentid": 6815,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "577e5845541d4bce4ba541f3effa2d477b60828034319d0564e7adf99e5b6943"
	},
	{
		"studentid": 6816,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "95efd9103b3cad18ba105c9db76e9d482343a8ee5f478947def1ae9c089befb6"
	},
	{
		"studentid": 6817,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "c1fec6dadf64078657dc9250019efe6d9869361caf0348e33f2c169fab84c735"
	},
	{
		"studentid": 6818,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "3802ae18e3c00a50a3383cd3e2f7e8a2c4d09b226b09428132a02fce9358891c"
	},
	{
		"studentid": 6819,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "536b4a83203e4b7c7e6959d72f3f8912a0a24eb3b05ad16607b239d3c54e5451"
	},
	{
		"studentid": 6820,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "6ca73491766f5475be2f6b8ada520a22f397ee9ea7f625cd85764ea45579b15b"
	},
	{
		"studentid": 6821,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "84ca0ec5ae8bc7fcf522de9c2b9dff01245f0667d9a07e0b0200e53c3eb36cde"
	},
	{
		"studentid": 6822,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "b80b1a2370d879df9b4ac426194e27d81570abb4c6c4df6b3b97b505eb4db90a"
	},
	{
		"studentid": 6823,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "c7f84d9cf69cc797a514a0dd10f7c4d2688a9e5b3353bf50eda0afd6f8cabdea"
	},
	{
		"studentid": 6824,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "00145185e0107fad0390443173f1b50d4815bd4ea2f70cbffe2ff68d3996b0f7"
	},
	{
		"studentid": 6825,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "7c055f9db9ad685e853b83f931514979972e77b9fa6c01841a6d1f85fad2cfdd"
	},
	{
		"studentid": 6826,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "cb12436b429b568b91c419b86dfe0f3fde8c6ed8571ca2be450b4e004055abc9"
	},
	{
		"studentid": 6827,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "1e5b8dc6aa5e6a86017af5dffca68925c442cbb40c4efa5842958285c576ef93"
	},
	{
		"studentid": 6828,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "a7c2e3c07081088a55c2759c3dabc399b5bd739f2a5d253ba391d2c331e418f2"
	},
	{
		"studentid": 6829,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "ea8d1ecda775b8f054253385dd3eab6266dc7e848654cb155d9fd18eb50b922e"
	},
	{
		"studentid": 6830,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "775b9bd8c1174ad06ccd4c8bc3e9ecc4a740c19c1aa6d8f1438be64827989959"
	},
	{
		"studentid": 6831,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "e97da08f795ca555e1d7a4f2f3d0083910cdf1785b90e4bc3a7c519cef988b00"
	},
	{
		"studentid": 6832,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "f9a100f2d431d49e8f00a112975df7605903641bd1a1c50588076cfc6a0008ca"
	},
	{
		"studentid": 6833,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "f10929874947bd7b42e2065190f4b871d46b24482db7f0c76a0075e32f19fb3f"
	},
	{
		"studentid": 6834,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "563561678241c24793480aeafe80799e0b6d973e167e4ccaed1119a54bb4d15f"
	},
	{
		"studentid": 6835,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "795ba00554483fdf87b39ff55cf26153921a7cbe4dd91b3cdea65e396e4965a5"
	},
	{
		"studentid": 6836,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "6d775f21b02647d7d39efcac84fc882d664ba06e10dcb2acd9d32fa731554ae2"
	},
	{
		"studentid": 6837,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "fea3d9287131c3fa433518a551dc7f95b51600270f3059b5740750539c39556f"
	},
	{
		"studentid": 6838,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "5f81f41d389d07cb8549f3e9c7ce76eac4e95ff3faccfc6d00185ed590775e50"
	},
	{
		"studentid": 6839,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "1051f39e771c41f9a3625c5c393595c3aa608176ef26abf8d3447b8db76742dd"
	},
	{
		"studentid": 6840,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "c068d5c85ea329c57fa385431e5f751a4901bdcb8156d5fcbf9be305c2b2e68e"
	},
	{
		"studentid": 6841,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "b47aa22a5e624a42e156d8a0d88d303707631afe29f252905231ab0d66dcffc1"
	},
	{
		"studentid": 6842,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "8b8e3eb3c8bd7bbb32f3a339f7d413fb1a7c244dc958237cfb47874649c1107c"
	},
	{
		"studentid": 6843,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "1f54fc58b4aa6ec41df94dc1cf58d7ced02c78913e3fa5bb11a6b9765dbf51e8"
	},
	{
		"studentid": 6844,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "5ae9ceabf9e690cfe0a6d77c40f08248327afb2ba5a6516611ce35350457dc57"
	},
	{
		"studentid": 6845,
		"classid": 7015,
		"schoolid": 6456,
		"studentpin": "ef70e70c7b8557538c80f1995e6ee0c6b535dcc98aa3441badd197c132728a7a"
	},
	{
		"studentid": 6846,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "67ec0274b2caa70dcd05066d52379754fe063cc0091d4f301be80881d2eb111a"
	},
	{
		"studentid": 6847,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "973ebfe983a36b9d194cb67c9d93bae9f63e6a49e1bbea635ef08b985076b40a"
	},
	{
		"studentid": 6848,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "1a7a99b401d84c5248b967993491783cd764a93f3c6e7b9e2060250dd5ced762"
	},
	{
		"studentid": 6849,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "5e7a5c0c4684724720f8330b3d1f2cce406fed00cc107268362d41fa4e0f69b3"
	},
	{
		"studentid": 6850,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "3def120a1845d89b2e1527ae97cefd892c60b4e05c801abdd42e402402a3cbbc"
	},
	{
		"studentid": 6851,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "7d7e67ca4efd0ab5df85e76c3f9bf4d39c368d01a90ac0b28e6412055c574605"
	},
	{
		"studentid": 6852,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "2932762ac21f70d9f39041a82e1df9b2abb39d03e49f598413322886de26b1e3"
	},
	{
		"studentid": 6853,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "aca5005cb08858246ca25bdef3aeaaacf70e45bfbce950b516f127d8c4d41977"
	},
	{
		"studentid": 6854,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "d0e0bee2934a281244b9430726e86a92d34e490489d425e10fc88969d774c0a4"
	},
	{
		"studentid": 6855,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "ca0bcbefc0a07a84a33467ddc729ce1471e1d2ab84afcebf5e382b47b2018faa"
	},
	{
		"studentid": 6856,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "fbeef857ecd0c6672d245f3994817d2cef904420dc29a40aad4662aebc7f4cd1"
	},
	{
		"studentid": 6857,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "9e1ee07a523998a66e1f1a53230367225b3c4e0d512dad3e14c088235a3df1b0"
	},
	{
		"studentid": 6858,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "acb5b99aa686b820869b06488ab38c0f44498a53f9967e0f8ff93fbbd400563b"
	},
	{
		"studentid": 6859,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "a9eaba5257541552452faa8a6afe6d66b9969ae6518ffa5664bfc899a38a409c"
	},
	{
		"studentid": 6860,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "b3b9bf6d80d6c10b2a6ada66722c621f758eefd4b897cf1d39677f025056f9ce"
	},
	{
		"studentid": 6861,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "169af13197859b506a76cee30d153f5be4be379c4fe2edbd646c047fd2360748"
	},
	{
		"studentid": 6862,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "41db9823f7179bdc99c6bb8d66a9d19768a86736426ac406f16cdaf0732a4da9"
	},
	{
		"studentid": 6863,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "99ffdd7506226617a6fb6d79820cb785361abb98a4f9cb2ae84ba8caddff5f1a"
	},
	{
		"studentid": 6864,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "72d0baaf49d455ed02ec82b2ef9b5d46fad514642db4dcc1ee283bcfc1a7ee52"
	},
	{
		"studentid": 6865,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "78c536448aaff56e761dc8d0b5eae111f08df3715cbbc2d3e591de9c689c7a5e"
	},
	{
		"studentid": 6866,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "d2b7723026640af11f720b5244c8b8a14b060b3c539ae7c5a0513b796151fcc2"
	},
	{
		"studentid": 6867,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "9eb46816cb1e271252a6d9fa69afbd19cf32df9995682794c05a173ca044b860"
	},
	{
		"studentid": 6868,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "a4db4113471f398c9ace65036b5c16aa378e723d9103c1506fb39f1b98755959"
	},
	{
		"studentid": 6869,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "93d8b75cf222d03753f0e42187a05aabbdc3a7d106e7596f8f3e3ed5bacdf8ad"
	},
	{
		"studentid": 6870,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "16b21ea30cfb0ac7a94178724b017cc6ca9790af7ff42f50048b2aaf403aac26"
	},
	{
		"studentid": 6871,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "5657f07dbc5a669f033a1ef806df6d0dc3124ccfd0efea8946f598317d73afc2"
	},
	{
		"studentid": 6872,
		"classid": 7016,
		"schoolid": 6456,
		"studentpin": "fe5b13e428f18d66a3bebf3d2042fe7358db86b72a364cdb46c6fa5f23fdf8b1"
	},
	{
		"studentid": 6873,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "41678a7d178ad743a5f3d2ab77c07e64f670d8032b18bcc6c4a0b0f54520cbc0"
	},
	{
		"studentid": 6874,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "dd30dc5f9e3fdbac3eeb5e157756b74016b6ebeb2ca95506139e0070909749f7"
	},
	{
		"studentid": 6875,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "af9f4f6cff4cb017a7a5217e76fb00d800a169d499953e98420702a60f209fe4"
	},
	{
		"studentid": 6876,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "adf97c2fc09d04c49bce892663c706a0eae6625ea15914c5520a05bfba53619d"
	},
	{
		"studentid": 6877,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "ca891e08c5c8088472ba9502f9758e519b5220afe31c84cf245fa3215db72fbc"
	},
	{
		"studentid": 6878,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "154d13c54bd130f1660c52e5d7d0cae8c99f37af7ce3940773b5e745c98ace58"
	},
	{
		"studentid": 6879,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "52f6f37e79cf8eb1d0c54b69d788a97c50ca441be3f614880b551855f7bfb17b"
	},
	{
		"studentid": 6880,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "6265a879b85486cee141067ce2921e3daa0eaf141f9ea62c53374e71ccd7b362"
	},
	{
		"studentid": 6881,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "d6220136ff4918973b07351403a251f15f9dd0923c34209056837b27cff83872"
	},
	{
		"studentid": 6882,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "956c095314c0946094278869a8efd7cea2f9837a82142ec548986ce7020dc26e"
	},
	{
		"studentid": 6883,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "c0ec54a395862eb047b029249849707aa8d1bf41fd576983b86872a4ddf593e4"
	},
	{
		"studentid": 6884,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "2ac3a29d03704c301bdcd2a7e1033ffe0e0278c1b0a48bf93fb79e63da7ee9cb"
	},
	{
		"studentid": 6885,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "a133d8d64a6366b520ba902fd0356bc985ed4177911f16c123f7ce0e45b309fd"
	},
	{
		"studentid": 6886,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "e8b7a9793f4dc1baa71aafb67438e3f7a0d2c44abb73bc7eb2df0038c80b1a60"
	},
	{
		"studentid": 6887,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "cfbef339e0bfbe83fbeae87485a89f508e8a63fbd1e42472a1f17134043ad71c"
	},
	{
		"studentid": 6888,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "f8720a08cbc0ca7545ee3d6956487506ef4c73cff4af87fc0a44c4e9089764be"
	},
	{
		"studentid": 6889,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "e92c5ecff052fdc00141c0eb97ddc1f4c7da68141806d354320fe2dbf08cb693"
	},
	{
		"studentid": 6890,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "4eb835c67a1532601d9c1308b1ea7c95add7d9fd303b30661993a9df6b87507b"
	},
	{
		"studentid": 6891,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "55f1c0c0ac8d0bfa2525d9c004fea37b11f56c30d95ce065d49b9282ac7e5832"
	},
	{
		"studentid": 6892,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "d4107c0940412125836e05d136a73760d1c0c68911d62adf7aa60f5813910299"
	},
	{
		"studentid": 6893,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "d61d01c82e8e7e74f2af4a60170aa9e35105c1e5d118d63c9b8acb19785b040c"
	},
	{
		"studentid": 6894,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "4dea726fb36cadc66f0c8b4e9dda06a9f37d90b8e017c88e499c10cc68e4b56c"
	},
	{
		"studentid": 6895,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "d3197078f77157a335eef7dd1bd05b356e70f0214e034290603a18b1a4842afe"
	},
	{
		"studentid": 6896,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "c743ecf3f78a31043a7ae30b69ef95f90f1276ea80f0c1eb30e0561380c28836"
	},
	{
		"studentid": 6897,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "2a4d034e6bf90d37a086209742fff043b0af42dbb0eb9a48556b3bf9b827daad"
	},
	{
		"studentid": 6898,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "58c12bce211da5a02c9783b90afab2d20c76d9adce479f728d1690c503fd2cc9"
	},
	{
		"studentid": 6899,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "6d1588fb474589832f0641c5bb1078158d6f5f542777c676be0e61913cfefec0"
	},
	{
		"studentid": 6900,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "aaa40b2dada06fe1a3a90d312c6b54f9cfc98c9d03b109957b1ad922ca67dc3e"
	},
	{
		"studentid": 6901,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "25ae5e14f0b214db09dcd1e13f6550f1181aef7503345c6931df4e6b3f78bc43"
	},
	{
		"studentid": 6902,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "9da87b3757b669b7c3f333b4e811e1b0bd6cfb65786fbc4f2fa009a71b774ed5"
	},
	{
		"studentid": 6903,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "91f063476969e0c65b3dc146ebbf78e4996c2f86227ef64d31a9903b2d563136"
	},
	{
		"studentid": 6904,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "3067bb6409301f1a23d3097fc70bbf29ee0bd6abc3eae78b6e4e890a33e78365"
	},
	{
		"studentid": 6905,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "6ea0fabe8be04105df894985f49fa74eb0e019ac0850b8c17d08ff9f650a7d0a"
	},
	{
		"studentid": 6906,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "6f1cacccd194106600045eedcf3fe0b0a4aee883efc4ebc6c86514283b3d13e2"
	},
	{
		"studentid": 6907,
		"classid": 7017,
		"schoolid": 6456,
		"studentpin": "582145179f0eca569c3e9da2bcf41357bccddbc144cd2ebb337ee7aaf7f1fec8"
	},
	{
		"studentid": 6908,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "707c27914647008dc23cef8767f19dac479df80c3593829bb6b56a859a00f098"
	},
	{
		"studentid": 6909,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "ded0bd1ea8809111fa451ce90f0ee468450356b29fbbde89cd8a154fcdab36d4"
	},
	{
		"studentid": 6910,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "ad897c54de94c9e2f9cc0fb16b8162fd11bb97f32a7b015551759a6e9189030d"
	},
	{
		"studentid": 6911,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "4b7d9f8db66b3e2bc5009d7d248402193673b4eff561551525f0e74bbd7107c1"
	},
	{
		"studentid": 6912,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "501fd59a51dbae20c0fc1383498580225cf17db6381f854648b814740c024463"
	},
	{
		"studentid": 6913,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "95d78f9cea8f71b8185a3d9e6cbacd105532499f5568db63e9ba699663307e1e"
	},
	{
		"studentid": 6914,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "91671aee36b81be8e8bb09aca71ba65d7d18aa23af0b2bd091a35efaa9465fe0"
	},
	{
		"studentid": 6915,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "e2f6917d68d741f521e5834112942a1852a6ead193da0943a21dbc83907862d9"
	},
	{
		"studentid": 6916,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "40cc941b6cf66c33ada588fb5d3a6af8271384ca41c7d7bb1c4529b341c93856"
	},
	{
		"studentid": 6917,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "ffbad5541ef1535d51c264bd78bb1818ea9f16d8c54cede8d4fc47c2525acb22"
	},
	{
		"studentid": 6918,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "03c0ad50ddcb07d95663963d92d982f16d80669ec16a79e7e873ebf676a5b3a6"
	},
	{
		"studentid": 6919,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "dedd4d53f05abcc1c9c0cf9745c088f16779c6fccd1b25a803929d89f60597d2"
	},
	{
		"studentid": 6920,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "4399d053140dbeacf2e26658a955c7e565fb3a8a24154b117c50cc62bd713b7a"
	},
	{
		"studentid": 6921,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "a2b631f45d6361a850bd8a93200eff71cdf4f4cc47fd7a96f5e099ababbecce4"
	},
	{
		"studentid": 6922,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "dcd84604bcc25c39c5e816ad1000668d3a76831bfae4286fb4ee1ecb7a4572bb"
	},
	{
		"studentid": 6923,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "b2d7a97bd9d30b6af798988b3e3809219c735928722190a6b066ced37f4e4a17"
	},
	{
		"studentid": 6924,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "3276f8a04cc8c2629b66603038354a649fe0a65ec7c6131a015350e9546c3951"
	},
	{
		"studentid": 6925,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "0171aa5ad4af9ca7077e23dbeec2efc6dc3c58a2c36fbeb63fa38c0443dc7eb7"
	},
	{
		"studentid": 6926,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "c23e8765e378896d7496c7d84ff85d0ce273c6707f86553cebe043fec2f4d9d7"
	},
	{
		"studentid": 6927,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "2beb27295f36bf054e71de4f74e74905c615b38906e46f254ddb4b11f8936c75"
	},
	{
		"studentid": 6928,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "12c91891c3faa87d2d849df6d7eb371f7d5395388f32f51a97c2c1b1af6a8d0a"
	},
	{
		"studentid": 6929,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "6a7fe09ed9d77f9437a68c7ceb12da8c626b44f2eb775ec895b147c0f20cf546"
	},
	{
		"studentid": 6930,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "58abc7d410a4e35ac47a6ab59d2615f36c21c14d572602e7736ff4c80dab1f00"
	},
	{
		"studentid": 6931,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "8b93f43b05ab9b666e32006b6321695afd29e2ce887c08914d970b1bd74db3f1"
	},
	{
		"studentid": 6932,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "f0841de13459309501271f393c3b90458eb45d1deb25a9934b7ba541d5c63f63"
	},
	{
		"studentid": 6933,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "14655d861c27b308bf66a502ccc4796da1edec8920275282dc3fe9cb939a191f"
	},
	{
		"studentid": 6934,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "91809f64cdba693359fb1c03929802da216f71dadc5c5dbf9875c06ee54a4e4d"
	},
	{
		"studentid": 6935,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "64c7177a25ba115e69976711b01a41253d2d8bd38cbd39c96fff060e8376b765"
	},
	{
		"studentid": 6936,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "c0ae52330a85a724c9e456ef5f5d57c13a070784e3441368c6f809e171127cf6"
	},
	{
		"studentid": 6937,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "7183e41972233306d0de3d3264f69e4d884717188203594f5ff4c82a04062495"
	},
	{
		"studentid": 6938,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "d6c264104195645f9ac1bb19c741b23cd10ca8f90a96423377faf955d1cfbd96"
	},
	{
		"studentid": 6939,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "cf7b6071613c8bfed8e2e2fb179aad45809207ac8f6b058c32379cfc5f49ef80"
	},
	{
		"studentid": 6940,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "7e07a83e8a5f902e3099e89c56503dc3a79b206ef0123c0ba13670b6a7dc0232"
	},
	{
		"studentid": 6941,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "692d517e333fe59fe644af51a2b1ef4ba47835187c255b55e1faaa647c8eb499"
	},
	{
		"studentid": 6942,
		"classid": 7018,
		"schoolid": 6456,
		"studentpin": "d7a764ec5ffeea56d5aabdfde550be3ef6997dc5a80a4b6fb7f6b30eaa79d3ab"
	},
	{
		"studentid": 6943,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "2e0acf614c92308261e2c7e2639d8ba417e2b8ce49f69a7ae906e6e29b70ef51"
	},
	{
		"studentid": 6944,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "81365e9f1d5eb0f05562eed2722cb4b9679b4e2bce4610d7d18b07d37550c4e1"
	},
	{
		"studentid": 6945,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "fba49cf9f4b6b9135dce39c7577bf89a72bd8e1ae6af4a2113f0f015f0be13b3"
	},
	{
		"studentid": 6946,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "36bb688928940846fb28dcf1e041919755846985c1b9175be5021e1bea393590"
	},
	{
		"studentid": 6947,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "741e357c48a6918321b72b7f8dd68ad7f4247ccf1d8ab808ac497b5b653198c1"
	},
	{
		"studentid": 6948,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "4cceadaf224e27bfb152bfd648e3d0bf1a3e06d04bf4bce5f77856935f7e8726"
	},
	{
		"studentid": 6949,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "9d565b6b8cef1e2b01c4043ff5dbb12222b216e7b26db9c04a92dd3f1bcdb917"
	},
	{
		"studentid": 6950,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "de6ef846e96953d812bcfd7f65ec07826b0f3894bd403a51a133ed2260d56d52"
	},
	{
		"studentid": 6951,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "09eeda1afc1a7c23d9a930597fdc9d8b68f8168942341b1e6acfd864bf939d41"
	},
	{
		"studentid": 6952,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "476c99c00f48c20442cc599e67805ea798e171a1cc09b25d0c84c61188613017"
	},
	{
		"studentid": 6953,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "18a8f8c612b435f017651f7343264edbf6313e412a145a5f08124cfa76c15529"
	},
	{
		"studentid": 6954,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "ace1f071e4c519327e6372adb40a419df1c814d9989a67c396d5049d3dffee48"
	},
	{
		"studentid": 6955,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "6d9c147410875c4f3c4311d90c2fe082ac5b5d72e6e5351bdd099ec8733d0347"
	},
	{
		"studentid": 6956,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "cdb6e1448333e28cdc1e288f0dbc6d5aa22f5bf396c0ad0af0dd865cfc32aebe"
	},
	{
		"studentid": 6957,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "9361415e21a9fc95af919640490202e20805973aef137a4f9518d51a32bbb543"
	},
	{
		"studentid": 6958,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "be7d282ae71502ea00195b7110764889dae84daf6b1ec386bb490598385183c4"
	},
	{
		"studentid": 6959,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "f7f8f3b5943faee84d6660f19ec9d42d9326fa092544987ea0ca73bdcf305337"
	},
	{
		"studentid": 6960,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "2d245c5e645df28fdb5baa6488ac77f1f80e51b916d12e862418dd8f4953338f"
	},
	{
		"studentid": 6961,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "e033987c4b13ebbc84cd3294516eda4d2536673322cd1ab9874b0a8e4e1e97ef"
	},
	{
		"studentid": 6962,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "0e17d6d98acbb05333e8288b0df1c0a5b9cee9a0ea0908526f5b5b066112aed8"
	},
	{
		"studentid": 6963,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "92097f066e32f0d80802437d68862287a2f0103f1a0670eb9d3504d4872f7afa"
	},
	{
		"studentid": 6964,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "8fe875a2bc93b102e3953d9e9c291b4a5839b7bd752fc33e37c28a146b374ca7"
	},
	{
		"studentid": 6965,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "b3680cae2411777f311982f5aa0c598b877824e3d8797a6a564d3896bdb81d07"
	},
	{
		"studentid": 6966,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "9ebd5717394999f31b682ea31c7f27ddacbe7415d36c54241d6d462e5324938a"
	},
	{
		"studentid": 6967,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "aedea6493646d59bb8577016b1228d54e8ee6de3ea9cddb7edf769bd9a8eb5d1"
	},
	{
		"studentid": 6968,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "efb72613eab8489930ee05e2b2b89bdfad65b48cbd1e7d0d8365971a7cfe15ed"
	},
	{
		"studentid": 6969,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "a54c8b1a68c20713b6daa7a3172815d58a83ee38977c9e7ee48d8cb4853b9e29"
	},
	{
		"studentid": 6970,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "9df5e2edea41ac6344ccfc09decf2cf4e7ba7d6f716e5870bf88ba9aad41bf80"
	},
	{
		"studentid": 6971,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "c99fcd62314037c55e0932bacc9cd5827615a4e835d11def256c39054029f8a2"
	},
	{
		"studentid": 6972,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "63d236a21b36edc7a3f9f9a456a1f7b0c266b6aaffac42fd54c88c83df5ae19b"
	},
	{
		"studentid": 6973,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "54dbb2fc7e26d8c63144ffc41862d831adab6cd7635597f34c902e90e72436ce"
	},
	{
		"studentid": 6974,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "94292ab8e4b6288bec8e8952f805f992e8181b77f9ddba6a5ca6c2f956866cd0"
	},
	{
		"studentid": 6975,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "5eb389a8fd27086b77583e59e8bb4f0d98eab65f70ba4e0c7658e031e5c1b7a9"
	},
	{
		"studentid": 6976,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "ef647ac74e6582ccb33fac4db8c36d8eaa6b43ae00484cadd611ca5b804620e4"
	},
	{
		"studentid": 6977,
		"classid": 7019,
		"schoolid": 6456,
		"studentpin": "ae4435221b420b7a9febc0aec0b8c41e855d5e14b84c792af6eef03af7286c62"
	},
	{
		"studentid": 6978,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "1c33a05dd925a1095f680f42ab228f4eb01bb10abd9e11ec0181eeb34cef92b5"
	},
	{
		"studentid": 6979,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "5d7d4a8fb84a80f089bd5bd4daa457ea267c40e2edc278429185d0c7d9c2bcca"
	},
	{
		"studentid": 6980,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "88090b73ce11d956bdc7f44671c4c353692c0230b42ed9b972aa477d2cae53a0"
	},
	{
		"studentid": 6981,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "664aaf3d447d325f914b4a0337b4b31655b09d84572281509fe8419485d0320c"
	},
	{
		"studentid": 6982,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "7f3e4befaecaf42d8fc0c577db92248e053f900978f92aa45c77b5e9815eef99"
	},
	{
		"studentid": 6983,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "cc50b5fd1dfc8ae1d359ee32db3b3799a6fb08412aad6f1a81b5033495a83850"
	},
	{
		"studentid": 6984,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "dccb3379fefd29b7d969b079402e05a5c8e307a331e2725d65f08db3ffc31cbf"
	},
	{
		"studentid": 6985,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "7a31ed7b427744bb214ec3211e474d05a77b2072ebbedf2a55114f312d50a370"
	},
	{
		"studentid": 6986,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "53e1f07e808c9925bbc9b8dd246e0b0d52c35edc594093765896855ac773e2f9"
	},
	{
		"studentid": 6987,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "519b70074f7d2cc6fcb19c4d2ba915e1ec00fc5dbd41f82f48b8d685ea85ce96"
	},
	{
		"studentid": 6988,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "82d1134523575527ef15ad356cd10eaae71f0cefa90e3d191d555d0584957793"
	},
	{
		"studentid": 6989,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "e72816ae6a6ccb526ff8c0985ca2f09e11330e57ab23ce7092a5bafd0dda7bb1"
	},
	{
		"studentid": 6990,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "830ab84dc9e5127edbd805ebfd8f2524e0fe8ec411987a9a75dcde0d9c112c6f"
	},
	{
		"studentid": 6991,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "673753319ac0c073606b40c1e33091594942274a451f28267d80ae3c1be2bbcd"
	},
	{
		"studentid": 6992,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "b9492b957dc798a526eecca93c7a82ac2d54816805773898087e1dfb6dbd36e7"
	},
	{
		"studentid": 6993,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "c4ad79c563f274fadb1c046dcd62e0705aec2f6ec578a34f3f1fc4f3c570dad9"
	},
	{
		"studentid": 6994,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "1f19d854469697bc7c4f36702d641414afd9cdd822ab3f646f52024075959d36"
	},
	{
		"studentid": 6995,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "a44dc79e88466708926b27b40b4424254b3b9ab91273862796dcf9e94abee4f0"
	},
	{
		"studentid": 6996,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "c445572e5f2dd42be708e894591a4a324d47cfea10e52f891bb9f34fd900c317"
	},
	{
		"studentid": 6997,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "2f473265f32b96e83b9a1d406b3cd782466e97b7047dcb5206b9eaaeb689f9e4"
	},
	{
		"studentid": 6998,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "8b84b355f36518ce27896c5b22a0554e5b99d69e25158375f83a99919f61d266"
	},
	{
		"studentid": 6999,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "e570de3e217722b9ea0ad00511c9ea2a92115dea0388e63c560c4506d4bd6f8b"
	},
	{
		"studentid": 7000,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "072d6442e31508bfb4eab55681dc2dcd20d1b81b02157b1dbc8678342922de43"
	},
	{
		"studentid": 7001,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "15c7fd048a5a374c3340f17b37ace6d280676fd1adb69b52f1e2eed444ddc57e"
	},
	{
		"studentid": 7002,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "4ed7ec57318d774d771db5e3ad1bc330574d4c3e6e939b3d345a9651740cd1c8"
	},
	{
		"studentid": 7003,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "bb79f584bf4e1884e9caf09e190afb9f679e98edc43d1b1b75b713156012b99f"
	},
	{
		"studentid": 7004,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "db9b273ad66ea94e28556c6230529f0b9bf6892ff6e1efff79b6cd943a19c46a"
	},
	{
		"studentid": 7005,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "73f92364d7d29774180ec43090f375dc3fab0f41d09e2cd992b22e8d37bd6a05"
	},
	{
		"studentid": 7006,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "f9c840206862b09a035e552c94c7c67839b3b8fa9c06c8e6eaa5250594c9c8fd"
	},
	{
		"studentid": 7007,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "e17fe33ef474d4c00d2a09fb72312c2671abd996b36c41f812389628142ea77c"
	},
	{
		"studentid": 7008,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "840fd7ca1512fdccc9d474b926bc8640fac7f14266cfd2352925d491fee42464"
	},
	{
		"studentid": 7009,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "cf810aa247a02e0a744d0a5702c5f559e040c318d850cafe7681868337abaa9d"
	},
	{
		"studentid": 7010,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "af020f94c63cc64d2f735eb8164933c28491664f9d52d8c327bec064743ac8fb"
	},
	{
		"studentid": 7011,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "9a19fcb3bc03a68b7b94a9f72109ba2796a8f29e6b00bd3a56d14f5c5cd9ca38"
	},
	{
		"studentid": 7012,
		"classid": 7020,
		"schoolid": 6456,
		"studentpin": "85f16f97efc33dcdbed864ca5718a13e47d01108462cf0f5c08cc72bc6a78de4"
	},
	{
		"studentid": 7013,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "1a2104363ed9c79951ad677181dd03ffc804e815bdc21036a1ec0a1d61611525"
	},
	{
		"studentid": 7014,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "a3a48ba46fee954455a2f9e78d0894657ed4aef0ff200fd012b0aed9a03bd2ca"
	},
	{
		"studentid": 7015,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "134d29ee8512fcbf1e9c2ff6b8c0e4c85a074e95d1f5323500b44bb08af1ef63"
	},
	{
		"studentid": 7016,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "0b6e1ee77974cf384e72dd690793f9058aef637fa08e71bf6e2cda52e17d698f"
	},
	{
		"studentid": 7017,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "8de1bd01334f9f2deb878d9edfafb43e6add5e6a47cede5eaa15172c6a52117b"
	},
	{
		"studentid": 7018,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "b2b2a2177e1aba94186fd83a92f819a6919acc8e05931391c87e22ef47354f89"
	},
	{
		"studentid": 7019,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "021e197a43fcd1e3016da5adffdf0229c3c38b7463aed057572c377bdf51e1d9"
	},
	{
		"studentid": 7020,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "64bd3979e089c2793104948606c8638624874a5ce4c396d9e302e1eacc4815ab"
	},
	{
		"studentid": 7021,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "a5f5b95f6281a43dddc2c697190becd36e3c7e982507d3043bdaf4644f7cfb48"
	},
	{
		"studentid": 7022,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "51fac6f4172ef9b8f3439ec2740b90ab07d51b277afd7444eb8b96986355f794"
	},
	{
		"studentid": 7023,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "4aeeae75acd8e22a5893f66b6ee52e01a997255434122587d39520eedaaaef2c"
	},
	{
		"studentid": 7024,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "391745ba87b490b10b785232b79f891f0eef314f932066a9cbaffd19b73c9159"
	},
	{
		"studentid": 7025,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "4755c8731170933c45ec7297897d4f116d544ad47bcca5caa3dbdae2b58140f9"
	},
	{
		"studentid": 7026,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "2efea33e2ee53bf0e86412113c97f1c52333855efb9b888b7ed93f031d1eb174"
	},
	{
		"studentid": 7027,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "7f886b3203649d931920e4a809008332567a5a373d1361aff28233100da2442f"
	},
	{
		"studentid": 7028,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "09876d52a90d5a7849acff5500d74fa68622b38937ee70b83bed2738c02322cc"
	},
	{
		"studentid": 7029,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "87c72d1b0f420b0c73d1072810752e6d4351c553cd742ae3a6944861b5d3ea71"
	},
	{
		"studentid": 7030,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "ab7e5abf0881406f8d5f54f912a044bfad9718f94125ec7c4e72e8805176d321"
	},
	{
		"studentid": 7031,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "20c7034a804aaf1cf2d6a14d13393c7358b991eafb8d4af7a8ac14ba22909529"
	},
	{
		"studentid": 7032,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "18fe986954dd3a77a945167f03392ba903399c444c1abc017fa4ef7c21d04a08"
	},
	{
		"studentid": 7033,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "083b8a5e949d1d0f616781f89605d835813e4ea628003d96f22fd594dfa125fc"
	},
	{
		"studentid": 7034,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "41e26f6c6e157eddeaf9b47b6dd1b9160c7426b02e30ebfa6a21f6f1e5dad536"
	},
	{
		"studentid": 7035,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "79f7872ff1a5c4947b9ae04515a0bb8575f6284e1d5b0baedc7a02ac54cde6ca"
	},
	{
		"studentid": 7036,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "404752c1ed496a8fcf413be8901b029bec4aef9d9ace3a7db08cc3240f77e1b1"
	},
	{
		"studentid": 7037,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "f4c77914fdabe8f64d0106304cc714d40619f3aa168b4740e1e93ef21f40971c"
	},
	{
		"studentid": 7038,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "fbcd5d16b3cd1b34075bab024e3a5a73da70d2a190a29d8403e12c7ccf1f29f7"
	},
	{
		"studentid": 7039,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "175ab8920c2f6262f5706f5e8b8b942a08f1af2a939c05d31a38fb7b25dcd997"
	},
	{
		"studentid": 7040,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "aaa54491e6ecc381e233661c2f8dda42f6c22a3466424e937369c2e207f3b1f0"
	},
	{
		"studentid": 7041,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "dd5daa8e4cda92240416179acbc45a1ef967d18be31ee250746b00e45d03e82e"
	},
	{
		"studentid": 7042,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "8789613cd12d7db8ddcd6eead62c213790bab52cf34e1fb625cce9006f66d863"
	},
	{
		"studentid": 7043,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "da803e753c6e3551d11bfdb8caa42537cc30098eeb93ecc9212874f2798dc7f8"
	},
	{
		"studentid": 7044,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "418eda346b5f4644ee2fd955f7d8830d1350635ae50d1b43d1aab49bbc4c31a1"
	},
	{
		"studentid": 7045,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "26e3d9459ac73ceb3d8447d4847935b3ae28a82f91c5d43aec6fd6ffc865370c"
	},
	{
		"studentid": 7046,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "a96c9cba511a97dd1bcf42a02a5b8527468a62bd033e731978b74044fadbf0ec"
	},
	{
		"studentid": 7047,
		"classid": 7021,
		"schoolid": 6456,
		"studentpin": "3a81a3bfe8c0e363592c30fd0208441584d426e8b79f28044bc38d47985d533d"
	},
	{
		"studentid": 7048,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "6a7acf82a44c63c83d257b5e3183dfb99183a578a305afe11bb14db9e295dc98"
	},
	{
		"studentid": 7049,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "820937570032e94ae0b99736511b39f3af97c66bcb6f09856f8353af9ba34825"
	},
	{
		"studentid": 7050,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "a2c5a3807cba8332b864b0b95ff7d83c3f540fbeb2ca886c5c356a77b85f3f2e"
	},
	{
		"studentid": 7051,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "2428c8977f2e1e487bdde220674801f84cf863ff1d39b293be3d1e113410aa3f"
	},
	{
		"studentid": 7052,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "dd1289f2ac7e3773ad39bd3877cd4976d390d723eb7fb1eb8bce7ceffa3b53f5"
	},
	{
		"studentid": 7053,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "00264bbb0c0ba952f6417c4e3f720e97dff5e389ae46fdf502c413e523307981"
	},
	{
		"studentid": 7054,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "cb02ec12c96d3a623da014aa03ae48fdc3a355c7a12bccb64701d67a2abee538"
	},
	{
		"studentid": 7055,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "bbd81a272177f0adb94508f1f6ab2217d7d8f338305c34c5ff699e7673afdb9a"
	},
	{
		"studentid": 7056,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "f0c268c331d24bc2dddf75664fc9d0b9bb3ef7dc744d4292e8a2a0688e1348d4"
	},
	{
		"studentid": 7057,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "957616d487888aef20de7177762b88879e34bd76efaf86c318532c1b1155286a"
	},
	{
		"studentid": 7058,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "ec62fa2022489d45ffc087dd2c56bd6e850a4668441da652c16fd616eca6e29b"
	},
	{
		"studentid": 7059,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "58f7aa54cd6030c5e1fe81bef5a05b865c6d8437d0fdd9c32258294078b7039f"
	},
	{
		"studentid": 7060,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "c6a5a5129a32487a0bb98eed2714ae522eb286cbf174259394ac3a8f012ea72a"
	},
	{
		"studentid": 7061,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "8fe0af51ee212bb2a8d955a7d834a057b7ac34bd733fc950f346f26ed26216cd"
	},
	{
		"studentid": 7062,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "65c70517b010f4d71f00d189ad1b64bd7fc54732941270440362f3b40dbc925d"
	},
	{
		"studentid": 7063,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "ede890301faade8ef47b668197f1696de151ebb963ffca3f792357acd4b17df6"
	},
	{
		"studentid": 7064,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "a3e75d1ddc8b524c03e0001c9ee6f027cf4abd6bcc90b03f0eb5bf270a7b1ec0"
	},
	{
		"studentid": 7065,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "4677fbb873c5a7cc7b05d7163d642507fed5ce4c5e5a8952bfe05f02113ba369"
	},
	{
		"studentid": 7066,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "dda411665b8c4bed7dbd5bcba9c40daf08676c391404ca8b4cb0c249620718c1"
	},
	{
		"studentid": 7067,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "7cdbf2ca1d63ad9f6ee55d52a17cc626d217c81ef65ff95377a7d9a600d26f40"
	},
	{
		"studentid": 7068,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "41bbf2a9e543807d53225114355330e257ddf07a23e5f8cd430c15576c078552"
	},
	{
		"studentid": 7069,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "df70242c1b5de4d0786280c0e5340b3fff17e6b938404a2407118a24a4c06beb"
	},
	{
		"studentid": 7070,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "7ea02078c66b2f2040ed35e5fb817010e284b450423b199778858a30399fd2f2"
	},
	{
		"studentid": 7071,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "6626b21da85083a5dd1861ade3306fd10943e686810ec2a6f54c65e85d21b3c1"
	},
	{
		"studentid": 7072,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "a32e45785e2ca83e3717c1eab62fac543cc47e918cdefa5f9796b8e4cec3532f"
	},
	{
		"studentid": 7073,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "8ee325ebbb7c95ed778ea4132e90d07d6e860ed5d6194c5294d4710511d493ad"
	},
	{
		"studentid": 7074,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "6706899aa98ec77c583ec1d988b9d90fce59be00156e286e98be08ac0a06c748"
	},
	{
		"studentid": 7075,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "ad6a4ccf2ea7deb2a05918285aeb7ae3cfb7864c1fc6caab3169e4b1ce401b24"
	},
	{
		"studentid": 7076,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "a6c346b17f86104a6673a95f9d799b9f6d6f7ea43f473feb12d199d307cc69b1"
	},
	{
		"studentid": 7077,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "b81187005d4b43214c7d7856554ae3ceac2cc98817760627781496ee43a0b6d6"
	},
	{
		"studentid": 7078,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "e8ed112a04e7dd0521cebadd03780cd136b972f41913d8da28df86735429f453"
	},
	{
		"studentid": 7079,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "7ca74be4e85432d8edd2ff594a18b938c1ecff838fde6d35b60e2429f29dd3a1"
	},
	{
		"studentid": 7080,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "a5abbb533dcaf450e9c947d757b4a73af7966ecb3221fc0a53c786cbdee22ce9"
	},
	{
		"studentid": 7081,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "2038e234e11daaba866e4c32d5cb58b34a78ea5f05dd5dd21c57095825157204"
	},
	{
		"studentid": 7082,
		"classid": 7022,
		"schoolid": 6456,
		"studentpin": "52717064635ed2143bea81fbc19734987ddd447cd87acc59c9dc4bc5393e7eb3"
	},
	{
		"studentid": 8707,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "79e09117d830b1a28a7a0322ab0e43629d3e747db9edd9ef09b9132bf866ccad"
	},
	{
		"studentid": 8708,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "0f64f5c73d68e13389306a2de165024bfe2e77b8167462dbe81784bbeffb1299"
	},
	{
		"studentid": 8709,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "0a7f998cac86b25bcf74bf4a98cf7efdccc060f110223e9eb34a4321d118f5f0"
	},
	{
		"studentid": 8710,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "a875472e53dca873f1190a7df3defaba96a9cb9e7470d9a778711e39faef8184"
	},
	{
		"studentid": 8711,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "a122a80f073f7c12d3beca15ea3acee8cb5fbfa50f2c506980798ec0c7fc074c"
	},
	{
		"studentid": 8712,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "3e4d750a71c808a865cc68c527eddafed59e5c518cdf2f8836653f8192cbcdc4"
	},
	{
		"studentid": 8713,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "a8ab2fe881954519afb793741ba18ff18546345ed06b663e500edf0b44491f79"
	},
	{
		"studentid": 8714,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "ccf2556ffc9fc179956e098925e41560bb40a44f9a2f2e6c21bdb29e04ec4c34"
	},
	{
		"studentid": 8715,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "b692310ae361c4576e794f6fb53cd3017d0cffe2ad90a77bf37f5b6a389016a3"
	},
	{
		"studentid": 8716,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "98edb6c0de0870403d3d38a1dd5eea404fb932c19c01252c42cfa3008445f96f"
	},
	{
		"studentid": 8717,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "62f1a26c50d9a34072f756be3aeb950e9fe87258d7b68fcbca9430d4331c859c"
	},
	{
		"studentid": 8718,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "41adc5a34a61fdfb7e3a7fb0f636fc36cd75f00f38bcf73e7bb759b7df27f633"
	},
	{
		"studentid": 8719,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "0fe1f6690eb3b715c8b92baf7cbfe0b61b0021b1f610d56d24ca56cfeaa81cfe"
	},
	{
		"studentid": 8720,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "0224e8db69ee65d5bcbece7054941489c83b7bd8227d197dd792189d0b10af8a"
	},
	{
		"studentid": 8721,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "6db2f9548cfb66d872726fa4aac9ec3f2399d542891b0f5be9396ab473983d04"
	},
	{
		"studentid": 8722,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "b4a65d42727a2c9c50fbeabe92e49c60960d277de7f02f049cd1f37779447ef0"
	},
	{
		"studentid": 8723,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "0b67144c4c0a75db23d98326078ec7718fef1442b746369ee13e84d6536acaf1"
	},
	{
		"studentid": 8724,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "1e5f5cba3ef5a829d3e6d4e94456eb879ba5a92aee59d9b48f413648b67a21d8"
	},
	{
		"studentid": 8725,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "c72f91565659d892aaaf9ea9c58ffc14a7be6360c12f1f566cbb8206d62a7562"
	},
	{
		"studentid": 8726,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "d70352439b41854f1021af0c313f7ab47d4109d196258b7162ac1e52f76b1e55"
	},
	{
		"studentid": 8727,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "6508e32bc95327645639ddf3af068729b260699ad0d88551fb2559f5a74fd730"
	},
	{
		"studentid": 8728,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "92951f4d2452d9f44245d1872187c3684afe8e9a44c38a748132dfd69973e775"
	},
	{
		"studentid": 8729,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "8846a958ae22b7a2caf5ce0f3e8df35524598b1373a5eb15275b301e727c472d"
	},
	{
		"studentid": 8730,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "2dd209ed5a8aef8904582853a6e193ae7fb51f55db8b40d3eb8be70a2a42eaef"
	},
	{
		"studentid": 8731,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "685aab929bbce4763dd05f2297aa9242d1428511172a0a669b9ada0ddcd46311"
	},
	{
		"studentid": 8732,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "a60c5813fc0da13707672dcde71e16562cefd27d3aa8ed5141035223efff4b3b"
	},
	{
		"studentid": 8733,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "5b518f29f8be56e4b2a028555fec7310eb2484345fe5733b98b2c62ebb3d0132"
	},
	{
		"studentid": 8734,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "938d57bead1b1f82c383afb48138ddfe54561600e7b93b8070186d92ffec7005"
	},
	{
		"studentid": 8735,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "4152dcb33ee1a9da4b8c74d56198145ebfcdf6cc2069ed970c104c37543fc109"
	},
	{
		"studentid": 8736,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "fc21fc656c5661f0eb7e44b317aed539e0e4aa515f826d40c4e9dbfbede78025"
	},
	{
		"studentid": 8737,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "ef4ee3e9f49cf7c0ec55712a86fae7ab3034afac7e441124645806167bd22b31"
	},
	{
		"studentid": 8738,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "51fef81bf449adb25575ad88a898012e305b77162c2907f7c8945bd75d236744"
	},
	{
		"studentid": 8739,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "283f652a9c306145a9d8ab497aacb842e916f8bf42850636c1d5bb04c9e368ec"
	},
	{
		"studentid": 8740,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "ecc2a57793a2d19291127749352671bed0c1c469fd10d4868ad6afb72efab16f"
	},
	{
		"studentid": 8741,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "5402364323f253098be4a0b19a934971b6c6c9c8aeee669e8454f6e4c7eb25ec"
	},
	{
		"studentid": 8742,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "d36d1efe15317d8d3205aa1af62323b0b13d7fe77ad3980d1ceb9d12ca7a4da0"
	},
	{
		"studentid": 8743,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "46bd3af1274a6c5d2eb40d136963986e67abdfbbbd1cdff363f811e040edf58e"
	},
	{
		"studentid": 8744,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "c3301cfc5345271fcf2a6b942e915ad4fc59465a480b80ee4c8b47090acfb65d"
	},
	{
		"studentid": 8745,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "23bc0fa5570a2b6385e5c79fa6a2bbb407559d2085f584e68c200f03ca1e837a"
	},
	{
		"studentid": 8746,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "b763a9baa87d12b1a41e8ae3b782019d2e7f7b1773024e5808e03c1726c2940b"
	},
	{
		"studentid": 8747,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "a492d0b817daa93065adbc5b2f1155178f5c19801d955654c42f1f1af90d063f"
	},
	{
		"studentid": 8748,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "056551f4cdb5662ee0568480e72edc343714a3e57ad0cd95df0b4fb5cbf6bd97"
	},
	{
		"studentid": 8749,
		"classid": 8269,
		"schoolid": 6524,
		"studentpin": "f53c3d756ed50b12e2343c21078d9053567b6be456bb76e3a79c1f69d909c63a"
	},
	{
		"studentid": 8750,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "a0261bf693b813da05dd4b7dd8ce70f056401be6d700154057a1068c0f25e080"
	},
	{
		"studentid": 8751,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "5ca301cc6b6dc8d496f89af5eb9308ee2f522df9fe3d9a8b18e3a04bd012655f"
	},
	{
		"studentid": 8752,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "26b83aee9c0da3c06b336e6547313f23c3e520394a9b65706f4e434e07a980fe"
	},
	{
		"studentid": 8753,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "8efb6bac160f8a8672e2f9e273f44cc1eba9964cedc2f56d877939d4781a84ff"
	},
	{
		"studentid": 8754,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "b88aba5395ce16a0167443180d594c7fb85f3ae3e196964f06bce990d198b90c"
	},
	{
		"studentid": 8755,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "534b33e7d1e6803f6ad9a0b223da11f80805edc584938bdaaa21b3b910c527bb"
	},
	{
		"studentid": 8756,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "0017ad6898bd2d826cf832fa815b350ef7cf3c628843f87ccf26c9fdb211ba9b"
	},
	{
		"studentid": 8757,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "9ce751c6635f6c5061a1d6082ca2a87cd54bdf3d2e80111e8d1ae72965ec6304"
	},
	{
		"studentid": 8758,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "e0b78fc86fc94b20e9839f29a236e992c5cd0ea8d60854bea34160defcf94475"
	},
	{
		"studentid": 8759,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "d8a1e392eb8c8ca85d544f865b9e1c4b8c6471e4f2cd3f49dbcb7bc9fc410b0c"
	},
	{
		"studentid": 8760,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "6ce3060a67e5a86da7e5cdbc6b48bcab5275368d8cf245f23aee8225513e7ae7"
	},
	{
		"studentid": 8761,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "129647c746f814597dcc582946fde03648df49d58d84abf34532de85974be7ca"
	},
	{
		"studentid": 8762,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "756ab59f1262f8002b79118eac212275a5d5a17baddc6d4e2cad352d53d75a8c"
	},
	{
		"studentid": 8763,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "43eaf566ab7e863dee19d18ad11e86749bb12c5c26e3c2da369c66bfab4d557d"
	},
	{
		"studentid": 8764,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "ccc6b9c433a85eebdd07456dcf1219489d386750b654f7933717acfd65cdde59"
	},
	{
		"studentid": 8765,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "3cc3f6018caa9eccd0fd6799d3d25c5d29aaf46a7fae90854f1f8def4b177243"
	},
	{
		"studentid": 8766,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "90889d0ce92a5c4c0e71e0c07dbd876fd517a72180c7b06d5d7d89e5995f9609"
	},
	{
		"studentid": 8767,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "65d3b7c79934c12a8939a46d52b81eac7ea9fdf049fd36ce265e03ec2a51eda5"
	},
	{
		"studentid": 8768,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "58eb2cf71ab9f918eb4fd11bfc1416b3797157865d8b427caff7e35906bbb9b2"
	},
	{
		"studentid": 8769,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "c12936c89deab919dcbc41a38c021acc2b2a0c7678d4f39a3da22831c4bb9ae5"
	},
	{
		"studentid": 8770,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "72cd70d605e4a71d989f560659c2f19348d0d24e54fa4ac4008e915061f7f974"
	},
	{
		"studentid": 8771,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "4ecd648b8dd8a382af0a514bffe13b46bf4044ba135b1eecdca9afaf5aa0a71f"
	},
	{
		"studentid": 8772,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "8f70f2d348b2c464dc0d748707f088dc0b1c6cc2c0422775c07c7e952168404a"
	},
	{
		"studentid": 8773,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "ed9acefa425433b43c3c4cc045788136f314f8b47e8125ab952b641c2f6d1d09"
	},
	{
		"studentid": 8774,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "d8e02a7e8dc836940f75e59f65b40d295f7dbd5a5085a05c07d3e3d2b4213304"
	},
	{
		"studentid": 8775,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "b14c8e4d50b8d2d756a4f5470a35ec480cfc58d3c49ecbe4b3bce645f28bff21"
	},
	{
		"studentid": 8776,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "0da31189b32dcb8bb03746864bb7f506b1df3928df4af11b7bbfa95004d1f578"
	},
	{
		"studentid": 8777,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "965afd32a365af1587391c1ff10457e172d52b34dfbcfb0dbf67e33a7f253f62"
	},
	{
		"studentid": 8778,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "bf0555fa634e5fe4480892570f18096b0b35c4dfe36ebbdd2e268662abb2b81b"
	},
	{
		"studentid": 8779,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "4fcd4fdd277f28fdac82100977830a82a86e94699dc84ccc948bdf9b46ffd5bc"
	},
	{
		"studentid": 8780,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "46e93c8e3e705548c31652161f66c73713547da276e9ee066737ff51c435adb0"
	},
	{
		"studentid": 8781,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "0c1013d71cde94ac414887c6c83283bbb78c9b1eec0f48a30ce341c5d130925f"
	},
	{
		"studentid": 8782,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "c8eafaef0b4a7d3195c7d51933434da1b8377b559ef1eb1934c9a907a9ba5bda"
	},
	{
		"studentid": 8783,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "b336f11ac753b5c062eb102a38cafa3d56fd91666388b35859b8dcadbc8f8b99"
	},
	{
		"studentid": 8784,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "e13a1f216ef9a5e66820546953c2eccee113850e208fc7baf090e29d6967c64e"
	},
	{
		"studentid": 8785,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "7b0bb8426f6f9024c3c7e9a87a863187739e5c480a4075b055f36a748a23b408"
	},
	{
		"studentid": 8786,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "615d44cd95cafcaeadab101961cfc52832ca2cbed57f108606b177ec7ec53b2c"
	},
	{
		"studentid": 8787,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "2af9e34fadb2d43adc76e92e524491711f9b41eebd3ad68b9ce6577f530a9f48"
	},
	{
		"studentid": 8788,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "0febe5671569514af9f67701e9847bbf38757bb9c14014991b2a3fa0feb6bf90"
	},
	{
		"studentid": 8789,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "b48508bb0df0e8d805b68fb9cec4d20fb4c179fdca6320232dd75245080cb7f8"
	},
	{
		"studentid": 8790,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "cd84e9f716defbee75f36998bb1be7e03dfa05443c8087b882bc6a4a4e6d6d1a"
	},
	{
		"studentid": 8791,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "c89f8f8b1f1700fd34a3e087d3a2168832d6bb1015e080b1f8dec27618c25700"
	},
	{
		"studentid": 8792,
		"classid": 8270,
		"schoolid": 6524,
		"studentpin": "90143d6eac5e0340229be8f14540eb25e4cbf295caa22332ccd93e2e004f5c83"
	},
	{
		"studentid": 8793,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "0987458bf83b2c4b99d7c7135b079026c2950203cf2c67071991b59a736acc0e"
	},
	{
		"studentid": 8794,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "e18c315c71910ab4507c772073fb2468d39867a0f13cd247eaa1ba050d47f6f3"
	},
	{
		"studentid": 8795,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "2f09dacf1650cf7fe988b79a44fb92be8cce8238fc856e4b3d5cce1c796d0b9e"
	},
	{
		"studentid": 8796,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "750f645df23458bd5d0cb0b3973fa5d51a357d67154fc6da5dff6b10be61c0dc"
	},
	{
		"studentid": 8797,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "428fa7b556c6bc1a2d24ff7d239e6b53845ebf4c833e71624c866ad949c2fba7"
	},
	{
		"studentid": 8798,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "61793d30893565087a0a5c25b573cc38e22ece8e8f07929cf68a77383b952721"
	},
	{
		"studentid": 8799,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "690d6f83bfe2cb9f7d87a3fcd95feca6bd4b7594d1768832605ec3a91521f2ea"
	},
	{
		"studentid": 8800,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "1454eb522d5d555cd40b23b04ab2faf606da113f969ab2830fd38ad0f0af6b7c"
	},
	{
		"studentid": 8801,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "bac1aeff9f6501cec238074c72bf6932b2a0effa515a7b6b5473a6d810436cb8"
	},
	{
		"studentid": 8802,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "a1c9ffcca254e66a29b65318e8849c8264890f303371040896b870dd1544a145"
	},
	{
		"studentid": 8803,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "afb64248b6bf21c686b8ae9540c549ce6c9fed11863e3fe88c35cf87d4783bc5"
	},
	{
		"studentid": 8804,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "a054440765cea75ac8239fb9584816d3c5cd5d04ce827b5c6b6b7d584d521116"
	},
	{
		"studentid": 8805,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "880e4277e1989e93a7cefecb8230fa268032c50771167dc94f1637afc3d91e2b"
	},
	{
		"studentid": 8806,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "6f49b07c950c34134dc583a557e470421eb687902841ad1b379c2eca57b86b8b"
	},
	{
		"studentid": 8807,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "34b67e68e76a4d963e0fa5e8b70ab438b39ecabb8e5330eea33129ad461b1316"
	},
	{
		"studentid": 8808,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "3475e69397e950eaf557d39a66dc327b381b4384ed9ee7ec9d9647f4a4570ae2"
	},
	{
		"studentid": 8809,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "4aa09da9fe92b45d3fde56b26d086d024c3b7282bd66bed891d9c124805c2a5a"
	},
	{
		"studentid": 8810,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "e37a8eb4fd8f424911c66ff4f2c0719de86402d23dafcfe10ddb919eac11f206"
	},
	{
		"studentid": 8811,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "0f73711c0092cabdd329b056cfb7e02ceb2cdc2350d178aa446a3663842b3c62"
	},
	{
		"studentid": 8812,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "64f9e396d5ed7c755aa899d7ff4ed0de8f1019eb59393da224f91770c5fd25e1"
	},
	{
		"studentid": 8813,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "6c629420ebfa33fec5fc2d8986e68c95912d0098439a6b407f7c7a9948f7c958"
	},
	{
		"studentid": 8814,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "b075f61b0707a03c2de0e4263803d87f382756afd55855b2140d07023ddd37cb"
	},
	{
		"studentid": 8815,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "3abfd2685b343ecb732edd2b33dc17679c0ea7161dc1303c6e5e6ea9d6ddb3b7"
	},
	{
		"studentid": 8816,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "5da572fc4521c3852596bcb48a98265d52a9dba9e1fdc67b5d09935068b4673e"
	},
	{
		"studentid": 8817,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "5acd358063c6bb52de2c002d364c1778851147b1cf8ab988517280cd3f155732"
	},
	{
		"studentid": 8818,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "9cfdd5ac7e0d315aeff2491454b26c78601567b9b9fa6bec23457fc6be8821be"
	},
	{
		"studentid": 8819,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "741d86bdf861d015566b674bc00216ed3672509bdecbdfa4d9eb1e7e604bf595"
	},
	{
		"studentid": 8820,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "49e1d4bf10d18389d27350b109ee72732ae11e43dcb4a8a34d856237c32f3b11"
	},
	{
		"studentid": 8821,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "6fab10e86b8c7f7ffa71478de0333040aa066d54310284f0f485d8761fb39f8e"
	},
	{
		"studentid": 8822,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "c27afc07b4c747ef5a1e0981e75f4fa57a6b3fd5917ff9bfce7e4289c454ebbb"
	},
	{
		"studentid": 8823,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "70553da0999c2c30da5935c07644c59c5afcb7e00f3469ce17a8c337cd412dd3"
	},
	{
		"studentid": 8824,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "88abe3f977f3b037ca111e2dc0034b099b0acab9eefc1bad6d60b8f9a66a1a75"
	},
	{
		"studentid": 8825,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "4aa2152983aa9a6fc8ee5999e8f1e862e1d158fff0876625c779613e335b8849"
	},
	{
		"studentid": 8826,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "6155b14622aaff19a4176ca95a6d3005e571eeb9f7a8f95777c1fe96f4cbc0cd"
	},
	{
		"studentid": 8827,
		"classid": 8271,
		"schoolid": 6524,
		"studentpin": "213f2d250b23be40f85b8bfb2286e916a78b713a07f40b2248485263ded59238"
	},
	{
		"studentid": 8828,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "ef507e5457bc131390f5f54073913c1ef6c41e7a2782ec1d2aad8abc6cc9cd5b"
	},
	{
		"studentid": 8829,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "d5eebe3d14734323119b2a6b35e8f57a14d8fe6521253805f45202c4f908b463"
	},
	{
		"studentid": 8830,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "0b727164eb7915d18c9779f9b4a982d62f894b333b828b109849732b2568138d"
	},
	{
		"studentid": 8831,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "7f4d324fba40b8519acc1defc8258b67d84bcf8be58f3431f61675fcc0b9685d"
	},
	{
		"studentid": 8832,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "5d138c6f1b54304400f29c13a0d322ad14a160d559bd2508ae707c556ca6da39"
	},
	{
		"studentid": 8833,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "7ae05f20a0dda34d09c133fd19395366af83d381a17e8e0c9b1164a0ef1c0d42"
	},
	{
		"studentid": 8834,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "7b8fabd992eeedfa13f51848a7815581ff861af8f30b9cb260c2661df7a53351"
	},
	{
		"studentid": 8835,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "e569a5f7cad50a93f66546f032bb6f3e64a77145aabc915255c9f521dd633953"
	},
	{
		"studentid": 8836,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "370082e3e67e5d46a5c7af10e7f51f8b1b171044a4d3331875e68c24850961e7"
	},
	{
		"studentid": 8837,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "ad6b72b8805a2d2d7cda528ab2678a517d772d1decf55730200dad58b8f63466"
	},
	{
		"studentid": 8838,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "b50031b606da2b1b2a5804c09b23650c5499db61022656c23b5847b55c0f9e93"
	},
	{
		"studentid": 8839,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "bf2ea31ef5ce23f73a327958b3f352ddb1504a8b7db3dd6a6a78beb6c43d8eb7"
	},
	{
		"studentid": 8840,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "6c088bd780eaebee513ef4e3fc6533b6add69bb82df527b10669225b05d013bb"
	},
	{
		"studentid": 8841,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "a328c3b3fa198a620e85ecff5d950d0fea968aeed45b9dde79dd6e0532741095"
	},
	{
		"studentid": 8842,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "b84662e18137b4efa04a12702e26a2bb749f716ca70cec4674e590983c7d5e94"
	},
	{
		"studentid": 8843,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "7d37a76066ec165367427d1e6227073b5a5a343dc9a933b5925948643d89d1cc"
	},
	{
		"studentid": 8844,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "bbe3ebd89e5bf811273f95d59eddc06b739d2c4af10bcca4e8ecd4488011daf3"
	},
	{
		"studentid": 8845,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "ff628b1d0c9202c230c880c5a388c1c6531dac8d75bc5ed0b9755d050a43f92e"
	},
	{
		"studentid": 8846,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "f7b9797506fdd22fbdc9991d4daab80caabaa71d294435066dfa53a8d2ca91ed"
	},
	{
		"studentid": 8847,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "651d121237cf9c20287c23838b058cc84c893d4928de2e559ba1146ec684e1f0"
	},
	{
		"studentid": 8848,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "39bcba8e22c3d10da80c76afb0e3b784d8a1eab696f84948e5ee39821adf904e"
	},
	{
		"studentid": 8849,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "831c14a10e78cc9899fe0cb8a7a35a40bf91dd68330fcb507d564ecfc2b47f0d"
	},
	{
		"studentid": 8850,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "c870175b5c86667ce2f743e15c504672e849a50fa692b716608a571ba3a8dc83"
	},
	{
		"studentid": 8851,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "a5070e372a491b980ac81bfb533f5bf2c5a42e2bf26c40a1ea23912f1fcdfcd9"
	},
	{
		"studentid": 8852,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "7fb749d9a1824b826657324875d35aa6b2193a53b0ff80fa8ab3b1304fd27417"
	},
	{
		"studentid": 8853,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "cae3455e8b8256cf3bb37929b7c7b21d397c0c577e93feac3e268571266d5480"
	},
	{
		"studentid": 8854,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "29ec45863b5d9f565cdc02022f4e2f62f0f024cae6125f92248344a5d366d7db"
	},
	{
		"studentid": 8855,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "4d857e03259312bfbdd7552493b50fc92808266a589332bbb84d07102102be62"
	},
	{
		"studentid": 8856,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "706a7d81cb5fe2d52957b004f2a5c90e030f13d9fe80778c4ae6d7b934f985db"
	},
	{
		"studentid": 8857,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "817e60c3b22f1db8afa66dee9cb12ac7c24c384593eb13a7699d35ed06cbe00d"
	},
	{
		"studentid": 8858,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "e30c787b220ed26cd8d4fff5372c30d8dc632ad1212725f841226cea9fe33b0b"
	},
	{
		"studentid": 8859,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "25311353fa2bf0e93b4b8172c07fbe6aef2ce0cb0d2af3d5ce61a655cdb21da7"
	},
	{
		"studentid": 8860,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "224b98c1dd04f172b76c2f626b51a46ba33d842ca397c8e354cfba78370c2109"
	},
	{
		"studentid": 8861,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "7cff96828fd93a402204c482adc82db0b2627115c60daa01f46b7929ce169204"
	},
	{
		"studentid": 8862,
		"classid": 8272,
		"schoolid": 6524,
		"studentpin": "2ff5dba2aca6ac9d1a0afb75b39dae6c207378ef171cc8bc340dad0979bd3185"
	},
	{
		"studentid": 8863,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "994279609c56a6928e86aefdb4daba37eddb9d20cb2762fa9796f9c525bf0230"
	},
	{
		"studentid": 8864,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "c92f9bea29902df1b6f25d74ac9d6df9d9f742ccd1255415750e8c0123e2f554"
	},
	{
		"studentid": 8865,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "972ede07e0a63ef18047b144fce7e3d531300a8b92359579a3d6cb475b3c04fc"
	},
	{
		"studentid": 8866,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "e2d61f640b86e0b606e2ae8d7cdfd9da6be76db6b850bb008601b512149584c9"
	},
	{
		"studentid": 8867,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "ee2160edb637142f71dc2898ca7e22142d11fff2c537b75c825441975cf5c3d1"
	},
	{
		"studentid": 8868,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "64682d23e73d79c50c9582288a7cf90b41b1cb7ae98b1e50104b08372410f931"
	},
	{
		"studentid": 8869,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "2b980f81292a536fac4a95a694f59dc2c382c2fdd08e4c64ea8e6e7f290158fd"
	},
	{
		"studentid": 8870,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "de65b69066780877ecdb6426e4527eb326d5c51cc895200c0da8c12fd6f76183"
	},
	{
		"studentid": 8871,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "fb040097b278f7cfb12307d0a37597753fc1ca0dda11d4b61f6e5d78163b9407"
	},
	{
		"studentid": 8872,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "0f9c19437ee59fc6268dc3016b5c1ff96ab3727889685dfc8bef3ef10072ad91"
	},
	{
		"studentid": 8873,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "ca339a19c85809655e94b8bf022784c154fdd50e7f125aace42c5152ec8b3430"
	},
	{
		"studentid": 8874,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "b05de5fdb94c6c770fcbd3127f2e4fd66d08a7f59a14c47dd6340156e65ae7cd"
	},
	{
		"studentid": 8875,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "958e9d76c44e6eb5f10b92bea0b693be2170b5fd24a7137c81a399adc5201f84"
	},
	{
		"studentid": 8876,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "50b656a906eef7377e7b852fd9f27d302ab540c0fc8d4f3f190211da20861a5e"
	},
	{
		"studentid": 8877,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "fec34008864b1fef231387a293daa2fa8bd31bd7559d9d1ac1d288f078258a47"
	},
	{
		"studentid": 8878,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "431f28b0226da1c6af55e6012fd06cfb18b345571396e28ef7196c5713d3fd5c"
	},
	{
		"studentid": 8879,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "bf9fa19c431303b53ca7b7df650d1d070ef2ed2520aea18524220bee0a057ea0"
	},
	{
		"studentid": 8880,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "b9918c00d0937a6494002ac10a9629696b41e6203d1838df8000bed36fa3a6e7"
	},
	{
		"studentid": 8881,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "cda3b080c11ff6af62dfa38e636a2bee96d3848ee3efc4da1e6b83ab70a87453"
	},
	{
		"studentid": 8882,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "5355272602bd6b2909d27258db5f2163eeef2e76d1da11e022420be7bf178b58"
	},
	{
		"studentid": 8883,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "898e5631c5cb1ad621e330faa34bd7d6ab30ac8ffcfd7bcb7e2460c8fd23ab39"
	},
	{
		"studentid": 8884,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "3cd5c0d112e569ecaa56716cf1b951d7a7aaf8af2809e546e351a414de74d40f"
	},
	{
		"studentid": 8885,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "1b36011780507ded2dfc83d18b4b4d7e6eebb0d28b12a323c9f982b34d176c02"
	},
	{
		"studentid": 8886,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "f0d136971fa9d653d261c35c8caee408c474fbf387b46648daa9b42650eff855"
	},
	{
		"studentid": 8887,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "df00811968aa5fb91f4b890133ddafd810ac5e94b020596ab219b7d0f453e5e8"
	},
	{
		"studentid": 8888,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "8ec6537506024ccf546b2ea737160cd9a3a725493a682f71781bcfbf962e0cda"
	},
	{
		"studentid": 8889,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "a744a83f9fecd4aef555dd7a5eecef2bc7798f5a0f6750c21ffea2c67c4ffde4"
	},
	{
		"studentid": 8890,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "9c07fd56a41f867fbd8183ddfc2377dd07afb6b5a81bbc6a3e7b105e10422c89"
	},
	{
		"studentid": 8891,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "0bd36d98364b64e585c74ba910e1453248176eb704b83a8de31dee873e44fbba"
	},
	{
		"studentid": 8892,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "01a02c01439765fdb77866190f29c90d2ead9f0d7599b4977ca7d1f7af2640e4"
	},
	{
		"studentid": 8893,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "cc66397eb6c6dbd18643957a69bc24dafcc045332ac16ed760f039998949c7d5"
	},
	{
		"studentid": 8894,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "49c9d1610274dca9b84c1d9ba0f69611624121f1e33fe7ad0085de987c444987"
	},
	{
		"studentid": 8895,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "e1f63fcd8b4442951f3aefcbe20f05afb67cfc92d37f6248a81df1a264406696"
	},
	{
		"studentid": 8896,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "e66010839dc6a32ce2729abd7efe2137eee3239cc57fd1df4a74c96b2276e77e"
	},
	{
		"studentid": 8897,
		"classid": 8276,
		"schoolid": 6432,
		"studentpin": "fd3575ed3e1a43660433bf54b85a55115988b828e0b3852cdaf6827affa84205"
	},
	{
		"studentid": 8898,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "6b2d9b1b02c6289f7cd2837d7b4fc5f82ea68abd2ffff7511f37563d0cbecfe5"
	},
	{
		"studentid": 8899,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "00bfb1b9620c86426e1458e62d84c536eb6e4c283f9a28e427796e6f2fa6e8d5"
	},
	{
		"studentid": 8900,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "6efadafd77f8f9b40ef0b73613db86088667a633355e561e0a24b633d3e7d1df"
	},
	{
		"studentid": 8901,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "7eb420fe9b5686983fab0dbbf8531b36e8234522252f28314b106de64bf48bfd"
	},
	{
		"studentid": 8902,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "8e559650f40ea8bd44444155f3af181dd52314653cb44d11c925a9c8c3c43872"
	},
	{
		"studentid": 8903,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "0e94791c6431a7fa848d1111ce9bbd2665f7275cd33c3d9a217517e5f79cd265"
	},
	{
		"studentid": 8904,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "5be3f926b8d56ea44df1c9703771dc3b8821cf6da9390c8ea4d74ae41b375ae9"
	},
	{
		"studentid": 8905,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "b5fe214c5194b8f33f138f297d54b43133a89a2ec8d01816e93f8c19e97243fb"
	},
	{
		"studentid": 8906,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "bba04c5047243c0f8b7af0450109d0b3f01916bb97cfa92aa632d3e59dd53197"
	},
	{
		"studentid": 8907,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "a6f3779f156580893f879a3285cc7800c82f912f0b278155b6fbf9b09231cb80"
	},
	{
		"studentid": 8908,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "bc8b27007d805aa5ac5ea3f2781a625cbb71ea584c84c3eea43a95e4bee9cc37"
	},
	{
		"studentid": 8909,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "e9917f74300d8f6844bb3c3d6b433d2a400b7a1bfb5b028becc80b01a5868c3e"
	},
	{
		"studentid": 8910,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "94133d009df855a2ccac2b95b500d1fd42ad3dad9d0e7d46189bf8627a07c283"
	},
	{
		"studentid": 8911,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "f2c8e6dba729468d706978c060f62f050f624f7ead81d30d8aa82c6f4ebc87f0"
	},
	{
		"studentid": 8912,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "681afb031bafbc551eb7a67fa00ade1cfb15ebae2767b15294179dea7997f32a"
	},
	{
		"studentid": 8913,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "6f0e72a6d5d3eb9642783ee228753e27075169a6081c97a36bbe12e01714f539"
	},
	{
		"studentid": 8914,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "5f26a123067d4d78ce422704e66de7ce7b4fa9011be856e38becafcce7a7c455"
	},
	{
		"studentid": 8915,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "0cc803f1409d3ff64a9c7f74ad6ad8d0ede94f1e05fed8fdbb13000f26aa177d"
	},
	{
		"studentid": 8916,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "a294fa947fec223b11d9385aa308a671b0a6df5a5fbb800d6073e66995a74997"
	},
	{
		"studentid": 8917,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "ea97756f83aacfa16af87aae4a3e7b10a2adc824b28a77f3c006b71d8f52899f"
	},
	{
		"studentid": 8918,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "bb5bcab3a1354c6c32bbc12ba0e6721a4d74ebc8c68c0ce3b9ecaf18e1dd5ac9"
	},
	{
		"studentid": 8919,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "aa93f40db1e725e3c844abd2f12e670e934225d4333a5080483584695b47cf3c"
	},
	{
		"studentid": 8920,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "8209251682135a5b0c63dcf9908cc45b9d0b957558e7baa9f8aa03069cf8a504"
	},
	{
		"studentid": 8921,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "05183a1c312677403446cf197c11f99097971a1b30c7197a33f7bb329a8da7bd"
	},
	{
		"studentid": 8922,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "93093d68e5ecf6b5de02b473143550e8c72165c5e7baf01773521af17607f505"
	},
	{
		"studentid": 8923,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "1839498038f8563ae7aec97b705f94044c3f0afac69fa66d72ffb9cb13e7f1be"
	},
	{
		"studentid": 8924,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "a2836df8fd2649d9fd06f44092e9d66efc1d93aba4b602cdf77326e4084a6513"
	},
	{
		"studentid": 8925,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "c652bf121e35ea561ffa405ad56c4242b1999a55501a771977da68480d85d267"
	},
	{
		"studentid": 8926,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "be778250f548e6d5bc9d8eb57e47233f1c6fc63822d74a42f661393a9589a5e2"
	},
	{
		"studentid": 8927,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "e9f0e3247211442088e36f7ba2d1b3278ef97b94f899fc94f12fbce604a25633"
	},
	{
		"studentid": 8928,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "b5eedfd3736c0ae826fcd65c43fbbca4796ec8897e5a3cb44cc5bd72e92fec8d"
	},
	{
		"studentid": 8929,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "fa354eadc06470838c658327a826a1f5b8fd5a6791ac0f70733b67540c001d9b"
	},
	{
		"studentid": 8930,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "139eb36929cdd9149d0d26969338df9d91e0cbba2cdd185565f5568f7065dcb6"
	},
	{
		"studentid": 8931,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "ad1bb71b9cb7b09fff4e0129124784f58c3cb92cf018593dcffbea6e93e77bd5"
	},
	{
		"studentid": 8932,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "2d3dd561369a7f05bd9810b4233484dc442c37c5b5c9affbf9702c92b056fbb9"
	},
	{
		"studentid": 8933,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "f9fac7eeb645f473feaca5d56ad67ac67a8657cdaeef4c37f0858d59effdf44d"
	},
	{
		"studentid": 8934,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "a7c3ce7b02c16fba7596a8ca087296c95b6ae87712eed54b769e8adf85671af9"
	},
	{
		"studentid": 8935,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "5308442cf6f766d151a93aba3556c76304dcf0c95c698fbcfc716987e81e35d3"
	},
	{
		"studentid": 8936,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "342bf531c922dc44b499602d0a7fa9c0fa51c73eec07b78c1e00cff172f3dd09"
	},
	{
		"studentid": 8937,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "8320839516909573892c96cb6cb033462725263af5609bfc18bbdd70357ac275"
	},
	{
		"studentid": 8938,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "5377d391218a8d83f3c3c0f04fbed53a8371f4af1d6e887d6becfc1bf201cc61"
	},
	{
		"studentid": 8939,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "9981ca704896436533e46d2adbc812692c350634bcf62ff01312bcd6a4b47302"
	},
	{
		"studentid": 8940,
		"classid": 8277,
		"schoolid": 6432,
		"studentpin": "3286f54e49122cff8e6ff314770931735da790dd23ecf58d72143b889af52e19"
	},
	{
		"studentid": 8941,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "988f922c3aebe6230fb967ae501daa1236132faa885583f20e7fb0fd3eb20569"
	},
	{
		"studentid": 8942,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "58b89c2f4e4fa477a472ec09ad871e4a2ac1dc4fb585509af3d805c3aaa1145a"
	},
	{
		"studentid": 8943,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "26ec7ea017b5f00565239067a61f6ecdc88c4c74e1daffd8fdfc0784b0f0a0b2"
	},
	{
		"studentid": 8944,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "3c8ae334e24d8e05ac169643e8ec3fd1a0141551661aa5af23d7e9164afb761e"
	},
	{
		"studentid": 8945,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "0c3b7734427ef2911eaf21abae1686b3ad68e42621d4c618b056e72a905948a9"
	},
	{
		"studentid": 8946,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "d0f73eb8d843772cd01482c15ecedfbbc28eaaa8984bb6e805866f27b89dc9ee"
	},
	{
		"studentid": 8947,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "778ed96c69b87ad94320b93a14ea5ef1593a870a08be46786171e6cc71fb7f71"
	},
	{
		"studentid": 8948,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "995d1083eb5746d15a29337b702ef0438dee3ee2fb897fe117fbf58c3ef52b49"
	},
	{
		"studentid": 8949,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "ddec35199b739fc3270a5f58be02e0fc2973f006670bfd5154893abd742f320a"
	},
	{
		"studentid": 8950,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "135ec5455a1144dd9c4256cdc95bf4ea7cf10e9d3471c288491c82a40a002b44"
	},
	{
		"studentid": 8951,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "68a075fc92ff0ea7703ddfbc87ecd7d7cbe6c7903c6adc2fc3f239e32b1e16d3"
	},
	{
		"studentid": 8952,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "ec18f1f6571623164bc8c0696dbb47a684b59e808b183dc5f512ac428a24ad6d"
	},
	{
		"studentid": 8953,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "41ea101239e5a61cf71fbc99ddee93737d3d317ce45aa8dfbbdc6845fcb0ed3e"
	},
	{
		"studentid": 8954,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "daaef8ec809691385bee38d95997fe02cdbc2d0d8e7f60b104d6f1f596604378"
	},
	{
		"studentid": 8955,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "6c28d5578f5b3b16a31ead71a43fb6fad6d24dd348a74baf79d60c3d2c569243"
	},
	{
		"studentid": 8956,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "55031e202cabe75fb33d2e3290371c1f641fd17a4ab5b114d4dbac3781343e79"
	},
	{
		"studentid": 8957,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "c2370caaa5a5d49834ef4f0823171465f7aef5e03a6f6af2f9919595279a5c53"
	},
	{
		"studentid": 8958,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "df2cf3f2104ba7d7f05bdc76a9aeb344577a5ae2d7eb4d603e3347dd3761fca1"
	},
	{
		"studentid": 8959,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "67a433cca9e8b09c350b3853da38151dad8ea527a84d17de662657149a9491a4"
	},
	{
		"studentid": 8960,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "506ce8179c5f4d0edcd63ae1017a2a1707a2ed5f800237642f3603c4bb8260b3"
	},
	{
		"studentid": 8961,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "f869c6532426b49fef55d65e2d4e9e1527f0bc4f0c2deb069ea243583118abbb"
	},
	{
		"studentid": 8962,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "e6967b12bd53ce888ad3a6f203a37db9a8d7123ab3e6bc31ec8d15929031919a"
	},
	{
		"studentid": 8963,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "3affcd4287012967c996306d4e30b2efcd03203f1c1d36e0dc67d3afdc10f65d"
	},
	{
		"studentid": 8964,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "9714f1f09ad8c3ba57a628d9527815ec7563d3293c66161d93bdcd4b55aeadbe"
	},
	{
		"studentid": 8965,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "95caedec088ac3db333b1e38e0c3421d5b0e0e7d7b41e42e8e419375b0cf8982"
	},
	{
		"studentid": 8966,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "d3e4c61987551a33e36cf4ffde3b9e689f163b6c311870c3cc60bcde67fb189d"
	},
	{
		"studentid": 8967,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "d993286df194321a7c55d2137263c800db6a3daa1cb37f5f26d79028b1acec5d"
	},
	{
		"studentid": 8968,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "936ad73d192e575edafeea6c5ef51eb0ec8a1700296254adbb9f5920c09c1a13"
	},
	{
		"studentid": 8969,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "e26c262894001e6c046d0d28e33e39000df3b073e78699674bb281bb5d3070dc"
	},
	{
		"studentid": 8970,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "24a0885e2debe9245b9e7040c401e7edb9e74547ff9d58753108fbf9227b2e01"
	},
	{
		"studentid": 8971,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "2c924172002adffcba61d1fce0893a4f48b6be83e036caee6456327664419ad1"
	},
	{
		"studentid": 8972,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "bc4153dd8612a3c00f3966af7e8be5f0da74581c6e1b55a932c4e72071779f72"
	},
	{
		"studentid": 8973,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "ba4c0f48d3ea1adc6ee569e575581c784f5eddfc1a3a45890aa6c67005b9253e"
	},
	{
		"studentid": 8974,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "92bd8701c8447781625da4f22c5cdbc87fc8c81d58a6c31c9bc0028c60174576"
	},
	{
		"studentid": 8975,
		"classid": 8278,
		"schoolid": 6432,
		"studentpin": "1de397937d7f9bb85f3fa8f34a74ef5d7c5240d400a38e0bc77717912cdec4fb"
	},
	{
		"studentid": 8976,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "e593e669e113a182f6a8e6ce4b24de032d925c0c7b47f887a25a8974a761030d"
	},
	{
		"studentid": 8977,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "d1c6d02972635770bc821da68404fdac37ad89f48fc2b8ea686c6b36e926999c"
	},
	{
		"studentid": 8978,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "f4c7cf95da73284e5847bfb2e924dbfb3131a2b188540d35d44e3c55bfbad8c1"
	},
	{
		"studentid": 8979,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "94f0c487e154b95939995368551d254c9851909523017a9fdde910eb3d373ed4"
	},
	{
		"studentid": 8980,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "06b53695a8f09a4fc8b9c4fa86673ae1d1a0e494d28ccc08d13a0d68008e236c"
	},
	{
		"studentid": 8981,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "81626f3fd22bb472af801acf7aeb8997b03a5f006a88d1e51c88cc17fd83ce94"
	},
	{
		"studentid": 8982,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "f4e0c9055c47862c17bae61a67fa2223ebfa2cf4f6e367ded026f3aebd45bb3e"
	},
	{
		"studentid": 8983,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "0a743d8942f575bca27dbe62b50ec054a64903a1a0fd426c94463309cd6a18ea"
	},
	{
		"studentid": 8984,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "05ff82d3629ec092511376989cde7c3eb4dc95131a3581686130a73bdff62aaf"
	},
	{
		"studentid": 8985,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "0b7d37ffee1e61d3f215dfbba3451cbce6fb5d16803d8a7a7f6b59123fad14ec"
	},
	{
		"studentid": 8986,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "bbaa032aef5e3f420d0630e8f895cc6ba7d489013f16373f9e003ce2a486946b"
	},
	{
		"studentid": 8987,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "29286b8cea3c57787e76ffeb44d671ad1a0d00f1fe9ef438ac9191ea471d67b4"
	},
	{
		"studentid": 8988,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "4e0fda4feee2d3bb6e516b7804ed88a7f7bcc82d557140968067ee8a5f052289"
	},
	{
		"studentid": 8989,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "c3699198424c4f6535dde7eda81e69579114613ef75f6a5f4f211098a76c1180"
	},
	{
		"studentid": 8990,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "8aa0c1648849081fb8c847ccc302c55513459082d77d06d3eaa7f40727c4c3d4"
	},
	{
		"studentid": 8991,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "27cdfa130de415212515acd0f8233b3f3bbfb58061803fe55ec51e22f35b73ec"
	},
	{
		"studentid": 8992,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "b2134ef60134de1e11940ebb37c23f23ff473074e1b9e7879402d0bc5d08e423"
	},
	{
		"studentid": 8993,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "800a67ab0e43ce514e41fb480b568548f6bdeeaf7af43a30c25329200add52ff"
	},
	{
		"studentid": 8994,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "c5a2613b2f260090db6b8613895e4897cb2844ebf9bdce78927ae09494653eaa"
	},
	{
		"studentid": 8995,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "fe90526968f37e39f42308e90de666635bca27c1b3363ba3a1b13da4879b80b2"
	},
	{
		"studentid": 8996,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "e6f36dde9b96ae8a6ee8212fde97b39ca7b1803d69b6ed58f9c4650dc016fe84"
	},
	{
		"studentid": 8997,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "6bcd9a1e15e96457fcd500542369758891ca79602489056aba99e404fb6a5fda"
	},
	{
		"studentid": 8998,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "469930b4add39e556a027dcc4aad7bc9c4efa17a74fcd34fd7cd578b4231c5a2"
	},
	{
		"studentid": 8999,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "7d46193afe986b6a93061e6581fcd1591944fb72b8e07d8c1a9d32df14e2cd35"
	},
	{
		"studentid": 9000,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "3a2d25750493d206f13a3e7f83436ef4cf6db36d71d989bf95ab85aa5fa5d903"
	},
	{
		"studentid": 9001,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "da5fde51eba95b6a6e55943c2730dd11b6a8aef0b1cdb8b00e045d7a1c3b9bc1"
	},
	{
		"studentid": 9002,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "e97ccc1daa8fb789a919d5a4bb9ce14f64d7d8a1c6869d4338effafd8cffa4db"
	},
	{
		"studentid": 9003,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "c53386546fe0a592dbc3ddc019c352de012376f5bb18cd5e55814c6dd0b500ec"
	},
	{
		"studentid": 9004,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "cf6d44e1e32ed7e7d2a20074df092590333b27eb72649fc847523113e1896197"
	},
	{
		"studentid": 9005,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "905f4285e6bb021859ef4c778a671f778bf04c840c47a530e6956722b8ca11a2"
	},
	{
		"studentid": 9006,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "7a7dc84649105c4a9046d863c2fbb2267297e760ae60fcb0b098eafcc6f5004e"
	},
	{
		"studentid": 9007,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "7d369b799e13ab08b7283938171c2d922af43599be968bbf086f72e96a6662b5"
	},
	{
		"studentid": 9008,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "5a2cb2b9d9acc1445c5f5289bf90c17e5d1463647883cdf518d2862c0c63401e"
	},
	{
		"studentid": 9009,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "bbc017371b1a2248c42d20559d190a11ed1565996b6cefc55547793ab9ace6f5"
	},
	{
		"studentid": 9010,
		"classid": 8279,
		"schoolid": 6432,
		"studentpin": "2b95f190f9f958b1e40f6847117b6b036e9ce2237391b2242ee5cd7fd9e836e7"
	},
	{
		"studentid": 9011,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "81f4418529848b21f16ca8695bf09cdac1d51669f1b879c2325a3ec8c8336259"
	},
	{
		"studentid": 9012,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "6667fe42b19612dd135362331723d5a8686f8c06a16a213cb53e05908f518a4c"
	},
	{
		"studentid": 9013,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "9b7ac2182ab88399b6d5f781423ff70ae78069e022c8a460406e0d30f8c45401"
	},
	{
		"studentid": 9014,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "5cc148f5854598e01872b967f03489b1c9d5af52c6b4807470b8788dfec115eb"
	},
	{
		"studentid": 9015,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "2aa3adf26f7d4d8cbd59abc60f9f61620933b731c4c48c341445eeefbada53e5"
	},
	{
		"studentid": 9016,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "67df0ac9d0560dc7215831111012440c5b049de39ee4c85ce33391bee25a30e3"
	},
	{
		"studentid": 9017,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "ad183c3e95edb7a5f66a796dc6af3fdd241e2e7aa4ccefbfb24a3dea906d9d27"
	},
	{
		"studentid": 9018,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "042612ddaae8690e528fc06ffa9a942ed35e6220d427b82ece3ca92ac07e4ad7"
	},
	{
		"studentid": 9019,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "3cd3353ac49478ffff0e8835dbf9a585da7f3a165c34eca84d4d316dcb5585fa"
	},
	{
		"studentid": 9020,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "32b623e2c481289690bb02da8599531063c8f99dda4c81d2159e8c93d5a3befa"
	},
	{
		"studentid": 9021,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "9db6738c95548794001b0d55273cf069b3d086409aecbed66f2ff8b2c5a936d0"
	},
	{
		"studentid": 9022,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "732095d48b2412b2147a2547ca01595f7cd755eff3863736d60486d5e033b2e8"
	},
	{
		"studentid": 9023,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "a5fd2cb613fbdf72112364f844d84cc8074a1d1206cb6bdb430ef5f4c326fcfc"
	},
	{
		"studentid": 9024,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "93d1d42d27aafb76da3954ef90865592d5d2c0c5a5dfd30fb374b33614652a61"
	},
	{
		"studentid": 9025,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "a07afa6122002873db925637b9811be340d43ee78e3fbf3dff4d557d65fcfa9c"
	},
	{
		"studentid": 9026,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "2560d88df81b234eb15ba67e50d8f8e31bbe13539951f7c406cab020e180f4e5"
	},
	{
		"studentid": 9027,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "3529cbd5019de8e59be103fdf8603abf90b73541afb7b36b9d334bc1b50e7394"
	},
	{
		"studentid": 9028,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "dad1dd26866b0ae017176d416aa8e107862c6e0b1e45c9ff04c04ce529a6410c"
	},
	{
		"studentid": 9029,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "dca93b0e967b62306bc51def321c39e01510b89a2083058d84d3ac11ccbda8ab"
	},
	{
		"studentid": 9030,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "093f41dc9eca6b955f17dc5c32e7a05d9c876e2cc84b23e46ebd0b5b0b0d5c9e"
	},
	{
		"studentid": 9031,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "492ab829be90f8c3f7aab439d3d9a6d0add88b66861c8f17dc87e0c68ede6e85"
	},
	{
		"studentid": 9032,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "5972502a5976fb662e329639c14202ad796f3a97f6f9fbdc99480bfaa0eafe93"
	},
	{
		"studentid": 9033,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "b40a8b70184339d8aca61c0ca66d9532f889e3bca73a5416a7689442a630fd36"
	},
	{
		"studentid": 9034,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "5d0e12704449bcb150471ece2227ffbd757cead63cb73cec90f3ac10d043c4aa"
	},
	{
		"studentid": 9035,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "058b383e298bdcff3f8b8f0f47ef68a68970a34192648552d3f4421e4ea30ffe"
	},
	{
		"studentid": 9036,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "9c2a695797a98b0da4c65cd3c3443ec828de6cfeeaeb3986de4975bdb0827f5c"
	},
	{
		"studentid": 9037,
		"classid": 8280,
		"schoolid": 6432,
		"studentpin": "c9e9946005179ba217104939f2dfb582b7adba6f7b0f7b7f58f0d1baa9d5aba6"
	},
	{
		"studentid": 9038,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "8b150c40c60ce291e21fa24d5baff9d47f8a93285c8f49f01d102738362d78a1"
	},
	{
		"studentid": 9039,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "98bc4ec5ae73c4a39d6168d565f88b0aba507a7a6cf705b9f7c943ae722dd08b"
	},
	{
		"studentid": 9040,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "ae73153a3465d80849ea7b99e7cfd45f87c27cbbafcd49bdfe95203bdbb5087d"
	},
	{
		"studentid": 9041,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "5377aaae6f330d6b58878ea231bc82082050e247925f6f49c5b204a6b61fa45d"
	},
	{
		"studentid": 9042,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "4f152300002ad02649941077eac656c7fc0d8f74712f74c73e653919f5294559"
	},
	{
		"studentid": 9043,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "046330c44b4e92167907fed2db7a5803998b4ec98164f96cfa5bb8677f8b5d94"
	},
	{
		"studentid": 9044,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "621ddeac5159f316d88e43c4f8e408d9eeff55f88e82dc9dac07e95058bb1379"
	},
	{
		"studentid": 9045,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "5e9d75dd8d6058130af10ed15679887f37ee0ed11f97ad3a047e0aa05f35cf6b"
	},
	{
		"studentid": 9046,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "2f0ec623b822723578231e4495be64036c1737a96e6f719e1314d69a3ccbbd2a"
	},
	{
		"studentid": 9047,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "4c6f0c4b9ee351f1f8ac412878fafd4ccbf049f546195a755ab949780716921a"
	},
	{
		"studentid": 9048,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "4306e79a278cd11616f2b90aade58e675e5587a169b4e0b8c518f07dd13cc3ed"
	},
	{
		"studentid": 9049,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "311ae8ee1c7bfea15074165275c9c2ee16f9117009b84b9d2c6e9846b5dba3e9"
	},
	{
		"studentid": 9050,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "082545acde2f7d5da76d38b85f87181ee12ee43bc5681c38bb87c34cabc18e15"
	},
	{
		"studentid": 9051,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "ba0677438f9e53699182f8d1cbb2058083795e064c2dd40d8c1fb9a1829b0fef"
	},
	{
		"studentid": 9052,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "09ea414b4887bb7c4d6ac0d6fbeef4b023fdef2572adbc34a810c4d8742d7519"
	},
	{
		"studentid": 9053,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "f030665174c25a9b640acffc26c16a00ec288253f6f35b825b8264c317cdf1f2"
	},
	{
		"studentid": 9054,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "c36af8faaba6aa9c75420d63b64e48178a1cfae6e92333d547d15a571fd30837"
	},
	{
		"studentid": 9055,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "639e4ae18e615113173b4d946f15f0df7eee300f3d021b7c0e725688a450237a"
	},
	{
		"studentid": 9056,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "5fa7dc601fa02df6dbe8f16fb3f1c6122c3b9046ff660448b7aa81c59b77e4e7"
	},
	{
		"studentid": 9057,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "7edc939122bfae957f9f037031c2636d68cb505e112b9f3cb04baf876c685227"
	},
	{
		"studentid": 9058,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "cff94bbf785073944c25dcdc33ef1417f848fbbf904acd0ebab992e28594d8ee"
	},
	{
		"studentid": 9059,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "416abce109df80e7ef4dcb74d5b64319afbfce334d510a388993a37499b27899"
	},
	{
		"studentid": 9060,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "517c1004f9af431da36c426e8abfe2d4cc2accb814df2358a149e34476931d3f"
	},
	{
		"studentid": 9061,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "7e997d6e485b68e6b2af30b8ac844b3e990a481d331774efcebe7db4474477a8"
	},
	{
		"studentid": 9062,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "5d3150789ee95af68a56e033fe34ce94006e1877c3ae98e4710bf09820ff22d2"
	},
	{
		"studentid": 9063,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "798520000d1c4d58f33ca3000838a951a788b238abf5d292737f61afe2800279"
	},
	{
		"studentid": 9064,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "ff532718ace69b1e080f1403f41eea73402dcbf1d3054275969dbd15c7a680fb"
	},
	{
		"studentid": 9065,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "95d6af899bbbbdc9f7c16e00ebfb9929ade33a2ad611774687dd2bfa2f772e5e"
	},
	{
		"studentid": 9066,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "9d22112f113cfc64a3c7e148eece77764d7ba9c96e9a88d0ea18a3762fc815c2"
	},
	{
		"studentid": 9067,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "99b0e52e9d3f8df3a02f5791fd49b04361d654c63108b68691f359559196929c"
	},
	{
		"studentid": 9068,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "7a81aa54fc7ed12d5d8649188538e3414715078e9237ad85bf070fb5b30bdbc2"
	},
	{
		"studentid": 9069,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "9e97539e487b49e9309a3704650391d18f51b077ac3a4c279347703a78675716"
	},
	{
		"studentid": 9070,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "fd1cb5c50ab0b0fee6dd883b540fc19c9201287600bfe20cc1db5dff019b03fc"
	},
	{
		"studentid": 9071,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "a39fd4b2262ae118aac9d895b0add8f2ca8902a2e5a0f660c41d27733616e17e"
	},
	{
		"studentid": 9072,
		"classid": 8281,
		"schoolid": 6432,
		"studentpin": "2e6682fec5161ae6c910c0acddaa0667a732f3ed0c3ca7edb0fa6614c8baa05d"
	},
	{
		"studentid": 9213,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "7a1155ab83bd39a11cefa6442310b22ebe5716da8951d86d6bec762ccaffc0f4"
	},
	{
		"studentid": 9214,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "a1e883c5af3cacc4039eab526739d036ce17860a0df0289b5e4e3a1068dc1afb"
	},
	{
		"studentid": 9215,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "9fd22dd58b133e10aeec1a665448df5591a44f72584f54ae692efc0d6a156178"
	},
	{
		"studentid": 9216,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "ee3cda2839097ce7c56ef4a0253df871a2d63a9e96cd8a72aa603806da7b7e3d"
	},
	{
		"studentid": 9217,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "92eb5eddd0135b39da426d9a2af27a38694b1ef10f5a74c61ee3685b35bedbaa"
	},
	{
		"studentid": 9218,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "ed2fdb174c32abbb906eeaa82b6f7179bce8c7381a96c111412c11168a0d69a4"
	},
	{
		"studentid": 9219,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "555c299522963536fa5a6239c6f91b5cbcfb4f205eeedb87f5f92d61ea7d4eb0"
	},
	{
		"studentid": 9220,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "3127c0e75a065f522c27d627f99a547b333b923f7b5aaccc8b0a70345b420d89"
	},
	{
		"studentid": 9221,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "aa7ff955628789b00b25aef6f06d0e62613ff4210fed607de8bf8ba84245cdee"
	},
	{
		"studentid": 9222,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "4474547256c2282e008b2fa4a39912de6fb7bfa7a2a810e1fd6dfe9b1268b623"
	},
	{
		"studentid": 9223,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "e594870bbf844f785ee91232c2faeb4bb9e6aeee054153d1267e0dab92d4352c"
	},
	{
		"studentid": 9224,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "317c94edeb759767eec8d3e69e9cd0b787aa95caede53e38b17eeeed91a9e996"
	},
	{
		"studentid": 9225,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "085c36cd63e117abeccc6ac17a610aa51c7af2d05c4d9c2de2357221bb91984a"
	},
	{
		"studentid": 9226,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "b702c9c174e373b477a0598769ddb52aeb8315457d47f5c568b8b31d600fdb59"
	},
	{
		"studentid": 9227,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "770e73c0f7716a37b7972eb701f7af548e65a2c4349bb74a3f3a4a4b34522e93"
	},
	{
		"studentid": 9228,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "9f531cf9341aa29fe75e3ce9b1b618335009588a80fb20ab3b09f307037d02c3"
	},
	{
		"studentid": 9229,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "fd170bfa3b130953f7310247ca94bd8f1973ba143a47e2f32eba03cf462f05a2"
	},
	{
		"studentid": 9230,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "e64ce8ea536a70329ee839a390fd9735242dd0c8f297fdd310eab095038a644b"
	},
	{
		"studentid": 9231,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "8ea15bd027fae076e5801bedb7864de72f74ce5f1a67335ed32936d03ee7e8b9"
	},
	{
		"studentid": 9232,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "5f234ea026c83eb75127e0755cf12bbf5c1cf4b4662eb10fe665c0eaf6369ceb"
	},
	{
		"studentid": 9233,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "c0f21f37f7a285489e6050edd24eb41438bc662639a87a86eb6e2bee7cd67633"
	},
	{
		"studentid": 9234,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "298550dc779f537bd8a856f89d7cf778f5335388a10a0d5c93a0ffc2ab620fb5"
	},
	{
		"studentid": 9235,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "07b1ec4a602225675f156f7ffe5cb672409ac5b5f06b69df2294f33f0dd2c397"
	},
	{
		"studentid": 9236,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "17cf170e6fada44056ec6559c94eb802b54063f7da4dffde5666ea5917c5f9e7"
	},
	{
		"studentid": 9237,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "eb3c905eba0290e3356f8c22d8f1385c1d6854941ea029029b3b51ad9d486361"
	},
	{
		"studentid": 9238,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "ffeb1ec31cadbc4fc94706cc3df953c57fdf5de0af127863f5dca9b5e27d08a0"
	},
	{
		"studentid": 9239,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "3d2404eb5626e719f7a44ae4a570eb20f470995b1646dcc41155156123061390"
	},
	{
		"studentid": 9240,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "8af3a6c1d6bf36c795bbac6d326b4186b5f9b7d1cdb31251c17a26d71cae2f5b"
	},
	{
		"studentid": 9241,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "a7b5ec6a821b16882db80ede61e84c435a5613b8b14a17eb8b67e9a9c96887d5"
	},
	{
		"studentid": 9242,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "762fbda503ea8cf74bcf08c1eb7b34fcffd8a7fa3fb2cfd7d2cb9c85ba77bb61"
	},
	{
		"studentid": 9243,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "74da0c5addf444bdb3931de0dee429c5b57d56ac71df955bcad7b70eeb5aa28c"
	},
	{
		"studentid": 9244,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "4ee6f2e0c0d0585eae7a11bf26aed673bbfc60337775c231ee46327c76ecd6d5"
	},
	{
		"studentid": 9245,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "5a5e81ba8c475be9ead1dd86ec9a3447ea0f14017aca8d8bc8590a3a0955c7a3"
	},
	{
		"studentid": 9246,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "214c8a841b5dd61774c1224f33e456beada7a51e743dc02248ff495450613caf"
	},
	{
		"studentid": 9247,
		"classid": 8445,
		"schoolid": 6433,
		"studentpin": "0f6ca355868ca5cea89f58fcd520834973e900635e8a8aaf4073d12e74692a66"
	},
	{
		"studentid": 9248,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "c314c0c42079b0073405d7f5a8a631bfb7fe8aab26dccc9ecd1370e4ee4bbadb"
	},
	{
		"studentid": 9249,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "7467f7b14b6f2c06ef495adf01c9c82fb56e307111c81c99da415e2c7a34c501"
	},
	{
		"studentid": 9250,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "865c149baf7b5a895e5786cc4ee16f7d0274b48b5adca31084d7d8182a15081d"
	},
	{
		"studentid": 9251,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "382bacf632bfa1abb5bf9bc94e6f9ae3073ec2afa2c9396a0675235703eff58d"
	},
	{
		"studentid": 9252,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "8c748cee99e9c3a2d1bde318b0891b4087aaf07e2a38d2f3eb410fa12da51dab"
	},
	{
		"studentid": 9253,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "0af7c6e415b71cbc7f60caaa38549fe41be1f2607f0f5a7d26474bdcfa51f047"
	},
	{
		"studentid": 9254,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "59908fb53989e4d373e277b52860ecadd780edafd3143863cdbb204c0d556553"
	},
	{
		"studentid": 9255,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "9483d174091ce37c6018c52a1b20cab28d3fe6318826d40209b48a3ed34ee543"
	},
	{
		"studentid": 9256,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "47a23688886ef28bc5c40ac395745d5785934102903f269cb85ae9efb8068ed5"
	},
	{
		"studentid": 9257,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "d41cbcc8e08c913ca5cb0f9dc4b59b4df7b92f8bea22ee8b249714721a507937"
	},
	{
		"studentid": 9258,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "ebc98055ab62b333817b9e867361bb6dbdc70177709facfdbb19b676554557d2"
	},
	{
		"studentid": 9259,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "4aa916366e60d731b08bcb14faaddd3e56b1359463fc7b7440af4086ed2749b9"
	},
	{
		"studentid": 9260,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "3de1cac586267cad4d576b85f20fb4b7d3903dc858f0fc89383cc38c5a88bd51"
	},
	{
		"studentid": 9261,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "4d70bda91382628f7fc260642533b53617b5bf710c8246ba4b3b1a088d9f1a4a"
	},
	{
		"studentid": 9262,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "94d6cf7e118093c74deb477949332cd52c5f3516fe680e59b9bb0fa707198750"
	},
	{
		"studentid": 9263,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "f60a4e204e26ba14f7a9c112943018fe67f4de539c4a48a09170fe94be8a1023"
	},
	{
		"studentid": 9264,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "d2f12891edf6e5a36beaa8ae4ca38e65ee342cf7885960e0c3c4158f6df3191b"
	},
	{
		"studentid": 9265,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "8f8914d759b23b1ef74836eaced3cc9e98aca92c934e1a5a7c09815bbe12ccb6"
	},
	{
		"studentid": 9266,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "8d1889add66163cdc8a4877303bdbef6149fc294b7e9590304e2219383e35cda"
	},
	{
		"studentid": 9267,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "4bac64766cdfec5deecda2c218991c584ebce3262c86514fec3ea06467aa827c"
	},
	{
		"studentid": 9268,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "4a476ef7995bda62213744936a577d21109c01a82da57fde2e954304fc4237c8"
	},
	{
		"studentid": 9269,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "804894351074dbfbf35e7e61b71aaaea7833b1687c0d6cbd4fde777b098ed7ba"
	},
	{
		"studentid": 9270,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "c2cacf0f7ea3da91a5e671ff4b68ad4ff92df868d4a4bc1299117fda469cf5bd"
	},
	{
		"studentid": 9271,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "88a6dc08b273854a781481428d9f02069035f49df16a1b86a27110e04cd69514"
	},
	{
		"studentid": 9272,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "4864bf26cdfa6397b225ed64bd0d4bf1bec69c7869c858bc75d1237f970bf1dd"
	},
	{
		"studentid": 9273,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "35fea42f9d4f05bd10fb4aa32297d83a807d7b80e05df6ff65cddec25f44bdf8"
	},
	{
		"studentid": 9274,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "5e6eaff1f8351cb81ef0354482bf1eaf34ca8cc23047f29c753b0eda3615079b"
	},
	{
		"studentid": 9275,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "caddfa9c88e83d1287982fcfb0ba3c3b708abecbc3c107192992bb904b248067"
	},
	{
		"studentid": 9276,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "962383755ddf7f84b59b92ed9b142d34c61667d2471eefcd2c451e076af2182f"
	},
	{
		"studentid": 9277,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "6f92dd1617c9067088e21afd67500bd11912f8d69dbe8a2fdb62f96882523b5f"
	},
	{
		"studentid": 9278,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "a4025df928cd86e45db7dc90e6d00a07edb3abd82094a774e1bf9da992fd2d89"
	},
	{
		"studentid": 9279,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "2106c11f701ee33ec716cbc2814e249f038cb95611da2e41a84e806cff2e40f4"
	},
	{
		"studentid": 9280,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "4ef9e0ee86e84ba5831f503de89c21b615a4d0ca78bef09f4e88d4787fc292fb"
	},
	{
		"studentid": 9281,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "b6c9326a6a20787d4dd61091cabe706451ac21bc6d763249cfafb811f1396778"
	},
	{
		"studentid": 9282,
		"classid": 8446,
		"schoolid": 6433,
		"studentpin": "5251f4b9d3d6e8fc05174fc716ea511f58f55b16c12cb4894276ec7f064bdf88"
	},
	{
		"studentid": 9283,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "c98628eee5d54acdd0f5b3e6324419d988dc53186a21eb2eee05f4f129774b61"
	},
	{
		"studentid": 9284,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "5b2898ece37ec4dba42ecaa9d1ff6bd64338d3f97e18c5bc183dd7d9affc7fab"
	},
	{
		"studentid": 9285,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "a605343c62f501c35fd6aab36152a1a0bd306085b4026d1df187e7ed8f7b7758"
	},
	{
		"studentid": 9286,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "c304ec3263ef6da0ef0806153215250c16ec2c5d354a2e61e6c626e80f908967"
	},
	{
		"studentid": 9287,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "b332f65947a347d2cd2373a7560377220900a8d9169977ef0426dc9e29197b0c"
	},
	{
		"studentid": 9288,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "458b8f5590a42a9600ece778496577507b50f7ae5bd60b3956fd026ec6b5d422"
	},
	{
		"studentid": 9289,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "f21596ca1150214761e9e9fc6c4c48ede1d3364ee1f97b5951366ad422b69f2f"
	},
	{
		"studentid": 9290,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "e97ef30ec9bec25bf5a9598e4d8350fdb786b5ecd4e18719a1a68d49e9ae08a6"
	},
	{
		"studentid": 9291,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "0b4d8e83ee67e4006105a1abfd5e84d5399b4bb6a64b225fd874958d3eee40d5"
	},
	{
		"studentid": 9292,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "58a9d27f7f16ac966ea87522ecad4741810f71ccac81355cfb3afd38ffad62eb"
	},
	{
		"studentid": 9293,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "86178cc67d297b61bf52621d2da35f8008cc175c0f543190890b62f73a8d5e55"
	},
	{
		"studentid": 9294,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "635a7f359afed1b8e4ff4d6e4121159c18be21b5cf23e8ce46c915343922eec4"
	},
	{
		"studentid": 9295,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "b0683be88201feba4b790c8db17f84fa41b742a404f331de4f9ea75468f09bc4"
	},
	{
		"studentid": 9296,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "5b594926d29d02ec4e915b667d99905fdb4e4783aabfefee4e086bde0751480c"
	},
	{
		"studentid": 9297,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "c846e5a55853aca54de11c170e706bdbc0fab7dde34591db601066e5da4ff89b"
	},
	{
		"studentid": 9298,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "7446eac628353d4409691d73891205a3583ddd15b049d52f9e1430b57216ffb5"
	},
	{
		"studentid": 9299,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "876e3af79ae81a79a25a5e43ef296ea533d5de5e9e8b655c4d9aededf9dd3239"
	},
	{
		"studentid": 9300,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "99612c28e96dc4a685ba1d22820caf2f1308afcf8b9b58b500c6c2429a6a6a6c"
	},
	{
		"studentid": 9301,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "56d0c04f4fd8a4d2284cbd866595deb86be7e24ab5b0e1e47bbdd4a9c94af27d"
	},
	{
		"studentid": 9302,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "57de81454bec3b35e65c8bdad1817cce11bedfd80c78c97ef2ed45aba7ac308d"
	},
	{
		"studentid": 9303,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "b8e04cea1cb006453037b40c65d92545e38c1e94a456d558792a06eee6b04be2"
	},
	{
		"studentid": 9304,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "c30a4040354a6b5a8054fb3f71091b5cd1662fc3bb9fdef5c6098f4291618dc5"
	},
	{
		"studentid": 9305,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "631928a8728c9036e981522f05604f89d9b686e570440fa060bf7fd4c84c7df0"
	},
	{
		"studentid": 9306,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "b3724ae710b04ad500815fe7d53394354730d98b5e59b95a1d243f900f2a4466"
	},
	{
		"studentid": 9307,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "45e1b5e003b03ee9473b74444028a594bdec91f551c5c4eec378836ef905c22c"
	},
	{
		"studentid": 9308,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "7309eeebd3e94e84093f45eaa0b960f8398da8c4b0a823b3569fdccc5c855c13"
	},
	{
		"studentid": 9309,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "4040eaa282bc80b18aea44f9dea87ac96d045ed0ddfda9485dd14c6dfe7c9ab0"
	},
	{
		"studentid": 9310,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "c82cc84f30d8398d3a2c7d394e770c8c940feab247b1bade36126939814928f0"
	},
	{
		"studentid": 9311,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "48d57f1ab5d1805c0da5479df7b450187ea6458e264d5546e4502d939ba586d1"
	},
	{
		"studentid": 9312,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "7227e296663f4f7aa4069cbfa186c5ee37fd311bcc2af1003478d8f1f2962d6b"
	},
	{
		"studentid": 9313,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "b6ce80a7a102dc5af2a8f057f709e175bf9a12e2eb298254a67c1207f6715ceb"
	},
	{
		"studentid": 9314,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "20d195007fce375cfe454b02c38700660809a96cd19d1aa9904538e6f20b0484"
	},
	{
		"studentid": 9315,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "e22e4c6f8855459be517f7ea8847e8b7634733637fe9c09d1a43ae3cbce440d4"
	},
	{
		"studentid": 9316,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "55c08e68a2551ae3caaf1afd879b162bc5b8873d8f0fb2c808126c7331f12dd3"
	},
	{
		"studentid": 9317,
		"classid": 8447,
		"schoolid": 6433,
		"studentpin": "8dfb45c52d892915636f231b7fc38ce783ad3b6e356a653d843899bdc92d1046"
	},
	{
		"studentid": 9318,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "a70b05b1403e503baad89f65f5953db9eeb5575580e7a7e109e672ab9403ed38"
	},
	{
		"studentid": 9319,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "5291ec5618e591a9e2fd30643681c025160d95844bb18d7363ef39a062a1ccba"
	},
	{
		"studentid": 9320,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "796c00e4ecd92ec79d357c83167b04c9f077f1379cdd46faa0cc163f9346ba5e"
	},
	{
		"studentid": 9321,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "c39fb9c660afa8defd8d4f5d11d392815cc2aeb827bb031cc9c148e6fe3a8f90"
	},
	{
		"studentid": 9322,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "f1d61594aeda6c22e904ddb4c8f2de2f77da2cb07c07a087ab2e4e689834421c"
	},
	{
		"studentid": 9323,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "d5adb76dd5c7fdba41ad9ecdeca6e09f3893b87765893b289d98e87165edb284"
	},
	{
		"studentid": 9324,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "d255932485d785cc48eb443fead105069b7db4d7b3184e8327a432798ef2fc90"
	},
	{
		"studentid": 9325,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "75bcfd39cedf3a9575caef046d0894e615f76058078fcc8c2dff2a12ca99c389"
	},
	{
		"studentid": 9326,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "8484026b8107a52e3d795ad1456c493942ee23d5099525f6020e20c60e8afcb9"
	},
	{
		"studentid": 9327,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "99a53d94af51c953d66bbad6f939f239f814c02f431877f7eb3612ce71fc3c9e"
	},
	{
		"studentid": 9328,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "276874c97b8a3831f619fb6752fe54099e46b7fde94c915e2daf583214efdb4a"
	},
	{
		"studentid": 9329,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "1151537cbe90657845a40025183769fd6746ebf031c47eb839b1d8129e0430c0"
	},
	{
		"studentid": 9330,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "6de52191b1cb5eca65ccaa47805c0fd03c2e6d936adc754bbfcf6375033f027f"
	},
	{
		"studentid": 9331,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "3e3377afa0073892695d345aef957f88c51447c84e1dcc0e74d20404b4d93335"
	},
	{
		"studentid": 9332,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "cb68ed27313105ace40c0dee4f91399f62a21066ab7b5e6952bb9df934e3cc95"
	},
	{
		"studentid": 9333,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "4f3078378d2e6b750f9c80658a70d22d1b593de543201d4c2b5d585ba80886c0"
	},
	{
		"studentid": 9334,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "6f2cd689fa64c42a544179b96ee531dd29837284c14f0cc2d7a8076e69889e2a"
	},
	{
		"studentid": 9335,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "541af079ed31b543b2fdc4bb853b19660d27577f6ebd19ed39b459a95d2452b7"
	},
	{
		"studentid": 9336,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "506000410718d360c4365d6f7c7157b039eb9747f331dbaf9104055896921612"
	},
	{
		"studentid": 9337,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "2d5fa65c32343d2c5f03bbc3655ea6277ca138127e005b32caef8d1df2b85eb3"
	},
	{
		"studentid": 9338,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "fb6d5352f2f3c4e5567847a733e47b61624cbe994054f88f0a3b9dc769ceb9db"
	},
	{
		"studentid": 9339,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "123ee973ce2d6b1a39f08d40874eee16ed9448a9221e580ad36f61010bc7294d"
	},
	{
		"studentid": 9340,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "78260e5b1717889519dea1f6bd82782d0ad49fbd92c4266b7c6032b430fea2ed"
	},
	{
		"studentid": 9341,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "887060dd8b18ed04b2b66eaec6e5aa072fcd864f700636846ee9c9d71d65b749"
	},
	{
		"studentid": 9342,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "71d1b31084bbd2fc1eb918065cf110d51c26808c87875dee77eb142ec7a20650"
	},
	{
		"studentid": 9343,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "0cca61b7608397040b2d8de3c436021ac4077b0f9170605ca7c6c3a883bdfa7e"
	},
	{
		"studentid": 9344,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "902fa9a322b9f95edd799b69b228557934a927418257a715f6081417d9c30d09"
	},
	{
		"studentid": 9345,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "013221cca5b99c691ae24a025e6fca9864ab0d4f55573366ddd38b1dde07dce5"
	},
	{
		"studentid": 9346,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "6e09c456f85b506d2b21e81228101c58c858167a4a3455f08459f85b43a3b94f"
	},
	{
		"studentid": 9347,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "86c83722a396a0cb2a74c04d456f27bc0a2fb748f0a84716062b97b190c1d7a6"
	},
	{
		"studentid": 9348,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "5d8413031fd8646135dcf3090965bcac42a9868cf99b84c1c4e54203247c7c0f"
	},
	{
		"studentid": 9349,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "546a7caaaa650b671155455908eda76c87dbeaa3ea6d0439373476f5d55922f2"
	},
	{
		"studentid": 9350,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "13e103208a5744d2837c0b51b46e23100848686156ee22940fe290c2a2eca8ee"
	},
	{
		"studentid": 9351,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "ca9245a53724426739ff4c143efcc11efda58d2ff1dee365ebeb1b137b42552c"
	},
	{
		"studentid": 9352,
		"classid": 8448,
		"schoolid": 6433,
		"studentpin": "0d8eccdd7610f8f3685f9de3014d1990571168b980b0e6bef98867e5d541f8c3"
	},
	{
		"studentid": 9353,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "7df99885fd3ad88efcdedabc1aa3c1ba00a0392836c5c07f576e72902fdbd581"
	},
	{
		"studentid": 9354,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "cca1f0ab63d9792e383aaeb7520fabde81a842c9c4c4495b39f0b32a3d285791"
	},
	{
		"studentid": 9355,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "d9b30ec6eec4164083f6833d0f88c0d606e7f8e3565f31590b3ff3f11862eb24"
	},
	{
		"studentid": 9356,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "edfedfb9d50aa859d922a6b97d8cbc0d5bdcbda7f027915680b72bf146c4a8e8"
	},
	{
		"studentid": 9357,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "7e51a1ab0e9599d9b3b034a52b037c05f1ab8bbd8af8681de0ef842b2f586c59"
	},
	{
		"studentid": 9358,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "35e988a278843a2b2800a1329d736b8eb5008e88c630f5a3ec4309cf0aeac1a7"
	},
	{
		"studentid": 9359,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "b104a82e9068f5846f16309cf7ae62be3b6bcf5088004723a66d6e1ed4d01143"
	},
	{
		"studentid": 9360,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "abd17aad38e960460d2e7248d03e74c75749f039afd87fc0fb7ae9baa8439365"
	},
	{
		"studentid": 9361,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "85b89dda89d91e5f2ffef3753aefc3579e82ee5bcf3ffa3277fb2fd5018e8cc1"
	},
	{
		"studentid": 9362,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "cf9eeef37c4b6e0d395b2186d0aee7be0ca7233e2ef5d2205c670e037b6f000a"
	},
	{
		"studentid": 9363,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "39071ea10224b5ff23ce710d22228b6124e000a313f9cc52751c606444c817de"
	},
	{
		"studentid": 9364,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "b6bb0e461cd9f8e2c26e5a7a255f4bbe4a825800edda0048dcce95b83f9de6e0"
	},
	{
		"studentid": 9365,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "1da3195866b75c0531a50376adaa5680e55bda56adbb918b6a75445280afaa3d"
	},
	{
		"studentid": 9366,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "1dce468ddb50e0b395adbb1bb1339cf869f04b98fce47066abcd0809c0a0bb18"
	},
	{
		"studentid": 9367,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "0d72e9ad5dae81dafa1c8c74cb6119292288050b2664820d949e62a0ad7782c6"
	},
	{
		"studentid": 9368,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "4755e0d6cd771fae890ad7940581fcafad97d3ae00020680a7767b44f583c6a3"
	},
	{
		"studentid": 9369,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "ba81498b863f6f10270b85282a951f8d3e0b249ffe61fd939b0f34dfc1046e26"
	},
	{
		"studentid": 9370,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "934e3093cf21044e287213d200ebf30907f2aee35651b0043f5f3dfd2365eb05"
	},
	{
		"studentid": 9371,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "705a65c06b07af67b61cd173922969750732cb1fab5e2fa9f23cfbfe491eceeb"
	},
	{
		"studentid": 9372,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "98f409bb58bb46d21260aa191041e358acb7592a9ef6dce36233e92c4a52ec47"
	},
	{
		"studentid": 9373,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "61cc096e039c4b72abe25d958de770ef8bcf6cbe73e5a1b8c9a6ac87095aa919"
	},
	{
		"studentid": 9374,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "c74b2d6075317947ca31060ce4d6bdd71c54e923552d4f655c4948289cef856a"
	},
	{
		"studentid": 9375,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "b7543c7cac0683aa481d11ec67a89829b066852f1b827253e6bf10d6de0c3e35"
	},
	{
		"studentid": 9376,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "f4b5b9f183cc4521e521fa10e947e30b3a3ae1c1e5fbf5730113c8c5c440e474"
	},
	{
		"studentid": 9377,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "1ee37030225714437c00520eefdf5b64a45f8ab60c25a605f2acc163fba60949"
	},
	{
		"studentid": 9378,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "7a7fb9eaa7f7f0a863fb4a4afa9786167658a0f9daa3cc54ac44dbaf6756677b"
	},
	{
		"studentid": 9379,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "ea475b3d91ab8da66bde6e0c954f08c34f82ca859af308fe63414b58b53c937b"
	},
	{
		"studentid": 9380,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "f5f8845b2edc72eb33df6cf15c335e904697fe6ef433791b1edef58db70ffb09"
	},
	{
		"studentid": 9381,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "e1a78744d6844c6b384b99ab776a9d116f4cb71c7aaa01661e311a5b3e98c16a"
	},
	{
		"studentid": 9382,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "890b5d87e54ca47249025de86c421e5c0e3d6e397209c35108dfc92c75f3ee25"
	},
	{
		"studentid": 9383,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "7b7ed9923688d017f51ca215d45767b05eaf1c9127214af9432080fb80ad96c2"
	},
	{
		"studentid": 9384,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "691b4d56dc179dc373ba0b079c916bc01c7303e522520bca30c98c854ea949b2"
	},
	{
		"studentid": 9385,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "faa205ff6d9fcd49dd93790936f828442cde1ff9bdeae69fad4f8702a3023728"
	},
	{
		"studentid": 9386,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "e5c2f07a9c8cc1e1ba5152105667acc9a2817ee310022a4cbebd0e2d28ba2586"
	},
	{
		"studentid": 9387,
		"classid": 8449,
		"schoolid": 6433,
		"studentpin": "4d6e8580858c5f9e3cd14e983db34acee0569be87a2b1bab36dc4c05d9974562"
	},
	{
		"studentid": 9388,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "8092017d7aab080938ded399ed3060f53b6e74660add19ed636d22e6c2d677b2"
	},
	{
		"studentid": 9389,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "92cc5c57a1f108115ececd106bbf7d35e12257e57278c047422f3ef379144f00"
	},
	{
		"studentid": 9390,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "d1b043006d570c098f370ca98712083f776f5ff7b0779bdd999443a6548f873f"
	},
	{
		"studentid": 9391,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "cd3e08991d8463c90525d48fe5a5c08f83aebec27ba8ee3db1a8432d086f1904"
	},
	{
		"studentid": 9392,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "6d8b690f868648c1ecd3fee8a1ce88f4847edb6bd333da2390dd7461e40b3026"
	},
	{
		"studentid": 9393,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "0ab2b993b686af5ecf3fa7ad9767b3307e5fa339d6742082f72c6a4f1df59ae3"
	},
	{
		"studentid": 9394,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "6faa4b5f540d81a6407d0864134a456b3da1834502bba79c6e7c9fa3dc4d1aef"
	},
	{
		"studentid": 9395,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "258324f9dcbbb2de956c664b71112c24cb4459fd3ebc68b9164893701331a21e"
	},
	{
		"studentid": 9396,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "40b2de3a73c25ce1d8988494a27b5fe6e475b8999d5fcb4eb04595aef07884ab"
	},
	{
		"studentid": 9397,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "fff2f88bbbe567f1b348f2a1effa076efd092f276b5a9d464b8c6f6efd295529"
	},
	{
		"studentid": 9398,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "3553bfac79b52ebcaf9dfc2fe332ea1141bee7c2c1895effc8554555d1c8c468"
	},
	{
		"studentid": 9399,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "72f324578a1a0d34953b5f05132d293ed69284ec9d3066c98ee28117544d2ea4"
	},
	{
		"studentid": 9400,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "9c0ec501afe23288753b80080a4f495ddbcf9b2a454567ec523fb12339e8b9cf"
	},
	{
		"studentid": 9401,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "59121a89fa61e9f673772371ce5385b6f383c7fb866a0385c7823031e7de2d0c"
	},
	{
		"studentid": 9402,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "0a46dc6691b4dcabffab3fce388a5175919f7662ee9f51ff0b304bc7742f0e17"
	},
	{
		"studentid": 9403,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "bdb6fcf409e3c82cc62aa95989859740063ff2b2469ac3278872c57ccf96c2d0"
	},
	{
		"studentid": 9404,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "ec1fdc9c35645dbd01df32bcce81babf279a1901c707558d1bcac9ae5585f4f6"
	},
	{
		"studentid": 9405,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "faf21e201969f1a36fdf3104b34256dc517ef8c87ed99ca4ae7628e34558d878"
	},
	{
		"studentid": 9406,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "8ba4467ba042595935a925e3380e77bd4b7d785baf006b51b2c9cafe0e3554e7"
	},
	{
		"studentid": 9407,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "a6c4ef13e76066242648755edf44ad8aef86fd1c97cd8d9f14307abfac5f398f"
	},
	{
		"studentid": 9408,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "69e2bd0f699e2a6fd8302918746dc7b20530ea88ff725f671160023e64fd22db"
	},
	{
		"studentid": 9409,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "5d43b917f1838b0c5640d57467fd79c4c626259b5ce59e462491430fee2084f5"
	},
	{
		"studentid": 9410,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "a31644252b5ca1babc69f2ceb2715d3f528f968e957cb6572e98448fb0542205"
	},
	{
		"studentid": 9411,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "8f50fba730fd59f8aa8718fd9c6ad828e195f4484cee4bce975afe9067988a5b"
	},
	{
		"studentid": 9412,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "22a82e1198da7090b1025d045f7c46f30cefa1b7d734b6feb533802a45cb892e"
	},
	{
		"studentid": 9413,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "bf94c068c8a68e26b0d1191d59136c98f60244936e2fffcee7240a42068ea39a"
	},
	{
		"studentid": 9414,
		"classid": 8450,
		"schoolid": 6433,
		"studentpin": "2a4f05d764ff78dc2f1b7867cf7d20bc66a388aee285c2e32fa60a10dc4a9896"
	},
	{
		"studentid": 9415,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "f6306ad610744b90f0c9b7367063eb515263c8f0349174dd39f9177db1fd9b94"
	},
	{
		"studentid": 9416,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "d0a8416819aef0705dd9b78fa06805530bb2821878ff536306fae1de0c1c4562"
	},
	{
		"studentid": 9417,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "a0d9616afbc14981faed1101fe00e51dd117e56089b556ec4a63a012502df03f"
	},
	{
		"studentid": 9418,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "f2106b696096c614abe0e0bab185cf8ccdb15b99c09d44997ae0473250328a92"
	},
	{
		"studentid": 9419,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "0c25ca2e909a5c31085b753ab8025b3a61c808803de1e5858d5b180d52e37e21"
	},
	{
		"studentid": 9420,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "7cffd6832d4da977aa1cf8e64ee14ccd812075b358f5d9eceed0499f229c165c"
	},
	{
		"studentid": 9421,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "638597c40b59b6309d3015ead8dea267ca2f2f56a35fcd5194763e941855bc94"
	},
	{
		"studentid": 9422,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "2d849e6bcc0f94c33962a6823511ff58b3497f68f130e190e0c57d1c8a57f02d"
	},
	{
		"studentid": 9423,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "e33e586cf5c8dea47ef848424e32955f63469914363bf5d11d8576bcf0488661"
	},
	{
		"studentid": 9424,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "3070d52055dc280612d1beb1bc891ee53270fb4cad6e7ed1491b9e9c7381664d"
	},
	{
		"studentid": 9425,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "d2968925470736bd756724af4b391e42f5ba2cf1e0ba48c81df8362a74ba3980"
	},
	{
		"studentid": 9426,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "822b272d959902deb42dbd5bf9882ee622201aa9b2546e3f68e57ce3e79849c4"
	},
	{
		"studentid": 9427,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "159892e926cae068ca372ea75dfccccb4dfd102d9c4317199d1a89716e68c651"
	},
	{
		"studentid": 9428,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "b24c5c924832bff7ff7e1a2d2714fd33286cbf51eeeaa14ea7b3031e338d4471"
	},
	{
		"studentid": 9429,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "3bf1de51f8fb1b87a79db9b95a2018b44c2cb03d807060caf2f622359ddd6b8a"
	},
	{
		"studentid": 9430,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "afc934ab9cea89f62bfa551cea7e8f4b053adfbcf50f57ecf381f40b7fd626f5"
	},
	{
		"studentid": 9431,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "2ef3fcf6f39d77e13f9df9aa412ceafb49ae623968019e46ea15605e8fa438bf"
	},
	{
		"studentid": 9432,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "483fd453c04894b7904914015270a96dbd8198da754e639a4408bc25240252dc"
	},
	{
		"studentid": 9433,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "04037bc738ee7495de8d6db287fee2f9b86ba18266dab99288dc156fd6ee1008"
	},
	{
		"studentid": 9434,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "135993d13a643aadfba5ebe3bcc46138cc063d6bebaababbeaee6450bf55c884"
	},
	{
		"studentid": 9435,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "74a48757b0f96f6f3ac175840020be4f3cae62c48b990921c8e039ae1d9f69a0"
	},
	{
		"studentid": 9436,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "84fd45024730cf5cce802666e2505cacad23de21f80a791337ee7b15fa9ffc9f"
	},
	{
		"studentid": 9437,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "035276e93080f85054daa267bd3578fde746f290d4a09bb0e974f2a711705079"
	},
	{
		"studentid": 9438,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "659460d3038e95f0f32da796cc01e7ae12774e9b0a18f1108de23b3eccc0fb0b"
	},
	{
		"studentid": 9439,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "6628c80c620ff3e3f7fa088efd22ab5becf2e88794b3e1468bb331bfa15e4008"
	},
	{
		"studentid": 9440,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "2a0a4d29636e5a4d9dbc342fa160297194c47dd3ab66f9c2c16dbcdf6f73b86c"
	},
	{
		"studentid": 9441,
		"classid": 8451,
		"schoolid": 6433,
		"studentpin": "65b582294aad023ddc94fd44672f8cb52af634dfab8512e9fa225b2e4e7c11da"
	},
	{
		"studentid": 9442,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "53143d134853ea0c871681234e233ecdc155e0be2fee8a16bcfd32f0faca2446"
	},
	{
		"studentid": 9443,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "75b41a98742e7c4d4988c1a892f35c9ed27de89e614aa7522312f83d9b80adf9"
	},
	{
		"studentid": 9444,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "7e19c49a0804ce3ea8d25710ca9b7b72f06b2f0737b633ae279b0b9d93b86c80"
	},
	{
		"studentid": 9445,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "77e02ab08786ef366138b1364d4d69e32d8bf272753a058031f124088842ab46"
	},
	{
		"studentid": 9446,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "228340cc6e757d3eb7b93f0487f8e2921549181f5e44c07589ce1f71bdb85bb6"
	},
	{
		"studentid": 9447,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "352c40b2c3f462767379c6dae2200d3a2c27e0da4e49e7aba2b1b10b3ac23e2d"
	},
	{
		"studentid": 9448,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "2d25fea7e7be4a0ffa56119d625cb407d833d76785c818808b1d675aa416a55f"
	},
	{
		"studentid": 9449,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "56fd3dd2a1dba9698aef78f0c1f765622c93384d7c74c7d91506c20de4c2a27b"
	},
	{
		"studentid": 9450,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "570599cfbd18d5ebe8f3bf9ed9e2cc39e0b3947862c5ba57f2a520a6f4f0a2d6"
	},
	{
		"studentid": 9451,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "28884d3a55cf2e5563e8a01b28f6adbeb7b54f1dfa7936afd1a52a79e185fb4e"
	},
	{
		"studentid": 9452,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "18068d863de7c946be3ec620a68ad71dca5a6f47e1fec088920e2da411d934e4"
	},
	{
		"studentid": 9453,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "7f0313732dd733be41a602836989419e15f5673d4250de038319bb214266edf2"
	},
	{
		"studentid": 9454,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "38b4df846759e0e88f8a67886a2f2c1577e4a4cb54a037c87d01a68e4b6e5b0c"
	},
	{
		"studentid": 9455,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "b7838137fc900b46ef58090a77d0c9d98f66a69ce9a21fc52950ec01b6c768d4"
	},
	{
		"studentid": 9456,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "ef136f02ea16eecdd60b4c56a0a7193861a9f96e23e22c56420e345cbd70e5be"
	},
	{
		"studentid": 9457,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "0e991348de12b9244b4f1c9e482be2e14810f259c4efb877bde57c21b21cc5eb"
	},
	{
		"studentid": 9458,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "abbbef9e882f4e93d13c2b2612ff0530e99df8e91b8ef3c90aea810ebdba1d1c"
	},
	{
		"studentid": 9459,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "9d17040baf38a32ab363aa50bcc78ebe19b791d49f77c268fc5d79b26775e0b8"
	},
	{
		"studentid": 9460,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "baed8e3811f0511847a84040730e567c349f95c7a8db65bfac81cddd8067110f"
	},
	{
		"studentid": 9461,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "60a7e252452b41950a3d700e945c77f01a4522f2712eae7939a7972db042ebf6"
	},
	{
		"studentid": 9462,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "09f6e173d2f93886a38feefdeb03aa07bf7aa4782ba3edb44717090324acfdcb"
	},
	{
		"studentid": 9463,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "79d8ea3126e4e59db55d0549cef74488e3e03c39149c252c2b8b77a26765ae2b"
	},
	{
		"studentid": 9464,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "61ad8136b5ed4a882d50c49bf912ff8c7cd5cce921c80a98ef12fa6b9844ce8f"
	},
	{
		"studentid": 9465,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "ca98226e7153f71fcc5dec97453955704a15ef85447e522ee603c80553b0dbd9"
	},
	{
		"studentid": 9466,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "003a78c23aa9032e068636bd0031d7c15ce95aa49bdbe5dae68ceb5c6378ce01"
	},
	{
		"studentid": 9467,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "c4f9e237f71a9b4b580ced4a2af4c9489d6796f0ff634389f340ce55abc3e6c3"
	},
	{
		"studentid": 9468,
		"classid": 8452,
		"schoolid": 6433,
		"studentpin": "5e3b61ef42f901a04d415eccc868d04001727c68ba03c13d47998bf11d87f5af"
	},
	{
		"studentid": 9609,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "d06fa830f1b1fcc2123fd7126521988adab1afb9ce45a36e111187f9e2263ea2"
	},
	{
		"studentid": 9610,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "b8bd613f467ea877d16b92fa716d9cfe5da5ef8ed2636b1085c6366657a95346"
	},
	{
		"studentid": 9611,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "3aabbc22ccfe296f591ffed2b73521fc648c526dda652af268447c267cc42030"
	},
	{
		"studentid": 9612,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "bb346a0f0ef6025dc38b3cdfc43aeb4a548355fd202903f6ff4fdb0a6298b0b2"
	},
	{
		"studentid": 9613,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "0276cb2a0b4826fe8fff7e6a0233033f76e4c0684bfe071106bc9b60b6cd9900"
	},
	{
		"studentid": 9614,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "05b92b9869a51e7d61cdd7c5b0bf705b4b24c2e9fd0fd40a82f0f46671fcbf80"
	},
	{
		"studentid": 9615,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "03f089cb7cf8eb4ad2ccddfb32f7c7f5d088ecb9c60d26eeb3fc422849b00097"
	},
	{
		"studentid": 9616,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "dd3b9b333994b9ad29ebb9b920560e77129c844e96053bca2f41fd8ff3a81ca8"
	},
	{
		"studentid": 9617,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "568ec97c1779da40109026edf5090a5e0a54ad09ffdecbedaae6e63c30a5c75d"
	},
	{
		"studentid": 9618,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "b3f26115b4c1f7f5ecf7beb004fee20ca57f7bf78a94dfd29bd610396a14637a"
	},
	{
		"studentid": 9619,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "415f9dfbf815b4131881ce7b6d07bbaffbfdce68fb6106c1b1f660007b21ef65"
	},
	{
		"studentid": 9620,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "86d317a04664d5f467f9ae8f91a4e177a26aaba179e4f0a0a028cde03ddb892b"
	},
	{
		"studentid": 9621,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "71501672677c0c3955643185bcb64ad6fc649fd4dca6732817e6ee317a9e1160"
	},
	{
		"studentid": 9622,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "0fc2afe6be5deeb060f087244449a446d1b18d178a7531216df22828711acae5"
	},
	{
		"studentid": 9623,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "9804691d3c155798288419764932cddb6439702f04a2e533941c90c09220e896"
	},
	{
		"studentid": 9624,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "47c284306ad1382a7c3fcaa2ea3040c79b9c205834ab264ef90bc3207ec17b20"
	},
	{
		"studentid": 9625,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "92d027a8c79c2e0c49173f0bba030e868230c4d98c2757d60f976772cb0b1e53"
	},
	{
		"studentid": 9626,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "6f274e90dc7ba1b8cd05e7cee78d74b7695dbf3c7ec617be51ff1135ae501103"
	},
	{
		"studentid": 9627,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "cfbc64b2a68f6ddc5b456e186d7b10687abd5d147c43b2591deb7acf363e52c5"
	},
	{
		"studentid": 9628,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "80f0c8793bfe13ded39f1a7b5ff4bebb96b68a833d1da3ed71ceb61a15ee81be"
	},
	{
		"studentid": 9629,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "f73960fb246f9e418370f217eca90b225ed7aaeadf7b0f8a318af45b7e7206e2"
	},
	{
		"studentid": 9630,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "f3b8d32427051cc775d5a21deedb05f91b92c474615fcc94b9ea73f50cfe56ce"
	},
	{
		"studentid": 9631,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "233a329653324e37abb211db65a40f704f481eba38c0ffebff1d65c1e7ac95da"
	},
	{
		"studentid": 9632,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "173caefbf5ecd9cbb4d60ec34a532d768e8e016a925462bfb4ed6f1791c49053"
	},
	{
		"studentid": 9633,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "04bbec1ffd6b767d543cd9558bc3f9d8e97685f1953ce22002da50a7a9ad22a6"
	},
	{
		"studentid": 9634,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "455210eb527ea44eef607568809dbbee308da03ae9efa4338c60e983c3ad514a"
	},
	{
		"studentid": 9635,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "f43514e548fc3f80621890bb416d54fe0157ccc8001cc0b97a731fe8393b6f38"
	},
	{
		"studentid": 9636,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "18288be13b1f21b7ff2046e9230885068b5709fe146969bc3ddc374044aa4d72"
	},
	{
		"studentid": 9637,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "0062ee11eaa9eaf557e9402e231aadc5232ee608f194e2f6a7c148f69b0dfed5"
	},
	{
		"studentid": 9638,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "d13a722d5f8910ec48c60ed90e7a7c9e6acd2a4da95ba8096b0f22f6a2e3b923"
	},
	{
		"studentid": 9639,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "f336628140d73c5ef84a602aade46b4ea49d485359eecd0026f4404d58736536"
	},
	{
		"studentid": 9640,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "784f3639adefbfb62f6880cc394e87d49688e2132b0f1ab9e72a0f097c46ab2b"
	},
	{
		"studentid": 9641,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "203484c9848632909ea78ef9a723a4cf592566582f8923ba4aebf7f33272ccf1"
	},
	{
		"studentid": 9642,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "ceed19051045d1aa3d5cc111160c1173cc78467d7787cc2344eadad35389992a"
	},
	{
		"studentid": 9643,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "2173897c0a6605e3e5a2c8a5fb5706c86c71c61065cd93957668edf72c98ab2e"
	},
	{
		"studentid": 9644,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "a0f8def1611f226a3610d0114e21dc76b05ed65a3bee1fe9a0e3535686246c81"
	},
	{
		"studentid": 9645,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "57d27214ab78d32343355bf5456414e4364985bd013e3a146ae0f0d6fa28f6ce"
	},
	{
		"studentid": 9646,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "ace1946c691519f303ad0943fdd642cdd5a271d74e324fc76f9b7ec744b768e8"
	},
	{
		"studentid": 9647,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "8431b5e49325c0ab6d7da7804638fc23381eeb499b08c96ee5189e605c1778ca"
	},
	{
		"studentid": 9648,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "ca04287bae02b9a927fdcb970b801b37be3739b78dddcf0a25151ee89f17750a"
	},
	{
		"studentid": 9649,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "7af3b58ac8fd2ed7de20b74a4964b62837fea01d37b716955cf037257a6276ad"
	},
	{
		"studentid": 9650,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "2951da23d6952753387d8d9299975dcb9d56075525cb87a3ca131f8c16038bf2"
	},
	{
		"studentid": 9651,
		"classid": 8611,
		"schoolid": 6418,
		"studentpin": "cffd790e308046f3ebe2221752cc39e05eb984e08d0a3126a8ff439171390142"
	},
	{
		"studentid": 9749,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "078c818a7f522a38aa239fb2bc3e940486070443aef35ec59f7d785b00481c51"
	},
	{
		"studentid": 9750,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "ab9f3dad5a769bf69c3478f7c06d0e35b31510e224e13894bca5bb61e9b5fbd9"
	},
	{
		"studentid": 9751,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "8c1a545661a03b9dc8ba12481951308ad8896887816c250ae1916c08e6dcf794"
	},
	{
		"studentid": 9752,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "512354362f93de2a9bc3bf3d301ad804978d86b63dbaed9f1e918e011f67b514"
	},
	{
		"studentid": 9753,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "472b7b9bf79e0cf105190856bd7e07b4323676b76a39ad6d34c637d7f52fe545"
	},
	{
		"studentid": 9754,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "798aa74f54f74fee94e28c360a9a9d85640345f12d1255153a4d498a2b7214cc"
	},
	{
		"studentid": 9755,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "657fd9115981b82ec12a782b348fada8257be25217071b3d24f94e0588a15c1a"
	},
	{
		"studentid": 9756,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "2d31d50c132266512bf4ee918ed5cf0f1cfef36cb5bf82e8605abcbeeef5e234"
	},
	{
		"studentid": 9757,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "8a95c5972884a8fb89c5a3319e34f3426281062da232713ef5b8fc82f67ae37d"
	},
	{
		"studentid": 9758,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "c8119d24bc0afb627b82c2400a666c6ad086bfb9bb4a12596bf4ae9a6fad6aba"
	},
	{
		"studentid": 9759,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "617dd21691dc2445b566c44f0ba937a3de9dee0a3425b0d42ea9370859de3a92"
	},
	{
		"studentid": 9760,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "e7c98b3e6730cf93224405e163be2cdb0b6e08709539418099054450dec10fef"
	},
	{
		"studentid": 9761,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "55c856567f48f04db749e34dce157c46b318aeae798f9a72153efedfb6a14bab"
	},
	{
		"studentid": 9762,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "5992f3a3cad0ac01d9b743291fa128460d0b1cb13d2abbff34cd637beb0930ef"
	},
	{
		"studentid": 9763,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "3ffd0483490d83b7b01e893d4367831540fa960c98c64ff24e13fb0841f8b665"
	},
	{
		"studentid": 9764,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "812eb7c0af443d2b1b1710cd21d119a0fdc5724cbf1011ea3a97c1c2ce25df75"
	},
	{
		"studentid": 9765,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "fc9e4ba474c0d64e4bdc9cc871c381ef20c16e3ef08064d64b76c150e59b07e7"
	},
	{
		"studentid": 9766,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "93673cd472e192722236f2c333c897bcbbf8a6fbb99b46c75b8a982df0720e75"
	},
	{
		"studentid": 9767,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "c5b83065c860ffb34ba80816e60bfa5e13936179029bf0feb6880e9c8159c4da"
	},
	{
		"studentid": 9768,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "0617c2b29827bbaf8abca160fbe2cfcce16d7c9be286c6efaabb31ac61048136"
	},
	{
		"studentid": 9769,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "cd579ab83f0b5799598ba4ee2a84641f9c02935d0030960755f1b0dcf9c91c9a"
	},
	{
		"studentid": 9770,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "e869107c6712361b7e2f7d67e166b3f76da0df4a6777b063592c2cb01664ead5"
	},
	{
		"studentid": 9771,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "9b40ddc0d8afed99f0fba64a9994882846be31901a308dc6e62af9f3df821908"
	},
	{
		"studentid": 9772,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "835ec47c590e989f27780fdf4609ad338deedda0614bac86efb12dfe8fb2e6b4"
	},
	{
		"studentid": 9773,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "ea5610c4c0febee78b3b47163987db5f7bbd2208e5c0059eef2d4b5662463503"
	},
	{
		"studentid": 9774,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "9c6b3c407228074a09de3b8d70457d3e7d81a447a3f294a0ebe955b08de84126"
	},
	{
		"studentid": 9775,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "ff92d6a0ee062bc8d3736cb68730ef41c4cf6acbb22aebad8bb8f43d158cb892"
	},
	{
		"studentid": 9776,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "1053c95c162e051c1ee488e6249c4090e8dc765725c5bf0f590fb08cb49986d2"
	},
	{
		"studentid": 9777,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "531d7125838081c399c00bad6221f0dab476de59cce1aedd6c340b4964e53424"
	},
	{
		"studentid": 9778,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "a4f92edb13b3d15b4bf0722441bc5283a45e01dc962d4d2e095a023077f8744d"
	},
	{
		"studentid": 9779,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "4f339895d09a2d50fc904e0ed5ee15ade2bf0c254682cc7fdb1207868844e11c"
	},
	{
		"studentid": 9780,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "2495732ea58be173bd4d922b785e7a44d2e44768e691438939fff2b16534052b"
	},
	{
		"studentid": 9781,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "859d250b88b2803c806c77e95db5a17b364570213a6751fdf5b3e716be1a6d7f"
	},
	{
		"studentid": 9782,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "7f177edd9ddd05ec6438d3cc6288cb9d147ff7fcd63c11f262a6147a00adb6ab"
	},
	{
		"studentid": 9783,
		"classid": 8803,
		"schoolid": 6348,
		"studentpin": "4d9660e708060d55224229d73cafca5ecef270b2f9cc3fa6da85bc64b380b305"
	},
	{
		"studentid": 9784,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "7ce843c7dc7c5246bb1cbdb31298e733163ce4a8c41222b0605ab5cd03c03f5e"
	},
	{
		"studentid": 9785,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "4a96194c4123f54827e51ae091be8c2ca187908ac88036cbe17405fc84a620ef"
	},
	{
		"studentid": 9786,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "bc67c9a87db50783a3ba282df77a6c00b100f851ea4ca8336529b1386b2183fc"
	},
	{
		"studentid": 9787,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "6c18afc4354f71ab2a9b7ee66b8f2e65e782f72a856dd5baa9c6379bfb7e4a89"
	},
	{
		"studentid": 9788,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "1f78c4ae4f48a3ca52d54859715ef29f49301af76e4184d9e2c15f3dbd99b288"
	},
	{
		"studentid": 9789,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "5d7f2a3467f657d29ca46893c7c4a210344307d5beb1b73732113f45c4271ee6"
	},
	{
		"studentid": 9790,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "e945c129eb268795fd59138a59a320c5ae22feb899ba865fd7d4691772635157"
	},
	{
		"studentid": 9791,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "286511d8fbd9d228b61db1cdcfa068e454559fd7e87595d7d920fb25de03d3ff"
	},
	{
		"studentid": 9792,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "11acb262ba24a45d332aee1e2be7d15992d32e7b501c57051f7f442919784a3e"
	},
	{
		"studentid": 9793,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "467ae241e33c7c2e9f3d353c6d23628e8117b57137216a8c0694ba4228c6088a"
	},
	{
		"studentid": 9794,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "05c9f6bb0c4f73e0d31fc16fe728767b5eb26554b8f9799a6d288939cab429fd"
	},
	{
		"studentid": 9795,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "12e6ee478714fcb89c5f16b2b519006a8a10de97a93a20f77922c47aa843523e"
	},
	{
		"studentid": 9796,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "aaee64167b24a00fe0a3aa87359afacbbc376c0b29cb6174395ced60ab84c8fa"
	},
	{
		"studentid": 9797,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "c62a454c747e290ecaf6cc9511fe581ddeb54213535eb446d872a6a6721baa5e"
	},
	{
		"studentid": 9798,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "3d99bc972346924c93ca6a607482eafadbbe726e3492ae3f9c8116f56b6d4b86"
	},
	{
		"studentid": 9799,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "94503f584404338f7f5711219590aa611edcdd62527a102132728d2d8509372a"
	},
	{
		"studentid": 9800,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "0acacfdd4557452b9bb7fd29817fd19999e3582635b61e4de703271fedd6247e"
	},
	{
		"studentid": 9801,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "2eae1ba8a27ca570aa525a5693aca1072b928dfbb058f258ca81243d94931212"
	},
	{
		"studentid": 9802,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "39c4d3489a6a147232621995fdd2a8d6c91003cb52a195d3be941859dc3ca25e"
	},
	{
		"studentid": 9803,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "5a6ea7b5feedb0868c93731add62068f072fdc04024056ad3bb9884f74ba3de5"
	},
	{
		"studentid": 9804,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "ae7faffee6eef1a254976890acd2c002de12379ed387608b9d1d79f555279a87"
	},
	{
		"studentid": 9805,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "e4fff8405810ea84c27fe71381593982cb678a37151246c5ca23faeaa6bcedc4"
	},
	{
		"studentid": 9806,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "dee1e5cdc55264edf29ff75157c2d49d6f88d8c1bf1f153d2e9a9c4b83f4067e"
	},
	{
		"studentid": 9807,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "8f44b9406eba2e8478889c71233eac36019450a4d85358ebb508496b10b5d3bf"
	},
	{
		"studentid": 9808,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "2b862b02729b3884433406f094c774d941b7fc861196be83ec685d5e749e28cd"
	},
	{
		"studentid": 9809,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "0e533ac0db6249d02b8e5f2b4463057443fc572c485a4bffdf8daf5e624d7fee"
	},
	{
		"studentid": 9810,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "7f7edff9492cfab8b22aad29b0777553916479cc56ffef006b978ab71b5eaabc"
	},
	{
		"studentid": 9811,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "e0591ece9fe4e9b264c76a67cdabd04532d6cc33fc27d92b658cea2391ab86ef"
	},
	{
		"studentid": 9812,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "e12ae21f6f8a90ea7531641f8eaa340c848bdcc94c062d2dd528a312da3c15a1"
	},
	{
		"studentid": 9813,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "14642f40c6253dd22ebb15961d9dfde28a7476b24cf4c12906e8d9169283899c"
	},
	{
		"studentid": 9814,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "848f9e512ad566f01ca706f730056aed456069cc3fb39fbd96fed832b5f681b7"
	},
	{
		"studentid": 9815,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "6700ccfc837c34ab362f4b09fa16d4ef524309171789c6ef23d73130f960fea8"
	},
	{
		"studentid": 9816,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "2765095ea763d8ea58a44437e0feb60bd9ebfb54770942acada36040c8066fce"
	},
	{
		"studentid": 9817,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "004dfd65b8e42a36c045d73ae6390642c1b587c896a9e51900ff06a53752de50"
	},
	{
		"studentid": 9818,
		"classid": 8804,
		"schoolid": 6348,
		"studentpin": "52f974c01341f0df04186191a80bddbb6ce85aa2b341055b4212c5a76876fd52"
	},
	{
		"studentid": 9819,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "e8632bd2ef01bbba8902095fdd6bdc488c19181596cd652ed9539003ed79cf04"
	},
	{
		"studentid": 9820,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "9e48671b6c56f57b9320031be97ee208a80da5ae481b4cc7baca634992565a6b"
	},
	{
		"studentid": 9821,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "3611cde8685b2b45758c01c938878d40d8c355c13775d8bbe85746215232348a"
	},
	{
		"studentid": 9822,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "b3f428a33574e16977e96c6f5baf21f6eeef3c7617b52c56fe930e5be435f4ad"
	},
	{
		"studentid": 9823,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "96c38f78fce3fdade56cb5adc3d98807fbb4872e4edad72cd3295c450eabfb64"
	},
	{
		"studentid": 9824,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "28a584e10771f1687d850203469ac0dfc905176a8218a3110b82909665132e0f"
	},
	{
		"studentid": 9825,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "4ea8ea8e7fff563f25f7323e12d0db7420d25e77e2f67059b7dd5e9b191153af"
	},
	{
		"studentid": 9826,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "62009d2d691871ce3080b4f5fb03d1bf47981e33338945022782fa3f0cf7dfc9"
	},
	{
		"studentid": 9827,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "1a486291e294e321d576ec6797787ac68e6c8ad884eec229370fcfdf141f7f15"
	},
	{
		"studentid": 9828,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "6c65ac76acf09849168b434a288b66e93e36746738c18a98de6ae361bc45b129"
	},
	{
		"studentid": 9829,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "d2fd74a420ad9fb66213f4d76998a7527f93d6fb386dedd232dbc790e6a1e7ef"
	},
	{
		"studentid": 9830,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "17bc41ef54165418998001045e1cd83d010c3969bd81abaa4ebd3a28afd31fd0"
	},
	{
		"studentid": 9831,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "55b6b111781436f2db83009e40257861f5f7b30f7c1941512da473cdfcfb81fb"
	},
	{
		"studentid": 9832,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "672da406990c11c9b670547e1ba11d7e581e36bbdd5124f9aedf6d27c5abab08"
	},
	{
		"studentid": 9833,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "5e9dc11166718a14a3b8a404afeadfb3c12cfd91d07402a348b96cbf5e2c3d14"
	},
	{
		"studentid": 9834,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "2ef73e0649003ecf7147c901aaffac735792a4b14059e0fa627321733cb0fce5"
	},
	{
		"studentid": 9835,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "664bad3e2005d33a78c76fbf0f4ea836fc3d4b286b042d6c952d9771a3dfb754"
	},
	{
		"studentid": 9836,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "a4e4cfcd84fac4345cda38f8b4640ec02a0446fc357eb989ee0f18cad7d3a079"
	},
	{
		"studentid": 9837,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "79d5daf66f2a3e709619526929707f20546e4a14a89592b488ded15af4623f96"
	},
	{
		"studentid": 9838,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "88cf578a87b171534422c956f46f3f14aa90f4bd4ca023b4bef389008a0d5635"
	},
	{
		"studentid": 9839,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "ed88cb26bc6290a9878d5968436a48d9dd828641507ed4f4bb25a00db69d2461"
	},
	{
		"studentid": 9840,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "411ecef18eeb3f43be961e39c24b7f9d34cea4df9debe4fdf5a00290578b8efc"
	},
	{
		"studentid": 9841,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "7110746d9c8461f7922e6cd59fc102b29a92ab9bd2f451fc803f2c13c840e523"
	},
	{
		"studentid": 9842,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "2ac359bb8bbe78037cae3c1fdd0c3195519dc096b684d74f4094e7ea305f50fa"
	},
	{
		"studentid": 9843,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "7e251db51a925179fd4506cd5c2d1f946303bdb26c0c4e6cd39798cfb5fb0e08"
	},
	{
		"studentid": 9844,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "eea10dcbb425133b68130f120d171e24171bf1e9d4b3a1685075f6cfe542dabb"
	},
	{
		"studentid": 9845,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "7d85f7a0ca7304e97eceea4be1a724c629c9cd62927542592f7c18d38aeca2e0"
	},
	{
		"studentid": 9846,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "c9995d18ece7226ed9f1c03cc3dbf79b9cde7e55154f33869bd90e01ad7f4854"
	},
	{
		"studentid": 9847,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "99c5da4a9a0b187e03cc3e46d61d4ad63cf4d891d4ce1421044dc19112d7df98"
	},
	{
		"studentid": 9848,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "55bbb7e09f469569153cbab7adff1d6c1797822a46aa5e60ea84e4ca93a63dd7"
	},
	{
		"studentid": 9849,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "c8f696aa9750e22a4adab589669e8d56e3927d597eb09f1a2e152199f60c0232"
	},
	{
		"studentid": 9850,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "d585a117154769de85a97d2ccb20d6030bd2e7c91a5af453ac85789690077606"
	},
	{
		"studentid": 9851,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "b5ef2986f33ff85307631d08e1699226c016b28230ff59abe1b2a0f4a8ff1314"
	},
	{
		"studentid": 9852,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "0d05f6833fd08d632f349515a2b9ace7d7ab549971efcf35eba0d8a84a7c12d8"
	},
	{
		"studentid": 9853,
		"classid": 8805,
		"schoolid": 6348,
		"studentpin": "995158bdec9210b76c8024d2f43a532351e7cb59db6ce3b10435dc20d832c2ff"
	},
	{
		"studentid": 9854,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "7ca5ec4524025df1803028ae766b16bb55598c3d44eb7de50a18e96decf24664"
	},
	{
		"studentid": 9855,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "2fde16f73f1048399e9e03532902801ef51afcace0d8a84c80d09f325f653533"
	},
	{
		"studentid": 9856,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "30cb9c9ccb6f01d74244842304e121add1e0510b42d59260032b19c7e54f8789"
	},
	{
		"studentid": 9857,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "a67d6e06049f81bafcf290b5f51f5b9dc064eddd9aa68b092af73d38006853e3"
	},
	{
		"studentid": 9858,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "4d1e021b3cd99df8691394c9295852fcd72a2b513ee156802ca625a3f021001f"
	},
	{
		"studentid": 9859,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "7c93c3920f03c6cfa36f90c94bfdb889c6cfb12882b3f817a2704755064a0ed1"
	},
	{
		"studentid": 9860,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "24a177ed30115fd4896c5ebb3033c698706089258e6918d5645529f73c572f83"
	},
	{
		"studentid": 9861,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "dae4ca72a407622a2ce256d97c709de01450d9193498e2d0c73e689ce2066203"
	},
	{
		"studentid": 9862,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "a13a61cf44d3951c79f79de8831e8cb498f93b0b3e86c3ca7ef6f54c7badf678"
	},
	{
		"studentid": 9863,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "8654a282bcbdcc6b6ada54427392677d7a505045d833d3178ffdcf641b31af8d"
	},
	{
		"studentid": 9864,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "b9fe131124b6d579c8dd38a0bc8e622eecb170bbf80037d50e9a5aea8dc07b7b"
	},
	{
		"studentid": 9865,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "297fc3581ead77bcea3470d28332f3b20edf5233ec248368d5b27e5d002d8423"
	},
	{
		"studentid": 9866,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "24e891159920bf7c51fd5358a3d42e7e02ad0c4a43053b43fb7f9b2eede71595"
	},
	{
		"studentid": 9867,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "6248706fb494e8c71fef677cf845b35d6a57c6e3c463574158ebe78667ad947c"
	},
	{
		"studentid": 9868,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "a71e075c6270f30fa0a020d8b6a3c07eb291f3246e7f969d8309a4fc0f7c7282"
	},
	{
		"studentid": 9869,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "58f77820c3de0bbb7d7195604ddb1bf4dcedb36b9b2164d3463d8b070a672b6b"
	},
	{
		"studentid": 9870,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "9b434d9f162bda8b2d1eb68fb53830ef8b522c26644cdab7676505e0159eb43f"
	},
	{
		"studentid": 9871,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "f429b3ee64b21604fbbcb5008f0fff03b263ded1b3a8d5bd9a3921b3e0a34b24"
	},
	{
		"studentid": 9872,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "fe07fcff182ac25c00fa12c1280e51bae4f5b70924bdb6f7d34fb7748d234509"
	},
	{
		"studentid": 9873,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "0a77051847f1e1640d276b8b5189d58215e69d615d3aabe0eb98382512a4ea8a"
	},
	{
		"studentid": 9874,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "6e77a2d334ac6d1acf352ef2b265025c991d374a145f6f1dab04f1fa1da2f80a"
	},
	{
		"studentid": 9875,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "2ad5d3afe229f07ca7f31f335638804dbf1157451a330bb86efe943d3cdc953b"
	},
	{
		"studentid": 9876,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "1d2f44f3b09d6554090472cac895fc4f276096c3b7e03f78aeb33a4632495467"
	},
	{
		"studentid": 9877,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "1a725689f9cc29f2cd1b2d934925a832abeb8d0083208e4bc8ef761d43dad163"
	},
	{
		"studentid": 9878,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "db543b329317ef601b34d3660e212fa46a9687bc7b754b10c610bb0be3c87537"
	},
	{
		"studentid": 9879,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "c5e001777456a7400b34f9679064c255b643c0c73f2229b579cd2668e63c7d1b"
	},
	{
		"studentid": 9880,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "9958f995ec9a738fa19daa0da344aa9938a1b2efcf667fb82a88dcf5c4f53db2"
	},
	{
		"studentid": 9881,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "1467dee46445e9d1d8e440919286abc6a7efec04787008a5b46c9e488cfbb0a7"
	},
	{
		"studentid": 9882,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "5efa28d16a6c4291d1cd0c9bfa8e26b351a7c6c74edd5cd8af86a60bd70ffaf1"
	},
	{
		"studentid": 9883,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "7929027a4ca972ff338d0dbbba53648e879836c5acb12cdb238f224c5ecf51a9"
	},
	{
		"studentid": 9884,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "3138e1edb859c8d12527dd7febd9501014f844f028cbf6c39be25fc55f87d7c3"
	},
	{
		"studentid": 9885,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "e292cb1970d264a80931a58512e7dfa028f4634bc44e5a391025bcc61db34fa9"
	},
	{
		"studentid": 9886,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "320f604cff4e347b1bd1911478023c11f48b78ab588a9051ef57ae6c23c12ab3"
	},
	{
		"studentid": 9887,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "3c2bdd7fc3286ecbdc83b0bbb3b63834b596aef8159cdc6c7e61990b1946ade6"
	},
	{
		"studentid": 9888,
		"classid": 8806,
		"schoolid": 6348,
		"studentpin": "f221e20faf2ebc946cbd1629de0c56af47ba09258fd308de7672a7727f31a529"
	},
	{
		"studentid": 9889,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "c339a6c103df75feebe04d5de894363032969ed4e5e2df6cdc410cd967229fb5"
	},
	{
		"studentid": 9890,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "b17314bcdc2ce2f5212735de361b28befc762c2993cfff15cb36f4a0b6e102c8"
	},
	{
		"studentid": 9891,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "f8a6adb98897ad4e32c9a6f4b3faec046ed393989df3bef8c82693c5fb250c2f"
	},
	{
		"studentid": 9892,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "04b0e5682874dc95f8af6b6e66a1a7c385b2b0a36e9994ac5786d837c5fde410"
	},
	{
		"studentid": 9893,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "103e69514cc103cfdae809adc760ff9b92326a744c60bcd6632e9545405506a4"
	},
	{
		"studentid": 9894,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "145c0f8f5a3776370e37317ec597a1f49b5e38d10b377d8f1ac06caa64d8d227"
	},
	{
		"studentid": 9895,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "b8c231ebcf0e3f558437600b6605f2e7d5d7492d858a4ed3c36beca5461a2819"
	},
	{
		"studentid": 9896,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "91515917a8708bd45fb12fd10ab2f3077ca58c3a69ffc2ba201a91841a71d345"
	},
	{
		"studentid": 9897,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "7aad6bab167267f53db2fd58f81c74bbb2b926548bcfa53c6540b385f3b9757d"
	},
	{
		"studentid": 9898,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "b2fdf9f98430f3ce90741ef5281b06f35e183f82f3f5bd340744509ba32d4503"
	},
	{
		"studentid": 9899,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "a022f10915764b6e2f43d9c2dc15e8afc5111e69db03f281b51d769c2f8986bf"
	},
	{
		"studentid": 9900,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "6d1a233275920f3fe346ec2808f86d18fc17f0f0b999cc218a0cafffab44bc0f"
	},
	{
		"studentid": 9901,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "4de0381729bc887d0056045150d9ae5439d73adbf17fa6ea623eb1963785ba70"
	},
	{
		"studentid": 9902,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "4b5e1995ba1200cfe3b00a0b52b9357f07fa33db643746293af18f56fc9b6b8b"
	},
	{
		"studentid": 9903,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "d4938b6f0f4d99345969ef2703f54a427578c6b4669e904660e651602d6d3700"
	},
	{
		"studentid": 9904,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "5aa76b258d776991360d99195ef5a7090a07e3b81cb5237683219c30a1b8db2e"
	},
	{
		"studentid": 9905,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "8102840fcd567d154fac0bb25fc421800cd0ee84d9c2440d6fd32eb4fb147280"
	},
	{
		"studentid": 9906,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "a66ad804d4b6306d74066a81f722fc83d1ad32f13ee0a1d35d9cd0cd0d9d5591"
	},
	{
		"studentid": 9907,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "f3f8c196d533c0c6062732e9d57db01f2f3491f959670380300b279f2c0a3eca"
	},
	{
		"studentid": 9908,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "fa5d10788b9fcde84ddcd6879355eaa2ed75a38bc57e3073a373dda30a5e2751"
	},
	{
		"studentid": 9909,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "191f0c552b6cbbce1c09fb8b315dc7a2a23a63b694402cb0c897c58f4b95a762"
	},
	{
		"studentid": 9910,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "eceb47cc309ac0731f7b9309b840837ca9156a59bbf0ca7070fe36e12729e1e6"
	},
	{
		"studentid": 9911,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "0a67fd9962e67584555082823912b9fff339c903289fd8364f5347eb8a9b4c9b"
	},
	{
		"studentid": 9912,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "6598fd3d234093d75900472e103d874de9eb2845ff6cedea5572c280c04b0222"
	},
	{
		"studentid": 9913,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "824410f178c9e87fbb644565332bca1f8bd191fbae946b12a966b8a064c793bd"
	},
	{
		"studentid": 9914,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "f4a74f7a4fbf76d3a103d63ff9c9ac2e1b2454ceed3c61ef31964fe0a9e69473"
	},
	{
		"studentid": 9915,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "b55fed2ebe9ef83f4e931240d851ad3fc242bfe35582569c15b245c36411347d"
	},
	{
		"studentid": 9916,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "c698f21742f7bccb3c242565e373bd7e95a1389947cd23b8aca058c9614bb710"
	},
	{
		"studentid": 9917,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "ca2ebe2c096fab13ca75c791dbd79e188b5fa50785c3ee8f98157abde3b6fd28"
	},
	{
		"studentid": 9918,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "891471c3619f90818f29730e8d6d7867380f29f8e3817d5d8d70911db0e04e56"
	},
	{
		"studentid": 9919,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "ee13ba26c38d0bfaaaff69c54d849f119b4c68db6eeb2968bf95c12a897efe2e"
	},
	{
		"studentid": 9920,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "9edbcb65088eb0378949da714926d99876d1ad5c40c469512d68535d32c45243"
	},
	{
		"studentid": 9921,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "6af82b7543010e0f12b03602aa048fa98ff2ec99416fdb593d0e79c9b5fcc0c3"
	},
	{
		"studentid": 9922,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "add39005e529c2e249daf6795776803797853543086fda064b3de098e5858967"
	},
	{
		"studentid": 9923,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "39fe48e856e31dec8aec7c7817541a8e521388c7d550fdc50b11d87300f72b53"
	},
	{
		"studentid": 9924,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "1702541448a091cbe897f0234971b3a2a83d94dfb3ab2a7f5abc5c13f5ed4a10"
	},
	{
		"studentid": 9925,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "280a53f15d9df920833192d4343209daaae2159a8ed6fdbf1d9a7de992344764"
	},
	{
		"studentid": 9926,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "06dfe1a5732b4ebe5d537efe22eb207deaf5cd9d797e3e96e0317a7ab3c065fd"
	},
	{
		"studentid": 9927,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "05a0f4e776e55ce13c23878c02c42f570efb438b55e2044886e69f97f2a8b20c"
	},
	{
		"studentid": 9928,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "ecb1a94c9072ce2e60fc075aa435db5bba776db25b63bc3f35eae25fff9f8105"
	},
	{
		"studentid": 9929,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "93fe10d09f54bb9350fac364d3442c952e7ac504c43a74a08c069c85125735e8"
	},
	{
		"studentid": 9930,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "1c5e424de7d8fae79528588c68fa533ec9b1e02ce61acae3aad6b308fea4173b"
	},
	{
		"studentid": 9931,
		"classid": 8807,
		"schoolid": 6348,
		"studentpin": "64c4b24d3afbd413d5d6a0e386af5ba140a0346b116a2714f0d8030fa41a5a96"
	},
	{
		"studentid": 9932,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "973fd01cdc9d6ab4d5475f8514d6103a12c311a8f35cc6834ce1ebad7fc66287"
	},
	{
		"studentid": 9933,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "36647043c9ffb71fe25a50996f89f56be3a8c6e4f42ac4c9326aa7ff8bfe73bb"
	},
	{
		"studentid": 9934,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "28fcc880c2f18feed250ba857a01ea8c01578bc804b39f39789b671dc81cb5e4"
	},
	{
		"studentid": 9935,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "43a3bbfe907919b6b414cd42ea4512db063a52c05162d0c0f0f3586cdd6bef33"
	},
	{
		"studentid": 9936,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "6d1e782e45ce7a63730d4d9127e016f155711fb92a0ea1ddaa9a4fda43a5c681"
	},
	{
		"studentid": 9937,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "70d90e43a9979add725dcf22985d2fb69e6b03816ef4b9e10258b4688106b890"
	},
	{
		"studentid": 9938,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "1e5fcca40151445d60c412eea1e84e85ce20c569739b3332baeabbdb6160df52"
	},
	{
		"studentid": 9939,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "0e30eafc5a41302c8125277ebaab1da024d5b270d42481103224a25b07ea72bc"
	},
	{
		"studentid": 9940,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "31fe42dc08d15db1c78e50d5ea7e9ae5a4ba5a5f24c7f2c3b5d547f8ba00e594"
	},
	{
		"studentid": 9941,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "1963b083791019ccd60a30a25ce69fea41d970f2bc0c9ba09b339c60d449cd58"
	},
	{
		"studentid": 9942,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "786a10df6dba85e1496602f6d48213b395d8540955e3f8fff8f87e5c67727637"
	},
	{
		"studentid": 9943,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "7387a42605529d109269ad5a759f8c88ecd74b3572614d54fa732bf3b7e7f73d"
	},
	{
		"studentid": 9944,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "b130a89f59c568580727e5f73afacd7044eff456d2e9c103c854a26d7d3c436f"
	},
	{
		"studentid": 9945,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "e33bbaba42276ff37151635c78e1fa307a1c09b826ecb3824ffcc9fb6903b81c"
	},
	{
		"studentid": 9946,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "dbed69a4088990be31d5c959bf7dc8c110cf983fb5e4ad5d0f9b7750b53c646e"
	},
	{
		"studentid": 9947,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "7553bc9213a1d6c7d06c1c74dbf87b1f76ade472c1d6dfc384931553729595ca"
	},
	{
		"studentid": 9948,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "969d9eb7886b082c3221d8936fd35bd925d72be229379be7930dcb21ca0c17b4"
	},
	{
		"studentid": 9949,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "95e41ccfcb30ce35332606696957955875d51e0bf6c502d8a1548cd71231681f"
	},
	{
		"studentid": 9950,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "0a884aaa0af9ef0babfe1419e37c12d96feb65af0c320e8d29570082c42c41f0"
	},
	{
		"studentid": 9951,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "6b6341334f461ef2bf6c9c2b4e2b752c89af488a066f0f02109aaf2c00d65a2d"
	},
	{
		"studentid": 9952,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "0f9a9171298dac30180b5d27245dd142681d153d388853f856a0d98ed1a67be5"
	},
	{
		"studentid": 9953,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "9b0044494e714fcfbcc84ed535ec61d749a62892ca25e64525ac648e0ef5741f"
	},
	{
		"studentid": 9954,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "98eb1814218bf7ebde666f7c5572cbab86d72328a031805c44b19297989c1420"
	},
	{
		"studentid": 9955,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "045634eb0084aa2c3e160862318db3353baf8597a0e3adabda628eb921670866"
	},
	{
		"studentid": 9956,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "ce55680bb18dc687a106bab11bfb20d024a0f2cb81feda836f00733efbb3028a"
	},
	{
		"studentid": 9957,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "e22708e1b3f81034fd2e7d51de5945c8fc1cbea1224b711cd8bd9d700a9ad6ac"
	},
	{
		"studentid": 9958,
		"classid": 8808,
		"schoolid": 6348,
		"studentpin": "0deb270bbc634f8f0a1c0a01e8199916e07af24fe9c58e4e9400d48359dc3a0a"
	},
	{
		"studentid": 9959,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "9c4c6e92de91bf2df335bd458df56e13a3b4a808de69198bbb05e05d8d00722b"
	},
	{
		"studentid": 9960,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "d3d16cfc7be47ceabf8963ef70d45c17105cf9188f4742da7762d7a18a30c6ef"
	},
	{
		"studentid": 9961,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "b2f7c8eebb9fbaafac98b46bf593b15adc3d8370f7185ff794aa922ac8e71b49"
	},
	{
		"studentid": 9962,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "4b45e30ad0f8a3cc7da0a82cba1494e91572cd3407c4f893e3eabd0b436837d4"
	},
	{
		"studentid": 9963,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "04b3e43c8172545d606a8ba5247bd5b5173939cefdce8986ac0eae604d8b966b"
	},
	{
		"studentid": 9964,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "5535537863a0960f6355e362a3ab2a5febd4205056a4cebb157f865958c697cb"
	},
	{
		"studentid": 9965,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "b3d8290002c867232dda91a8a0319ab333fa7306855a55ebf7b38599e6fd6c58"
	},
	{
		"studentid": 9966,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "be43e9b95a7e57b2d766a89b41d4dfc67708f7bb0f9edf2004d4ec42d13c499b"
	},
	{
		"studentid": 9967,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "f0ef3ab9b19aafbba628f4815bdaf520808d2ecf3b06c9a60a34c1d81c0300ae"
	},
	{
		"studentid": 9968,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "9c8e147aaf0de17e3afe7337ccc5a183af36443e54e2112e4dfdce124aa500af"
	},
	{
		"studentid": 9969,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "4ac949bbd99ae10c33bb7063c40c21e7c3dfdc3b00e7c95f13454e9b94aac329"
	},
	{
		"studentid": 9970,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "ece52a6b4dbca9aea9e2b30bb7f53f28d2c86cc5ab91998218cf6c9eaa0acfff"
	},
	{
		"studentid": 9971,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "be12914788ee701f64c4ed47fe8000e7f04c6caf0433ea9045bff15d19dc2605"
	},
	{
		"studentid": 9972,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "e5bc75d5946c413f7158f824bf93e50d427cfbff70a8817b549ba0d53cbf7327"
	},
	{
		"studentid": 9973,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "d5a2abe20d3221c6ee3cf51a4b20a638aee7906262e7c403f45fd62d20db373b"
	},
	{
		"studentid": 9974,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "fe88075fc96623e7ef1aca85dad71ad9b7bca111346ae5d160753b73df771040"
	},
	{
		"studentid": 9975,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "ed030f85834b41f3df2fa0cdf0b1a2ff087f17f1d98be6135f61f9dd445a43d1"
	},
	{
		"studentid": 9976,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "3353952fb2dc81eb5c533e4afd3570dea070f1bbc67b6ebbcf8eb4f85f1c499b"
	},
	{
		"studentid": 9977,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "838426bbe6ac77c0c147273988cf691fab55217c7ca31fd86faed40c5c42d83f"
	},
	{
		"studentid": 9978,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "fcf9c3546e79eb465fdea48124044b4eafd75dd8e9c7ba6210d9195933507cc8"
	},
	{
		"studentid": 9979,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "3ce35c4d4404f3fe01a68182e7efb4e6c735ea5880e083e2d8d7ad6a7ef51fd8"
	},
	{
		"studentid": 9980,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "47b83fb7408aff08f31cdfa8c26c16bd2a53757c5fa9059d99ed9ecac7766184"
	},
	{
		"studentid": 9981,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "fc720d755542621ccfca771eb52f307ab59eaa2b9ed1bd54a0da90866e1b5141"
	},
	{
		"studentid": 9982,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "13fd1f6c792b7954077ff003843a28ab53969dce2593589451f4d0b8afa2de57"
	},
	{
		"studentid": 9983,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "5aa1244fc899ef1ca11c3e3c4323894a43080654b3d585fc9e88111c3d6b9b8e"
	},
	{
		"studentid": 9984,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "a37036863136443029b4b4e8f1759eabcfc9c4f35340fdcc7664ec7ffdb4f461"
	},
	{
		"studentid": 9985,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "57bd4bd5fb73d066a481dad6fa2b0a4eed954deb9ae3a5fc88e83aa392927c9b"
	},
	{
		"studentid": 9986,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "96f7fc078529f8964541be186d9793c071dcdc13badeaed222867914af9c8945"
	},
	{
		"studentid": 9987,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "44db2b93a31f8e98c0f21af4a790b96feb6cfe249e4411910e061e9e571f044c"
	},
	{
		"studentid": 9988,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "cb3b5ec794fb7dd234ffb6032d9f47c43b12b5a7a4f00878436a5ca283b7166e"
	},
	{
		"studentid": 9989,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "9dbb51f0e53ded8145bd0ae2d41f32dd609ec0f2e5a293a5dd6670153b266474"
	},
	{
		"studentid": 9990,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "d0124051b420ddf25d90b290ecdf4157bbf0445ec231d87296db5d0214dfd4c9"
	},
	{
		"studentid": 9991,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "0689f258cb57f53938c3cc7933761460e94b5f647f4311b53d7fa02717eab19c"
	},
	{
		"studentid": 9992,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "1b121aae11d3a57e3dc64d1c48be80be60c8a42b66ac134606464d5bb9cd96a1"
	},
	{
		"studentid": 9993,
		"classid": 8809,
		"schoolid": 6348,
		"studentpin": "0d683e957fb91ac927639a0e7c52aea06a770c94df55848ec54ce051731eb88a"
	},
	{
		"studentid": 9994,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "0cb31bb9a603aa0be140f1b005f69d04a4e4accf7637820d20d4d6ce484744e9"
	},
	{
		"studentid": 9995,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "7db8434d4456522117e7b847d06e78af2a8ef05103ff9b537b90f8326d7230f2"
	},
	{
		"studentid": 9996,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "4d7713f61ab3d31b97079f22861370fa5d861f8dfac615c78fb607125956912c"
	},
	{
		"studentid": 9997,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "40e2a7c70e365a279e039208e3bc6f9bb1de4cf4352df033be8cf7ce61e10f42"
	},
	{
		"studentid": 9998,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "46ee1d3dbb869907c234bc72301a0e0973180d102900b4782e12dd28ec6de9da"
	},
	{
		"studentid": 9999,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "0029a76becd8198d8be972a1e26a175e5a9318e2544c479897981af3527d1f5e"
	},
	{
		"studentid": 10000,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "cd64e4c0d238dd89e425ab4d4a4fc71f29717f1834b51deefd615ac6990820de"
	},
	{
		"studentid": 10001,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "54619de5edd357430ac8224e2a5cf889c5d89a16621e03a63b06a24b1f571c61"
	},
	{
		"studentid": 10002,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "b8aa764b103679d1fbac119bb1ac2a8a52d4c1426dc6e6c26075ad9b462ca9d6"
	},
	{
		"studentid": 10003,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "8d8f4aa3e8a94ca26650e439587f914ac257f2d2e6196015bffa1e5c2ee54fbf"
	},
	{
		"studentid": 10004,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "7b5d62963692005a24d9ac82fad76dcd483ebd35bb57a1824d6510883bc29a1a"
	},
	{
		"studentid": 10005,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "43dd5bd1eab39fbba3c2e6b1e9425fda2bbd2c361c27c9b7574a4b0ce468c0f9"
	},
	{
		"studentid": 10006,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "82ddda45611d79902c152cf6554f8478eba4363d4c8241bf18e13709000cd42e"
	},
	{
		"studentid": 10007,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "08a2ed9c691377272e26407c505400da0fbab9f75ce2cc8c5c7e91d7ec3fb313"
	},
	{
		"studentid": 10008,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "c1d22623931f6fb7e6e3972dff2f9727bbc8256b748ecc4613a6b5dfdcf2cf30"
	},
	{
		"studentid": 10009,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "7561a5ef63c1e1879e406760f181cd3cac620fa74a66d5be0d221f161022d539"
	},
	{
		"studentid": 10010,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "57c1688165ac6e616fb317d037518dd0b21970ea301b0bbc2fbd50137b19a160"
	},
	{
		"studentid": 10011,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "04ed9175a417f2aba41b3f6c0ee7efb089df0f62d39d9b27ec41d193bc7666da"
	},
	{
		"studentid": 10012,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "bcc4889adcb297afc8b0cd7ac3cfec0af04550a1b72a7e7fe8d9a6826c20980a"
	},
	{
		"studentid": 10013,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "38c9d3a13ce58b26b1eab72c7c97f54f735785b94326869c21b26c6be82bb3c0"
	},
	{
		"studentid": 10014,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "4587fe59b6544708900e1e7b85d0b498e57a626c9e28039402ece3f75616b140"
	},
	{
		"studentid": 10015,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "0b339f4197dcfbc5f52cdafcd546c28fa5033297c5fcd0e644dad647b61144b9"
	},
	{
		"studentid": 10016,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "ae452cc13b75d8ac0bb069d047274195fa06111cb94f7e7e11c037b9e53943c9"
	},
	{
		"studentid": 10017,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "ae9340a09a43cd9018f5f7223de5c90263dda55db441bcfb3c8835db5823eead"
	},
	{
		"studentid": 10018,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "765c0c85aca9486b4ab87d0681ce19d3da27d96fe7bf77fa0892687e046d65cd"
	},
	{
		"studentid": 10019,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "6d890f01182a23883eca79368e5bbf68369b92dba8e90a0f43b8c317fe6066f1"
	},
	{
		"studentid": 10020,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "9ac7fb5f16363dcae2d58983a4c51bda8058d6349be9c2c355feaa1bf100fe2b"
	},
	{
		"studentid": 10021,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "c6f81b3f7df353aba0ca58e3d0fcc6952f6e105fe35b74d3e5ca2ed5fd77c5f2"
	},
	{
		"studentid": 10022,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "5e0a8df2e1cd7428de1666340ce85bc248e0c2adbd383d5c13e6f7e305c0813d"
	},
	{
		"studentid": 10023,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "57a3747ec574756118b3082d8756ba11120ab8ec1c37fa992887ff48d93ad027"
	},
	{
		"studentid": 10024,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "55a2c7129ca600b67a09a455fd915ba4a63762fe445444eb1523ca162a3fa01f"
	},
	{
		"studentid": 10025,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "422cb1b2dd503a21825eb245a19168fb4b72659f49c0a24524eeb83e3cac227a"
	},
	{
		"studentid": 10026,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "2636e8f368e5ea1bb36fe8d44cb28aa6317c1f88e36981fa3940140f75158595"
	},
	{
		"studentid": 10027,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "ad70a503398d4d42442fd9b881b12083cc63b2a62b2639556dcea5f4f89d8f18"
	},
	{
		"studentid": 10028,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "ff5dcca9c4e44df54f6622d6eaca5f57736db64e015e2219e103c86d5fc4f021"
	},
	{
		"studentid": 10029,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "6012d9c5ed7ea009e4a3fc8788712bb3f8fa74b15b4c0cc0fea7eb037ec407a1"
	},
	{
		"studentid": 10030,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "f740bc38b63a501b9b76d8e7d6b353167ea7558ae2c2298fa376c4e49e745dad"
	},
	{
		"studentid": 10031,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "2e4882353879a8624b0a2e9bd054ff279b453101e646bff8e2c790574799bba9"
	},
	{
		"studentid": 10032,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "1b651d4793ae727a50285cb7d9ac34f1888e613b603925c57eb716ea346fa15c"
	},
	{
		"studentid": 10033,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "b347ad8efa9f2009b4926eee55148bef8c6317fd179873f5e1473a3a1fd64fe7"
	},
	{
		"studentid": 10034,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "430fba21bdc55c1ace1a7104d919cc0e3a2238fea9da261bb2feb2dfbb17fb11"
	},
	{
		"studentid": 10035,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "afb3c1f50967aaa5c1d57531f77e168211c43ed2c59f6bd2612d5fda3349449f"
	},
	{
		"studentid": 10036,
		"classid": 8810,
		"schoolid": 6348,
		"studentpin": "f51cbf8674692d7b3e06c686968cbba16f6dbce056c944c42741efeb4885a30c"
	},
	{
		"studentid": 10199,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "3b44276492bc3aaf208a4764e87437b6782a9f186dbc6ff0005a9ec300e3e284"
	},
	{
		"studentid": 10200,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "4533386b6346261b43750b91266fafc20c56aa87f2a99bd3d4bc92949b968b76"
	},
	{
		"studentid": 10201,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "fa02b4b631e6a7e8ce58fabae5e7b6438c0d63759218a252275a4f5555ff8fa6"
	},
	{
		"studentid": 10202,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "f26e1ec692b11a091baf169ffd03853a7f80286e08003a0e69154de8587673f5"
	},
	{
		"studentid": 10203,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "5451931f3209e9b5f7d0b5d069cb51b54a3fa63552cc1a50e17f8e364822e95a"
	},
	{
		"studentid": 10204,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "ade5e94f03a30416c3acdb6fc4d9e1ec3cf3f9806498eaff983b06ed6aa45069"
	},
	{
		"studentid": 10205,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "0bfb03b75369f1b1eed48f41be99e987e3709d94baebcf18b44c93d7054b028b"
	},
	{
		"studentid": 10206,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "2a0b543cb55ae4703c1401a26674ca14199882343b5821608449d042bac41fa3"
	},
	{
		"studentid": 10207,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "f2a5bd9cde699125138fe5527b6fd920698a9fd0394ed66a40bdfa89446cfb4b"
	},
	{
		"studentid": 10208,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "4307154af15b9f4127c6a08e4a87bf7738ced6dae8a2a784b7318df76084b40b"
	},
	{
		"studentid": 10209,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "84f582e8e939804849292ded7c496af3f3401381a573c14c621889bb15d1d3a1"
	},
	{
		"studentid": 10210,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "75490adb81fe15b72ea0051f352aaff4be1335767d7913031023bfdc0d6f9d71"
	},
	{
		"studentid": 10211,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "7f14b2e8ec3b7f22e423061a22c85e81c14a2eddaf653271640e9eecdd20e105"
	},
	{
		"studentid": 10212,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "e142b5c5078b2245bed5e5a0cf56ca56559102f5fdb992a66efeb29d44d9596c"
	},
	{
		"studentid": 10213,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "22e933fd983a0fe081d7c891658722d700daf5557463eb416b3efec98858842e"
	},
	{
		"studentid": 10214,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "08c2ef383df8cb93800c89525698d589834c38cc370b631041a1ea481384c435"
	},
	{
		"studentid": 10215,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "e1dd69068e9405d7774583e3c2f32fe6a10d6403fbf37003eff459ce52b0caa7"
	},
	{
		"studentid": 10216,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "7bdd9a5974be66af4d5996924b16d2f5bab344186d84874fd4d6a6adc0ce9c43"
	},
	{
		"studentid": 10217,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "7a272d727fc33e1e78e6972be2854d45c83078ab70d845a734088fed459acf55"
	},
	{
		"studentid": 10218,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "68e43f7670ac10980f1f95dea28fc612ae2faaa56d9e3abaaa1e5149218ab477"
	},
	{
		"studentid": 10219,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "de5e7ff124f30e5e24e1e0efed41d30324547423d79922a878cdb87de5d61e8c"
	},
	{
		"studentid": 10220,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "304b25e24f52bcc269e7f0d761bfa2b8a91fb3371ac74508e1ddf97dd261d9fd"
	},
	{
		"studentid": 10221,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "5d5fd16d367843123badf91e6d826e242123fc72997336ebc0fed2c6630271fe"
	},
	{
		"studentid": 10222,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "73304621fe4c1106f1db6373efc8779d642c8f48a76df28802474478a4e52063"
	},
	{
		"studentid": 10223,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "b2f2b4c00945bdeb968c35041a93e6e4510f629d8436516bffb786e7a43df860"
	},
	{
		"studentid": 10224,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "b4bd0ced4753275efa17b7fa26ecb32de7daaf1805de2e3bc23976a2b7d65944"
	},
	{
		"studentid": 10225,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "508beac21320a02d22d3e7bbaf2bb3663ef15bbb55c1c5f5bb0babd44a2c2f9b"
	},
	{
		"studentid": 10226,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "56b87a1ac9d2652af17e642ea205ff683d04c72437dfd7320350117c5522334e"
	},
	{
		"studentid": 10227,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "01263b9d182307224f843e1094c26ed32b943611edd7f8d1369d2b2f507f5c02"
	},
	{
		"studentid": 10228,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "61d8a93dcc2f469c14aefb535f1364d8c5091c5875c2e2cfb3d6bde02c476afe"
	},
	{
		"studentid": 10229,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "7c10cc51fd06630fe446d9be8fe2d3dd5552031222b0e5cc4301e29c56cb6d8b"
	},
	{
		"studentid": 10230,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "bd5b74dadb2c9380d8c5da7a2199a5c05d02e87db796e3d658abba87d9faf3bc"
	},
	{
		"studentid": 10231,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "c16d24b3efb5b548209fa2cc256f8037966d8b6c7e1af880cee54054e58e2b81"
	},
	{
		"studentid": 10232,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "ad373447fb1cccb999daca3d2dab7c9e4868aa1872882944d870f8b47113c3d3"
	},
	{
		"studentid": 10233,
		"classid": 8863,
		"schoolid": 6382,
		"studentpin": "933d98ee55cf163c6d9a7a6a973214cc0440231753715540b648944b6d95a285"
	},
	{
		"studentid": 10234,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "6d99bbc458aba27648abdbe0807122e85b2d7c7e70e2e94e858f0248b7572540"
	},
	{
		"studentid": 10235,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "88a15394c0575b90739c23af1deb5ff0e5e8e986a8698095738b9e755aa7a821"
	},
	{
		"studentid": 10236,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "5d13d21c162f915f580681c3388f321ab78dc1c05b6d20b4d19a70515eaa19ab"
	},
	{
		"studentid": 10237,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "f44edd985b409cf33894a3b922beb759734cc2943e285fa3baf3d6424a9eb0d1"
	},
	{
		"studentid": 10238,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "4b8ec1ff98c12d177869d01f4aa23b7e92bf41b48989d5c197713ad85fcf9e74"
	},
	{
		"studentid": 10239,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "461c68af44c7e0eaea98d4262283f6217f68b15338922db610e9e21095cf4e8f"
	},
	{
		"studentid": 10240,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "52164655c4415703ea1ddd0ab0e7e4069331cf9900a143196572cc894878ea93"
	},
	{
		"studentid": 10241,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "266be278f9e19db9ed5da2fd2b1615ef25f2d99011fa78c9ce3072e8ed379963"
	},
	{
		"studentid": 10242,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "30e9f9d4a0c021ee76e589592f306bd57ee26e571d8df289c8070ee3bb513661"
	},
	{
		"studentid": 10243,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "aa608a45191e644372ef1dae226e01520d985338c23e27cacd3f6fcab7ae3b39"
	},
	{
		"studentid": 10244,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "a231c1d21e22b85b83785eda9166267b7bb4bec8e3e22ffebc903456f06f3aea"
	},
	{
		"studentid": 10245,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "378cc790fd99bef9f394321cab475f52751bac5514c43147c670b74162735650"
	},
	{
		"studentid": 10246,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "52fe5493f0e545c9d47538ccc987abf576b6f81485297ecb8f76a4cc8a454d37"
	},
	{
		"studentid": 10247,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "46f983e9b262ecf6c89467392635c8caf293bfabcbfea2fa0a08c7f406528cd2"
	},
	{
		"studentid": 10248,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "edd102c24e9b92f6422b96d9a0d18a84c4743ce5bc3696d394d3d0c5aeabe41a"
	},
	{
		"studentid": 10249,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "e25d755a9ca207eb03467fde72054ff165702751ce7e67ecf785bb0b82593328"
	},
	{
		"studentid": 10250,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "6e2e428c42b9938b0449c59d69f42ac95b0cb946cff857cb3574d654ac43e8d7"
	},
	{
		"studentid": 10251,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "fdb99095413d32cc8702ef6a4dc3f3912a2da23190276bf64ab5a53950c3a911"
	},
	{
		"studentid": 10252,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "6710b755e0752a759f23d3288bda2a1ce55a9cd1714d7f0b93fbdcb2e5fae277"
	},
	{
		"studentid": 10253,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "1bf8b34308fa91860596695f337778759564d3831d04500157786c4cc5bb0de9"
	},
	{
		"studentid": 10254,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "94c6f22b2d77dc1563e845f6299ef1a6fae936a7618fa4a1ed4f716c804df1d5"
	},
	{
		"studentid": 10255,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "a480eb7174aa9c851406fc116b8797d750066dc6e04226940e2e7933effd2622"
	},
	{
		"studentid": 10256,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "8829cce2778a7fdb782df8fda2fd86d655b05a63bf625f898063c0a3b6a70b8b"
	},
	{
		"studentid": 10257,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "5502266bccaaf1f13592aabf430c4dbafa142715caeb209ccb027bb460234826"
	},
	{
		"studentid": 10258,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "fcfbcccfe6b34a82c5e54f4214f6b8822548a2e5ea72e20a4ad3e78b37369010"
	},
	{
		"studentid": 10259,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "c69b4d52e9c14bfa7611a3adbaa730d6f2cd2bce26691a42dc83fe9db406e38b"
	},
	{
		"studentid": 10260,
		"classid": 8864,
		"schoolid": 6382,
		"studentpin": "3cbaee7a635cab302363b6b0a9b9d16d8129532ae276f2b74db64a08ba831645"
	},
	{
		"studentid": 10261,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "a4994ff22b815d1267c2b3bc0c5a57fecbd525af2f987fb3f71ccb1070ca28b9"
	},
	{
		"studentid": 10262,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "93e071118628fc6fdb7e17fc28191bc630402c43b6d548d0888ba9c6e89bfecc"
	},
	{
		"studentid": 10263,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "61322116e8bcfb85fad4f7fea553b0e3fe6ff8e6f84056f87295b04fa3756ce9"
	},
	{
		"studentid": 10264,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "9659b09e381bd0ff89886d2bff510ffb9a24dc48b55ea394d32d4e6ee6d8ca82"
	},
	{
		"studentid": 10265,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "2955b0af23d313426a348d47570b9fe31b70221338b9120a842abd4ff525f8e8"
	},
	{
		"studentid": 10266,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "8e6091552a5a7dddd012fdb3d46e5cd54d4baa4ed9bb1b160eb90bdba41f078b"
	},
	{
		"studentid": 10267,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "55b77c655e8312d46f7cbfc48af59eed1640569860e66abecd4ca0666d5d2cc2"
	},
	{
		"studentid": 10268,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "7ba96951924fd8781113c03d1a60af730adb96f7f3a7c897dd675413c459ac3e"
	},
	{
		"studentid": 10269,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "5a86de53eaa4e67625d7af3f4bdbd5b3b3de002102f5a07e50cb386c116c09ac"
	},
	{
		"studentid": 10270,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "3c3c8778bc7255b25350e65aa92abb9a8dfb65ad23a68deddfe52f02e1a4d06a"
	},
	{
		"studentid": 10271,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "2cc8cb0edc1bc5ced62d5893720f3e062759515feedc8d8b572b3ac2ba0f9379"
	},
	{
		"studentid": 10272,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "c9af674dc3aa3d056882719ad7d6670458712b3e1b98be7bd711e460ac8ef575"
	},
	{
		"studentid": 10273,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "7ac0d3e411dad078b5fd60bc1a255e6105875d6e232d7641f6d31f0aaed5939d"
	},
	{
		"studentid": 10274,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "6d3338d8440ee1e303f197debad915a54be320ee56ae458bd30575a368b0b243"
	},
	{
		"studentid": 10275,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "f185807d0fa1c3832a726c8b127ef75af363d1b04934836e11f6fd36f0db739c"
	},
	{
		"studentid": 10276,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "48b0f568c6ff2fb1fb823f704ab391bf58edf121f671c5f296a7bcda006c0905"
	},
	{
		"studentid": 10277,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "0d109bfadae79c8530b6c01f0466456ea6612ce5c9b4589aabea0389a286f7bd"
	},
	{
		"studentid": 10278,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "f07aad70abb4d02525d19ab728755e02b6af2d18536b2499868568a5f01aaabe"
	},
	{
		"studentid": 10279,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "153401691c0ec45d6341686d2ed27dbe9731a4d55d43c68a4f7579dd5c6d2e57"
	},
	{
		"studentid": 10280,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "a215fdf6055f7cd74b8f3e65e2f162ca92dbdb8c3bd55839f42625432897ef6e"
	},
	{
		"studentid": 10281,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "66ea8dd539aac2619d8c9d2d4fff508120f29048931682639800863a01ab560f"
	},
	{
		"studentid": 10282,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "3dc09814017bec780a13dcda1e17bcf27216d5fcc088c939e9b5f9898f9989f0"
	},
	{
		"studentid": 10283,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "b88bed95aac68e74af934c123c335beb4445f27001e09f7df12c0b51709ad166"
	},
	{
		"studentid": 10284,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "6fcca56d460716ebaa4473c6be01e688f6930a3469358d705b742a2ee046adc8"
	},
	{
		"studentid": 10285,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "a1826de62eb87e6a9fc1c9524e66c3a987a50a7934df07d71be5e810aa3245cc"
	},
	{
		"studentid": 10286,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "fc312052a31198474e977ee0db86113ea2a11bb4dc0708957e7071d9a84057a1"
	},
	{
		"studentid": 10287,
		"classid": 8865,
		"schoolid": 6382,
		"studentpin": "10227fa31647ccfd53ba9fa68fdb3fa7b88c187a29a6754e35c55e4c3116517a"
	},
	{
		"studentid": 10428,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "4d9b4ce411b85cf530753466f3692a4ae2d288492f8e65f25ff87363267710df"
	},
	{
		"studentid": 10429,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "65e511cb48d3c695b3fb5011c949bd951e67252ddbf997daeff16951534d5f51"
	},
	{
		"studentid": 10430,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "8b314ddb8fd0257ae968a3e960a47542ecbbe0633f489f3d302ba83e22df1bf9"
	},
	{
		"studentid": 10431,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "80d7fc80bcee4f317c5a3b19e8db668289873bb6d7c3c6cc0a1e855ac35cd982"
	},
	{
		"studentid": 10432,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "5df6ea426e4cda8260f2da3e9e69342d33003b9a16aa164754c975b781cd7182"
	},
	{
		"studentid": 10433,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "3672e1b867a29a72052f8c5539f90130d8f12a2f31dfe8b334eeca5236cd36cb"
	},
	{
		"studentid": 10434,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "5c07a32c506e24d87037ebcbadaae8ea81cf92d62d2d63eb4ef9f4c7d70a8324"
	},
	{
		"studentid": 10435,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "efab024442513b8088d7273041d65a97729eb25ff096220624511b10b9912f76"
	},
	{
		"studentid": 10436,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "73f658e75ad96b18f403895e0c4aee3f852099cf3a509f3d75675e95da359d34"
	},
	{
		"studentid": 10437,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "c5ef35eeedb94194151e40e1e3a7c845765b4e84ce2aff05c1f0408b0c2ea3dd"
	},
	{
		"studentid": 10438,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "50446dcdc3e0445d90f1bd169c1a3c8f29dc91095a658cb7283d28378f8a9d9f"
	},
	{
		"studentid": 10439,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "345dbca9ee18e4263a01d97997fd88f2914dca13e640683070c8179cd3eaa743"
	},
	{
		"studentid": 10440,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "cf51813f28cadf80ec3f02f595f2cba195ae38142c9851a022c04cb0ad705e9e"
	},
	{
		"studentid": 10441,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "452cef4ddb5eac02816c834551ea9832a954a14a4804f3b0470a6841a19086b8"
	},
	{
		"studentid": 10442,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "29caa55191f241f481ee5bae8c3e5d2c3d0b491731ca5755ed094fa0a651cb61"
	},
	{
		"studentid": 10443,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "074c4decd7a189707fd8f767b1c89970916de4dd25fbc06c38df998332e5a72f"
	},
	{
		"studentid": 10444,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "1fdd98870210db6f90ffe862976ff549b4489dba2feee02baabcbad4fee68660"
	},
	{
		"studentid": 10445,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "568b745a34eba741327bc6b4c0a6b9c345a38f10bde00223dcaa9801a0b26cf3"
	},
	{
		"studentid": 10446,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "b290e9be8194767afc6953e0995a4674b79bbb7876450be960a7b47ef612bf90"
	},
	{
		"studentid": 10447,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "8c7c1bce29359adcadc7393372a6bc71bbae27b3d04ff9a9e34b41d59d3c28c6"
	},
	{
		"studentid": 10448,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "7debf583143fcc94f00b2cf8d855eb89aed587fb792a6d7a195eee2183ec2cc6"
	},
	{
		"studentid": 10449,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "d2617ab6bbb7391bd23bd99fde4fff38d24f3919ef65af9ccf62281e4df1988f"
	},
	{
		"studentid": 10450,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "5e96ed2ba29484be812960052a6d73bb79da844e5b45af246a5ea218078b5009"
	},
	{
		"studentid": 10451,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "99770619442452ca5a52436d99946114ea9290358b32b7ea409073f242d0c2c4"
	},
	{
		"studentid": 10452,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "0901194bd0ea90b843a9fc2373619c4be84402b797353b73e0850fad69142f03"
	},
	{
		"studentid": 10453,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "df4bf72a86399dcef6d2e986322332ef25745333124862501ea7ca44ce10d9de"
	},
	{
		"studentid": 10454,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "e903cba350610fd6a63721e5ff2f606e1225d2917a6a2fafbb11984740a4fbac"
	},
	{
		"studentid": 10455,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "22e1c55b5ad3e6d77c55612a22b4e4f0d5e4100bd33d7a9838f4ef85e74058ee"
	},
	{
		"studentid": 10456,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "bb8ab02fa186db4c0f7e66e224dc64688b5c4b84dc35e93dc56db5652322c1d2"
	},
	{
		"studentid": 10457,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "e1962f2d1c6caeb9df3ad42ccea7a2e73fcb99f6d9145f3662c00d83faa98313"
	},
	{
		"studentid": 10458,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "95d6dbacea8eb772d21cde99622f897f77fff35d5f2452ddc6ea9091f9e81af1"
	},
	{
		"studentid": 10459,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "7ab26da1892389aec55a41cbde80aef8b5747296ce3542bb80bee95d60d034b4"
	},
	{
		"studentid": 10460,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "29be4190c0192401299f2e9d4d29a1a9a18cea6e35059f4204b121ee40ddcf28"
	},
	{
		"studentid": 10461,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "131f4b8dc726c0e78418dfdb8e28a9985464c0a4002c2178deac6db1dd61d4f5"
	},
	{
		"studentid": 10462,
		"classid": 8923,
		"schoolid": 6311,
		"studentpin": "bd052c24e6be1b91239b00b6d4e5558fb1254c30d20a5176fc0f3bf38fc0f9c5"
	},
	{
		"studentid": 10463,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "660b70428ea8bb07a042ac074360e3d276766498f79099b98c28713c3b484815"
	},
	{
		"studentid": 10464,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "e33fbd221342295b9f2aea35e5810ec7cc888a98acaa8c802a5096e38abf09c8"
	},
	{
		"studentid": 10465,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "34a9fc2b969b0538f2e91f86764c04289ce975370a247fa971f878cd04921a2c"
	},
	{
		"studentid": 10466,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "7740994509e64f704d34fd03919ee527b5ea4e6fdb2e9ce27f26eb7a033623ff"
	},
	{
		"studentid": 10467,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "6c9b0746ec4969740feb358bc34798ad9f42cb3ceddc45f96c64a2f2a553a64f"
	},
	{
		"studentid": 10468,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "8fb8b283e96c7ad1410de0026f8e2f517e270cd99e7f0205354df1a7311f9c8b"
	},
	{
		"studentid": 10469,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "8211d3721e12a6a8c92a2b36d154a09ebc3910364b09b423877fe9ae10d8a7b1"
	},
	{
		"studentid": 10470,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "f38230ef92a33d0a93e796cddbe476f086898060896b5cce145e3380e7cb7d4c"
	},
	{
		"studentid": 10471,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "66c313f62f81c740af9df83dad90d0aa3fe17a168373dba08370de60392b3f8d"
	},
	{
		"studentid": 10472,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "8dd22ef98920c375d15d11e552cc19ed2df646bbf1d97886a305556f65630d18"
	},
	{
		"studentid": 10473,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "ee9638c0f289d99264c52e983d695886962c55b3d8b7ffb9f0d901f92111a53e"
	},
	{
		"studentid": 10474,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "52f765adb8d1585c2c59e656a2918f954feafc834de6b97a1d6fd814d776de6b"
	},
	{
		"studentid": 10475,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "1ab65a26fce4c38feb2d363d1eb4dab45c8c1fd1779e319a6647a40f0dff30e3"
	},
	{
		"studentid": 10476,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "f89a2405a351c06f6613b0fde1b27eab5d34af90f3e898de6fe7d4392ffcf6ab"
	},
	{
		"studentid": 10477,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "dd9e7d42d73afb73583def20907bb9e129e5f2ca3da93dd4faff64ac036afdb0"
	},
	{
		"studentid": 10478,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "89d6185daff7c561e305513a4b179c2ea3bf97da54a17f41d7aa644f6619c9f2"
	},
	{
		"studentid": 10479,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "579b4c2d6bdb6f877501db0d7c38c1ad47d29db9aa9e94d7e855facfa1b8642f"
	},
	{
		"studentid": 10480,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "70ce87c0bab9bd2e09f8612a2af61475e43a7d5acb5294e2773f76f5609e9b51"
	},
	{
		"studentid": 10481,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "15756c24658a119b9b241c7abc2587461cd8e52adf8286132d07ba487207971f"
	},
	{
		"studentid": 10482,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "d19a8983c47ce7b5102b16dfc082b144e13cfec4615caa86cc2af10ed2d02b98"
	},
	{
		"studentid": 10483,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "77567a9f75c5de9848289342be2ff376ff36672fa729189bfd2b9f63fd410614"
	},
	{
		"studentid": 10484,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "7a0febf8ed7ca3336cb48a9cb9e0932e521419d301ac197a0c97d8cc09944bd5"
	},
	{
		"studentid": 10485,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "90df45a09976c0e82db59d204be470893640e27d493c313549a01646029ef48b"
	},
	{
		"studentid": 10486,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "295086469da28bde0fceccba461d9237adad39e17eb6e39eb2f2e21b8660e059"
	},
	{
		"studentid": 10487,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "48a3746d114a207e976c373d658f0a984f7fe01b04c6f37dbd2c7d879f4ead2e"
	},
	{
		"studentid": 10488,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "3e67e24db549575220342938b92e56ec43af607fb31dcb3f0332b52d69e35059"
	},
	{
		"studentid": 10489,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "849fc74f04fdb22d32f319e88396cfbd889d5bce09de7609c4544cd11a53d37e"
	},
	{
		"studentid": 10490,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "625e5b9cee13ab7423d1b4883698077bf7ab7e522efc6ef6f8a1f8cff681fe06"
	},
	{
		"studentid": 10491,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "4d33a0a4e73f137c2695b1b8469fe0db1c2f3a8fe233ad91067634b4e5a55153"
	},
	{
		"studentid": 10492,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "911ae461cdd65e08e414f37411c3f6d15750e48d40d02728ceb84610bad1fc39"
	},
	{
		"studentid": 10493,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "b6d2023357c7a56bdd48a8f9f65d25dc2de421a28f123d963cffe4c935b4c2ea"
	},
	{
		"studentid": 10494,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "558b2814c75702e55662a307d6a44462183296b538d2c0c63165714c3feeed35"
	},
	{
		"studentid": 10495,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "e638457141e5a773184c81789bb0a692a0a82a88ae45a6b32a7b245eafadb198"
	},
	{
		"studentid": 10496,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "3f6fa9f2fe5ed28af77788fbf451e35c3480e7de1aa28f94ebcafbb6331ca976"
	},
	{
		"studentid": 10497,
		"classid": 8962,
		"schoolid": 6404,
		"studentpin": "41e11e11b8238c742cfa58e2a845f180e95436b51e880d41f930759550d03a77"
	},
	{
		"studentid": 13357,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "78985bd04eefd60e0466b0a97f0f46ebc6ca53ea6a290e3c311aa9376c74f221"
	},
	{
		"studentid": 13358,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "472f46a6aaa9a581c23725ab3ca41bae01ef59925d518bd9dff938624c0d8c7f"
	},
	{
		"studentid": 13359,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "7ae1155ea33b0ccf007f8316edf249357b6ed99e26948d4bcc128b454c6ad9b0"
	},
	{
		"studentid": 13360,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "0f2554d6974f2f47b72371a543c4f2f945f4def0fa787d0126bf26781745dec8"
	},
	{
		"studentid": 13361,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "b6684051a876596059b53c20b60380e90c1bb6fcd19380838a8f31085f3ec2f2"
	},
	{
		"studentid": 13362,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "06779a13256ff7fa5e014a5fe69f0ff820ff16fde0d2bc2bb324fd377196f538"
	},
	{
		"studentid": 13363,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "94592f3d8ac10a7f5c038c56875b73dffdd2624f5c67778b86836d86a812172d"
	},
	{
		"studentid": 13364,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "e579112900fd5ad17a8d1f08a1421f7d6293f9c600b508f652535793edf4edeb"
	},
	{
		"studentid": 13365,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "7229070441c1f1ab8277d4e83b87ccfe73ff076a2301be8df81aaf0583faa376"
	},
	{
		"studentid": 13366,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "0edbcf197663ffc99dba51e5ad078fc1dceeeaee58fea922b54560ebb3dfa245"
	},
	{
		"studentid": 13367,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "bf51af6fbbaa262a68ff48dd6e83653de671d844cec82e01fa26d4a68c12d8dc"
	},
	{
		"studentid": 13368,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "7564d7b073a8c0cca659935855dbbd7bec022ffb4955d9e75b406039bc0e3a25"
	},
	{
		"studentid": 13369,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "a0de3850585c378e8141eb714ff4063942759b205c1a933811724d69dd3123d0"
	},
	{
		"studentid": 13370,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "dfbe3c219d3d9e46f996ea8fce67993c56b2336ac8f019349ba7213890bb72d1"
	},
	{
		"studentid": 13371,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "d3ffc5f479219869c13ab47bc06c7ac108f07b77d912e37be99bbbf7bef23e8d"
	},
	{
		"studentid": 13372,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "5da2e22541399871cb10329f2d149f0012d9666d44d375750f89c491059d57cc"
	},
	{
		"studentid": 13373,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "4d215e085ca0c4f0f6dd89cd2c5daae455afbd7d30a7d2072b2c8bf13e75bacb"
	},
	{
		"studentid": 13374,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "167a0de093f9aec07dada1501bccc83abc6da1168cf2506fbe01555415f12980"
	},
	{
		"studentid": 13375,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "5661220aee3b04b76cac4b5c171506f09e605b5ebfcb84eb3be217367453d878"
	},
	{
		"studentid": 13376,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "647338ae05df5403c92ae56df5884f9e6537af408208be8be7b177f4b01edb05"
	},
	{
		"studentid": 13377,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "5a4a9a38c6e3bff436c50fb958176a867030cd5e9c119c89ac7992cff33fafa7"
	},
	{
		"studentid": 13378,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "b9b0a3fc79853f56e78c839ff129683dc56766c27cf1aa95d07945661c903d30"
	},
	{
		"studentid": 13379,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "be1a74b89450b65761383f7faee0c2dfc7cf0bfdeece8d14eda026854ee14999"
	},
	{
		"studentid": 13380,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "bd9e90894eadba794ac0bfb9ab2ec6c997cdb6f72d09a55a99eed74a84bb1d95"
	},
	{
		"studentid": 13381,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "2678981c2c2f8c4f5ed113902711e595c5b2b204e22390d4077c1e5dd4706770"
	},
	{
		"studentid": 13382,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "fab0046627685d415310afeedd5083fcbc80ddb609c21abbe1a008bf1eaac303"
	},
	{
		"studentid": 13383,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "2136bb7ddf1ab27890915e37605e1f8552e541ef06bc0fba396387ab7a1fb14a"
	},
	{
		"studentid": 13384,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "64e88557df189edad56bf1ad1786b9f5254e659b6477df93fcffba4753ea1078"
	},
	{
		"studentid": 13385,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "5616a8cd40d30ca98377237da3236a5b1cd2403c5b9d2052f67df7c5429be3a9"
	},
	{
		"studentid": 13386,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "39f1328b4fee7e9ff75580d9d15931fce7ec13d812028b7b1027876b66248fcb"
	},
	{
		"studentid": 13387,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "bc3f4057a96411651c659dd4c2f16cce636f5180d6ac24b4d1e5deacce277bf8"
	},
	{
		"studentid": 13388,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "aea992f0f14ac7cb0e661bcdab05f06f0b5eee8bef2768a53e991159522f2e26"
	},
	{
		"studentid": 13389,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "be97b1d8e1a8f259f461d97920d71fbb5847a64493a62695782b1ee47ecf51a1"
	},
	{
		"studentid": 13390,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "8c6289142190267969048049a43481386eb3da83f89613b0f23d07f0ab7aaa81"
	},
	{
		"studentid": 13391,
		"classid": 10060,
		"schoolid": 6402,
		"studentpin": "1bb203a9c0c56e911cf2b2ea53bcb7b82e89b5e7de292d0bf92b80cd85f4a116"
	},
	{
		"studentid": 14591,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "f6122f6eb0e5ed9c50ec44743bbf22d8fd7a60a96f69c93d4d051f5e1b6ded69"
	},
	{
		"studentid": 14592,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "37cc15f0afe7353076fcd53a94eab8ea024b0d3c583a5eba8a115519717a7d24"
	},
	{
		"studentid": 14593,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "6d1dafc992c91c78d1f1f53ba2aa854b83491417a9a91cd9b25e5f94c57a393b"
	},
	{
		"studentid": 14594,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "0832ac2972f57440648ffba0885debfaf2f48c47eddc08c95bdf041bcf83826a"
	},
	{
		"studentid": 14595,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "ab4a4160ca14b4a2e9c664b3e02cb7b94d8de4c770c7d28615814016ea1d68e8"
	},
	{
		"studentid": 14596,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "ff4445a4d8611f6549765e712e6f2085ad67f6ff94d5fbe9577b887af6d2352f"
	},
	{
		"studentid": 14597,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "be262a0eac878983b7b4014f28f9de1d91f89307b804aa34691cb86cc9c99cb5"
	},
	{
		"studentid": 14598,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "e98cf168a95adcb85031ae8fa9c580c51da51fee5a7f8fb0c34bb9f6b386efd7"
	},
	{
		"studentid": 14599,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "6960c36517e0ef74dda3a2b3b5027c7fc64e38bdb268b4e1b7cc3dad678517cb"
	},
	{
		"studentid": 14600,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "94475366bbe503b0c8992eb7d32d1b22748f8b69fb0ca8eed64a1c0df5932f0b"
	},
	{
		"studentid": 14601,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "3f4c56ff186cbb990f339812778ce226c1523d41d3037a9b6e3ece7a422ad495"
	},
	{
		"studentid": 14602,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "071a0157dbdda2f88882746c4741d8460616e2223acc1cc093deadd57e959fad"
	},
	{
		"studentid": 14603,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "8fe75ebd52d81bb7019fa62679aec50874c5fd8eb6cb58b9176d9211cd5adeb1"
	},
	{
		"studentid": 14604,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "247d7eca08fbc21d0b3a1ea56dd19332dd3112d58ed662224ab154c915606a10"
	},
	{
		"studentid": 14605,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "d086258659b12352bf00aa586972d30f41fed9165274468ab70f35acc20d548f"
	},
	{
		"studentid": 14606,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "1abe42fc21c1bcafa3baa695708f587314e81190407d63eaf353d9b9496ad792"
	},
	{
		"studentid": 14607,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "943a65b16e5890b3bd4d35b2eff70808649584b928356cfd481a9ebcf019a7e5"
	},
	{
		"studentid": 14608,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "2f89cd6421f0e3f2b72831d5275ba3bdbda8e871d33ba653527f56e478df039e"
	},
	{
		"studentid": 14609,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "2df328f372e006f20e0e64157c5d38ba38588a3f1cad3e4b9133c6e331827c7d"
	},
	{
		"studentid": 14610,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "335379ad6317ca6b36251300aa9140c158b11957fceb86aa23dce8e8429e47eb"
	},
	{
		"studentid": 14611,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "e23c055fbbafc0967d18a05aa8ed8ef648f4abda5933c31e084a8cb06d5c22cd"
	},
	{
		"studentid": 14612,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "2544b136ff408699d3965e98a64e08ea8596830bd3b5932c45cf0dfac3c5c6cf"
	},
	{
		"studentid": 14613,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "0a6ae5580797d03e20511a1e7255271fd048498666014fd44bcb79ba083655d6"
	},
	{
		"studentid": 14614,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "cd8e89b1c9902b9708e34f0e7f2e2025178b5d25007bb84416d303c6b47c7bea"
	},
	{
		"studentid": 14615,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "4aae61a451d92fe8ede94220d1543fda9ee4a08aca69b15c5d628925a14649e0"
	},
	{
		"studentid": 14616,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "d87a79fbc0b19e3575fca3d3806dc854c3606a3d316f2e69e6e0383db923ece3"
	},
	{
		"studentid": 14617,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "7bf74565f408ca8f51d62f33496ecf77f7598bcd6b82fd3593d467b19bd66322"
	},
	{
		"studentid": 14618,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "7279bb0493103de303fe1874b5be47505323671ab9909550d5ed6c37ef4d7d84"
	},
	{
		"studentid": 14619,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "fa6af2c1f349eaab93a3cc2bb6854859a828de6af4450de323ad2659387f6fc1"
	},
	{
		"studentid": 14620,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "62005da6b16a354b38b83ebb43d71e53d59ee74e85affcc291ffb03756e40a07"
	},
	{
		"studentid": 14621,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "270aac84c775007a56575b399a2a1e1846ab1a89f80d1d99e4a9f276adcbeb18"
	},
	{
		"studentid": 14622,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "6c58970598d52643afc734407b13c881ab3c0ec4a9ced662de7e4cac8ae406b8"
	},
	{
		"studentid": 14623,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "825da6a9e6ed9b3b751d3cd8a89eac022a5c4176943a295079b565cd0bf1254d"
	},
	{
		"studentid": 14624,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "3892ab3820f0db46c1796f26484932f22dc4458a125fdc2756ca2115ce8c9d72"
	},
	{
		"studentid": 14625,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "46783a00cbbabb57ea0fc641df3e70b7dbb82d7255e64e7ab1c93866b401832a"
	},
	{
		"studentid": 14626,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "e460f621c856f269c3170d2bc78433631fb38fbbf0c685d68c6d99151a679cf0"
	},
	{
		"studentid": 14627,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "f6b42a00c270b1314bd06270d60bba095036c5e3693b9335d54f1a3c890b1b9d"
	},
	{
		"studentid": 14628,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "faaed23cb3c7d7b4f1e1c39e5cae9c53a741e28fb09a19c368224ad3d81b08eb"
	},
	{
		"studentid": 14629,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "a0a8ddc3a3874b6cc9e7c40165aa90cba693501728967edb10cb108eca892c3e"
	},
	{
		"studentid": 14630,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "aa3d9401420530b4ab77ec087530dc40058d590bdbece62b4e3f8ef3c5a00125"
	},
	{
		"studentid": 14631,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "1ff7b785c7f711ea90c0b2065994d6da378969f2b1d25ca6da887c13d0188bc6"
	},
	{
		"studentid": 14632,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "e6f471aa78d1ffe0c29cd2e443f885b0e491234796be4ff0b745e8b0c4de10af"
	},
	{
		"studentid": 14633,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "6f9bf85f96447202f269372a6453121cf42db4d17fd0f2a662d89761f38ce2f7"
	},
	{
		"studentid": 14634,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "e9cebc9e64d0f7402b18e1b9308f6fb0c0c935a38b8e2d0da4545ab9d2b77083"
	},
	{
		"studentid": 14635,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "afe5aaac48cd9e2d1eaa859a8fae3f7747a95b4632dfb0452085adbe0030fb52"
	},
	{
		"studentid": 14636,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "77e174149651227c476f9d28356b9bb12bbb5eed6830d4e4085ee180d748a5aa"
	},
	{
		"studentid": 14637,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "4515535543a925e853c15fee90601e66deff9e37ffe63bf7ac6e4dc344ecbeb4"
	},
	{
		"studentid": 14638,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "6164570bd03381e1701e2ce4505d8f12d14ba1fca6cf24734f5c5c7eaa2634b7"
	},
	{
		"studentid": 14639,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "7bc79a4fb102882a19004d8d0ce78f888336c35e7cd5ce752c5622c31fcf3dd0"
	},
	{
		"studentid": 14640,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "d4ff09afe9dcab27ae81be475760b4da8589d1ba4476b3c83aca2db42fce4510"
	},
	{
		"studentid": 14641,
		"classid": 11598,
		"schoolid": 8711,
		"studentpin": "a30a12e9d1f6e23f909ede2b1bfa75df81feea29e332afb94d8783e38fc84e28"
	}
]




class StudentLogin extends BaseTable {
	constructor() {
		const mapped = seedData.map(databaseMapper.toStudentLogin)
		super(db.studentLogin, mapped);
	}
}

const StudentLoginTable = new StudentLogin();

export default StudentLoginTable;
