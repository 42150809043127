import React, {useState} from "react";
import "./index.css";
import Typography from "@material-ui/core/Typography";
import useAppState from "../../hooks/useAppState";
import * as yup from "yup";
import {makeStyles, TextField} from "@material-ui/core";
import {replace, useFormik} from "formik";
import LoadingIndicator from "../../components/LoadingIndicator";
import Message from "../../components/Message";
import strings from "../../config/strings";
import Button from "@material-ui/core/Button";
import helpers from "../../utils/helpers";
import databaseService from "../../services/datbaseService";
import {useHistory} from "react-router-dom";
import {SETTINGS_ROUTE,SURVEY_ROUTE} from "../../config/routes";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility} from "@material-ui/icons";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {Input} from "@mui/material";

const validationSchema = yup.object({
    username: yup
        .string('Enter your username')
        .required('Username is required'),
    pin: yup
        .string('Enter your pin')
        .required('Pin is required'),
});

const useStyles = makeStyles({
    root: {
        marginTop: '20px',
        padding: 20

    },
    passWrapper: {
    position: "relative",
    display: "flex",
    marginBottom: "14em"
    },
    card_panel_message: {
        width: "100%",
        padding:"0.25em",
        marginTop: "0em",
        marginBottom: "0.4em",
        borderRadius: "0.15em",
        overflow: "hidden",
        textShadow: "none !important"
      }
})

const LockScreen = () => {

    const history = useHistory();
    const {
        updateLock,
        updateDefaultUser,
        version,
        navigate
    } = useAppState()
    const [values, setValues] = useState({
        password: "",
        showPassword: true,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const classes = useStyles();

    const pinLogin = async (values) => {
        try {
            setIsLoading(true);
            setError(false);

            const userRecord = await databaseService.getUserByUsername(values.username);
            if(userRecord && userRecord.HashedPIN && userRecord.HashedPIN !== "" && userRecord.Active && userRecord.RegisteredAppUser) {
                const hashedPIN = helpers.createHash(values.pin);
                if(hashedPIN === userRecord.HashedPIN) {
                    await updateDefaultUser(userRecord.UserID);
                    history.replace(SETTINGS_ROUTE);
                    await updateLock(0);
                } else {
                    setError(true);
                }
            } else {
                setError(true)
            }

            setIsLoading(false);
        } catch (err) {
            console.log(err)
            setError(true);
            setIsLoading(false);
        }
    }
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const formik = useFormik({
        initialValues: {
            username: '',
            pin: ''
        },
        validationSchema: validationSchema,
        onSubmit:pinLogin
    })

    const onStudentloginPressed = ()=>{
        history.replace(SURVEY_ROUTE)
    }
    if(isLoading) {
        return (<LoadingIndicator text={'Authenticating PIN Credentials'}/>)
    }

    if(error) {
        return (
            <Message title={'PIN Login Error'} text={'There was an issue logging you in. Make sure username and pin are correct. Also make sure you have registered a user while connected to the internet before trying to use PIN Login'} action={() => setError(false)} actionText={'Try Again'} error/>
        )
    }

    return (
        <div className="screen-container align-center">
            <Typography gutterBottom className=" margin-above-double align-self-center" color="primary" variant="h4" >Application is Locked</Typography>
            <div className="form-container-base full-width">
                <Button onClick={onStudentloginPressed} classes={{root: classes.root}} className="margin-above-double margin-below-double" color="primary" variant="contained" fullWidth type="submit">
                    <Typography variant="h5"><b>Click here for student login</b></Typography>
                </Button>
            </div>
            <div className="form-container-base full-width">
                <div className="margin-above-double margin-below-double"/>
                <Typography className="margin-above-double align-self-center" color="primary" variant="h4" >Data Collector Login</Typography>
                <Typography gutterBottom className=" align-self-center" color="primary">Enter your user name and PIN to unlock this application.</Typography>
                <form onSubmit={formik.handleSubmit} className=" full-width margin-below-double margin-above-double">
                    <TextField
                        fullWidth
                        id="username"
                        name="username"
                        label="Username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                    />
                    <TextField
                        fullWidth
                        id="pin"
                        name="pin"
                        label="Pin"
                        type={values.showPassword ? "password" : "text"}
                        value={formik.values.pin}
                        onChange={formik.handleChange}
                        error={formik.touched.pin && Boolean(formik.errors.pin)}
                        helperText={formik.touched.pin && formik.errors.pin}
                        className="margin-below-double"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )}

                        }
                    />


                    <Button classes={{root: classes.root}} className="margin-above-double" color="primary" variant="contained" fullWidth type="submit">
                        <Typography variant="h5">Unlock</Typography>
                    </Button>
                </form>
            </div>
            <div className="margin-above-double form-container-base">
                <Typography className="margin-above-double" variant="body2" gutterBottom>{strings.DATA_COLLECTOR_LOGIN_MESSAGE}</Typography>
            </div>
            <div className="flex-grow"/>
            <Typography variant="body2">{version}</Typography>
        </div>
    )
}

export default LockScreen