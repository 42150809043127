import ApplicationVariableTable from "../database/Tables/ApplicationVariable"
import ApplicationVariableCodeTable from "../database/Tables/ApplicationVariableCode";
import DescriptorCodeTable from "../database/Tables/DescriptorCode";
import InputTypeCodeTable from "../database/Tables/InputTypeCode";
import InstrumentTypeCodeTable from "../database/Tables/InstrumentTypeCode";
import LanguageCodeTable from "../database/Tables/LanguageCode";
import SiteTable from "../database/Tables/Site";
import SiteTypeCodeTable from "../database/Tables/SiteTypeCode";
import SkipCheckTypeCodeTable from "../database/Tables/SkipCheckTypeCode";
import SurveyLogCodeTable from "../database/Tables/SurveyLogCode";
import SurveyTable from "../database/Tables/Survey";
import SurveyResponseTable from "../database/Tables/SurveyResponse";
import UserTable from "../database/Tables/User";
import UserSchoolClassTable from "../database/Tables/UserSchoolClass";
import StudentLoginTable from "../database/Tables/StudentLogin";
import QuestionSkipTable from "../database/Tables/QuestionSkip";
import InstrumentTable from "../database/Tables/Instrument";
import SectionTable from "../database/Tables/Section";
import SectionDescriptorTable from "../database/Tables/SectionDescriptor";
import QuestionTable from "../database/Tables/Question";
import QuestionDescriptorTable from "../database/Tables/QuestionDescriptor";
import ResponseTable from "../database/Tables/Response";
import ResponseDescriptorTable from "../database/Tables/ResponseDescriptor";
import helpers from "../utils/helpers";
import databaseMapper from "./mappers/databaseMapper";
import QuestionSkipRuleTable from "../database/Tables/QuestionSkipRule";
import QuestionSkipRuleValueTable from "../database/Tables/QuestionSkipRuleValue";
import BackupTable from "../database/Tables/Backup";
import QuestionHideTable from "../database/Tables/QuestionHide";
import  * as _ from "lodash"
import SurveyArchiveTable from "../database/Tables/SurveyArchive";

const databaseService = {
	seedDatabase: async () => {
		await ApplicationVariableTable.seedTable();
		await ApplicationVariableCodeTable.seedTable();
		await DescriptorCodeTable.seedTable();
		await InputTypeCodeTable.seedTable();
		await InstrumentTypeCodeTable.seedTable();
		await LanguageCodeTable.seedTable();
		await SiteTable.seedTable();
		await SiteTypeCodeTable.seedTable();
		await SkipCheckTypeCodeTable.seedTable();
		await SurveyLogCodeTable.seedTable();

		if(process.env.REACT_APP_SEED_LOGIN && process.env.REACT_APP_SEED_LOGIN === 'true') {
			await UserSchoolClassTable.seedTable();
			await StudentLoginTable.seedTable();
		}
	},
	getApplicationVersion: async function() {
		let applicationVersionRecordObj = await ApplicationVariableTable.getByAttrs(2);
		return applicationVersionRecordObj.Value;
	},
	updateApplicationVersion: async function(version) {
		let applicationVersionRecordObj = await ApplicationVariableTable.getByAttrs(2);
		applicationVersionRecordObj.LastUpdatedDateTimeUTC = helpers.currentDateTimeUTC();
		applicationVersionRecordObj.Value = version;

		return ApplicationVariableTable.save(applicationVersionRecordObj, [2]);
	},
	updateLock: async function (lock) {
		let lockRecordObj = await ApplicationVariableTable.getByAttrs(8);
		lockRecordObj.LastUpdatedDateTimeUTC = helpers.currentDateTimeUTC();
		lockRecordObj.Value = lock;

		return ApplicationVariableTable.save(lockRecordObj, [8]);
	},
	getLock: async function () {
		let lockRecordObj = await ApplicationVariableTable.getByAttrs(8);
		return lockRecordObj.Value;
	},
	getInstrument: async function() {
		const instrumentRecordObj = await ApplicationVariableTable.getByAttrs(12);
		return instrumentRecordObj.Value
	},
	setDefaultUser: async function (val) {
		let defaultUserRecordObj = await ApplicationVariableTable.getByAttrs(5);
		defaultUserRecordObj.LastUpdatedDateTimeUTC = helpers.currentDateTimeUTC();
		defaultUserRecordObj.Value = val;

		return ApplicationVariableTable.save(defaultUserRecordObj, [5]);
	},
	getSectionBySectionId: async function(SectionID) {
		return SectionTable.getByAttrs({SectionID})
	},
	getQuestionByQuestionId: async function (QuestionID) {
			return QuestionTable.getByAttrs({QuestionID})
	},
	getApplicationGUID: async function () {
		const applicationGUIDObj = await ApplicationVariableTable.getByAttrs(1);
		return applicationGUIDObj.Value;
	},
	getSelectedInstrumentID: async function () {
		const instrumentIDObj = await ApplicationVariableTable.getByAttrs(12);
		if(instrumentIDObj && instrumentIDObj.Value !== "")
			return [parseInt(instrumentIDObj.Value)];
		else 
			return null;
	},
	getUserClassBySchoolId: async function(schoolId) {
		const school = await UserSchoolClassTable.getByAttrs({SchoolID: schoolId})
		return school
	},
	getUsersForSync: async()=>{
		let result = await UserTable.table.filter((el) => (el.BackupID === "0" || el.BackupID===0 ||el.BackupID===undefined || el.BackupID === null) && el.Active === true).toArray();
		return result ?  result: null;
	},
	setLastApplicationSession: async function (val) {
		let lastAppSessionRecordObj = await ApplicationVariableTable.getByAttrs(10);
		lastAppSessionRecordObj.LastUpdatedDateTimeUTC = helpers.currentDateTimeUTC();
		lastAppSessionRecordObj.Value = val;

		return ApplicationVariableTable.save(lastAppSessionRecordObj, [10]);
	},
	saveUserPin: async function (userdId, pin) {
		const hashedPin = helpers.createHash(pin);
		let userRecordObj = await UserTable.getByAttrs(userdId);
		userRecordObj.HashedPIN = hashedPin;
		userRecordObj.PINUpdateDateTimeUTC = helpers.currentDateTimeUTC();
		userRecordObj.BackupID = "0";
		return UserTable.save(userRecordObj, userdId);
	},
	getUserByIndex: async function (indexObj) {
		return await UserTable.getByAttrs(indexObj);

	},
	getUserByUsername: async function(username){
		const users = await UserTable.table.where("Username").equalsIgnoreCase(username).toArray();
		console.log("user",users);
		const user = users[0];
		return user;
	},
	upsertQuestionSkipRule: async function(arr) {
		return QuestionSkipRuleTable.updateBulk(arr)
	},
	upsertQuestionSkipRuleValue: async function(arr) {
		return QuestionSkipRuleValueTable.updateBulk(arr)
	},
	upsertUserData: async function (arr) {
		return UserTable.updateBulk(arr);
	},
	upsertQuestionHideData: async function(arr) {
		return QuestionHideTable.updateBulk(arr)
	},
	upsertUserSchoolClassData: async function (arr) {
		const res = arr.map(us => databaseMapper.toUserSchoolClass(us))
		for(let i = 0; i < res.length; i++) {
			const el = res[i]
			const item = await UserSchoolClassTable.getByAttrs({ SchoolID: el.SchoolID, ClassID: el.ClassID, UserID: el.UserID });
			if(!item) {
				await UserSchoolClassTable.add(el);
			} else {
				await UserSchoolClassTable.save({UserSchoolClassId:item.UserSchoolClassId, ...el})
			}
		}
	},
	getSkipRules: async function(){
		const rules = await QuestionSkipRuleTable.getAll()
		const rulesArr = []
		for (let i = 0; i < rules.length; i++) {
			const rule = rules[i]
			let values = await QuestionSkipRuleValueTable.getByAttrs({QuestionSkipRule: rule.QuestionSkipRuleID})
			if(!Array.isArray(values)){
				values = [values]
			}
			rulesArr.push({ rule, values })
		}

		return rulesArr;
	},
	getQuestionHides: async function(School_id) {
		const questionHides = await QuestionHideTable.table.filter(t => t.School_id === School_id).toArray()
		if(!questionHides) return
		let hides;
		if(!Array.isArray(questionHides)) {
			hides = [questionHides]
		} else {
			hides = questionHides
		}

		return hides;
	},
	upsertUserStudentLoginAccessCodesData: async function (arr) {
		return StudentLoginTable.updateBulk(arr.map(s => databaseMapper.toStudentLogin(s)));
	},
	upsertQuestionSkipData: async function (arr) {
		return QuestionSkipTable.updateBulk(arr);
	},
	upsertInstrumentData: async function (arr) {
		return InstrumentTable.updateBulk(arr);
	},
	upsertSectionData: async function (arr) {
		return SectionTable.updateBulk(arr);
	},
	upsertSectionDescriptorData: async function (arr) {
		return SectionDescriptorTable.updateBulk(arr);
	},
	upsertQuestionData: async function (arr) {
		return QuestionTable.updateBulk(arr);
	},
	upsertQuestionDescriptorData: async function (arr) {
		return QuestionDescriptorTable.updateBulk(arr);
	},
	upsertResponseData: async function (arr) {
		return ResponseTable.updateBulk(arr);
	},
	upsertResponseDescriptorData: async function (arr) {
		return ResponseDescriptorTable.updateBulk(arr);
	},
	upsertSurveyMetaData: async function (result) {
		console.log(result, 'resss')
		await InstrumentTable.updateBulk(result.instrumentMetadataArr);
		console.log('1')
		await SectionTable.updateBulk(result.sectionMetadataArr);
		console.log('2')

		await SectionDescriptorTable.updateBulk(result.sectionDescriptorMetadataArr);
		console.log('3')

		await QuestionTable.updateBulk(result.questionMetadataArr);
		console.log('4')

		await QuestionDescriptorTable.updateBulk(result.questionDescriptorMetadataArr);
		console.log('5')

		await ResponseTable.updateBulk(result.responseMetadataArr);
		console.log('6')

		await ResponseDescriptorTable.updateBulk(result.responseDescriptorMetadataArr);

		await QuestionSkipRuleTable.updateBulk(result.questionSkipRule)

		await QuestionSkipRuleValueTable.updateBulk(result.questionSkipRuleValue)

	},
	updateUserBackUPIDByUserId: async(userId,BackupID) => {
		let  userArray = await UserTable.table.filter((el) =>el.UserID === userId).toArray();
		let userObject = userArray[0];
		userObject.BackupID=BackupID;
		return await UserTable.save(userObject);
	},
	getDefaultUser: async () => {
		let result = await ApplicationVariableTable.getByAttrs(5);
		return parseInt(result.Value);
	},

	getHashedPinByUserID: async (userID) => {
		let result = await UserTable.getByAttrs(userID);
		if (result) return result.HashedPIN;
		else return null;
	},

	getStudentLoginByHashedAccessCode: async (hashedAccessCode) => {
		let studentLoginResult = await StudentLoginTable.getByAttrs({ studentpin: hashedAccessCode });

		if (studentLoginResult) {
			let surveyStudentLoginResult = await databaseService.getSurveyByStudentLoginID(studentLoginResult.StudentLoginID);
			if (surveyStudentLoginResult && surveyStudentLoginResult.length) return { surveyStudentLoginResult: surveyStudentLoginResult[0], studentLoginResult };
			else return { studentLoginResult };
		}
		return null;
	},
	checkIfSurveyArchivedByStudentLoginID: async (StudentLoginID)=> {
		const arcSurv = await SurveyArchiveTable.getByAttrs({StudentLoginID})
		return !!arcSurv
	},
	getNewAccessCodes: async () => {
		const surveys = await SurveyTable.getAll()
		if(surveys && surveys.length) {
			const studentLoginIds = surveys.map(s => s.StudentLoginID)
			const studentLogins = await StudentLoginTable.table
				.filter(sl => studentLoginIds.indexOf(sl.StudentLoginID) === -1)
				.toArray()

			return studentLogins
		} else {
			return StudentLoginTable.getAll()
		}
	},
	checkIfHashedAccessCodeExists: async (hashedAccessCode) => {
		let studentLoginResult = await StudentLoginTable.getByAttrs({ studentpin: hashedAccessCode });
		return studentLoginResult ?  true: false;
	},

	getStudentLoginsBySchoolIDClassID: async (schoolID, classID) => {
		return await StudentLoginTable.table.filter((el) => el.schoolid === schoolID && el.classid === classID).toArray();
	},

	getSurveyByStudentLoginID: async (studentLoginID) => {
		let result = await SurveyTable.table.filter((el) => el.StudentLoginID === studentLoginID && el.IsActive === 1).toArray();
		return result ? result : null;
	},

	getSurveyBySurveyID: async (surveyID) => {
		let result = await SurveyTable.table.filter((el) => el.SurveyID === surveyID && el.IsActive === 1).toArray();
		return result ? result[0] : null;
	},
	getSurveysForSync: async()=>{
		let result = await SurveyTable.table.filter((el) => el.BackupID === "0" || el.BackupID===0 ||el.BackupID===undefined || el.BackupID === null).toArray();
		return result ?  result: null;
	},
	getUserSchoolClassData: async () => {
		let result = await UserSchoolClassTable.table.toArray();
		return result ?  result: null;
	},
	deleteSurveysandSurveyResponses: async()=>{
		let result = await SurveyTable.table.filter((el)=>(el.BackupID !=="0" && el.BackupID != null && el.BackupID !== 0) && el.IsComplete === 1 ).toArray();
		let surveyResponsesCount = 0;
		let SurveyCount = result.length;
		for(let i=0; i<result.length; i++){
			surveyResponsesCount+= await SurveyResponseTable.table.where("SurveyID").equals(result[i].SurveyID).delete();
			await SurveyTable.table.where("SurveyID").equals(result[i].SurveyID).delete();
			console.log("Deleting surveys",surveyResponsesCount,i+1);
		}
		return SurveyCount;
	},
	getSurveys: async () => {
		let result = await SurveyTable.table.toArray();
		return result

	},
	getSurveyResponses: async () => {
		let result = await SurveyResponseTable.table.toArray();
		return result
	},
	updateSurveyBackupIDbyUserId: async(surveyID,BackupID)=>{
		let survey = await SurveyTable.table.filter((el) => el.SurveyID === surveyID && el.IsActive ===1).toArray()
		let surveyRecord = survey[0];
		surveyRecord.BackupID = BackupID;
		return await SurveyTable.save(survey);
	},

	createSurveyWithStudentLoginId: async (studentLoginID, userID, schoolID, instrumentID, languageCodeID) => {
		const questionsArr = await databaseService.getAllQuestionsByInstrumentID(instrumentID);
		const lastViewedQuestionIDByFirstPage = questionsArr.filter(el => el.Active ===1 && el.PageID ===1)[0].QuestionID;
		const surveyRecord = {
			SurveyGUID: helpers.guid(),
			UserID: userID, //default userID
			SiteID: schoolID, // siteID column is reused as schoolID
			StudentLoginID: studentLoginID,
			InstrumentID: instrumentID, // current InstrumentID
			LastViewedQuestionID: lastViewedQuestionIDByFirstPage,
			StartDateTimeUTC: helpers.currentDateTimeUTC(),
			EndDateTimeUTC: "",
			IsComplete: 0,
			IsActive: 1,
			LastUpdateDateTimeUTC: helpers.currentDateTimeUTC(),
			AssignedToUserID: userID,
			AssignedDateTimeUTC: helpers.currentDateTimeUTC(),
			AssignedByUserID: userID,
			AssignmentAccepted: "1",
			BackupID: "0",
			SurveyInstrument: helpers.getSurveyInstrumentDevice(),
			LanguageCodeID: languageCodeID
		};

		return SurveyTable.add(surveyRecord);
	},
	createBackup: async ()=> {
		const backupRecord = {
			BackupID: helpers.guid(),
			syncSuccess: false,
			syncStart: helpers.currentDateTimeUTC(),
			syncEnd: null
		}
		const res = await BackupTable.add(backupRecord);
		console.log(res, 'yooo')
		return res
	},
	setBackup: async (BackupID)=>{
		let backupArray = await BackupTable.table.filter((el)=>el. BackupID === BackupID).toArray();
		console.log(backupArray, BackupID, 'back')
		const backupRecord = backupArray[0]
		backupRecord.syncSuccess = true;
		backupRecord.syncEnd = helpers.currentDateTimeUTC()
		return await BackupTable.save(backupRecord);
	},
	getAllQuestionsByInstrumentID: async (instrumentID) => {
		let sectionArr = await SectionTable.table.filter((el) => el.InstrumentID === instrumentID && el.Active === 1).toArray();
		let sectionIDs = sectionArr.map((el) => el.SectionID);

		if (sectionIDs.length === 0) return null;

		return QuestionTable.table.filter((el) => sectionIDs.includes(el.SectionID) && el.Active === 1).toArray();
	},
	getNumberOfAllQuestionsByInstrumentID: async(instrumentID) => {
		let sectionArr = await SectionTable.table.filter((el) => el.InstrumentID === instrumentID && el.Active === 1).toArray();
		let sectionIDs = sectionArr.map((el) => el.SectionID);


		if (sectionIDs.length === 0) return 0;

		console.log(sectionArr, 'these are questions');
		let count = 0;
		count = await QuestionTable.table.orderBy('SectionID').keys(function(SectionIDS){
			return SectionIDS.length;
		})
		console.log(count);
		return count;

	},
	updateUsersBulk: async (users) => {
		return UserTable.updateBulk(users);
	},
	updateSurveysBulk: async (surveys) => {
		return SurveyTable.updateBulk(surveys);
	},
	updateSurveyResponsesBulk: async (surveyResponses) => {
		return SurveyResponseTable.updateBulk(surveyResponses);
	},
	insertDefaultSurveyResponsesBySurveyID: async (surveyID, instrumentID) => {
		let questionArr = await databaseService.getAllQuestionsByInstrumentID(instrumentID);
		if (!questionArr || questionArr.length === 0) return;
		
		// filter questions that are not "section-questions"
		let filteredQuestionIDs = questionArr.filter((el) => el.ResponseRequired).map((obj) => obj.QuestionID);
		if (!filteredQuestionIDs || filteredQuestionIDs.length === 0) return;

		let filteredResponseArr = await ResponseTable.table
			.filter((el) => filteredQuestionIDs.includes(el.QuestionID) && el.CodebookValue === "ND" && el.Active === 1)
			.toArray();
		
		const applicationGuid = await ApplicationVariableTable.getByAttrs(1);
		let surveyResponses = filteredResponseArr.map((el) => {
			return {
				SurveyID: surveyID,
				QuestionID: el.QuestionID,
				ResponseID: el.ResponseID,
				ResponseValue: "",
				Active: 1,
				LastUpdateDateTimeUTC: helpers.currentDateTimeUTC(),
				FromApplicationGUID: applicationGuid.Value,
			};
		});

		return await SurveyResponseTable.addBulk(surveyResponses);
	},
	insertDSForSurvey: async (surveyID, instrumentID) => {
		let questionArr = await databaseService.getAllQuestionsByInstrumentID(instrumentID);
		if (!questionArr || questionArr.length === 0) return;

		// filter questions that are not "section-questions"
		let filteredQuestionIDs = questionArr.filter((el) => el.ResponseRequired).map((obj) => obj.QuestionID);
		if (!filteredQuestionIDs || filteredQuestionIDs.length === 0) return;

		let filteredResponseArr = await ResponseTable.table
			.filter((el) => filteredQuestionIDs.includes(el.QuestionID) && el.CodebookValue === "DS" && el.Active === 1)
			.toArray();

		const applicationGuid = await ApplicationVariableTable.getByAttrs(1);
		let surveyResponses = filteredResponseArr.map((el) => {
			return {
				SurveyID: surveyID,
				QuestionID: el.QuestionID,
				ResponseID: el.ResponseID,
				ResponseValue: "",
				Active: 1,
				LastUpdateDateTimeUTC: helpers.currentDateTimeUTC(),
				FromApplicationGUID: applicationGuid.Value,
			};
		});

		return await SurveyResponseTable.addBulk(surveyResponses);
	},
	insertSurvey_A_SurveyResponses: async (surveyID, instrumentID) => {
		let questionArr = await databaseService.getAllQuestionsByInstrumentID(instrumentID);
		if (!questionArr || questionArr.length === 0) return;

		// filter questions that are not "section-questions"
		let filteredQuestionIDs = questionArr.filter((el) => el.ResponseRequired).map((obj) => obj.QuestionID);
		if (!filteredQuestionIDs || filteredQuestionIDs.length === 0) return;

		let filteredResponseArr = await ResponseTable.table
			.filter((el) => filteredQuestionIDs.includes(el.QuestionID) && el.CodebookValue === "DS" && el.Active === 1)
			.toArray();

		const applicationGuid = await ApplicationVariableTable.getByAttrs(1);
		let surveyResponses = filteredResponseArr.map((el) => {
			return {
				SurveyID: surveyID,
				QuestionID: el.QuestionID,
				ResponseID: el.ResponseID + 3,
				ResponseValue: (el.QuestionID === 606 || el.QuestionID === 607) ? 80:"",
				Active: 1,
				LastUpdateDateTimeUTC: helpers.currentDateTimeUTC(),
				FromApplicationGUID: applicationGuid.Value,
			};
		});

		return await SurveyResponseTable.addBulk(surveyResponses);
	},
	getSchoolClassByUserID: async (userID) => {
		return await UserSchoolClassTable.table.filter((el) => el.UserID === userID).toArray();
	},

	getClassByStudentLoginID: async (studentLoginID) => {
		const studentLoginRecord = await StudentLoginTable.getByAttrs({ StudentLoginID: studentLoginID });
		if (!studentLoginRecord) return null;

		const classID = studentLoginRecord.classid;
		const record = await UserSchoolClassTable.getByAttrs({ ClassID: classID });
		if (!record) return null;

		return `School: ${record.SchoolName}, Grade: ${record.Grade} Class: ${record.ClassNumber}`;
	},

	getLastQuestionViewedByStudentLoginID: async (studentLoginID) => {
		const surveyRecord = await SurveyTable.table.filter((el) => el.StudentLoginID === studentLoginID && el.IsActive === 1).toArray();

		if (!surveyRecord || surveyRecord.length === 0) return null;
		else return surveyRecord[0].LastViewedQuestionID;
	},

	getQuestionDescriptorsByQuestionID: async (questionID) => {
		return await QuestionDescriptorTable.table.filter((el) => el.QuestionID === questionID).toArray();
	},

	getDescriptorsByDescriptorCodeID: async (descriptorCodeID) => {
		return await DescriptorCodeTable.getByAttrs({ DescriptorCodeID: descriptorCodeID });
	},

	getQuestionsByPageID: async (pageID) => {
		return await QuestionTable.table.filter((el) => el.PageID === pageID && el.Active === 1).toArray();
	},

	setLastQuestionViewed: async (surveyID, questionID) => {
		let surveyRecord = await SurveyTable.table.filter((el) => el.SurveyID === surveyID && el.IsActive === 1).toArray();
		if (!surveyRecord || surveyRecord.length === 0) return;

		surveyRecord[0].LastViewedQuestionID = questionID


		return await SurveyTable.save(surveyRecord[0]);
	},
	completeSurvey: async (surveyID) => {
		let surveyRecord = await SurveyTable.table.filter((el) => el.SurveyID === surveyID && el.IsActive === 1).toArray();
		if (!surveyRecord || surveyRecord.length === 0) return;
		surveyRecord[0].IsComplete = 1;
		surveyRecord[0].EndDateTimeUTC = helpers.currentDateTimeUTC()
		surveyRecord[0].LastUpdateDateTimeUTC = helpers.currentDateTimeUTC()
		return SurveyTable.save(surveyRecord[0]);
	},
	getActiveSurveyResponse: async (surveyID, questionID) => {
		return await SurveyResponseTable.table
			.filter((el) => {
				return el.QuestionID === questionID && el.SurveyID === surveyID && el.Active === 1;
			})
			.toArray();
	},
	getSurveyResponsesBySurveyID: async (surveyID)=>{
		return await SurveyResponseTable.table.filter((el) => el.SurveyID===surveyID && (el.BackupID === "0" || el.BackupID===0 ||el.BackupID===undefined || el.BackupID === null)).toArray();
	},
	getResponsesByQuestionID: async (questionID) => {
		return await ResponseTable.table.filter((el) => el.QuestionID === questionID && el.Active === 1).toArray();
	},
	getSurveyResponsesForUpSync: async ()=>{
		const responses = await SurveyResponseTable.table.filter((el) => el.Active === 1 && (el.BackupID === "0" || el.BackupID===0 ||el.BackupID===undefined || el.BackupID === null)).toArray();
		return responses;
	},
	getResponseDescriptorsByResponseID: async (responseID) => {
		return await ResponseDescriptorTable.table.filter((el) => el.ResponseID === responseID).toArray();
	},
	deactivatePreviousSurveyResponsesBySurvey: async (surveyID) => {
		let prevSurveyResponses = await SurveyResponseTable.table
			.filter((el) => el.SurveyID === surveyID && el.Active === 1)
			.toArray();

		if (!prevSurveyResponses || prevSurveyResponses.length === 0) return;

		prevSurveyResponses.forEach((el) => {
			el.Active = 0;
			el.LastUpdateDateTimeUTC = helpers.currentDateTimeUTC();
			el.BackupID = "0"
		});

		return await SurveyResponseTable.updateBulk(prevSurveyResponses);


	},
	deactivatePreviousSurveyResponses: async (surveyID, questionID) => {
		let prevSurveyResponses = await SurveyResponseTable.table
			.filter((el) => el.QuestionID === questionID && el.SurveyID === surveyID && el.Active === 1)
			.toArray();

		if (!prevSurveyResponses || prevSurveyResponses.length === 0) return;

		prevSurveyResponses.forEach((el) => {
			el.Active = 0;
			el.LastUpdateDateTimeUTC = helpers.currentDateTimeUTC();
			el.BackupID = "0"
		});

		return await SurveyResponseTable.updateBulk(prevSurveyResponses);
	},

	createSurveyResponse: async (surveyID, questionID, responseID, responseValue) => {
		const applicationGuid = await ApplicationVariableTable.getByAttrs(1);

		let surveyResponseObj = {
			SurveyID: surveyID,
			QuestionID: questionID,
			ResponseID: responseID,
			ResponseValue: responseValue,
			Active: 1,
			LastUpdateDateTimeUTC: helpers.currentDateTimeUTC(),
			BackupID: 0,
			Deleted: 0,
			FromApplicationGUID: applicationGuid.Value,
		};
		return await SurveyResponseTable.save(surveyResponseObj);
	},

	getInputTypeByInputCodeID: async (inputCodeID) => {
		return await InputTypeCodeTable.getByAttrs({ InputTypeCodeID: inputCodeID });
	},

	insertSingleAnswerSurveyResponse: async (surveyID, questionID, responseID, responseValue, active) => {
		await databaseService.deactivatePreviousSurveyResponses(surveyID, questionID);

		if (active) {
			console.log(`The responseID is ${responseID}. the The questionId is ${questionID}`)
			return await databaseService.createSurveyResponse(surveyID, questionID, responseID, responseValue);
		}
		else {
			return await databaseService.insertDSIfNDOrNoActiveResponse(surveyID, questionID);
		}
	},

	deactivateSurveyResponsesWithDefaultCodebookValues: async (surveyID, questionID) => {
		let codebookValues = ["ND", "DS", "SK"];
		let responseArr = await ResponseTable.table
			.filter((el) => el.QuestionID === questionID && codebookValues.includes(el.CodebookValue) && el.Active === 1)
			.toArray();
		let responseIDs = responseArr.map((obj) => obj.ResponseID);

		let surveyResponseArr = await SurveyResponseTable.table
			.filter((el) => el.SurveyID === surveyID && el.QuestionID === questionID && responseIDs.includes(el.ResponseID) && el.Active === 1)
			.toArray();

		if (!surveyResponseArr || surveyResponseArr.length === 0) return;

		surveyResponseArr.forEach((el) => {
			el.Active = 0;
			el.LastUpdateDateTimeUTC = helpers.currentDateTimeUTC();
			el.BackupID = 0;
		});

		return await SurveyResponseTable.updateBulk(surveyResponseArr);
	},

	upsertMultipleAnswerSurveyResponse: async (surveyID, questionID, responseID, responseValue, active) => {
		if (active) {
			// this will remove all the default codebook values
			await databaseService.deactivateSurveyResponsesWithDefaultCodebookValues(surveyID, questionID);
			return await databaseService.createSurveyResponse(surveyID, questionID, responseID, responseValue);
		}

		// if option is inactive
		else {
			let surveyResponseArr = await SurveyResponseTable.table
				.filter((el) => el.SurveyID === surveyID && el.QuestionID === questionID && el.ResponseID === responseID && el.Active === 1)
				.toArray();

			if (!surveyResponseArr || surveyResponseArr.length === 0) return;

			surveyResponseArr.forEach((el) => {
				el.Active = 0;
				el.LastUpdateDateTimeUTC = helpers.currentDateTimeUTC();
				el.BackupID = "0"
			});

			await SurveyResponseTable.updateBulk(surveyResponseArr);

			return await databaseService.insertDSIfNDOrNoActiveResponse(surveyID, questionID);
		}
	},

	// check for ND or no active responses  -> insert DS SurveyResponse for that question
	insertDSIfNDOrNoActiveResponse: async (surveyID, questionID) => {
		let surveyResponse = await databaseService.getActiveSurveyResponse(surveyID, questionID);
		if (!surveyResponse || surveyResponse.length === 0) {
			return await databaseService.insertSurveyResponseByCodebookValue(surveyID, questionID, "DS");
		} else if (surveyResponse.length === 1) {
			try {
				let responseObj = await ResponseTable.getByAttrs(surveyResponse[0].ResponseID);
			if (responseObj.CodebookValue === 'ND'){
				return await databaseService.insertSurveyResponseByCodebookValue(surveyID, questionID, "DS");
			}

			} catch {
				console.log("no response obj")
			}
		} else {
			return null;
		}


	},

	insertSurveyResponseByCodebookValue: async (surveyID, questionID, codebookValue) => {
		await databaseService.deactivatePreviousSurveyResponses(surveyID, questionID);
		let responseArr = await ResponseTable.table
			.filter((el) => el.QuestionID === questionID && el.CodebookValue === codebookValue && el.Active === 1)
			.toArray();
		if (!responseArr || responseArr.length === 0) return;

		let responseID = responseArr[0].ResponseID;

		return await databaseService.createSurveyResponse(surveyID, questionID, responseID, "");
	},

	deactivateSurveyResponseByCodebookValue: async (surveyID, questionID, codebookValue) => {
		let responseArr = await ResponseTable.table
			.filter((el) => el.QuestionID === questionID && el.CodebookValue === codebookValue && el.Active === 1)
			.toArray();
		if (!responseArr || responseArr.length === 0) return;

		let responseID = responseArr[0].ResponseID;

		let surveyResponseArr = await SurveyResponseTable.table
			.filter((el) => el.SurveyID === surveyID && el.QuestionID === questionID && el.ResponseID === responseID && el.Active === 1)
			.toArray();

		if (!surveyResponseArr || surveyResponseArr.length === 0) return;

		surveyResponseArr.forEach((el) => {
			el.Active = 0;
			el.LastUpdateDateTimeUTC = helpers.currentDateTimeUTC();
			el.BackupID = "0"
		});

		return await SurveyResponseTable.updateBulk(surveyResponseArr);
	},

	getSkipLogicByQuestionID: async (questionID) => {
		return await QuestionSkipTable.table.filter(el => el.QuestionID === questionID).toArray();
	},

	getUnansweredQuestionsNonSkip: async (instrumentID, surveyID) => {
		const questionInfoArr = await databaseService.getAllQuestionsByInstrumentID(instrumentID);
		if (!questionInfoArr || questionInfoArr.length === 0) return;

		// filter questions that are not "section-questions" and get an array of QuestionIDs
		let questionIDsArr = questionInfoArr.filter((el) => el.ResponseRequired).map((obj) => obj.QuestionID);

		// get responseIDs that have codebook value of ND or DS
		const responseID_ND_DS_temp = await ResponseTable.table
			.filter((el) => questionIDsArr.includes(el.QuestionID) && ["ND", "DS"].includes(el.CodebookValue) && el.Active === 1)
			.toArray();
		const responseID_ND_DS = responseID_ND_DS_temp.map((obj) => obj.ResponseID);

		// get all the surveyResponses of the current survey
		const surveyResponses_temp = await SurveyResponseTable.table.filter((el) => el.SurveyID === surveyID).toArray();
		
		// get unanswered questionIDs that have active surveyresponse of ND or DS
		const unAnsweredQuestionsIDs = surveyResponses_temp
			.filter((el) => responseID_ND_DS.includes(el.ResponseID) && el.Active === 1)
			.map((obj) => obj.QuestionID);
		if (unAnsweredQuestionsIDs.length === 0) return [];

		// if there are unanswered questions filter the above fetched QuestionInfoArray
		let filtered_questionInfoArr = questionInfoArr.filter((el) => unAnsweredQuestionsIDs.includes(el.QuestionID));
		
		// attach label and text from QuestionDescriptors to the filtered QuestionInfoArray
		let questionDescriptorArr = await QuestionDescriptorTable.getAll();
		return filtered_questionInfoArr.map((el) => {
			let label = questionDescriptorArr.find((des) => {
				return des.QuestionID === el.QuestionID && des.DescriptorCodeID === 2;
			});

			let text = questionDescriptorArr.find((des) => {
				return des.QuestionID === el.QuestionID && des.DescriptorCodeID === 3;
			});

			return { questionLabel: label?.Text, questionText: text?.Text, ...el };
		});
	},

	getSectionDescriptorsBySectionID: async (sectionID) => {
		return await SectionDescriptorTable.table.filter(el => el.SectionID === sectionID).toArray();
	},

	setSurveyComplete: async (surveyID) => {
		let surveyRecord = await SurveyTable.table.filter((el) => el.SurveyID === surveyID && el.IsActive === 1).toArray();
		if (!surveyRecord || surveyRecord.length === 0) return;
		surveyRecord[0].IsComplete = 1;
		surveyRecord[0].LastUpdateDateTimeUTC = helpers.currentDateTimeUTC();
		surveyRecord[0].EndDateTimeUTC = helpers.currentDateTimeUTC();

		return await SurveyTable.save(surveyRecord[0]);
	},
	setSurveyForUpSync: async (surveyID) => {
		let surveyRecord = await SurveyTable.table.filter((el) => el.SurveyID === surveyID && el.IsActive === 1).toArray();
		if (!surveyRecord || surveyRecord.length === 0) return;
		surveyRecord[0].BackupID = "0";
		surveyRecord[0].LastUpdateDateTimeUTC = helpers.currentDateTimeUTC();
		return await SurveyTable.save(surveyRecord[0]);
	},
	setSurveyResponseForUpSync: async (SurveyResponseID) => {
		let surveyResponseRecord = await SurveyResponseTable.table.filter((el) => el.SurveyResponseID === SurveyResponseID).toArray();
		if (!surveyResponseRecord || surveyResponseRecord.length === 0) return;
		surveyResponseRecord[0].BackupID = "0";
		surveyResponseRecord[0].LastUpdateDateTimeUTC = helpers.currentDateTimeUTC();
		return await SurveyTable.save(surveyResponseRecord[0]);
	},
	setSurveyToSynced: async (surveyID) =>{
		let surveyRecord = await SurveyTable.table.filter((el) => el.SurveyID === surveyID && el.IsActive === 1).toArray();
		if (!surveyRecord || surveyRecord.length === 0) return;
		surveyRecord[0].BackupID = "1";
		surveyRecord[0].LastUpdateDateTimeUTC = helpers.currentDateTimeUTC();
		return await SurveyTable.save(surveyRecord[0]);

	}
};

export default databaseService;
