import { Typography } from "@material-ui/core";
import React, {useEffect,useState} from "react";
import config from  "../../../config/strings.js"
import "./index.css";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";



export default function SurveyInstructionsScreen({handleLanguage,language,getClass, setViewed,getSurveyStatus,setStart,getInstructions}) {
	const [userClass, setClass] = useState(undefined);
	const [surStatus,setSurveyStatus] = useState(undefined);
	const [hasViewed, setView] = useState(false);
	const {t} = useTranslation();
 	useEffect(() => {
		(async () => {
			const schoolClass = getClass();
			setClass(schoolClass);
			setSurveyStatus(getSurveyStatus());
			console.log(schoolClass,"school class")
		})()
	}, [])

	const useStyles = makeStyles({
		root: {
			marginTop: '20px'
		},
		leftText: {
			textAlign: "left"
		  },
		  rightText: {
			width:"100%",
			textAlign: "right"
		  },
		  NewSurvey:{
			backgroundColor:" #ff0000"
		  },
		  InProgressSurvey:{
			backgroundColor: "#ffff00"
		  },
		  CompletedSurvey:{
				backgroundColor: "#00ff00",
				
		  },
		  card_pannel:{
			margin: '0.5em auto',
			padding: '1em',
			border: '1px solid #C7C7C7',
			backgroundColor: '#FFFFFF',
			webkitBorderRadius: '0.1em',
			mozBordeRradius: '0.1em',
			borderRadius: '0.1em',
			textShadow:' none !important'
		  },
		  card_panel_message: {
			border: "1px solid #E3E3E3",
			backgroundColor: "#F1F1F1",
			padding:"0.25em",
			marginTop: "0em",
			marginBottom: "0.4em",
			borderRadius: "0.15em",
			overflow: "hidden",
			textShadow: "none !important"
		  },
		warning: {
			border: "1px solid #E3E3E3 !important",
			backgroundColor: "#FFFFCC !important"
			},
		error :{
				border: "1px solid #A3A3A3 !important",
				backgroundColor: "#FFB3B3 !important"
		},
		omb_panel_instruction:{
			border: ".1px solid #E3E3E3",
			backgroundColor: "#F1F1F1",
			padding: "0.1em",
			marginTop: "0em",
			marginBottom: "0.4em",
			webkitBorderRadius: "0.15em",
			mozBorderRadius: "0.15em",
			borderRadius: "0.15em",
			overflow: "hidden",
			textShadow: "none !important",
			fontSize: ".99em !important",
			fontWeight: "bold"
		},
		omb_panel_message: {
			border: "1px solid #E3E3E3",
			backgroundColor: "#F1F1F1",
			padding: "0.25em",
			marginTop: "0em",
			marginBottom: "0.4em",
			webkitBorderRadius: "0.15em",
			mozBordeRradius: "0.15em",
			borderRadius: "0.15em",
			//max-width: 27em;
			overflow: "hidden",
			textShadow: "none !important",
			fontSize: ".8em !important"
		},
		invisible_card_filler: {
			height: "0.01em",
			width: "27em"
		},
		ThankYouText: {
			textAlign: "center",
			fontStyle: 'italic',
			fontSize: "20px",
			fontWeight: "bold"
		}

	})
    
	const classes = useStyles();

	let surveyMessage = <Typography  >Unable to determine the status of current survey</Typography>;
	if(surStatus === 'New'){
		surveyMessage = <Typography  >{t('NewSurvey')}</Typography>
	} else if (surStatus ==='In-Progress'){
		surveyMessage = <Typography   >{t('OldSurvey')}</Typography>
	} else if(surStatus === 'Complete'){
		surveyMessage = <Typography  >{t('CompletedSurvey')}</Typography>
	}

	if(!getInstructions()) {
		return (
			<div className="flex-column full-width">
				<div className="align-self-end">
					<ToggleButtonGroup
						value={language}
						exclusive
						onChange={handleLanguage}
						aria-label="text alignment"
					>
						<ToggleButton value="English" aria-label="Select English Language Toggle Button" disabled={language==="English"}>
							<Typography variant="h5">{t('EnglishButton')}</Typography>
						</ToggleButton>
						<ToggleButton value="Spanish" aria-label="Select Spanish Language Toggle Button" disabled={language==="Spanish"}>
							<Typography variant="h5">{t('SpanishButton')}</Typography>
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<div className={classes.card_pannel}>
					<div className = { `${classes.card_panel_message}`}>
						<Typography fontSize="20px"><b>{t('TitleInstructions')}</b></Typography>
					</div>
					<div className = { `${classes.error} ${classes.card_panel_message}`}>
						<Typography >{userClass}</Typography>
					</div>
					<div className={`${classes.warning} ${classes.card_panel_message}`}>
						{surveyMessage}
					</div>
					<div className={classes.omb_panel_instruction}>
						<p >{t('Survey_instructions_headerInstructions')}</p>
					</div>
					<div className={classes.omb_panel_message}>
						<Typography variant="body2" align="right">
							{t('FormMessage1')}
							<br></br>
							{t('FormMessage2')}
							<br></br>
							{t('FormMessage3')}
						</Typography>
						<br/>
						<Typography> {t('Survey_Instructions_bodyInstructions')}</Typography>
						<br/>
						<Typography> {t('Survey_Instructions_bodyInstructions2')}</Typography>
						<br/>
						<Typography> {t('Survey_Instructions_bodyInstructions3')}</Typography>
						<br/>
						<Typography> {t('Survey_Instructions_bodyInstructions4')}</Typography>
						<br/>
						<Typography> {t('Survey_Instructions_bodyInstructions5')}</Typography>
					</div>

					<Button value="Continue Survey Instructions Button"  onClick={() => {setViewed(true)}} classes={{root: classes.root}} className="margin-above-double" color="primary" variant="contained" fullWidth type="submit">
						{t('ContinueButton')}
					</Button>
					<div className={classes.omb_panel_message}>
						<Typography variant="body2"> {t('endMessage')}</Typography>
						<p className={classes.ThankYouText}>{t('ThankYouMessage')}</p>
					</div>

				</div>
			</div>

		)
	} else {
		return (
			<div className="flex-column full-width">
				<div className="align-self-end">
					<ToggleButtonGroup
						value={language}
						exclusive
						onChange={handleLanguage}
						aria-label="text alignment"
					>
						<ToggleButton value="English" aria-label="Select English Language Toggle Button" disabled={language==="English"}>
							<Typography variant="h5">{t('EnglishButton')}</Typography>
						</ToggleButton>
						<ToggleButton value="Spanish" aria-label="Select Spanish Language Toggle Button" disabled={language==="Spanish"}>
							<Typography variant="h5">{t('SpanishButton')}</Typography>
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<div className={classes.card_pannel}>
					<div className = { `${classes.error} ${classes.card_panel_message}`}>
						<Typography >{userClass}</Typography>
					</div>
					<div className={classes.omb_panel_message}>
						<Typography gutterBottom>{t('SecondInstructions')}</Typography>
						<Typography gutterBottom>{t('SecondInstructions2')}</Typography>
						<Typography gutterBottom>{t('SecondInstructions3')}</Typography>
						<Typography gutterBottom>{t('SecondInstructions4')}</Typography>
						<Typography gutterBottom> {t('SecondInstructions6')}</Typography>
					</div>
					<div className={`${classes.warning} ${classes.card_panel_message}`}>
						{surveyMessage}
					</div>

					<Button value="Start Survey Button"  onClick={() => {setStart(true)}} classes={{root: classes.root}} className="margin-above-double" color="primary" variant="contained" fullWidth type="submit">
						{t('StartButton')}
					</Button>
				</div>
			</div>

		)
	}
}
