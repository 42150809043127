import { db } from "../index";
import BaseTable from "./index";

const seedData = [
	{
		SiteTypeCodeID: 1,
		Description: "Regional Operations Center (ROC)",
	},
	{
		SiteTypeCodeID: 2,
		Description: "Study Location",
	},
];

class SiteTypeCode extends BaseTable {
	constructor() {
		super(db.siteTypeCode, seedData);
	}
}

const SiteTypeCodeTable = new SiteTypeCode();

export default SiteTypeCodeTable;
