import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button'

const Message = ({title, text, action, error, actionText}) => {
	return (
		<div style={{padding: "24px"}} className="full-width full-height flex-column justify-center align-center">
			{!!title && <Typography gutterBottom className="margin-below" color={error ? 'error':'primary' } variant={'h3'}>{title}</Typography>}
			{!!text && <Typography gutterBottom className="margin-below">{text}</Typography>}
			{!!action && !!actionText && <Button variant="contained" color="secondary" onClick={action}><Typography>{actionText}</Typography></Button>}
		</div>
	)
}


export default Message
