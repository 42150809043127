import {createTheme, responsiveFontSizes} from "@material-ui/core/styles";

let theme = createTheme({
	palette: {
		primary: {
			main: '#215181'
		},
		secondary: {
			main: '#BFBFBF'
		}
	}
})

theme = responsiveFontSizes(theme)

export default theme
