const config = {
	sessionKey: 'yrbs-survey-session',
	requestTimeout: 10000,
	jwtKey: 'yrbs-survey-jwt',
	syncAttemptKey: 'yrbs-sync-attempt',
	syncSuccessKey: 'yrbs-sync-success',
	userSyncTotalKey: 'yrbs-user-sync-total',
	userSyncKey: 'yrbs-user-sync',
	surveySyncTotalKey: 'yrbs-survey-sync-total',
	surveySyncKey: 'yrbs-survey-sync',
	surveyResponsesSyncTotalKey: 'yrbs-survey-responses-total',
	surveyResponsesSyncKey: 'yrbs-survey-responses',
	surveyApplicationID: 'yrbs-survey-application-id',
	surveyApplicationGUID: 'yrbs-survey-application-guid',
	surveyApplicationSessionID: 'yrbs-survey-application-session-id',
	surveyApplicationSessionValue: 'yrbs-survey-application-session-value',
	surveyBackupID:'yrbs-survey-backup-id',
	appTitle: 'National Youth Risk Behavior Survey',
	appDescription: 'Application that provides survey questions as a form of data collection.',
	dbVersion: 9,
	instrument: 4,
	defaultSessionValue: "00000000-0000-0000-0000-000000000000",
	defaultGUIDValue: "00000000-0000-0000-0000-000000000000",
	surveyStatus: {
		NEW: 'New',
		IN_PROGRESS: 'In-Progress',
		COMPLETE: 'Complete'
	},
	feetDisplayArr: ['3','4','5', '6', '7'],
	inchesDisplayArr: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
	hundredDisplayArr: ['0', '1', '2', '3'],
	tenDisplayArr: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
	oneDisplayArr: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
	appVersion: "2.0.5",
	migrationVersion: "2",
	migrationKey: "yrbs-migration",
	surveyQuestions: 'yrbsQuestionObject',
	optimization: "yrbs-optimization",
	responseHiding: {
		663: {
			QuestionID: 662,
			ResponseID: [5560],
			HideResponseID:5566
		},
		664: {
			QuestionID: 662,
			ResponseID: [5560],
			HideResponseID:5578
		},
		665: {
			QuestionID: 662,
			ResponseID: [5560],
			HideResponseID:5589
		},
		666: {
			QuestionID: 662,
			ResponseID: [5560],
			HideResponseID:5601
		},
		667: {
			QuestionID: 662,
			ResponseID: [5560],
			HideResponseID:5608
		},
		611: {
			QuestionID: 610,
			ResponseID: [5074, 5075, 5076, 5077, 5078],
			HideResponseID: 5083
		},
		634: {
			QuestionID: 633,
			ResponseID: [5272, 5273, 5274, 5275],
			HideResponseID: 5280
		},
		636: {
			QuestionID: 635,
			ResponseID: [5287],
			HideResponseID: 5293
		},
		638: {
			QuestionID: 637,
			ResponseID: [5305, 5306, 5307, 5308, 5309, 5310],
			HideResponseID: 5315
		},
		641: {
			QuestionID: 640,
			ResponseID: [5333, 5334, 5335, 5336, 5337, 5338],
			HideResponseID: 5343
		},
		648: {
			QuestionID: 646,
			ResponseID: [5396, 5397, 5398, 5399, 5400, 5401],
			HideResponseID: 5417
		},
		649: {
			QuestionID: 646,
			ResponseID: [5396, 5397, 5398, 5399, 5400, 5401],
			HideResponseID: 5429
		},
		651: {
			QuestionID: 650,
			ResponseID: [5442, 5443, 5444, 5445, 5446, 5447],
			HideResponseID: 5452
		},
		670: {
			QuestionID: 669,
			ResponseID: [5628, 5629, 5630],
			HideResponseID: 5635
		},
		622: {
			QuestionID: 621,
			ResponseID: [5180, 5181, 5182, 5183, 5184],
			HideResponseID: 5189
		}
	}
}

export  default config;
