import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const LoadingIndicator = ({text}) => {
	return (
		<div className="full-width full-height flex-column justify-center align-center">
			{!!text && <Typography>{text}</Typography> }
			<CircularProgress />
		</div>
	)
}

export default LoadingIndicator;
