import axios from "axios";
import config from "../config"
import instrument from "../database/Tables/Instrument";
import BaseAPIService from "./baseAPIService";
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.timeout = 10000;


class ApiService extends BaseAPIService {
	constructor() {
		super();
	}

	checkJWTAuth = async () => {
		const result = await this.authService.get('/auth/me')
		return result.data.data
	}

	getUsersData = async (applicationSessionValue) => {
		const result = await this.authService.get(`/user/getUsersData/${applicationSessionValue}`);
		return result.data.data;
	}

	authenticate = async(username,password, applicationGUID) => {
		const body = {
			username,
			password,
			applicationGUID
		}
		const result = await this.baseService.post('/auth/tablet/datacollector/login', body);
		return result.data.data;
	}

	getSurveyMetadata = async(instrumentId) => {
		const result =  await this.authService.get(`/data/GetSurveyMetadata/${instrumentId}`);
		return result.data.data;
	}

	getUserSchoolClassData = async (applicationSessionValue, currentuserid) => {
		const result = await this.authService.get(`/userSchoolClass/getUserSchoolClassData/${currentuserid}/${applicationSessionValue}`);
		return result.data.data;
	}

	getQuestionHideData = async (applicationSessionValue, currentuserid) => {
		const result = await this.authService.get(`/questionHide/getQuestionHideData/${currentuserid}/${applicationSessionValue}`);
		return result.data.data;
	}

	getStudentLoginAccessCodesData = async (applicationSessionValue, currentuserid) => {
		const result = await this.authService.get(`/studentLogin/getStudentPins/${applicationSessionValue}/${currentuserid}`);
		return result.data.data;
	}

	getQuestionSkipData = async (applicationSessionValue) => {
		const body = {
			applicationSessionValue
		};
		const result = await this.authService.get(`/questionSkip/getQuestionSkip/${applicationSessionValue}`);
		return result.data.data;
	}
	getQuestionSkipRuleData = async (applicationSessionValue) => {
		const body = {
			applicationSessionValue
		};
		const result = await this.authService.get(`/questionSkipRule/getQuestionSkipRule/${applicationSessionValue}`);
		return result.data.data;
	}

	getQuestionSkipRulValueData = async (applicationSessionValue) => {
		const body = {
			applicationSessionValue
		};
		const result = await this.authService.get(`/questionSkipRuleValue/getQuestionSkipRuleValue/${applicationSessionValue}`);
		return result.data.data;
	}
	upsyncUser = async (applicationSessionValue,user) => {
		const body ={
			applicationSessionValue,
			users:user
		}

		const result = await this.authService.post("/user/upsyncUser",body);
		return result.data.data;
	}

	sendSurveyData = async (applicationSessionValue,survey) => {
		const body = {
			applicationSessionValue,
			surveys:survey
		}
		const result = await this.authService.post("/survey/sendSurvey", body);
		return result.data.data;
	}

	sendSurveyResponseData = async (applicationSessionValue,surveyResponseDataArray)=>{
		const body ={
			applicationSessionValue,
			surveyResponses:surveyResponseDataArray
		}
		const result = await this.authService.post("/surveyResponse/sendSurveyResponses",body)
		return result.data.data
	}

	createApplication = async (userId) => {
		const body = {
			userId
		}
		const result = await this.authService.post("/application/web/create/application", body)
		console.log(result.data.data, 'rsss')
		return result.data.data
	}

	studentLoginWeb = async (accessCode, isNew) => {
		const body = {
			accessCode,
			isNew
		}
		const result = await this.baseService.post("/auth/web/student/login", body)
		return result.data.data
	}
	beginSurveyWeb =  async (studentLoginID, siteID, instrument, backupID, sessionID, userID, languageCodeID) => {
		const body = {
			studentLoginID,
			siteID,
			instrument,
			backupID,
			sessionID,
			userID,
			languageCodeID
		}
		console.log(siteID)
		const result = await this.authService.post("/survey/begin", body)
		console.log(result)

		return result.data.data
	}

	updateLastQuestionViewed = async (surveyId, QuestionId) => {
		const result = await this.authService.put(`/survey/${surveyId}/last/viewed/${QuestionId}`)
		return result.data.data

	}

	getActiveSurveyResponse = async (surveyId, questionId) => {
		const result = await this.authService.get(`/surveyResponse/active/${surveyId}/${questionId}`)
		return result.data.data
	}

	getQuestionCountByInstrument = async (instrument) => {
		const result = await this.authService.get(`/question/instrument/${instrument}/count`)
		return result.data.data
	}
	getQuestionById = async (questionID) => {
		const result = await this.authService.get(`/question/${questionID}`)
		return result.data.data
	}

	loadQuestion = async (pageID, surveyID, backupID) => {
		const result = await  this.authService.get(`/question/load/${pageID}/${surveyID}/${backupID}`)
		return result.data.data
	}
	getQuestionsByPageID = async (pageID) => {
		const result = await  this.authService.get(`/question/page/${pageID}`)
		return result.data.data
	}
	completeSurvey = async (surveyID) => {
		const result = await  this.authService.put(`/survey/${surveyID}/complete`)
		return result.data.data
	}
	createLocalApplicationSession = async (applicationId, userId) => {
		const body = {
			applicationId,
			userId
		}

		const result = await  this.authService.post(`/localApplicationSession/web`, body)
		return result.data.data
	}

	upsertMultipleAnswerSurveyResponse = async (surveyID,questionID, responseID, responseValue, active, backupID) => {
		const body = {
			surveyID,
			questionID,
			responseID,
			responseValue,
			active,
			backupID
		}

		const result = await  this.authService.post(`/surveyResponse/insert/multiple`, body)
		return result.data.data
	}

	insertSingleAnswerSurveyResponse = async (surveyID,questionID, responseID, responseValue, active, backupID) => {
		const body = {
			surveyID,
			questionID,
			responseID,
			responseValue,
			active,
			backupID
		}

		const result = await  this.authService.post(`/surveyResponse/insert/single`, body)
		return result.data.data
	}

	insertCodebookValueSurveyResponse = async (surveyID, questionID, codebookValue, backupID)=> {
		const body = {
			surveyID,
			questionID,
			codebookValue,
			backupID
		}

		const result = await  this.authService.post(`/surveyResponse/insert/by/codebookvalue`, body)
		return result.data.data
	}

	deactivatePreviousSurveyResponses = async (surveyId,questionId) => {
		const body = {
			surveyId,
			questionId
		}

		const result = await  this.authService.post(`/surveyResponse/deactivate/${questionId}/${surveyId}`, body)
		return result.data.data
	}

	surveyReview = async (surveyID) => {
		const result = await  this.authService.get(`/survey/${surveyID}/${config.instrument}/review`)
		return result.data.data
	}

	setDisplayed = async (questionIDS, surveyID, backupID) => {
		const body = {
			questionIDS,
			surveyID,
			backupID
		}

		const result = await  this.authService.put(`/surveyResponse/update/displayed/`, body)
		return result.data.data
	}
}
const apiService = new ApiService()

export default apiService;
