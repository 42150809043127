import helpers from "../../utils/helpers";
import {db} from "../index";
import BaseTable from "./index";
import config from "../../config";

const currentDateTime = helpers.currentDateTime();
const seedData = [
	{
		ApplicationVariableCodeID: 1,
		Value: helpers.guid(),
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 2,
		Value: "1.0.0",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 3,
		Value: "",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 4,
		Value: "PIN",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 5,
		Value: "0",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 6,
		Value: "ROC_WEST",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 7,
		Value: "",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 8,
		Value: "Yes",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 9,
		Value: "1",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 10,
		Value: "",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 11,
		Value: "0",
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
	{
		ApplicationVariableCodeID: 12,
		Value: config.instrument,
		isVisibleToUser: 1,
		isEditableByUser: 0,
		LastUpdatedDateTimeUTC: currentDateTime,
		lastUpdatedByUserID: 0,
		BackupID: 0,
	},
];
class ApplicationVariable extends BaseTable {
	constructor() {
		super(db.applicationVariable, seedData);
	}

}

const ApplicationVariableTable = new ApplicationVariable();

export default ApplicationVariableTable;
