import { db } from "../index";
import BaseTable from "./index";
const seedData = [
	{
		"UserSchoolClassID": 2,
		"SchoolName": "Harvest Preparatory Academy",
		"Grade": "10th",
		"ClassID": 4782,
		"SchoolID": 6200,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 3,
		"SchoolName": "Harvest Preparatory Academy",
		"Grade": "11th",
		"ClassID": 4783,
		"SchoolID": 6200,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 4,
		"SchoolName": "Harvest Preparatory Academy",
		"Grade": "12th",
		"ClassID": 4784,
		"SchoolID": 6200,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1,
		"SchoolName": "Harvest Preparatory Academy",
		"Grade": "9th",
		"ClassID": 4781,
		"SchoolID": 6200,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 5,
		"SchoolName": "Fourth Avenue Junior High School",
		"Grade": "6th",
		"ClassID": 5338,
		"SchoolID": 6201,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 7,
		"SchoolName": "Fourth Avenue Junior High School",
		"Grade": "7th",
		"ClassID": 5339,
		"SchoolID": 6201,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 9,
		"SchoolName": "Fourth Avenue Junior High School",
		"Grade": "8th",
		"ClassID": 5340,
		"SchoolID": 6201,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 11,
		"SchoolName": "Fourth Avenue Junior High School",
		"Grade": "8th",
		"ClassID": 15603,
		"SchoolID": 6201,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 13,
		"SchoolName": "Gila Vista Junior High School",
		"Grade": "6th",
		"ClassID": 6553,
		"SchoolID": 6202,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 14,
		"SchoolName": "Gila Vista Junior High School",
		"Grade": "7th",
		"ClassID": 6554,
		"SchoolID": 6202,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 15,
		"SchoolName": "Gila Vista Junior High School",
		"Grade": "8th",
		"ClassID": 6555,
		"SchoolID": 6202,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 29,
		"SchoolName": "Manila High School",
		"Grade": "10th",
		"ClassID": 10164,
		"SchoolID": 6205,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 30,
		"SchoolName": "Manila High School",
		"Grade": "10th",
		"ClassID": 10165,
		"SchoolID": 6205,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 31,
		"SchoolName": "Manila High School",
		"Grade": "11th",
		"ClassID": 10166,
		"SchoolID": 6205,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 32,
		"SchoolName": "Manila High School",
		"Grade": "11th",
		"ClassID": 10167,
		"SchoolID": 6205,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 33,
		"SchoolName": "Manila High School",
		"Grade": "12th",
		"ClassID": 10168,
		"SchoolID": 6205,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 34,
		"SchoolName": "Manila High School",
		"Grade": "12th",
		"ClassID": 10169,
		"SchoolID": 6205,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 27,
		"SchoolName": "Manila High School",
		"Grade": "9th",
		"ClassID": 10162,
		"SchoolID": 6205,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 28,
		"SchoolName": "Manila High School",
		"Grade": "9th",
		"ClassID": 10163,
		"SchoolID": 6205,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 35,
		"SchoolName": "Manila Middle School",
		"Grade": "6th",
		"ClassID": 13826,
		"SchoolID": 6206,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 36,
		"SchoolName": "Manila Middle School",
		"Grade": "6th",
		"ClassID": 13827,
		"SchoolID": 6206,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 37,
		"SchoolName": "Manila Middle School",
		"Grade": "7th",
		"ClassID": 13828,
		"SchoolID": 6206,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 38,
		"SchoolName": "Manila Middle School",
		"Grade": "7th",
		"ClassID": 13829,
		"SchoolID": 6206,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 39,
		"SchoolName": "Manila Middle School",
		"Grade": "8th",
		"ClassID": 13830,
		"SchoolID": 6206,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 40,
		"SchoolName": "Manila Middle School",
		"Grade": "8th",
		"ClassID": 13831,
		"SchoolID": 6206,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 252,
		"SchoolName": "St Marys Episcopal Day School",
		"Grade": "6th",
		"ClassID": 14080,
		"SchoolID": 6260,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 261,
		"SchoolName": "St Marys Episcopal Day School",
		"Grade": "6th",
		"ClassID": 14086,
		"SchoolID": 6260,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 264,
		"SchoolName": "St Marys Episcopal Day School",
		"Grade": "7th",
		"ClassID": 14087,
		"SchoolID": 6260,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 255,
		"SchoolName": "St Marys Episcopal Day School",
		"Grade": "7th",
		"ClassID": 14081,
		"SchoolID": 6260,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 258,
		"SchoolName": "St Marys Episcopal Day School",
		"Grade": "8th",
		"ClassID": 14082,
		"SchoolID": 6260,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 267,
		"SchoolName": "St Marys Episcopal Day School",
		"Grade": "8th",
		"ClassID": 14088,
		"SchoolID": 6260,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 500,
		"SchoolName": "Rivermont Collegiate School",
		"Grade": "10th",
		"ClassID": 13076,
		"SchoolID": 6310,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 501,
		"SchoolName": "Rivermont Collegiate School",
		"Grade": "11th",
		"ClassID": 13077,
		"SchoolID": 6310,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 502,
		"SchoolName": "Rivermont Collegiate School",
		"Grade": "12th",
		"ClassID": 13078,
		"SchoolID": 6310,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 499,
		"SchoolName": "Rivermont Collegiate School",
		"Grade": "9th",
		"ClassID": 13075,
		"SchoolID": 6310,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 503,
		"SchoolName": "Riverdale Heights Elementary School",
		"Grade": "6th",
		"ClassID": 8923,
		"SchoolID": 6311,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 512,
		"SchoolName": "Bowling Green Junior High School",
		"Grade": "6th",
		"ClassID": 4959,
		"SchoolID": 6314,
		"UserID": 266,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 514,
		"SchoolName": "Bowling Green Junior High School",
		"Grade": "6th",
		"ClassID": 4960,
		"SchoolID": 6314,
		"UserID": 267,
		"ClassNumber": 2,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 516,
		"SchoolName": "Bowling Green Junior High School",
		"Grade": "7th",
		"ClassID": 4961,
		"SchoolID": 6314,
		"UserID": 268,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 518,
		"SchoolName": "Bowling Green Junior High School",
		"Grade": "7th",
		"ClassID": 4962,
		"SchoolID": 6314,
		"UserID": 269,
		"ClassNumber": 2,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 520,
		"SchoolName": "Bowling Green Junior High School",
		"Grade": "8th",
		"ClassID": 4963,
		"SchoolID": 6314,
		"UserID": 270,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 522,
		"SchoolName": "Bowling Green Junior High School",
		"Grade": "8th",
		"ClassID": 4964,
		"SchoolID": 6314,
		"UserID": 271,
		"ClassNumber": 2,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 756,
		"SchoolName": "St Paul Conservatory for Performing Artists",
		"Grade": "10th",
		"ClassID": 8805,
		"SchoolID": 6348,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 758,
		"SchoolName": "St Paul Conservatory for Performing Artists",
		"Grade": "10th",
		"ClassID": 8806,
		"SchoolID": 6348,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 760,
		"SchoolName": "St Paul Conservatory for Performing Artists",
		"Grade": "11th",
		"ClassID": 8807,
		"SchoolID": 6348,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 762,
		"SchoolName": "St Paul Conservatory for Performing Artists",
		"Grade": "11th",
		"ClassID": 8808,
		"SchoolID": 6348,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 764,
		"SchoolName": "St Paul Conservatory for Performing Artists",
		"Grade": "12th",
		"ClassID": 8809,
		"SchoolID": 6348,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 766,
		"SchoolName": "St Paul Conservatory for Performing Artists",
		"Grade": "12th",
		"ClassID": 8810,
		"SchoolID": 6348,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 752,
		"SchoolName": "St Paul Conservatory for Performing Artists",
		"Grade": "9th",
		"ClassID": 8803,
		"SchoolID": 6348,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 754,
		"SchoolName": "St Paul Conservatory for Performing Artists",
		"Grade": "9th",
		"ClassID": 8804,
		"SchoolID": 6348,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 908,
		"SchoolName": "Christa McAuliffe Middle School",
		"Grade": "6th",
		"ClassID": 8863,
		"SchoolID": 6382,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 910,
		"SchoolName": "Christa McAuliffe Middle School",
		"Grade": "7th",
		"ClassID": 8864,
		"SchoolID": 6382,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 912,
		"SchoolName": "Christa McAuliffe Middle School",
		"Grade": "8th",
		"ClassID": 8865,
		"SchoolID": 6382,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 920,
		"SchoolName": "Livingston High School",
		"Grade": "10th",
		"ClassID": 14475,
		"SchoolID": 6383,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 923,
		"SchoolName": "Livingston High School",
		"Grade": "10th",
		"ClassID": 14476,
		"SchoolID": 6383,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 926,
		"SchoolName": "Livingston High School",
		"Grade": "11th",
		"ClassID": 14477,
		"SchoolID": 6383,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 929,
		"SchoolName": "Livingston High School",
		"Grade": "11th",
		"ClassID": 14478,
		"SchoolID": 6383,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 932,
		"SchoolName": "Livingston High School",
		"Grade": "12th",
		"ClassID": 14479,
		"SchoolID": 6383,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 935,
		"SchoolName": "Livingston High School",
		"Grade": "12th",
		"ClassID": 14480,
		"SchoolID": 6383,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 914,
		"SchoolName": "Livingston High School",
		"Grade": "9th",
		"ClassID": 14473,
		"SchoolID": 6383,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 917,
		"SchoolName": "Livingston High School",
		"Grade": "9th",
		"ClassID": 14474,
		"SchoolID": 6383,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 969,
		"SchoolName": "American History High School",
		"Grade": "10th",
		"ClassID": 14177,
		"SchoolID": 6390,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 971,
		"SchoolName": "American History High School",
		"Grade": "11th",
		"ClassID": 14178,
		"SchoolID": 6390,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 973,
		"SchoolName": "American History High School",
		"Grade": "12th",
		"ClassID": 14179,
		"SchoolID": 6390,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 967,
		"SchoolName": "American History High School",
		"Grade": "9th",
		"ClassID": 14176,
		"SchoolID": 6390,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1004,
		"SchoolName": "Edmund W Miles Middle School",
		"Grade": "8th",
		"ClassID": 10060,
		"SchoolID": 6402,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 1006,
		"SchoolName": "Bay Shore Middle School",
		"Grade": "6th",
		"ClassID": 8961,
		"SchoolID": 6404,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 1007,
		"SchoolName": "Bay Shore Middle School",
		"Grade": "7th",
		"ClassID": 8962,
		"SchoolID": 6404,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 1008,
		"SchoolName": "Bay Shore Middle School",
		"Grade": "8th",
		"ClassID": 8963,
		"SchoolID": 6404,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 1010,
		"SchoolName": "Brentwood High School, Ross Campus",
		"Grade": "10th",
		"ClassID": 11731,
		"SchoolID": 6405,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1011,
		"SchoolName": "Brentwood High School, Ross Campus",
		"Grade": "11th",
		"ClassID": 11732,
		"SchoolID": 6405,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1012,
		"SchoolName": "Brentwood High School, Ross Campus",
		"Grade": "12th",
		"ClassID": 11733,
		"SchoolID": 6405,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1009,
		"SchoolName": "Brentwood High School, Ross Campus",
		"Grade": "9th",
		"ClassID": 11730,
		"SchoolID": 6405,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1013,
		"SchoolName": "Castle Hill Middle School 127",
		"Grade": "6th",
		"ClassID": 11624,
		"SchoolID": 6406,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 1014,
		"SchoolName": "Castle Hill Middle School 127",
		"Grade": "7th",
		"ClassID": 11625,
		"SchoolID": 6406,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 1015,
		"SchoolName": "Castle Hill Middle School 127",
		"Grade": "8th",
		"ClassID": 11626,
		"SchoolID": 6406,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 1023,
		"SchoolName": "James Madison High School",
		"Grade": "10th",
		"ClassID": 11776,
		"SchoolID": 6408,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1025,
		"SchoolName": "James Madison High School",
		"Grade": "10th",
		"ClassID": 11777,
		"SchoolID": 6408,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1027,
		"SchoolName": "James Madison High School",
		"Grade": "11th",
		"ClassID": 11778,
		"SchoolID": 6408,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1029,
		"SchoolName": "James Madison High School",
		"Grade": "11th",
		"ClassID": 11779,
		"SchoolID": 6408,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1031,
		"SchoolName": "James Madison High School",
		"Grade": "12th",
		"ClassID": 11780,
		"SchoolID": 6408,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1033,
		"SchoolName": "James Madison High School",
		"Grade": "12th",
		"ClassID": 11781,
		"SchoolID": 6408,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1019,
		"SchoolName": "James Madison High School",
		"Grade": "9th",
		"ClassID": 11774,
		"SchoolID": 6408,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1021,
		"SchoolName": "James Madison High School",
		"Grade": "9th",
		"ClassID": 11775,
		"SchoolID": 6408,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1037,
		"SchoolName": "The Metropolitan High School",
		"Grade": "10th",
		"ClassID": 11735,
		"SchoolID": 6410,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1039,
		"SchoolName": "The Metropolitan High School",
		"Grade": "11th",
		"ClassID": 11736,
		"SchoolID": 6410,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1041,
		"SchoolName": "The Metropolitan High School",
		"Grade": "12th",
		"ClassID": 11737,
		"SchoolID": 6410,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1035,
		"SchoolName": "The Metropolitan High School",
		"Grade": "9th",
		"ClassID": 11734,
		"SchoolID": 6410,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1043,
		"SchoolName": "William Alexander Middle School 51",
		"Grade": "6th",
		"ClassID": 9853,
		"SchoolID": 6411,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 1045,
		"SchoolName": "William Alexander Middle School 51",
		"Grade": "6th",
		"ClassID": 9854,
		"SchoolID": 6411,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 1047,
		"SchoolName": "William Alexander Middle School 51",
		"Grade": "7th",
		"ClassID": 9855,
		"SchoolID": 6411,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 1049,
		"SchoolName": "William Alexander Middle School 51",
		"Grade": "7th",
		"ClassID": 9856,
		"SchoolID": 6411,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 1051,
		"SchoolName": "William Alexander Middle School 51",
		"Grade": "8th",
		"ClassID": 9857,
		"SchoolID": 6411,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 1053,
		"SchoolName": "William Alexander Middle School 51",
		"Grade": "8th",
		"ClassID": 9858,
		"SchoolID": 6411,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 1066,
		"SchoolName": "Paul J Gelinas Junior High School",
		"Grade": "9th",
		"ClassID": 8611,
		"SchoolID": 6418,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1116,
		"SchoolName": "Ben Franklin Middle School",
		"Grade": "6th",
		"ClassID": 8276,
		"SchoolID": 6432,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 1117,
		"SchoolName": "Ben Franklin Middle School",
		"Grade": "6th",
		"ClassID": 8277,
		"SchoolID": 6432,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 1118,
		"SchoolName": "Ben Franklin Middle School",
		"Grade": "7th",
		"ClassID": 8278,
		"SchoolID": 6432,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 1119,
		"SchoolName": "Ben Franklin Middle School",
		"Grade": "7th",
		"ClassID": 8279,
		"SchoolID": 6432,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 1120,
		"SchoolName": "Ben Franklin Middle School",
		"Grade": "8th",
		"ClassID": 8280,
		"SchoolID": 6432,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 1121,
		"SchoolName": "Ben Franklin Middle School",
		"Grade": "8th",
		"ClassID": 8281,
		"SchoolID": 6432,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 1126,
		"SchoolName": "South High School",
		"Grade": "10th",
		"ClassID": 8447,
		"SchoolID": 6433,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1128,
		"SchoolName": "South High School",
		"Grade": "10th",
		"ClassID": 8448,
		"SchoolID": 6433,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1130,
		"SchoolName": "South High School",
		"Grade": "11th",
		"ClassID": 8449,
		"SchoolID": 6433,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1132,
		"SchoolName": "South High School",
		"Grade": "11th",
		"ClassID": 8450,
		"SchoolID": 6433,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1134,
		"SchoolName": "South High School",
		"Grade": "12th",
		"ClassID": 8451,
		"SchoolID": 6433,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1136,
		"SchoolName": "South High School",
		"Grade": "12th",
		"ClassID": 8452,
		"SchoolID": 6433,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1122,
		"SchoolName": "South High School",
		"Grade": "9th",
		"ClassID": 8445,
		"SchoolID": 6433,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1124,
		"SchoolName": "South High School",
		"Grade": "9th",
		"ClassID": 8446,
		"SchoolID": 6433,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1218,
		"SchoolName": "Chartiers-Houston Junior Senior High School",
		"Grade": "10th",
		"ClassID": 11572,
		"SchoolID": 6448,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1221,
		"SchoolName": "Chartiers-Houston Junior Senior High School",
		"Grade": "10th",
		"ClassID": 11573,
		"SchoolID": 6448,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1224,
		"SchoolName": "Chartiers-Houston Junior Senior High School",
		"Grade": "11th",
		"ClassID": 11574,
		"SchoolID": 6448,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1227,
		"SchoolName": "Chartiers-Houston Junior Senior High School",
		"Grade": "11th",
		"ClassID": 11575,
		"SchoolID": 6448,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1230,
		"SchoolName": "Chartiers-Houston Junior Senior High School",
		"Grade": "12th",
		"ClassID": 11576,
		"SchoolID": 6448,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1233,
		"SchoolName": "Chartiers-Houston Junior Senior High School",
		"Grade": "12th",
		"ClassID": 11577,
		"SchoolID": 6448,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1212,
		"SchoolName": "Chartiers-Houston Junior Senior High School",
		"Grade": "9th",
		"ClassID": 11570,
		"SchoolID": 6448,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1215,
		"SchoolName": "Chartiers-Houston Junior Senior High School",
		"Grade": "9th",
		"ClassID": 11571,
		"SchoolID": 6448,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1262,
		"SchoolName": "Pennsbury High School",
		"Grade": "10th",
		"ClassID": 7017,
		"SchoolID": 6456,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1264,
		"SchoolName": "Pennsbury High School",
		"Grade": "10th",
		"ClassID": 7018,
		"SchoolID": 6456,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1266,
		"SchoolName": "Pennsbury High School",
		"Grade": "11th",
		"ClassID": 7019,
		"SchoolID": 6456,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1268,
		"SchoolName": "Pennsbury High School",
		"Grade": "11th",
		"ClassID": 7020,
		"SchoolID": 6456,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1270,
		"SchoolName": "Pennsbury High School",
		"Grade": "12th",
		"ClassID": 7021,
		"SchoolID": 6456,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1272,
		"SchoolName": "Pennsbury High School",
		"Grade": "12th",
		"ClassID": 7022,
		"SchoolID": 6456,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1258,
		"SchoolName": "Pennsbury High School",
		"Grade": "9th",
		"ClassID": 7015,
		"SchoolID": 6456,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1260,
		"SchoolName": "Pennsbury High School",
		"Grade": "9th",
		"ClassID": 7016,
		"SchoolID": 6456,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1304,
		"SchoolName": "Southwind High School",
		"Grade": "10th",
		"ClassID": 14322,
		"SchoolID": 6467,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1306,
		"SchoolName": "Southwind High School",
		"Grade": "10th",
		"ClassID": 14323,
		"SchoolID": 6467,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1308,
		"SchoolName": "Southwind High School",
		"Grade": "11th",
		"ClassID": 14324,
		"SchoolID": 6467,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1310,
		"SchoolName": "Southwind High School",
		"Grade": "11th",
		"ClassID": 14325,
		"SchoolID": 6467,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1312,
		"SchoolName": "Southwind High School",
		"Grade": "12th",
		"ClassID": 14326,
		"SchoolID": 6467,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1314,
		"SchoolName": "Southwind High School",
		"Grade": "12th",
		"ClassID": 14327,
		"SchoolID": 6467,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1300,
		"SchoolName": "Southwind High School",
		"Grade": "9th",
		"ClassID": 14320,
		"SchoolID": 6467,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1302,
		"SchoolName": "Southwind High School",
		"Grade": "9th",
		"ClassID": 14321,
		"SchoolID": 6467,
		"UserID": 261,
		"ClassNumber": 2,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1368,
		"SchoolName": "Elsie Robertson Lancaster Middle School ",
		"Grade": "7th",
		"ClassID": 12933,
		"SchoolID": 6488,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 1370,
		"SchoolName": "Elsie Robertson Lancaster Middle School ",
		"Grade": "8th",
		"ClassID": 12934,
		"SchoolID": 6488,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 1480,
		"SchoolName": "Inglemoor High School",
		"Grade": "10th",
		"ClassID": 8270,
		"SchoolID": 6524,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 10
	},
	{
		"UserSchoolClassID": 1482,
		"SchoolName": "Inglemoor High School",
		"Grade": "11th",
		"ClassID": 8271,
		"SchoolID": 6524,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 11
	},
	{
		"UserSchoolClassID": 1484,
		"SchoolName": "Inglemoor High School",
		"Grade": "12th",
		"ClassID": 8272,
		"SchoolID": 6524,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 12
	},
	{
		"UserSchoolClassID": 1478,
		"SchoolName": "Inglemoor High School",
		"Grade": "9th",
		"ClassID": 8269,
		"SchoolID": 6524,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	},
	{
		"UserSchoolClassID": 1499,
		"SchoolName": "Kelly Elementary School",
		"Grade": "6th",
		"ClassID": 9360,
		"SchoolID": 8327,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 6
	},
	{
		"UserSchoolClassID": 1501,
		"SchoolName": "Kelly Elementary School",
		"Grade": "7th",
		"ClassID": 9361,
		"SchoolID": 8327,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 7
	},
	{
		"UserSchoolClassID": 1503,
		"SchoolName": "Kelly Elementary School",
		"Grade": "8th",
		"ClassID": 9362,
		"SchoolID": 8327,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 8
	},
	{
		"UserSchoolClassID": 1510,
		"SchoolName": "TJ Otto Emergency Pack",
		"Grade": "9th",
		"ClassID": 11598,
		"SchoolID": 8711,
		"UserID": 261,
		"ClassNumber": 1,
		"GradeNumber": 9
	}
]




class UserSchoolClass extends BaseTable {
	constructor() {
		super(db.userSchoolClass, seedData);
	}
}

const UserSchoolClassTable = new UserSchoolClass();

export default UserSchoolClassTable;
