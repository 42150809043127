import React, {useState} from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import config from '../../../config'
import images from "../../../utils/images";
import useWebAppState from "../../../hooks/useWebAppState";
import Button from "@material-ui/core/Button";
export default function ApplicationBar() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const {
		survey,
		setSurvey,
		logout: stateLogout,
	} = useWebAppState()

	const handleClose = () => {
		setAnchorEl(null);
	};

	const logout = async () => {
		handleClose();
		stateLogout()
	}

	return (
		<AppBar color="secondary" position="sticky">
			<Toolbar>
				{!!survey && <IconButton onClick={handleClick} aria-controls="simple-menu" edge="start" color="inherit" aria-haspopup="true" aria-label="menu">
					<MenuIcon />
				</IconButton>}
				{!!survey && <Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={() => logout()}>logout</MenuItem>
				</Menu>}
				<Typography color="primary"  >
					<b>{config.appTitle}</b>
				</Typography>
				<Typography className="flex-grow" color="primary" variant="caption">({config.appVersion})</Typography>
				<img alt={'cdc logo'} src={images.logo} height="40" width="80" />
				<Typography color="primary" />
			</Toolbar>
		</AppBar>
	)

}


