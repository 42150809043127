import React from "react";
import "./index.css";
import Descriptor from "../Descriptor";

export default function SectionDescriptor({sectionDescriptor,isSection, sectionColor, isHelp,language}) {
	console.log(sectionDescriptor, 'sectoin desxropr',language)

		return (
			<Descriptor sectionColor={sectionColor} isSection={isSection} isHelp={isHelp}
						descriptorId={sectionDescriptor.DescriptorCodeID} value={sectionDescriptor.Text}/>
		)

	return
}
