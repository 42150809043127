import { db } from "../index";
import BaseTable from "./index";

class QuestionSkipRuleValue extends BaseTable {
	constructor() {
		super(db.questionSkipRuleValue);
	}
}

const QuestionSkipRuleValueTable = new QuestionSkipRuleValue();

export default QuestionSkipRuleValueTable;
