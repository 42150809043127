import React, { useEffect, useState } from "react";
import { useFormik } from "formik"; 
import * as yup from "yup";
import Typography from "@material-ui/core/Typography";
import { Button, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import "./index.css";
import databaseService from "../../services/datbaseService";
import helpers from "../../utils/helpers";
import useAppState from "../../hooks/useAppState";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: "20px",
	},

	formContainerBase: {
		flexDirection: "column",
		justifyDontent: "center",
		alignItems: "center",
		display: "flex",
		maxWidth: "90%",
		alignSelf: "center",
		marginTop: "5%",
		marginBottom: "5%",
	},

	formControl: {
		margin: theme.spacing(1),
		width: "70%",
	},

	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const CodesScreen = () => {
	const [schoolClassList, setSchoolClassList] = useState([]);
	const [schoolsList, setSchoolsList] = useState([]);
	const [classList, setClassList] = useState([]);
	const [selectedSchool, setSelectedSchool] = useState(0);
	const [selectedClass, setSelectedClass] = useState(0);
	const [isClassDisabled, setIsClassDisabed] = useState(true);
	const [isGetCountBtnDisabled, setGetCountBtnDisabled] = useState(true);
	const [noOfCodesResultText, setNoOfCodesResultText] = useState("");
	const [accessCodeExistsResultText, setAccessCodeExistsResultText] = useState("");
	const {
		user
	} = useAppState();
	const classes = useStyles();
	useEffect(() => {
		async function getSchoolClass () {

			let schoolClassArr = await databaseService.getSchoolClassByUserID(user);
			setSchoolClassList(schoolClassArr);

			const filteredArr = schoolClassArr.map((item) => ({ schoolID: item.SchoolID, schoolName: item.SchoolName }));
			setSchoolsList([...new Map(filteredArr.map((item) => [item["schoolID"], item])).values()]);
		}
		
		getSchoolClass();

	}, []);

	const handleChangeSchoolDropdown = (event) => {
		setNoOfCodesResultText("");	
		const selectedSchoolID = event.target.value;
		setSelectedSchool(selectedSchoolID);
		
		let classListArr = schoolClassList
			.filter((el) => el.SchoolID === selectedSchoolID)
			.map((item) => ({ classID: item.ClassID, className: `Grade: ${item.Grade} Class: ${item.ClassNumber}` }));
		
		setClassList(classListArr);
		setIsClassDisabed(false);
		setGetCountBtnDisabled(true);
	};

	const handleChangeClassDropdown = (event) => {
		setSelectedClass(event.target.value);
		setGetCountBtnDisabled(false);
	};

	const getAccessCodesCount = async () => {
		const studentLoginArr = await databaseService.getStudentLoginsBySchoolIDClassID(selectedSchool, selectedClass);
		setNoOfCodesResultText(`The number of access codes for this school and class are : ${studentLoginArr.length}`);
	};

	const validationSchema = yup.object({
		accessCode: yup.string("Enter your accessCode")
			.required("Access code is required")
			.min(5, "Must be exactly 5 digits")
			.max(5, "Must be exactly 5 digits"),
	});

	const checkPinLogin = async (values) => {
		const hashedPIN = helpers.createHash(values.accessCode);
		if (await databaseService.checkIfHashedAccessCodeExists(hashedPIN)) {
			setAccessCodeExistsResultText(`The access code ${values.accessCode} exists in the tablet`);
		} else {
			setAccessCodeExistsResultText(`The access code ${values.accessCode} does not exist in the tablet`);
		}
	};

	const formik = useFormik({
		initialValues: {
			accessCode: ""
		},
		validationSchema: validationSchema,
		onSubmit: checkPinLogin,
	});


	return (
		<div className="screen-container align-center">
			<div className={classes.formContainerBase}>
				<Typography gutterBottom className=" align-self-center" color="primary" variant="h4">
					Access Codes
				</Typography>
				<Typography className=" align-self-center" color="primary">
					Select the school/class name below to view the number of access codes.
				</Typography>

				<FormControl className={classes.formControl}>
					<InputLabel>Select a school</InputLabel>
					<Select id="schoolDropdown" value={selectedSchool} onChange={handleChangeSchoolDropdown} displayEmpty>
						{schoolsList.map((el) => (
							<MenuItem key={el.schoolID} value={el.schoolID}>
								{el.schoolName}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className={classes.formControl}>
					<InputLabel>Select a class</InputLabel>
					<Select id="classDropdown" value={selectedClass} onChange={handleChangeClassDropdown} displayEmpty disabled={isClassDisabled}>
						{classList.map((el) => (
							<MenuItem key={el.classID} value={el.classID}>
								{el.className}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Button
					id="getCountButton"
					classes={{ root: classes.root }}
					className="margin-above-double"
					color="primary"
					variant="contained"
					fullWidth
					disabled={isGetCountBtnDisabled}
					onClick={getAccessCodesCount}
				>
					Get Count
				</Button>
				<Typography className=" align-self-center margin-above-double" fullWidth color="primary" variant="h6">
					{noOfCodesResultText}
				</Typography>
			</div>
			<div className={classes.formContainerBase}>
				<Typography gutterBottom className=" align-self-center" color="primary" variant="h4">
					Find Access Codes
				</Typography>
				<Typography className=" align-self-center" color="primary">
					Enter the access code below to check if it exists in the tablet
				</Typography>
				<form onSubmit={formik.handleSubmit} className="margin-above-double">
					<TextField
						fullWidth
						id="accessCode"
						name="accessCode"
						label="5 character Access Code"
						value={formik.values.accessCode}
						onChange={formik.handleChange}
						error={formik.touched.accessCode && Boolean(formik.errors.accessCode)}
						helperText={formik.touched.accessCode && formik.errors.accessCode}
					/>
					<Button
						classes={{ root: classes.root }}
						className="margin-above-double"
						color="primary"
						variant="contained"
						fullWidth
						type="submit"
					>
						Check Access Code
					</Button>
				</form>
				<Typography className=" align-self-center" color="primary" variant="h6">
					{accessCodeExistsResultText}
				</Typography>
			</div>
		</div>
	);
};

export default CodesScreen;
