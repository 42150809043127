import React from 'react';
import logo from '../logo.svg';
import './App.css';
import {Container, createMuiTheme, MuiThemeProvider, Paper, Typography} from "@material-ui/core";
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

import CssBaseline from '@material-ui/core/CssBaseline';
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'

import ReactPWAInstallProvider from "react-pwa-install";
import config from '../config'
import {AppStateProvider} from "../hooks/useAppState";
import theme from '../config/theme'
import ApplicationRouter, {WebRouter} from "../router";
import {DatabaseProvider} from "../hooks/useDatabase";
import {ToastContainer} from "react-toastify"
import {WebAppProvider} from "../hooks/useWebAppState";

const queryClient = new QueryClient();

function TabletAppWithProvider() {
  return (
      <MuiThemeProvider theme={theme}>
          {serviceWorkerRegistration.register()}
          <>
              <CssBaseline />
              <ReactPWAInstallProvider>
                  <QueryClientProvider client={queryClient}>
                      <DatabaseProvider>
                          <ToastContainer />
                          <AppStateProvider config={config}>
                              <Container component={Paper} disableGutters className="app-container flex-container flex-column align-center"  maxWidth={'lg'}>
                                  <ApplicationRouter />
                              </Container>
                          </AppStateProvider>
                      </DatabaseProvider>
                  </QueryClientProvider>
              </ReactPWAInstallProvider>
          </>
      </MuiThemeProvider>
  )
}


function WebAppWithProvider() {
    return (
        <>
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <ToastContainer/>
                <WebAppProvider config={config}>
                    <Container component={Paper} disableGutters className="app-container flex-container flex-column align-center"  maxWidth={'lg'}>
                        <WebRouter />
                    </Container>
                </WebAppProvider>
            </MuiThemeProvider>
        </>
    )
}

console.log(process.env.REACT_APP_IS_WEB === 'true')

const App = process.env.REACT_APP_IS_WEB === 'true' ? WebAppWithProvider : TabletAppWithProvider
export default App