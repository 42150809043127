import React, {useState} from "react";
import "./index.css"
import myLogo from "../../logo.svg"
import  { useReactPWAInstall } from "react-pwa-install";
import Button from "@material-ui/core/Button";
import images from "../../utils/images";
import Typography from "@material-ui/core/Typography";
import useAppState from "../../hooks/useAppState";


export function PWAInstall({title, description, children}) {
	const { pwaInstall, supported } = useReactPWAInstall();
	const handleClick = () => {
		pwaInstall({
			title: `Install ${title} Application`,
			logo: myLogo,
			description,
		})
			.then(() => {
				alert("App installed successfully. Please check your home screen or where your devices stores applications")
			})
			.catch(() => alert("App was not installed. Please refresh the page and try again"));
	};

	return (
		<div className="full-width full-height flex-column align-center justify-center install-container">
			<Typography gutterBottom variant="h6" color="primary">Installation Page for {title}</Typography>
			<img src={images.logo} />
			<div className="margin-above-double margin-below-double"/>
			<Button variant="contained" color="primary"  onClick={handleClick}>
				Click here to install
			</Button>
		</div>
	);
}

export default PWAInstall
