import { db } from "../index";
import BaseTable from "./index";

class QuestionSkipRule extends BaseTable {
	constructor() {
		super(db.questionSkipRule);
	}
}

const QuestionSkipRuleTable = new QuestionSkipRule();

export default QuestionSkipRuleTable;
