import { db } from "../index";
import BaseTable from "./index";

// add seed data
const seedData = [
	{
		ApplicationVariableCodeID: 1,
		Description: "Application GUID",
		DefaultValue: "",
		PossibleValues: "",
	},
	{
		ApplicationVariableCodeID: 2,
		Description: "Application Version",
		DefaultValue: "",
		PossibleValues: "",
	},
	{
		ApplicationVariableCodeID: 3,
		Description: "Applied Payload Version",
		DefaultValue: "",
		PossibleValues: "",
	},
	{
		ApplicationVariableCodeID: 4,
		Description: "Authentication Mode",
		DefaultValue: "PIN",
		PossibleValues: "PIN|PORTAL_CREDENTIALS|NONE",
	},
	{
		ApplicationVariableCodeID: 5,
		Description: "Default User",
		DefaultValue: "",
		PossibleValues: "",
	},
	{
		ApplicationVariableCodeID: 6,
		Description: "Default ROC",
		DefaultValue: "ROC_WEST",
		PossibleValues: "ROC_WEST|ROC_EAST|ROC_CENTRAL|ROC_SOUTH",
	},
	{
		ApplicationVariableCodeID: 7,
		Description: "Activation Date",
		DefaultValue: "",
		PossibleValues: "",
	},
	{
		ApplicationVariableCodeID: 8,
		Description: "Locked",
		DefaultValue: "YES",
		PossibleValues: "YES|NO",
	},
	{
		ApplicationVariableCodeID: 9,
		Description: "Reset Data Increment",
		DefaultValue: "",
		PossibleValues: "",
	},
	{
		ApplicationVariableCodeID: 10,
		Description: "Last Application Session Value",
		DefaultValue: "",
		PossibleValues: "",
	},
	{
		ApplicationVariableCodeID: 11,
		Description: "Local Datetime offset",
		DefaultValue: "",
		PossibleValues: "",
	},
	{
		ApplicationVariableCodeID: 12,
		Description: "Instrument Selected",
		DefaultValue: "",
		PossibleValues: "",
	},
];

class ApplicationVariableCode extends BaseTable {
	constructor() {
		super(db.applicationVariableCode, seedData);
	}
}

const ApplicationVariableCodeTable = new ApplicationVariableCode();

export default ApplicationVariableCodeTable;
