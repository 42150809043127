import React from "react";
import "./index.css";
import Descriptor from "../Descriptor";

export default function QuestionDescriptor({descriptor}) {

	return (
		<Descriptor descriptorId={descriptor.DescriptorCodeID} value={descriptor.Text}/>
	)
}
