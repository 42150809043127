import { db } from "../index";
import BaseTable from "./index";

class QuestionSkip extends BaseTable {
	constructor() {
		super(db.questionSkip);
	}
}

const QuestionSkipTable = new QuestionSkip();

export default QuestionSkipTable;
