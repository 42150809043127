import { db } from "../index";
import BaseTable from "./index";

class ResponseDescriptor extends BaseTable {
	constructor() {
		super(db.responseDescriptor);
	}
}

const ResponseDescriptorTable = new ResponseDescriptor();

export default ResponseDescriptorTable;
