import { db } from "../index";
import BaseTable from "./index";

const seedData = [
	{
		DescriptorCodeID: 1,
		Description: "Introduction",
	},
	{
		DescriptorCodeID: 2,
		Description: "Label",
	},
	{
		DescriptorCodeID: 3,
		Description: "Name/Text/Content",
	},
	{
		DescriptorCodeID: 4,
		Description: "Help Text",
	},
	{
		DescriptorCodeID: 5,
		Description: "Notes",
	},
	{
		DescriptorCodeID: 6,
		Description: "Images",
	},
];

class DescriptorCode extends BaseTable {
	constructor() {
		super(db.descriptorCode, seedData);
	}
}

const DescriptorCodeTable = new DescriptorCode();

export default DescriptorCodeTable;
