import helpers from '../../utils/helpers';

const parser = require('fast-xml-parser')

const databaseMapper = {
	mapSurveyMetaData: async (xmlString) => {
		const xmlJSONObj = parser.parse(xmlString);
		console.log(xmlJSONObj, "xmlObj");
		// TODO parse and map the metadata to their own arrays and return in an Object {Instrument, Section, SectionDescriptor, Question, QuestionDescriptor}
		let surveyMetadata = [];
		// refactor ???
		if (xmlJSONObj && xmlJSONObj.SurveyMetadata && xmlJSONObj.SurveyMetadata.Metadata) {
			surveyMetadata = await helpers.parseSurveyMetaData(xmlJSONObj.SurveyMetadata.Metadata.Instruments.Instrument);
		}
		console.log(surveyMetadata, "SurveyScreen MetaData in object");
		return surveyMetadata;
		//return xmlJSONObj;
	},

	toStudentLogin: (attrs) => {
		return {
			studentid: attrs.StudentLoginID,
			classid: attrs.ClassID,
			schoolid: attrs.SchoolID,
			studentpin: attrs.StudentPIN,
			StudentLoginID: attrs.StudentLoginID,
		};
	},

	addSectionID: (attrs, SectionID) => {
		return {
			...attrs,
			SectionID: SectionID,
		};
	},
	
	addQuestionID: (attrs, QuestionID) => {
		return {
			...attrs,
			QuestionID: QuestionID,
		};
	},

	addResponseID: (attrs, ResponseID) => {
		return {
			...attrs,
			ResponseID: ResponseID,
		};
	},
	toUserSchoolClass: (userSchoolClass) => {
		return  {
			ClassID: userSchoolClass.Class_id,
			Grade: userSchoolClass.Grade,
			GradeNumber: userSchoolClass.GradeNumber,
			SchoolID: userSchoolClass.School_id,
			SchoolName: userSchoolClass.School_name,
			UserSchoolClassID: userSchoolClass.UserSchoolClassID,
			UserID: parseInt(userSchoolClass.DataCollectorTeamID)

		}
	}
};


export default databaseMapper
