// Synced on install to Device
export const applicationLevelSchema = {
	applicationVariable: "&ApplicationVariableCodeID",
	applicationVariableCode: "&ApplicationVariableCodeID",
	instrument: "&InstrumentID,SiteTypeCodeID,InstrumentTypeCodeID",
	instrumentTypeCode: "&InstrumentTypeCodeID",
	site: "&SiteID,SiteTypeCodeID",
	siteTypeCode: "&SiteTypeCodeID"
};
// Synced when Data Collector user logs in
export const userLevelSchema = {
	user: "&UserID, HashedPIN, Username ",
	userSchoolClass: "&UserSchoolClassId++,SchoolID,ClassID,UserID, [SchoolID+ClassID+UserID]",
	studentLogin: "&StudentLoginID,SchoolID,ClassID,studentpin",
};

// Synced when SurveyScreen Response Data is uploaded
export const surveyResponseSchema = {
	backup: "&BackupID, SyncSuccess",
	descriptorCode: "&DescriptorCodeID",
	inputTypeCode: "&InputTypeCodeID",
	languageCode: "&LanguageCodeID",
	payLoadData: "&PayLoadID",
	question: "&QuestionID,SectionID,PageID,Active",
	questionDescriptor: "&QuestionDescriptorID,QuestionID,LanguageCodeID,Active",
	questionHide: "&QuestionHideID, School_id",
	questionSkip: "&QuestionSkipID,QuestionID",
	questionSkipRule: "&QuestionSkipRuleID",
	questionSkipRuleValue: "&QuestionSkipRuleValueID, QuestionSkipRule",
	response: "&ResponseID,QuestionID,InputTypeCodeID,CodebookValue,Active",
	responseDescriptor: "&ResponseDescriptorID,ResponseID,LanguageCodeID",
	section: "&SectionID,InstrumentID,Active",
	sectionDescriptor: "&SectionDescriptorID,SectionID,LanguageCodeID,Active",
	skipCheckTypeCode: "&SkipCheckTypeCodeID",
	survey: "&SurveyID++,UserID,StudentLoginID,SiteID,InstrumentID,IsActive",
	surveyArchive: "&SurveyID++,UserID,StudentLoginID,SiteID,InstrumentID,IsActive",
	surveyLog: "&SurveyLogID,SurveyID,SurveyLogCodeID",
	surveyLogCode: "&SurveyLogCodeID",
	surveyResponse: "&SurveyResponseID++,SurveyID,QuestionID,ResponseID,Active, [QuestionID+SurveyID+Active]",
	surveyResponseArchive: "&SurveyResponseID++,SurveyID,QuestionID,ResponseID,Active",

};

export default  {
	...applicationLevelSchema,
	...userLevelSchema,
	...surveyResponseSchema
}


