import { db } from "../index";
import BaseTable from "./index";

class QuestionHide extends BaseTable {
	constructor() {
		super(db.questionHide);
	}
}

const QuestionHideTable = new QuestionHide();

export default QuestionHideTable;
