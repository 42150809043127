import axios, {AxiosInstance} from "axios";
import appConfig from "../config";
const headers = {
    'Content-type': 'application/json'
}

class BaseService {
    constructor(baseUrl) {
        this.baseUrl = process.env.REACT_APP_BASE_API_URL || baseUrl || 'http:localhost:5000'

        this.authService = axios.create({
            baseURL: `${this.baseUrl}`,
            headers,
            timeout: appConfig.requestTimeout
        })

        this.baseService =  axios.create({
            baseURL:`${this.baseUrl}`,
            headers,
            timeout: appConfig.requestTimeout
        })

        this.authService.interceptors.request.use( async function(config) {
            const token = window.localStorage.getItem(appConfig.jwtKey)
            const headers = {
                ...config.headers,
                'Authorization': `Bearer ${JSON.parse(token)}`
            }
            config.headers = headers
            return config
        }, function (error) {
            return Promise.reject(error)
        })
    }
}

export default BaseService
