import { db } from "../index";
import BaseTable from "./index";

const seedData = [
	{
		LanguageCodeID: 1,
		LanguageISOCode: "ENU",
		Description: "English - United States",
	},
	{
		LanguageCodeID: 2,
		LanguageISOCode: "ESM",
		Description: "Spanish - Mexico",
	},
];

class LanguageCode extends BaseTable {
	constructor() {
		super(db.languageCode, seedData);
	}
}

const LanguageCodeTable = new LanguageCode();

export default LanguageCodeTable;
