import { db } from "../index";
import BaseTable from "./index";

// removed unwanted record and modified existing app's metadata ...
// the values below have been gotten by going through the existing app's logic
const seedData = [
	{
		InputTypeCodeID: 1,
		Description: "Select Unique or Multiple",
	},
	{
		InputTypeCodeID: 2,
		Description: "Textbox",
	},
	{
		InputTypeCodeID: 3,
		Description: "Input Weight",
	},
	{
		InputTypeCodeID: 4,
		Description: "Input Height",
	},
	{
		InputTypeCodeID: 8,
		Description: "TextBox - Range(0-30)",
	},
	{
		InputTypeCodeID: 9,
		Description: "TextBox - Range(1-30)",
	},
];

class InputTypeCode extends BaseTable {
	constructor() {
		super(db.inputTypeCode, seedData);
	}
}

const InputTypeCodeTable = new InputTypeCode();

export default InputTypeCodeTable;
