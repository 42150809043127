import { db } from "../index";
import BaseTable from "./index";

const seedData = [
	{
		SkipCheckTypeCodeID: 1,
		Description: "Condition - Check before Question display",
	},
	{
		SkipCheckTypeCodeID: 2,
		Description: "Skip - Check after Question answered",
	},
];

class SkipCheckTypeCode extends BaseTable {
	constructor() {
		super(db.skipCheckTypeCode, seedData);
	}
}

const SkipCheckTypeCodeTable = new SkipCheckTypeCode();

export default SkipCheckTypeCodeTable;
