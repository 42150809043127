import Dexie from 'dexie';
import config from '../config'
import schema from "./schema";

const databaseName = `${config.appTitle}_DB`
const db = new Dexie(databaseName);
db.version(config.dbVersion).stores(schema);


class Database {
	constructor() {
		this.db = db;
		this.name = databaseName;
	}

	async init() {
		return this.db.open();
	}

	async exists() {
		const dbCheck = await Dexie.exists(this.name);
		if(dbCheck) {
			const tableCheck = await this.db.applicationVariable.get(1)
			return !!(tableCheck && tableCheck.Value && tableCheck.Value !== "");
		} else {
			return false;
		}
	}
}


export { db }

const DatabaseService = new Database();

export default DatabaseService
