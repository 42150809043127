import { db } from "../index";
import BaseTable from "./index";

class Response extends BaseTable {
	constructor() {
		super(db.response);
	}
}

const ResponseTable = new Response();

export default ResponseTable;
