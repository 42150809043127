class BaseTable {
	constructor(table, seedData, primaryKey) {
		this.table = table;
		this.seedData = seedData;
		this.primaryKey = primaryKey;
	}
	/**
	 *
	 * Returns collection that allows for a more complex and chained query interface.
	 * see here https://dexie.org/docs/Collection/Collection for more info
	 * */

	toCollection() {
		return this.table.toCollection();
	}

	/***
	 *attrs(key(columns), value(row value) pairs to be stored in row of table) Must include primary key
	 * Creates a row in the table
	 *
	 */

	async add(attrs) {
		return this.table.add(attrs);
	}

	async updateBulk(arr, drop=false) {
		if(drop) {
			await this.deleteAll();
		}
		return this.table.bulkPut(arr, {allKeys: true})
	}

	async addBulk(arr) {
		return this.table.bulkAdd(arr, { allKeys: true });
	}

	/**
	 * attrs(key(columns), value(row value) pairs to be stored in row of table). Must include Primary key
	 *  see https://dexie.org/docs/Table/Table.put() for more details
	 *
	 * */
	async save(attrs) {
		return this.table.put(attrs);
	}

	/**
	 * attrs(primary key or given criteria to match). Will return first result that matches given criteria
	 * see  https://dexie.org/docs/Table/Table.get() for more details
	 * */
	async getByAttrs(attrs) {
		return this.table.get(attrs);
	}

	/***
	 *
	 * Get all rows function.
	 * See here https://dexie.org/docs/Collection/Collection.toArray() more info.
	 *
	 */
	async getAll() {
		const collection = this.toCollection();
		return collection.toArray();
	}

	/**
	 * Deletes a singe row by primary key.
	 * See here https://dexie.org/docs/Table/Table.delete() for more info.
	 *
	 * */
	async deleteByPK(primaryKey) {
		return this.table.delete(primaryKey);
	}

	/**
	 * Delete all rows.
	 * see https://dexie.org/docs/Collection/Collection.delete() for more info
	 *
	 * */
	async deleteAll() {
		const collection = this.table.toCollection();
		return collection.delete();
	}

	async seedTable() {

		const count = await this.table.count();
		if(count) {
			await this.deleteAll();
		}
		return this.updateBulk(this.seedData);
	}
}

export default BaseTable;
