import { db } from "../index";
import BaseTable from "./index";

class SurveyResponseArchive extends BaseTable {
	constructor() {
		super(db.surveyResponseArchive);
	}
}

const SurveyResponseArchiveTable = new SurveyResponseArchive();

export default SurveyResponseArchiveTable;
