import { db } from "../index";
import BaseTable from "./index";

class Backup extends BaseTable {
	constructor() {
		super(db.backup);
	}
}

const BackupTable = new Backup();

export default BackupTable;
