import React, {useState} from "react";
import "./index.css";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import images from "../../utils/images";
import helpers from "../../utils/helpers";

export default function Descriptor({descriptorId, value, sectionColor, isSection, isHelp}) {
	if(isSection) {
		console.log(descriptorId, value, 'section values');
	}
	const sectColor = sectionColor ? sectionColor : 'pink';

	const Introduction = () => (
		<div className={`full-width introduction-container introduction-container-background-${sectColor}`}>
			<Typography variant={isSection ? 'h4': 'body1'}>{!isSection ? <b><div dangerouslySetInnerHTML={{ __html: value }} /></b>: <div dangerouslySetInnerHTML={{ __html: value }} />}</Typography>
		</div>
	)

	const Label = () => (
		<Typography>
			<b><div dangerouslySetInnerHTML={{ __html: value }} /></b>
		</Typography>
	)

	const Text = () => (
		<Typography>
			<div dangerouslySetInnerHTML={{ __html: value }} />
		</Typography>
	);
	const Help = () => {
		const [open, setOpen] = useState(false);

		return (
			<div className="full-width introduction-container flex-column align-center">
				{!isSection &&
				<>
					<Button fullWidth  variant="outlined" color="primary" onClick={() => setOpen(!open)}>Click to {open ? 'hide': 'show'} Section Image</Button>
					<div className="margin-below-double"/>
				</>
				}
				{(open || isSection) && <img className="margin-below-double margin-above-double" src={images[value]} />}
			</div>
		)
	}

	const DescriptorImage = () => {
		if(isHelp){
			return <Help />
		} else {
			return (<img alt={helpers.getImageAltText(value)} src={images[value]} />)
		}
	}
	switch (descriptorId) {
		case 1:
			return <Introduction/>;
		case 2:
			return <Label/>;
		case 3:
			return <Text/>;
		case 4:
			return<></>;
		case 6:
			return <DescriptorImage />
		default:
			return <Text/>
	}
}
