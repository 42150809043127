import { db } from "../index";
import BaseTable from "./index";

class Instrument extends BaseTable {
	constructor() {
		super(db.instrument);
	}
}

const InstrumentTable = new Instrument();

export default InstrumentTable;
