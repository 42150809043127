import { db } from "../index";
import BaseTable from "./index";

class Survey extends BaseTable {
	constructor() {
		super(db.survey);
	}
}

const SurveyTable = new Survey();

export default SurveyTable;
