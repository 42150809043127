import { db } from "../index";
import BaseTable from "./index";

class User extends BaseTable {
	constructor() {
		super(db.user);
	}
}

const UserTable = new User();

export default UserTable;
