import { db } from "../index";
import BaseTable from "./index";

class QuestionDescriptor extends BaseTable {
	constructor() {
		super(db.questionDescriptor);
	}
}

const QuestionDescriptorTable = new QuestionDescriptor();

export default QuestionDescriptorTable;
