import React, { useState, useEffect } from "react";
import { Button, List, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import strings from "../../config/strings";
import useAppState from "../../hooks/useAppState";
import databaseService from "../../services/datbaseService";
import "./index.css";
import apiService from "../../services/apiService";
import images from "../../utils/images";
import {useTranslation} from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LoadingIndicator from "../../components/LoadingIndicator";
import Message from "../../components/Message";
import helpers from "../../utils/helpers";
const SurveyReviewScreen = ({language,setLanguage, surveyId ,handleLanguage, reset, navigateTo,setIsSurveyReview,setIsReview, completeSurvey, isWeb, skipRules, hideQuestions, checkSkip}) => {
	const { instrument } = useAppState();
	const [unansweredQuestions, setUnansweredQuestions] = useState(undefined);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const {t,i18n} = useTranslation();
	const firstQuestion = {
		PageID:2
	}
	const submitSurvey = async () => {
		await  completeSurvey();
		setIsSubmitted(true);
	}
	const useStyles = makeStyles(() => ({
		question_list: {
			width: "100%",
			maxWidth: 360,
			backgroundColor: "#f9f9f9",
		},
		submitButton: {
			marginTop: "10px",
			marginBottom: "20px",
		},
	}));
	const classes = useStyles();
	const handleClick = ((el) => {
		console.log(el);
		navigateTo(el);
	});
	// const checkSkip = async (question, skipRules, surveyID, forward) => {
	// 	const rules = skipRules.filter(sr => sr.rule.CheckForwardSkip === question.QuestionID || question.QuestionID === sr.rule.CheckBackwardSkip)
	// 	if(!rules || !rules.length) return question
	//
	//
	// 	let isValid = false
	// 	let rule;
	// 	for(let j = 0; j < rules.length; j++) {
	// 		const r = rules[j];
	// 		for(let i=0; i < r.values.length; i++) {
	// 			const value = r.values[i]
	// 			const activeResponse = await databaseService.getActiveSurveyResponse(surveyID,value.QuestionID)
	//
	// 			if(activeResponse[0].ResponseID === value.ResponseID) {
	// 				isValid = true
	// 				rule = r;
	// 				break;
	// 			}
	// 		}
	// 	}
	//
	//
	// 	if(!isValid || ! rule) return question
	//
	// 	const questionID = forward ? rule.rule.ForwardSkipQuestion : rule.rule.BackwardSkipQuestion
	// 	return databaseService.getQuestionByQuestionId(questionID)
	//
	// }
	const fetchUnansweredQuestions = async () => {
		try {
			setIsLoading(true)
			setIsError(false)
			let unansweredQuestionsArr = []
			if(isWeb) {
				unansweredQuestionsArr = await apiService.surveyReview(surveyId)
				if(hideQuestions && hideQuestions.length) {
					unansweredQuestionsArr = unansweredQuestionsArr.filter(q => !hideQuestions.find(hq => hq.QuestionID === q.QuestionID))
				}
				if(unansweredQuestionsArr.length>0){
					if(skipRules && skipRules.length) {
						const skipQuestionIDs = [];
						for(let i =0; i < skipRules.length; i++) {
							const sr = skipRules[i]
							const question = await apiService.getQuestionById(sr.rule.CheckForwardSkip)
							const skipQuestion = await checkSkip(question, skipRules, surveyId, true)
							if(question.QuestionID !== skipQuestion.QuestionID) {
								if(sr.rule.CheckForwardSkip === sr.rule.CheckBackwardSkip) {
									skipQuestionIDs.push(sr.rule.CheckForwardSkip)
								} else {
									const firstSkipQuestion = await apiService.getQuestionById(sr.rule.CheckForwardSkip)
									const firstPageID = firstSkipQuestion.PageID
									const lastQuestion = await apiService.getQuestionById(sr.rule.CheckBackwardSkip)
									const lastPageID = lastQuestion.PageID

									for(let j = firstPageID; j <= lastPageID; j++ ) {
										const q = await apiService.getQuestionsByPageID(j)
										skipQuestionIDs.push(q[0].QuestionID)
									}
								}
							}
						}
						if(skipQuestionIDs.length) {
							unansweredQuestionsArr = unansweredQuestionsArr.filter(q => skipQuestionIDs.indexOf(q.QuestionID) === -1)
						}
					}
				}
			} else  {
				unansweredQuestionsArr = await databaseService.getUnansweredQuestionsNonSkip(instrument, surveyId);
				if(hideQuestions && hideQuestions.length) {
					unansweredQuestionsArr = unansweredQuestionsArr.filter(q => !hideQuestions.find(hq => hq.QuestionID === q.QuestionID))
				}

				if(unansweredQuestionsArr.length>0){
					if(skipRules && skipRules.length) {
						const skipQuestionIDs = [];
						for(let i =0; i < skipRules.length; i++) {
							const sr = skipRules[i]
							const question = await databaseService.getQuestionByQuestionId(sr.rule.CheckForwardSkip)
							const skipQuestion = await checkSkip(question, skipRules, surveyId, true)
							if(question.QuestionID !== skipQuestion.QuestionID) {
								if(sr.rule.CheckForwardSkip === sr.rule.CheckBackwardSkip) {
									skipQuestionIDs.push(sr.rule.CheckForwardSkip)
								} else {
									const firstSkipQuestion = await databaseService.getQuestionByQuestionId(sr.rule.CheckForwardSkip)
									const firstPageID = firstSkipQuestion.PageID
									const lastQuestion = await databaseService.getQuestionByQuestionId(sr.rule.CheckBackwardSkip)
									const lastPageID = lastQuestion.PageID

									for(let j = firstPageID; j <= lastPageID; j++ ) {
										const q = await databaseService.getQuestionsByPageID(j)
										console.log(q[0].QuestionID)
										skipQuestionIDs.push(q[0].QuestionID)
									}
								}
							}
						}
						if(skipQuestionIDs.length) {
							unansweredQuestionsArr = unansweredQuestionsArr.filter(q => skipQuestionIDs.indexOf(q.QuestionID) === -1)
						}
					}
				}
			}
			setUnansweredQuestions(unansweredQuestionsArr);
			setIsLoading(false)
		} catch (err) {
			setIsError(true)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchUnansweredQuestions();
	}, []);

	let unansweredQuestionsList;

	if(!!unansweredQuestions && !!unansweredQuestions.length){
		unansweredQuestionsList = (
			<>
				<Typography gutterBottom className=" align-self-center" color="error" variant="h6">
					{t('ReviewScreenWarningText')}
				</Typography>
				<Typography gutterBottom className=" align-self-center" color="primary">
					{t('ReviewScreenInstructionText')}
				</Typography>
				<div className={classes.question_list}>
					<List component="nav">
						{unansweredQuestions.map((el) => (
							<ListItem value={`Navigate to ${t('Question')} ${el.questionLabel} Button `} button onClick={()=> handleClick(el)}>
								<ListItemText primary={`${t('Question')} ${el.questionLabel}`} />
								<ErrorIcon />
							</ListItem>
						))}
					</List>
				</div>
			</>
		);
	}

	if(isLoading) {
		return (
			<div className="screen-container full-width full-height">
				<LoadingIndicator
				text={`${t('LoadingText')}`}/>
			</div>
		)
	}

	if(isError) {
		return (
			<div className="screen-container full-height full-height">
				<Message title={'Review Screen Error'} error text={'There was an error loading the review screen'} action={() => fetchUnansweredQuestions} actionText={'Reload'}/>
			</div>
		)
	}

	if(isSubmitted) {
		return (
			<div className="screen-container align-center">
				<div className="align-self-end">
					<ToggleButtonGroup
						value={language}
						exclusive
						onChange={handleLanguage}
						aria-label="text alignment"
					>
						<ToggleButton value="English" aria-label="Select English Language Toggle Button" disabled={language==="English"}>
							<Typography variant="h5">{t('EnglishButton')}</Typography>
						</ToggleButton>
						<ToggleButton value="Spanish" aria-label="Select Spanish Language Toggle Button" disabled={language==="Spanish"}>
							<Typography variant="h5">{t('SpanishButton')}</Typography>
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<div  className="form-container-base-reviewScreen">
					<Typography gutterBottom>{t('SurveySubmittedText')}</Typography>
					<img alt={helpers.getImageAltText('yrbs_logo')} src={images.yrbs_logo} height="200" width="400"/>
					<Button value={'Navigate to Access Code Login Button'} className={classes.submitButton} onClick={()=>reset()} color="primary" variant="contained" fullWidth>
						{t('LoginButton')}
					</Button>
				</div>
			</div>
		)
	}

	return (
		<div className="screen-container align-center">
				<div className="align-self-end">
					<ToggleButtonGroup
						value={language}
						exclusive
						onChange={handleLanguage}
						aria-label="text alignment"
					>
						<ToggleButton value="English" aria-label="Select English Language Toggle Button" disabled={language==="English"}>
							<Typography variant="h5">{t('EnglishButton')}</Typography>
						</ToggleButton>
						<ToggleButton value="Spanish" aria-label="Select Spanish Language Toggle Button" disabled={language==="Spanish"}>
							<Typography variant="h5">{t('SpanishButton')}</Typography>
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			<div className="form-container-base-reviewScreen">
				<Typography gutterBottom className=" align-self-center" color="primary" variant="h4">
					{t('ReviewThanks')}
				</Typography>
				<img alt={helpers.getImageAltText('yrbs_logo')} src={images.yrbs_logo} height="200" width="400"/>
				<Button className={classes.submitButton} onClick={()=>navigateTo(firstQuestion)} color="secondary" variant="contained" fullWidth>
					{t('ReviewButton')}
				</Button>
				{unansweredQuestionsList}
				<Typography gutterBottom className=" align-self-center" color="primary">
					{t('ReviewScreenSubmitMsg')}
				</Typography>
				<Button value="Submit Survey Button" className={classes.submitButton} onClick={()=>submitSurvey()} color="primary" variant="contained" fullWidth>
					{t('SubmitButton')}
				</Button>
				<div className="margin-above-double" />
			</div>
		</div>
	);
};

export default SurveyReviewScreen;
