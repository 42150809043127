import React from "react";
import "./index.css";
import NavigationArrows from "../../components/NavigationArrows";
import QuestionDescriptor from "../../components/QuestionDescriptor";
import ResponseDescriptor from "../../components/ResponseDescriptor";
import SectionDescriptor from "../../components/SectionDescriptor";
import {Typography,makeStyles } from "@material-ui/core";
import { useState, useEffect } from "react";
import databaseService from "../../services/datbaseService";
import Button from "@material-ui/core/Button";
import {useTranslation} from"react-i18next";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import moment from "moment";
const useStyles = makeStyles({
		progressText: {
			fontStyle: "italic"
		},
		question:{
			overflow:"scroll"
		}
	}
)

export default function QuestionsScreen({setLanguage, startTime,endTime,question,isLoading, handleLanguage,activeSurveyResponse,survey, questionDescriptors, previous, next, responses, sectionColor,page,questionCount,isSection,setIsSurveyReview,setIsReview,isReview, isWeb, backupID,language,skipRules}) {
	console.log('res', responses)
	const {t} = useTranslation();
	const [completionStr,setCompletionStr] = useState("You have started the survey")
	useEffect(() => {
		let status = (page / questionCount) * 100
		setCompletionStr(` ${Math.floor(status)}%`)
	}, [page,questionCount])
	useEffect(() => {
		if(!isWeb) {
			databaseService.setSurveyForUpSync(survey);
		}
	}, [page])
	const classes = useStyles();
	const languageCode = language === 'English' ? 1 : 2
	return (
		<div className="screen-container">
			{/*{(!!startTime && !!endTime) && <Typography variant="h3">Question Load time: {(!!startTime && !!endTime) ? moment.duration(endTime.diff(startTime)).as('ms') : '---' }</Typography>}*/}
			<div className="flex-row full-width justify-space-between">
				<Typography className={`${classes.progressText}`} gutterBottom color="primary">
					{t('Progress')}:{completionStr}
				</Typography>
				<ToggleButtonGroup
					value={language}
					exclusive
					onChange={handleLanguage}
					aria-label="text alignment"
				>
					<ToggleButton value="English" aria-label="Select English Language Toggle Button" disabled={language==="English"}>
						<Typography variant="h5">{t('EnglishButton')}</Typography>
					</ToggleButton>
					<ToggleButton value="Spanish" aria-label="Select Spanish Language Toggle Button" disabled={language==="Spanish"}>
						<Typography variant="h5">{t('SpanishButton')}</Typography>
					</ToggleButton>
					</ToggleButtonGroup>
			</div>
			{/*
			uncomment for section headers 
			{
				question.sectionDescriptors.map(sec => (
					<SectionDescriptor isHelp sectionDescriptor={sec} sectionColor={sectionColor}/>
				))
			} */}
			<div className="margin-below-double"/>
			<div className="margin-below-double"/>
			<div className="flex-row full-width align-start">
				{
					questionDescriptors.map(des => {
						if(des.DescriptorCodeID !== 6 && des.LanguageCodeID === languageCode
						) {
							return (<div className="flex-row">
								<QuestionDescriptor descriptor={des} language={language}/> &nbsp;
							</div>)
						}
					})
				}
			</div>
			<div className="full-width flex-row justify-center">
				{
					questionDescriptors.map(des => {
						if(des.DescriptorCodeID === 6 && des.LanguageCodeID === languageCode) {
								return (<div className="margin-right-double">
									<QuestionDescriptor descriptor={des} language={language}/>
								</div>)

						}
					})
				}
			</div>
			{/*<div className="full-width flex-row justify-center">*/}
			{/*	{*/}
			{/*		question.sectionDescriptors.map(sec => {*/}
			{/*			if(sec.DescriptorCodeID === 6 && ((language === "English" && sec.LanguageCodeID ===1) || (language === "Spanish" && sec.LanguageCodeID ===2)) )*/}
			{/*			 {*/}
			{/*				return (<div className="margin-right-double">*/}
			{/*					<SectionDescriptor sectionDescriptor={sec} sectionColor={sectionColor} language={language}/>*/}
			{/*				</div>)*/}
			{/*			}*/}
			{/*		})*/}
			{/*	}*/}
			{/*</div>*/}
			{/*<div className="margin-below-double margin-below"/>*/}
			<ResponseDescriptor question={question} backupID={backupID} survey={survey} activeSurveyResponse={activeSurveyResponse}
								responses={responses} numberOfResponses={question.MaxNumberOfResponses} isWeb={isWeb}
								language={language} skipRules={skipRules}/>
			<div className="flex-grow"/>
			<NavigationArrows 
				isReview={isReview}
				setIsReview={ (review) => setIsReview(review)}
				onPrevious={previous}
				onNext={next}
				pageNumber={page}
				questionCount={questionCount}
				isSection={isSection}
				setIsSurveyReview={(review) => setIsSurveyReview(review)}
				isLoading={isLoading}
			/>
		</div>
	)
}
