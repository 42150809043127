import React, {createContext, useMemo, useContext, useState, useEffect, useCallback} from "react";
import { isMobile } from "react-device-detect"
import PageVisibility from 'react-page-visibility';
import ReactGA from 'react-ga4'
import {useReactPWAInstall} from "react-pwa-install";
import databaseService from "../../services/datbaseService";
import apiService from "../../services/apiService";
import useLocalStorage from "../useLocalStorage";
import useDatabase from "../useDatabase";
import LoadingIndicator from "../../components/LoadingIndicator";
import Message from "../../components/Message";
import {useHistory} from "react-router-dom";
import config from "../../config";
import APIMapper from "../../services/mappers/apiMapper";
import moment from "moment";
import { toast } from 'react-toastify'
import Typography from "@material-ui/core/Typography";
import SurveyTable from "../../database/Tables/Survey";
import SurveyResponseTable from "../../database/Tables/SurveyResponse";
import chunk from "lodash/chunk"
import UserSchoolClassTable from "../../database/Tables/UserSchoolClass";
import {uniqBy} from "lodash";
import SurveyArchiveTable from "../../database/Tables/SurveyArchive";
import SurveyResponseArchiveTable from "../../database/Tables/SurveyResponseArchive";


let optimizationProgress = 0


const AppContext = createContext({})

export function AppStateProvider ({children, config}) {
	const { isInstalled: isInstalledFunc } = useReactPWAInstall();
	const [isInstalled, setIsInstalled] = useState(isInstalledFunc());
	const [session, setSession, clearSession] = useLocalStorage(config.sessionKey, undefined);
	const [jwt, setJWT, clearJWT] = useLocalStorage(config.jwtKey, undefined)
	const [migration, setMigration, clearMigration] = useLocalStorage(config.migrationKey, undefined)
	const [lastApplicationSyncAttempted, setLastApplicationSyncAttempted, clearLastApplicationSyncAttempted] = useLocalStorage(config.syncAttemptKey, undefined);
	const [lastApplicationSyncSuccess, setLastApplicationSyncSuccess, clearLastApplicationSyncSuccess] = useLocalStorage(config.syncSuccessKey,undefined)
	const [usersSynced, setUsersSynced, clearUsersSynced] = useLocalStorage(config.userSyncKey,0);
	const [surveySynced, setSurveySynced, clearSurveySynced] = useLocalStorage(config.surveySyncKey,0);
	const [surveyResponsesSynced, setSurveyResponsesSynced, clearSurveyResponsesSynced] = useLocalStorage(config.surveyResponsesSyncKey, 0);
	const [userTotal, setUserTotal, clearUserTotal] = useLocalStorage(config.userSyncTotalKey,0);
	const [surveyTotal, setSurveyTotal, clearSurveyTotal] = useLocalStorage(config.surveySyncTotalKey,0);
	const [surveyResponseTotal, setSurveyResponseTotal, clearSurveyResponseTotal] = useLocalStorage(config.surveyResponsesSyncTotalKey,0);
	const [isStudent, setIsStudent] = useState(false);
	const [isOnline, setNetwork] = useState(window.navigator.onLine);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [lock, setLock] = useState(1);
	const [user, setUser] = useState(undefined);
	const [guid, setGUID] = useState(undefined);
	const [instrument, setInstrument] = useState(undefined);
	const [version, setVersion] = useState(undefined);
	const [questionTimingStart, setQuestionTimingStart] = useState(undefined)
	const [questionTimingEnd, setQuestionTimingEnd] = useState(undefined)
	const [isSurveyReady, setIsSurveyReady] = useState(false);
	const [surveyQuestions, setSurveyQuestions, clearSurveyQuestions] = useLocalStorage(config.surveyQuestions, "")
	const [questionsObj, setQuestionsObj] = useState(undefined)
	const [surveyError, setSurveyError] = useState(false)
	const [optimization, setOptimization, clearOptimization] = useLocalStorage(config.optimization, "")
	const [optimizationState, setOptimizationState] = useState(0)
	const [storage, setStorage] = useState(undefined)

	const history = useHistory();
	const getAndSetSurveyQuestions = async () => {
		const surveyQuestionObject = {}
		const start = moment()
		for(let i=1; i< 134; i++) {
			let newQuestion =  await databaseService.getQuestionsByPageID(i)
			newQuestion=newQuestion[0]
			const nextQuestionObj={};
			nextQuestionObj.question=newQuestion;

			const newSection = await databaseService.getSectionBySectionId(newQuestion.SectionID);
			const newSectionDescriptors = await databaseService.getSectionDescriptorsBySectionID(newQuestion.SectionID);
			newSectionDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
			if (newQuestion.ResponseRequired === 0) {
				nextQuestionObj.IsSection = true;
				nextQuestionObj.Section = newSection;
				nextQuestionObj.sectionDescriptors = newSectionDescriptors;
				nextQuestionObj.question = newQuestion
				nextQuestionObj.updateSectionColor = true;
			} else {
				newQuestion.section = newSection;
				newQuestion.sectionDescriptors = newSectionDescriptors;
				const newQuestionDescriptors = await databaseService.getQuestionDescriptorsByQuestionID(newQuestion.QuestionID);
				newQuestionDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
				const newResponses = await databaseService.getResponsesByQuestionID(newQuestion.QuestionID);
				newResponses.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);

				for (let i = 0; i < newResponses.length; i++) {
					const newResponseDescriptors = await databaseService.getResponseDescriptorsByResponseID(newResponses[i].ResponseID);

					newResponseDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
					newResponses[i].descriptors = newResponseDescriptors;
				}
				nextQuestionObj.question = newQuestion
				nextQuestionObj.questionDescriptors=newQuestionDescriptors;
				nextQuestionObj.responses = newResponses;
			}

			surveyQuestionObject[i] = nextQuestionObj
			setOptimizationState(i)
		}
		const end = moment()
		const duration = moment.duration(end.diff(start)).as('seconds')
		const optimizationObj = {
			date: moment().format('LLLL'),
			duration
		}

		setQuestionsObj(surveyQuestionObject)
		setSurveyQuestions(surveyQuestionObject)
		setOptimization(optimizationObj)
		setIsSurveyReady(true)
		toast.info(`Survey preparation complete. You can now begin taking surveys. Preparation took ${duration} seconds`, {
			toastId: "SurveysReady", // Prevent duplicate toasts
			position: "top-center",
			autoClose: false // Prevents toast from auto closing
		})
	}


	const navigate = useCallback( (route) => {
		history.replace(route);
	}, [])
	const updateNetwork = useCallback( async () => {
		setNetwork(window.navigator.onLine);
		if(window.navigator.onLine) {
			await checkSession();
		}
	}, [setNetwork]);

	const handleVisibilityChange = useCallback(async (isVisible) => {
		if(window.navigator.onLine) {
			await checkSession();
		}
		await updateLock(1);
	}, []);

	useEffect(() => {

		window.addEventListener("offline", updateNetwork);
		window.addEventListener("online", updateNetwork);
		return () => {
			window.removeEventListener("offline", updateNetwork);
			window.removeEventListener("online", updateNetwork);
		};
	}, [updateNetwork]);


	const { exists } = useDatabase();
	const isLocalHost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
	const checkSession = useCallback(async () => {
		if(isOnline) {
			try {
				await apiService.checkJWTAuth();
				return true
			} catch (err) {
				clearJWT()
				clearSession();
				await updateLock(1);
				return false
			}
		}
	}, [session,jwt, clearJWT, clearSession]);

	const downSync = useCallback(async (ApplicationSessionValue, UserID) => {
		const userData = await apiService.getUsersData(ApplicationSessionValue);
		await databaseService.upsertUserData(userData);
		let userSchoolClassResult = await apiService.getUserSchoolClassData(ApplicationSessionValue, UserID);
		await databaseService.upsertUserSchoolClassData(userSchoolClassResult);
		const questionHideResult = await apiService.getQuestionHideData(ApplicationSessionValue, UserID)
		await databaseService.upsertQuestionHideData(questionHideResult)
		let studentLoginResult = await apiService.getStudentLoginAccessCodesData(ApplicationSessionValue, UserID);
		await databaseService.upsertUserStudentLoginAccessCodesData(studentLoginResult);
		let questionSkipResult = await apiService.getQuestionSkipData(ApplicationSessionValue);
		await databaseService.upsertQuestionSkipData(questionSkipResult);
		const instrument = await databaseService.getInstrument()
		let successObject = await apiService.getSurveyMetadata(instrument);
		await databaseService.upsertSurveyMetaData(successObject);
	}, [])

	const upsync = useCallback(async (uid = guid) => {
		if(isOnline) {
			try {
				const attempt1 = moment().format('LLLL');
				setLastApplicationSyncAttempted(attempt1);
				const backup = await databaseService.createBackup()
				let users = await databaseService.getUsersForSync();
				let surveys = await databaseService.getSurveysForSync();
				let surveyResponses = []
				const apiUsers = users.map(user => APIMapper.userMapper(user))
				const apiSurveys = []
				let apiSurveyResponses = []

				for(let i=0;i<surveys.length;i++){
					const responses = await databaseService.getSurveyResponsesBySurveyID(surveys[i].SurveyID)
					surveyResponses = surveyResponses.concat(responses)
					const apiSurveyRes = responses.map(res => APIMapper.SurveyResponseMapper(res, surveys[i].SurveyGUID))
					 apiSurveyResponses = apiSurveyResponses.concat(apiSurveyRes);
					const surmapp = APIMapper.SurveyMapper(surveys[i], uid);
					apiSurveys[i]=surmapp
					// await databaseService.setSurveyToSynced(surveys[i].SurveyID);
				}

				setSurveyResponseTotal(surveyResponses.length)
				setUserTotal(users.length)
				setSurveyTotal(surveys.length)

				await apiService.upsyncUser(session,apiUsers);
				await apiService.sendSurveyData(session,apiSurveys);
				if(!!apiSurveyResponses && !!apiSurveyResponses.length) {
					const arrChunks = chunk(apiSurveyResponses, 100)
					for(let i =0; i < arrChunks.length; i++) {
						await apiService.sendSurveyResponseData(session, arrChunks[i]);
					}
				}


				users = users.map(user => {
					user.BackupID = backup
					return user
				})
				const surveyIds= []
				surveys = surveys.map(survey => {
					survey.BackupID = backup
					surveyIds.push(survey.SurveyID)
					return survey
				})

				const surveyResponseIds = []
				surveyResponses = surveyResponses.map(surveyResponse => {
					surveyResponse.BackupID = backup
					surveyResponseIds.push(surveyResponse.SurveyResponseID)
					return surveyResponse
				})

				await databaseService.updateUsersBulk(users)
				await databaseService.updateSurveysBulk(surveys)
				await databaseService.updateSurveyResponsesBulk(surveyResponses)
				await databaseService.setBackup(backup)
				if(surveys && surveys.length && surveyResponses && surveyResponses.length) {
					await SurveyArchiveTable.addBulk(surveys)
					await SurveyResponseArchiveTable.addBulk(surveyResponses)
					await SurveyTable.table.bulkDelete(surveyIds)
					await SurveyResponseTable.table.bulkDelete(surveyResponseIds)
				}

				setUsersSynced(apiUsers.length)
				setSurveySynced(apiSurveys.length)
				setSurveyResponsesSynced(surveyResponses.length)
				const attemptSuccess = moment().format('LLLL')
				setLastApplicationSyncSuccess(attemptSuccess)
				let percentage = 0;
				let total = 0;
				let synced = 0;
				// total += userTotal ? parseInt(userTotal) : 0;
				total += surveys.length;
				total += surveyResponses.length;

				// synced += usersSynced ? parseInt(usersSynced) : 0;
				synced += apiSurveys.length ;
				synced += surveyResponses.length;

				if(total > 0 && synced > 0) {
					percentage = ((synced / total) * 100.0).toFixed(2);
				} else {
					percentage =0;
				}
				toast.info(<Typography style={{fontSize:"small"}}>{`Sync Results`}<br/>{`Attempted: ${attempt1}
				`}<br/>{'Percentage:'}<b>{percentage}</b>{`%`}
				<br/>{`${apiSurveys.length} out ${surveys.length} surveys synced and archived
				`} <br/>{`${surveyResponses.length} out of ${surveyResponses.length} survey responses synced and archived.
			`}<br/>{`Successful: ${attemptSuccess}`}</Typography>, {
					toastId: "SurveysSynced", // Prevent duplicate toasts
					position: "top-center",
					autoClose: false // Prevents toast from auto closing
				});
			} catch (err) {
				toast.error('Syncing Surveys failed. Please try again manually', {
					toastId: "SurveysSyncFailure", // Prevent duplicate toasts
					position: "top-center",
					autoClose:false,  // Prevents toast from auto closing
				})
			}
		}
	}, [guid, session]);

	const refreshLock = useCallback(async () => {
		const lock = await databaseService.getLock();
		setLock(lock);
	}, [setLock])

	const updateLock = useCallback(async (lock) => {
		await databaseService.updateLock(lock);
		setIsStudent(false);
		setLock(lock);

	}, []);

	const refreshDefaultUser = useCallback(async () => {
		const user = await databaseService.getDefaultUser();
		setUser(user);
	}, [])

	const updateDefaultUser = useCallback(async(user) => {
		await databaseService.setDefaultUser(user);
		setUser(user);
	}, [setUser])

	useEffect(() => {
		(async () => {
			try {
				setIsError(false);
				setIsLoading(true);
				const doesExist = await exists()

				if(!doesExist) {
					await databaseService.seedDatabase();
				}

				const appGuid = await databaseService.getApplicationGUID();
				const instrument = await databaseService.getInstrument();
				const version = await databaseService.getApplicationVersion();

				if(version !== config.appVersion) {
					await databaseService.updateApplicationVersion(config.appVersion);
				}


				if(migration !== config.migrationVersion) {
					const usc = await UserSchoolClassTable.getAll()
					const filtered = uniqBy(usc, u => `${u.SchoolID}${u.ClassID}${u.UserID}`)
					await UserSchoolClassTable.deleteAll()
					await UserSchoolClassTable.addBulk(filtered)
					setMigration(config.migrationVersion)
				}

				if(isOnline) {
					const isValid = await checkSession();
					if(isValid) {
						await upsync(appGuid);
						const user = await databaseService.getDefaultUser();
						if(user && user !== 0 && user !== '0') {
							await downSync(session, user)
						}
					}
				}


				setVersion(config.appVersion);
				setGUID(appGuid);
				setInstrument(parseInt(instrument));
				await updateLock(1);
				await refreshDefaultUser();
				// if(isInstalled || isLocalHost) {
				// 	console.log(exists, 'existss')
				// 	if(!exists) {
				// 		await databaseService.seedDatabase();
				// 	}
				//
				// 	if(isOnline) {
				// 		await checkSession(session);
				// 	}
				//
				//
				// 	const appGuid = await databaseService.getApplicationGUID();
				// 	setGUID(appGuid);
				// 	await updateLock(1);
				// 	await refreshDefaultUser();
				// }
				setIsLoading(false);

				ReactGA.set({
					tabletID: appGuid
				})
			} catch(err) {
				console.log(err, 'E-WAY')
				setIsLoading(false);
				setIsError(true);
			}


		})()
	}, [])

	useEffect(() => {
		(async () => {
			if(session) {
				try {
					const questionsString = window.localStorage.getItem(config.surveyQuestions)
					const optimizationString = window.localStorage.getItem(config.optimization)
					if(!questionsString || !optimizationString) {
						await getAndSetSurveyQuestions()
					} else {
						const parsedQuestions = JSON.parse(questionsString)
						setQuestionsObj(parsedQuestions)
						setIsSurveyReady(true)
					}
				} catch(err) {
					//TODO: Still need to implement error handling and validation of object
					setSurveyError(true)
				}
			}
		})()
	}, [session])

	const getAndSetStorageQuota = useCallback(async () => {
		if (navigator.storage && navigator.storage.estimate) {
			const quota = await navigator.storage.estimate();
			const percentageUsed = (quota.usage / quota.quota) * 100;
			console.log(`You've used ${percentageUsed}% of the available storage.`);
			const remaining = quota.quota - quota.usage;
			console.log(`You can write up to ${remaining} more bytes.`);
			let persisted = await navigator.storage.persist();
			if(!persisted) {
				console.log('You do not have permission to persist data')
			} else {
				console.log('Data is successfully being persisted')
			}

			const storageObj = {
				...quota,
				persisted,
				percentageUsed,
			}
			setStorage(storageObj)

		}
	}, [setStorage])

	useEffect(() => {
		(async () => {
			await getAndSetStorageQuota()
		})()
	}, [])

	const percentageSynced= () => {
		let total = 0;
		let synced = 0;
		// total += userTotal ? parseInt(userTotal) : 0;
		total += surveyTotal ? parseInt(surveyTotal): 0;
		total += surveyResponseTotal ? parseInt(surveyResponseTotal): 0;

		// synced += usersSynced ? parseInt(usersSynced) : 0;
		synced += surveySynced ? parseInt(surveySynced) : 0;
		synced += surveyResponsesSynced ? parseInt(surveyResponsesSynced) : 0;

		if(total > 0 && synced > 0) {
			return ((synced / total) * 100.0).toFixed(2);
		} else {
			return 0.0;
		}
	}
	const memoizedValue = useMemo(
		() => ({
			jwt,
			setJWT,
			isMobile,
			isOnline,
			session,
			setSession,
			clearSession,
			isStudent,
			setIsStudent,
			isInstalled,
			isLocalHost,
			lock,
			setLock,
			updateLock,
			checkSession,
			upsync,
			downSync,
			user,
			setUser,
			refreshLock,
			navigate,
			updateDefaultUser,
			guid,
			instrument,
			setIsInstalled,
			version,
			lastApplicationSyncAttempted,
			lastApplicationSyncSuccess,
			userTotal,
			usersSynced,
			surveySynced,
			surveyTotal,
			surveyResponsesSynced,
			surveyResponseTotal,
			questionTimingStart,
			setQuestionTimingStart,
			questionTimingEnd,
			setQuestionTimingEnd,
			questionsObj,
			isSurveyReady,
			percentageSynced,
			optimization,
			storage,
			setStorage,
			getAndSetStorageQuota,
			isLocked: () => (lock && (lock === "1" || lock === 1))
		}),[storage, setStorage, getAndSetStorageQuota, optimization, isSurveyReady, questionsObj, questionTimingStart, setQuestionTimingStart,questionTimingEnd, setQuestionTimingEnd, jwt, setJWT, version, setIsInstalled, instrument, guid,  navigate, updateDefaultUser, checkSession, user, setUser, updateLock, isMobile,lock, setLock, isOnline, session, setSession, clearSession, isStudent, setIsStudent, isInstalledFunc]
	)

	if(isLoading) {
		return(
			<LoadingIndicator text={'Setting Up Application'}/>
		)
	}

	if(isError) {
		return (<Message title={'Application Setup Error'} error text={'There was an error setting up the application. Please close out the application and try again or refresh the webpage.'} />)
	}

	if(surveyError) {
		return (<Message title={'Survey Optimization Error'} error text={'There was an error optimizing the survey or loading the optimization. Please close out the application and try again or refresh the webpage.'}/>)

	}

	if(session && !isSurveyReady) {

		return <div className="full-height full-width flex-column justify-center align-center">
			<LoadingIndicator text={'Setting up Survey Optimization. Could take several minutes or more.'}/>
			<Typography>{optimizationState} out of 133 pages optimized</Typography>
		</div>
	}



	return (
		<AppContext.Provider value={memoizedValue}>
			<PageVisibility onChange={handleVisibilityChange}>
				{children}
			</PageVisibility>
		</AppContext.Provider>
	)
}

export default function useAppState() {
	const context = useContext(AppContext);
	if (context === undefined) {
		throw new Error('useAppState must be used within a AppStateProvider');
	}

	return context;
}



