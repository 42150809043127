import { db } from "../index";
import BaseTable from "./index";

const seedData = [
	{
		InstrumentTypeCodeID: 1,
		Description: "Unknown",
	},
	{
		InstrumentTypeCodeID: 2,
		Description: "Checklist - Training Related",
	},
	{
		InstrumentTypeCodeID: 3,
		Description: "Checklist - Staffing",
	},
	{
		InstrumentTypeCodeID: 4,
		Description: "Checklist - IRB",
	},
	{
		InstrumentTypeCodeID: 5,
		Description: "Checklist - Study Event Specific",
	},
	{
		InstrumentTypeCodeID: 6,
		Description: "Interview Guide",
	},
	{
		InstrumentTypeCodeID: 7,
		Description: "Checklist - Bio Specimen Data Collection",
	},
	{
		InstrumentTypeCodeID: 8,
		Description: "Checklist - Environmental Data Collection",
	},
	{
		InstrumentTypeCodeID: 9,
		Description: "Checklist - Neuro/Psychosocial Data Collection",
	},
	{
		InstrumentTypeCodeID: 10,
		Description: "Checklist - Physical Measures",
	},
];

class InstrumentTypeCode extends BaseTable {
	constructor() {
		super(db.instrumentTypeCode, seedData);
	}
}

const InstrumentTypeCodeTable = new InstrumentTypeCode();

export default InstrumentTypeCodeTable;
