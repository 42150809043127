import React, {useRef} from "react";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Button,  makeStyles } from "@material-ui/core";
import { useState, useEffect } from "react";
import debounce from "lodash/debounce"
import "./index.css";
import {useTranslation} from "react-i18next";

export default function NavigationArrows({isReview,isLoading, isFirst, isLast, onPrevious, onNext,pageNumber,questionCount,isSection,setIsSurveyReview}) {
	const [completionStr, setCompletionStr] = useState(`You have completed 0% of the survey`);
	const useStyles = makeStyles(() => ({
		submitButton: {
			marginTop: "10px",
			marginBottom: "20px",
		},
	}));
	const {t} = useTranslation()
	const navigateNext = useRef(debounce(onNext, 1000, {leading: true}))
	const next = (ev) => {
		ev.preventDefault()
		navigateNext.current();
	}
	const navigatePrevious = useRef(debounce(onPrevious, 1000, {leading: true}))
	const previous = (ev) => {
		ev.preventDefault();
		navigatePrevious.current()
	};
	const classes = useStyles();
	if(isReview){
		return (
			<div className="flex-row full-width justify-space-around align-center">
				<IconButton aria-label={'Navigate Previous button'} disabled={!!isFirst || isLoading} color="primary" onClick={previous}>
					<ChevronLeftIcon fontSize="large"/>
				</IconButton>
				<Button aria-label={'End review button'} className={classes.submitButton}  color="primary" variant="contained" onClick={()=>setIsSurveyReview(true)} fullWidth>
					{t('EndReviewButton')}
				</Button>
				<IconButton aria-label={'Navigate next button'} disabled={!!isLast || isLoading} color="primary" onClick={next}>
					<ChevronRightIcon fontSize="large"/>
				</IconButton>
			</div>
		)
	}  else {
		return (
			<div className="flex-row full-width justify-space-around align-center">
			<IconButton aria-label={'Navigate Previous button'} value={"Survey Navigate Previous Button"} disabled={!!isFirst} color="primary" onClick={onPrevious}>
				<ChevronLeftIcon fontSize="large"/>
			</IconButton>
			<IconButton aria-label={'Navigate next button'} value={"Survey Navigate Next Button"} disabled={!!isLast} color="primary" onClick={onNext}>
				<ChevronRightIcon fontSize="large"/>
			</IconButton>
		</div>
		)
	}
}
