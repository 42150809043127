import { db } from "../index";
import BaseTable from "./index";

class SurveyResponse extends BaseTable {
	constructor() {
		super(db.surveyResponse);
	}
}

const SurveyResponseTable = new SurveyResponse();

export default SurveyResponseTable;
