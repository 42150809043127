import { db } from "../index";
import BaseTable from "./index";

class SurveyArchive extends BaseTable {
	constructor() {
		super(db.surveyArchive);
	}
}

const SurveyArchiveTable = new SurveyArchive();

export default SurveyArchiveTable;
