import React, {useEffect, useState} from "react";
import "./index.css";
import useAppState from "../../hooks/useAppState";
import {makeStyles, TextField, Typography} from "@material-ui/core";
import {useFormik} from "formik";
import * as yup from 'yup'
import strings from '../../config/strings'
import LoadingIndicator from "../../components/LoadingIndicator";
import Message from "../../components/Message";
import apiService from "../../services/apiService";
import Button from "@material-ui/core/Button";
import databaseService from "../../services/datbaseService";
import {CREATE_PIN_ROUTE, SETTINGS_ROUTE} from "../../config/routes";
import {useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {Visibility} from "@material-ui/icons";
const validationSchema = yup.object({
	username: yup
		.string('Enter your username')
		.required('Username is required'),
	password: yup
		.string('Enter your password')
		.required('Password is required'),
});

const useStyles = makeStyles({
	root: {
		marginTop: '20px'
	}
})
export default function LoginScreen() {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);
	const [values, setValues] = useState({
		password: "",
		showPassword: true,
	});
	const classes = useStyles();
	const {
		setSession,
		updateLock,
		updateDefaultUser,
		guid,
		downSync,
		setJWT
	} = useAppState()
	const history = useHistory();

	const loginAndSetUpUser = async (values) => {
		try {
			setIsLoading(true);
			setError(false);
			const { user: {UserID, HashedPIN}, applicationSession: {ApplicationSessionValue}, token } = await apiService.authenticate(values.username, values.password, guid);
			setJWT(token)
			await downSync(ApplicationSessionValue, UserID);
			await updateDefaultUser(UserID);
			setSession(ApplicationSessionValue);
			if(!HashedPIN || HashedPIN === "") {
				history.replace(CREATE_PIN_ROUTE);
			}  else {
				history.replace(SETTINGS_ROUTE);
			}
			await updateLock(0);
		} catch (err) {
			setError(true);
			setIsLoading(false)
		}
	}
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const formik = useFormik({
		initialValues: {
			username: '',
			password: ''
		},
		validationSchema: validationSchema,
		onSubmit:loginAndSetUpUser
	})


	if(isLoading) {
		return (<LoadingIndicator text={'Registering User and Downloading User Application Information'}/>)
	}

	if(error) {
		return (
			<Message title={'Registration Error'} text={'There was an issue with your registration. Make sure your are using the correct username and password combination and connected to the internet'} action={() => setError(false)} actionText={'Try Again'} error/>
		)
	}

	return (
		<div className="screen-container align-center">
            <div className="form-container-base">
				<Typography gutterBottom className=" align-self-center" color="primary" variant="h4" >Data Collector Registration</Typography>
				<Typography gutterBottom>{strings.DATA_COLLECTOR_LOGIN_MESSAGE}</Typography>
				<form onSubmit = {formik.handleSubmit} className="margin-above-double">
					<TextField
						fullWidth
						id="username"
						name="username"
						label="Username"
						value={formik.values.username}
						onChange={formik.handleChange}
						error={formik.touched.username && Boolean(formik.errors.username)}
						helperText={formik.touched.username && formik.errors.username}
					/>
					<TextField
						fullWidth
						id="password"
						name="password"
						label="Password"
						type={values.showPassword ? "password" : "text"}
						value={formik.values.password}
						onChange={formik.handleChange}
						error={formik.touched.password && Boolean(formik.errors.password)}
						helperText={formik.touched.password && formik.errors.password}
						className="margin-below-double"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{values.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							)}

						}
					/>

					<Button classes={{root: classes.root}} className="margin-above-double" color="primary" variant="contained" fullWidth type="submit">
						Register
					</Button>
				</form>
			</div>
		</div>
	)
}
