import { db } from "../index";
import BaseTable from "./index";

class SectionDescriptor extends BaseTable {
	constructor() {
		super(db.sectionDescriptor);
	}
}

const SectionDescriptorTable = new SectionDescriptor();

export default SectionDescriptorTable;
