import React, {useEffect} from "react";
import "./index.css";
import NavigationArrows from "../../components/NavigationArrows";
import SectionDescriptor from "../../components/SectionDescriptor";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {useTranslation} from 'react-i18next'
import databaseService from "../../services/datbaseService";
import {Typography} from "@material-ui/core";

export default function SectionScreen({setLanguage,handleLanguage,isLoading, sectionColor, sectionDescriptors, pageID, next, previous, setIsSurveyReview, isSection, survey, isWeb,language}) {
	if(!sectionDescriptors || !sectionDescriptors.length) {
		setIsSurveyReview(true)
	}
	const {t} = useTranslation()
	useEffect(() => {
		if(!isWeb){
			databaseService.setSurveyForUpSync(survey);
		}
	}, [pageID])

	return (
	<div className="screen-container full-height align-self-center justify-center">
		<div className="flex-row full-width justify-end">
			<ToggleButtonGroup
				value={language}
				exclusive
				onChange={handleLanguage}
				aria-label="text alignment"
			>
				<ToggleButton value="English"  aria-label="Select English Language Toggle Button" disabled={language==="English"}>
					<Typography variant="h5">{t('EnglishButton')}</Typography>
				</ToggleButton>
				<ToggleButton value="Spanish" aria-label="Select Spanish Language Toggle Button" disabled={language==="Spanish"}>
					<Typography variant="h5">{t('SpanishButton')}</Typography>
				</ToggleButton>
			</ToggleButtonGroup>
		</div>
		<div className="flex-grow flex-column align-center justify-center">
			{
				sectionDescriptors.map((sec) => {
					if((language === "English" && sec.LanguageCodeID ===1) || (language === "Spanish" && sec.LanguageCodeID ===2) ) {
						return (
							<div>
								<SectionDescriptor isSection sectionColor={sectionColor} sectionDescriptor={sec}
												   language={language}/>
							</div>
						)
					}
				})
			}
		</div>
		<NavigationArrows isLoading={isLoading} onNext={next} onPrevious={previous} />

	</div>

	)
}
